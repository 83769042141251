import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCheckCircle,
  faClock,
  faExclamationTriangle,
} from '@rq-ratings/pro-regular-svg-icons'
import React from 'react'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import { Info } from 'react-feather'

import { PanelStatus, PendingPanelAction } from '../../types/misc'
import {
  CreatePanelFromReferralCodeRequest,
  CreatePanelWithAgreementsRequest,
} from '../../types/requests/panels'
import {
  CreatePanelFromReferralCodeResponse,
  CreatePanelWithAgreementsResponse,
  PanelCollection,
  PanelCollectionWithStats,
  PanelItem,
  PanelItemCompany,
  PanelPartnersCollection,
  PanelReferralApprovalsCollection,
  UpdatePanelResponse,
} from '../../types/responses/panels'
import {
  EMAIL_ADDRESSES,
  PANEL_STATUS,
  PENDING_PANEL_ACTION,
} from '../constants'
import { getTooltipClassName } from '../helpers/helperFunctions'
import apiService from './apiService'

export interface GetPanelsParams {
  companyId: ID
  otherCompanyId?: ID
  otherCompanyName?: string
  otherCompanyTypeId?: number
  status: PanelStatus
  page?: number
  pageSize?: number
  referralStats?: boolean
  performanceStats?: boolean
  teamMemberId?: number
  serviceAreaId?: number
  startDate?: string
  endDate?: string
  order?: `order[${GetPanelsOrderParam}]`
}

export type GetPanelsOrderParam =
  | 'referralsIn'
  | 'referralsOut'
  | 'income'
  | 'conversion.referralsIn'
  | 'conversion.referralsOut'
  | 'conversion.allReferrals'
  | 'nps'
  | 'response'

export interface GetPanelPartnersParams {
  companyName?: string
  page?: number
  pageSize?: number
}

class PanelService {
  private endpoint = '/v1/panels'

  async getPanels(params: GetPanelsParams): Promise<PanelCollection> {
    const response = await apiService.get(this.endpoint, {
      params,
    })

    return response.data
  }

  async getPanelsWithStats(
    params: GetPanelsParams,
  ): Promise<PanelCollectionWithStats> {
    const response = await apiService.get(this.endpoint, {
      params,
    })

    return response.data
  }

  async getPanelReferralApprovals(params: {
    companyName?: string
  }): Promise<PanelReferralApprovalsCollection> {
    const response = await apiService.get(
      `${this.endpoint}/referral-approvals`,
      {
        params,
      },
    )

    return response.data
  }

  async getPanelPartners(params: {
    companyName?: string
  }): Promise<PanelPartnersCollection> {
    const response = await apiService.get(`${this.endpoint}/partners`, {
      params,
    })

    return response.data
  }

  async getPanel(panelId: number): Promise<PanelItem> {
    const response = await apiService.get(`${this.endpoint}/${panelId}`)

    return response.data
  }

  async acceptPanelInvite(panelId: number): Promise<UpdatePanelResponse> {
    const response = await apiService.patch(`${this.endpoint}/${panelId}`, {
      status: PANEL_STATUS.approved,
    })

    return response.data
  }

  async declinePanelInvite(panelId: number): Promise<unknown> {
    const response = await apiService.patch(`${this.endpoint}/${panelId}`, {
      status: PANEL_STATUS.rejected,
    })

    return response.data
  }

  async createPanelFromInvitePage(
    request: CreatePanelFromReferralCodeRequest,
  ): Promise<CreatePanelFromReferralCodeResponse> {
    const response = await apiService.post(
      `${this.endpoint}/create-from-referral-code`,
      request,
    )
    return response.data
  }

  getPanelStatusStyles(options: GetPanelStatusStylesOptions): PanelStatusStyle {
    const { panelStatus, textType, pendingActions = [] } = options
    const DECLINED_TOOLTIP = `You cannot request this company as a professional relationship as it was previously rejected. Please contact us on ${EMAIL_ADDRESSES.rqHello} if you would like to attempt to add this company to your professional network again`

    const isLongTextType = textType === 'long'

    const isAwaitingResponseFromCurrentCompany = pendingActions.includes(
      PENDING_PANEL_ACTION.respondToInvite,
    )

    const longTextTypeRequestStatus = isAwaitingResponseFromCurrentCompany
      ? 'Respond to invite'
      : 'Professional network relationship request sent'

    const PANEL_STATUS_STYLES: Record<PanelStatus, PanelStatusStyle> = {
      approved: {
        text: isLongTextType ? 'In your professional network' : 'Approved',
        textClass: 'text-success',
        icon: (
          <FontAwesomeIcon
            icon={faCheckCircle}
            style={{ fontSize: '14px', marginRight: '6px' }}
          />
        ),
      },
      requested: {
        text: isLongTextType ? longTextTypeRequestStatus : 'Requested',
        textClass: 'text-warning',
        icon: (
          <FontAwesomeIcon
            icon={
              isAwaitingResponseFromCurrentCompany
                ? faExclamationTriangle
                : faClock
            }
            style={{ fontSize: '14px', marginRight: '6px' }}
          />
        ),
      },
      rejected: {
        text: isLongTextType
          ? 'Professional network relationship request declined'
          : 'Declined',
        textClass: 'text-secondary',
        icon: (
          <OverlayTrigger
            overlay={
              <Tooltip className={getTooltipClassName(DECLINED_TOOLTIP)}>
                {DECLINED_TOOLTIP}
              </Tooltip>
            }
            placement="bottom"
          >
            <Info size={16} style={{ marginRight: '5px' }} />
          </OverlayTrigger>
        ),
      },
    }

    return PANEL_STATUS_STYLES[panelStatus]
  }

  getOtherPanelCompany(
    panel: PanelItem,
    currentCompanyIri: IRI,
  ): PanelItemCompany {
    return currentCompanyIri === panel.fromCompany['@id']
      ? panel.toCompany
      : panel.fromCompany
  }

  async createPanelWithAgreements(
    request: CreatePanelWithAgreementsRequest,
  ): Promise<CreatePanelWithAgreementsResponse> {
    const response = await apiService.post(
      `${this.endpoint}/create-with-agreements`,
      request,
    )

    return response.data
  }

  async updateInternalRelationship(
    panelId: number,
    enableInternalRelationship: boolean,
  ): Promise<UpdatePanelResponse> {
    const response = await apiService.patch(
      `${this.endpoint}/${panelId}/internal-referral`,
      {
        internalRelationship: enableInternalRelationship,
      },
    )

    return response.data
  }
}

interface PanelStatusStyle {
  text: string
  textClass: string
  icon: React.ReactNode
}

interface GetPanelStatusStylesOptions {
  panelStatus: PanelStatus
  textType: 'short' | 'long'
  pendingActions?: PendingPanelAction[]
}

const panelService = new PanelService()

export default panelService
