import React from 'react'
import { Helmet } from 'react-helmet-async'
import { useMount } from 'react-use'

import useAppDispatch from '../../../hooks/useAppDispatch'
import commercialAgreementService from '../../../lib/services/commercialAgreementService'
import referralService from '../../../lib/services/referralService'
import FromCompanyLogoOrName from '../../../pages/referralESignatureConsent/components/FromCompanyLogoOrName'
import { CommercialAgreementTypeOption } from '../../../redux/slices/commercialAgreementsForm'
import { setIsSignable } from '../../../redux/slices/referralSignatureConsentPdf'
import { HydraMember } from '../../../types/api'
import {
  AddressEntity,
  CompanyEntity,
  ReferralEntity,
} from '../../../types/entities'
import { ESignatureDetails } from '../../../types/responses/referrals'
import ConfirmESignatureSubmissionModal from '../../modals/ConfirmESignatureSubmissionModal'
import ServiceFeesTable from '../ServiceFeesTable/ServiceFeesTable'
import { ServiceFeeDetail } from '../ServiceFeesTable/ServiceFeesTableRows'
import AddressBlock from './components/AddressBlock'
import CommissionJustificationText from './components/CommissionJustificationText'
import Footer from './components/Footer'
import PdfPage from './components/PdfPage'
import PdfSectionContainer from './components/PdfSectionContainer'
import SectionHeading from './components/SectionHeading'

interface Props {
  referral: ESignatureConsentPdfReferral
  serviceFees?: ServiceFeeDetail[]
  commissionJustification?: string // Use to override the commission justification
  onEditCommissionJustification?: (commissionJustification: string) => void
  agreementType?: CommercialAgreementTypeOption // Use to override the agreement type
  isPreviewLetter: boolean
  onSubmit?: () => unknown
  isSubmitting?: boolean
}

export type ESignatureConsentPdfReferral = Pick<
  ReferralEntity,
  'id' | 'era' | 'serviceAreaString'
> & {
  client: {
    fullName: string
    firstName: string
    clientId?: string | null
  }
  fromCompany: ESignatureConsentPdfReferralCompany
  toCompany: ESignatureConsentPdfReferralCompany
  referralDate: string
  eSignatureDetails: ESignatureDetails
}

export type ESignatureConsentPdfReferralCompany = Pick<
  CompanyEntity,
  'presentationName' | 'isRegulatedByIcaew' | 'isFcaFirm' | 'logoUrl'
> & {
  mainAddress: HydraMember<
    Pick<AddressEntity, 'street' | 'county' | 'city' | 'postcode'>
  > | null
}

const ReferralESignatureConsentPdf: React.FC<Props> = ({
  referral,
  serviceFees = [],
  commissionJustification,
  onEditCommissionJustification,
  agreementType,
  isPreviewLetter,
  onSubmit,
  isSubmitting = false,
}) => {
  const { client, fromCompany, toCompany } = referral
  const { commercialAgreement, complianceText, independenceStatus } =
    referral.eSignatureDetails
  const existingServiceFees = referral.eSignatureDetails.serviceFees
  const commercialAgreementType =
    agreementType || commercialAgreement?.commercialAgreementType

  const commissionJustificationText =
    commissionJustification || // Use previously inputted text if available
    referral.eSignatureDetails.commissionJustification // Otherwise, use default wording
  const dispatch = useAppDispatch()

  const shouldShowServiceFees =
    commercialAgreementService.shouldShowServiceFees(commercialAgreementType)
  const shouldShowCommissionJustification =
    !!commissionJustificationText &&
    referralService.shouldShowCommissionJustification(commercialAgreementType)

  useMount(() => {
    dispatch(setIsSignable(!isPreviewLetter))
  })

  function getServiceFees(): ServiceFeeDetail[] {
    // If there are existing service fees, use them
    if (existingServiceFees.length > 0) {
      return existingServiceFees.map((serviceFee) => ({
        ...serviceFee,
        duration: serviceFee.duration?.toString(),
      }))
    }

    return serviceFees
  }

  const shouldShowIcaewCompensationNote =
    fromCompany.isRegulatedByIcaew && referral.era

  const fromCompanyName = fromCompany.presentationName
  const toCompanyName = toCompany.presentationName

  return (
    <>
      <Helmet>
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link
          rel="preconnect"
          href="https://fonts.gstatic.com"
          crossOrigin="anonymous"
        />
        <link
          href="https://fonts.googleapis.com/css2?family=Allura&display=swap"
          rel="stylesheet"
        />
      </Helmet>

      <main className="overflow-auto">
        <Helmet
          htmlAttributes={{ class: 'pdf-report' }}
          bodyAttributes={{ class: 'bg-white A4' }}
        />

        <PdfPage>
          <PdfSectionContainer>
            <header className="d-flex gap-5 justify-content-between mb-3">
              <FromCompanyLogoOrName
                company={referral.fromCompany}
                className="mb-5"
              />
              <AddressBlock referral={referral} />
            </header>

            <div className="mb-4">
              <p>
                {client.fullName}

                {!!client.clientId && (
                  <>
                    <br />
                    {client.clientId}
                  </>
                )}
              </p>
            </div>

            <div className="mb-3">
              <p>
                Subject:{' '}
                <strong>
                  {referral.serviceAreaString || '[Advice area]'} - Introduction
                </strong>
              </p>
            </div>
          </PdfSectionContainer>

          <PdfSectionContainer>
            <p>Dear {client.firstName},</p>

            <p>
              You have requested advice concerning{' '}
              {referral.serviceAreaString || '[Advice area]'}.
            </p>

            <SectionHeading className="mt-3">Regulation</SectionHeading>

            {complianceText.map((text, index) => (
              <p key={index}>{text}</p>
            ))}

            <p>
              We would, therefore, like to introduce you to{' '}
              {toCompany.presentationName}
              {independenceStatus && ` who offers ${independenceStatus} advice`}
              .
              {referral.toCompany.isFcaFirm && (
                <>
                  {' '}
                  They will issue you with their own terms and conditions and
                  take responsibility for compliance with the requirements of
                  the Financial Services and Markets Act 2000.
                </>
              )}
            </p>
          </PdfSectionContainer>

          {shouldShowServiceFees && (
            <PdfSectionContainer>
              <SectionHeading>Commercial interest</SectionHeading>

              <p>
                We may receive an introductory fee. The following are examples
                of likely fees that may be received by us and the likely
                amounts. These are examples only and may not cover all receipts
                in the future:
              </p>

              <div className="mb-3">
                <ServiceFeesTable
                  agreementType={commercialAgreementType}
                  serviceFees={getServiceFees()}
                  isPreviewLetter={isPreviewLetter}
                />
              </div>

              <p>
                If in future we receive more than double the amount referenced
                in the above examples, we will obtain updated consent to retain
                these funds.
              </p>
            </PdfSectionContainer>
          )}

          {shouldShowCommissionJustification && (
            <CommissionJustificationText
              text={commissionJustificationText}
              onEdit={onEditCommissionJustification}
            />
          )}

          {shouldShowServiceFees && (
            <PdfSectionContainer>
              {referral.era ? (
                <p>
                  We will inform you when any fee is due to be received by us
                  and agree with you how this is to be dealt with at that time.
                  Please note: you have the right to require us to remit any
                  fees to you.
                </p>
              ) : (
                <p>
                  You consent to us retaining such amounts and we shall not be
                  required to account to you for them.
                </p>
              )}
            </PdfSectionContainer>
          )}

          <PdfSectionContainer>
            {commercialAgreementService.isRqPartnershipAgreement(
              commercialAgreementType,
            ) && (
              <>
                <SectionHeading>Commercial interest</SectionHeading>

                <p>
                  We do not receive any income from {toCompany.presentationName}{' '}
                  but benefit from the profit made by that firm.
                </p>
              </>
            )}

            {commercialAgreementService.isJointVentureAgreement(
              commercialAgreementType,
            ) && (
              <>
                <SectionHeading>Commercial interest</SectionHeading>

                <p>
                  {fromCompanyName} has a commercial interest in {toCompanyName}{' '}
                  in the form of a joint venture and whilst we do not receive
                  any income from {toCompanyName}, {fromCompanyName} does share
                  in the profits made by {toCompanyName}.
                </p>
              </>
            )}

            {shouldShowIcaewCompensationNote && (
              <p>
                In the unlikely event that we cannot meet our liabilities to
                you, you may be able to claim compensation under the Chartered
                Accountants’ Compensation Scheme in respect of exempt regulated
                activities undertaken. Further information about the scheme and
                the circumstances in which grants may be made is available on
                ICAEW's website:{' '}
                <a
                  href="https://icaew.com/cacs"
                  target="_blank"
                  rel="noreferrer"
                >
                  https://icaew.com/cacs
                </a>
                .
              </p>
            )}

            <SectionHeading>Your data and personal information</SectionHeading>

            <p>
              To facilitate this introduction, we would like your consent to
              securely share the necessary personal data you have provided to us
              with {toCompanyName}. This will enable them to contact you and
              provide the requested services.
            </p>

            <p style={{ marginBottom: '7px' }}>
              How your Information will be used:
            </p>

            <ul>
              <li>
                {fromCompanyName} will share only the information relevant to
                the introduction, including, e.g., your name, and contact
                details.
              </li>

              <li>
                {toCompanyName} will use this information solely for the purpose
                of providing the services outlined above and in compliance with
                applicable data protection laws.
              </li>
            </ul>

            <Footer referral={referral} />
          </PdfSectionContainer>
        </PdfPage>
      </main>

      <ConfirmESignatureSubmissionModal
        onSubmit={onSubmit}
        isSubmitting={isSubmitting}
      />
    </>
  )
}

export default ReferralESignatureConsentPdf
