import React from 'react'
import { Navigate } from 'react-router'

import { useAuth } from '../../../hooks/useAuth'
import { ROUTES } from '../../../lib/routes'
import CreateAccountModal from './components/ClaimReferralModal'
import Header from './components/Header'
import { useReferralInvitesQuery } from './hooks/useReferralInvitesQuery'

interface Props {
  children: React.ReactNode
}

const ReferralInviteLayout: React.FC<Props> = ({ children }) => {
  const { isAuthenticated, currentCompany, isClientUser } = useAuth()
  const shouldCompleteCompanySetup =
    isAuthenticated && !isClientUser && !currentCompany

  useReferralInvitesQuery()

  if (shouldCompleteCompanySetup) {
    return <Navigate to={ROUTES.addCompany} replace />
  }

  return (
    <>
      <Header />
      {children}
      <CreateAccountModal />
    </>
  )
}

export default ReferralInviteLayout
