import React from 'react'
import { matchPath } from 'react-router'

import { SidebarPage } from './Sidebar'
import SidebarNavList from './SidebarNavList'
import SidebarNavListItem from './SidebarNavListItem'

interface Props {
  depth: number
  page: SidebarPage
  items: JSX.Element[]
  currentRoute: string
}

const reduceChildRoutes = (props: Props) => {
  const { items, page, depth, currentRoute } = props

  if (page.children) {
    const open = page.href
      ? !!matchPath(
          {
            path: page.href,
            end: false,
          },
          currentRoute,
        )
      : false

    items.push(
      <SidebarNavListItem
        depth={depth}
        icon={page.icon}
        key={page.title}
        badge={page.badge}
        open={open}
        title={page.title}
        href={page.href}
        onClick={page.onClick}
        className={page.className}
        linkClassName={page.linkClassName}
      >
        <SidebarNavList depth={depth + 1} pages={page.children} />
      </SidebarNavListItem>,
    )
  } else {
    items.push(
      <SidebarNavListItem
        depth={depth}
        href={page.href}
        icon={page.icon}
        key={page.title}
        badge={page.badge}
        title={page.title}
        onClick={page.onClick}
        onMouseOver={page.onMouseOver}
        className={page.className}
        linkClassName={page.linkClassName}
      />,
    )
  }

  return items
}

export default reduceChildRoutes
