import React from 'react'

import ImageWithText from '../../components/ImageWithText'
import screenshotUrl from './screenshot.png'

const CompassScreen1: React.FC = () => (
  <ImageWithText
    imageUrl={screenshotUrl}
    text={
      <p>
        <strong>Compass</strong> is RQ's client diagnostic tool, designed to
        give you the tools and processes to collect insights on your client
        base. Uncover areas where clients can improve their financial health,
        and identify which clients may benefit from being referred to another
        professional.
      </p>
    }
  />
)

export default CompassScreen1
