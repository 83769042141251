import { useMutation } from '@tanstack/react-query'
import classNames from 'classnames'
import React from 'react'
import { useNavigate } from 'react-router'
import invariant from 'tiny-invariant'

import { useAuth } from '../../../../hooks/useAuth'
import useCurrentCompanyOrFail from '../../../../hooks/useCurrentCompanyOrFail'
import useNotyf from '../../../../hooks/useNotyf'
import { useParamOrFail } from '../../../../hooks/useParamOrFail'
import { LONG_MESSAGE_DURATION, REFERRALS_TAB } from '../../../../lib/constants'
import referralInviteService from '../../../../lib/services/referralInviteService'
import { REFERRAL_INVITES_PARAMS } from '../../../../pages/referralInvites/constants'
import { buildReferralsUrl } from '../../../../pages/referrals/helpers'
import ActionButton, { ActionButtonProps } from '../../../misc/ActionButton'
import { buildReferralSummaryLink } from '../../../misc/ReferralDetails/helpers'
import { useHasMultipleReferralInvites } from '../hooks/useHasMultipleReferralInvites'
import { useReferralInvites } from '../hooks/useReferralInvites'
import { useReferralsTerm } from '../hooks/useReferralsTerm'

type Props = Omit<ActionButtonProps, 'isProcessing'> & {
  companyIri: IRI
  children: React.ReactNode
}

const ClaimReferralButton: React.FC<Props> = ({
  companyIri,
  children,
  className,
  ...props
}) => {
  const currentCompany = useCurrentCompanyOrFail()
  const token = useParamOrFail(REFERRAL_INVITES_PARAMS.token)
  const notyf = useNotyf()
  const navigate = useNavigate()
  const referralsTerm = useReferralsTerm()
  const hasMultipleReferrals = useHasMultipleReferralInvites()
  const referralInvites = useReferralInvites()
  const hasSelectedDifferentCompany = currentCompany['@id'] !== companyIri
  const { updateCompanyContext } = useAuth()

  const claimReferralMutation = useMutation({
    mutationFn: () =>
      referralInviteService.claimReferralInvite({
        company: companyIri,
        token,
      }),

    onSuccess: async () => {
      const singleReferral = referralInvites['hydra:member'][0]
      invariant(singleReferral, 'Expected at least 1 referral to be set')

      const redirectUri = hasMultipleReferrals
        ? buildReferralsUrl({ tab: REFERRALS_TAB.received })
        : buildReferralSummaryLink(singleReferral.id)

      // If the user selected a different company, update the company context
      // and then redirect them to one of the referral pages
      if (hasSelectedDifferentCompany) {
        updateCompanyContext({ company: companyIri, redirectUri })
        return
      }

      notyf.success(
        `${hasMultipleReferrals ? 'Referrals' : 'Referral'} successfully claimed`,
      )

      navigate(redirectUri)
    },

    onError: () =>
      notyf.error({
        type: 'error',
        message: `Sorry but there was a problem claiming ${referralsTerm} and our team has been notified. Please try again later.`,
        duration: LONG_MESSAGE_DURATION,
      }),
  })

  return (
    <ActionButton
      isProcessing={claimReferralMutation.isPending}
      onClick={() => claimReferralMutation.mutate()}
      className={classNames('btn-fluid', className)}
      size="lg"
      {...props}
    >
      {children}
    </ActionButton>
  )
}

export default ClaimReferralButton
