import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { NylasSchedulerBookingParticipant } from '@nylas/core'
import { faPlus, faTimes } from '@rq-ratings/pro-solid-svg-icons'
import { Field, FieldArray, Formik, getIn } from 'formik'
import React, { useState } from 'react'
import { Button, Form, Modal } from 'react-bootstrap'
import * as yup from 'yup'

import Guest from './Guest'
import NylasScheduler, { BookingDetails } from './NylasScheduler'

interface Props {
  schedulerConfigId: string
  className?: string
  primaryParticipant: NylasSchedulerBookingParticipant
  onBookingComplete: (completedBooking: BookingDetails) => void
}

const MeetingScheduler: React.FC<Props> = ({
  schedulerConfigId,
  className,
  primaryParticipant,
  onBookingComplete,
}) => {
  const [guests, setGuests] = useState<NylasSchedulerBookingParticipant[]>([])
  const [finalPrimaryParticipant, setFinalPrimaryParticipant] =
    useState(primaryParticipant)
  const [showModal, setShowModal] = useState(false)

  return (
    <div className="d-flex flex-column gap-3 align-items-stretch">
      <div
        className="p-4 gap-3 mb-0 fs-4 d-flex flex-column align-items-start card border mx-auto"
        // I don't like this, but it's the only way to match the width of the Nylas widget
        style={{ width: '785px', maxWidth: '100%' }}
      >
        <header>
          <h3 className="mb-0 h2">
            <strong>Invitees</strong>
          </h3>
          <p className="mb-0 fs-5">Your calendar invite will be sent to:</p>
        </header>

        <ul
          className="p-0 mb-2 align-self-stretch"
          style={{
            display: 'grid',
            gridTemplateColumns:
              'repeat(auto-fill, minmax(min(16rem, 100%), 1fr))',
            gap: '1rem',
          }}
        >
          <Guest {...finalPrimaryParticipant} />

          {guests.map((guest, i) => (
            <Guest key={i} {...guest} />
          ))}
        </ul>

        <footer className="d-flex justify-content-end align-self-stretch">
          <Button onClick={() => setShowModal(true)} size="lg">
            Change
          </Button>
        </footer>

        <Modal show={showModal} centered onHide={() => setShowModal(false)}>
          <Modal.Header closeButton>
            <h2 className="mb-0">
              <strong>Calendar invite details</strong>
            </h2>
          </Modal.Header>

          <Formik
            initialValues={{
              name: finalPrimaryParticipant.name,
              email: finalPrimaryParticipant.email,
              guests,
            }}
            onSubmit={(values) => {
              setFinalPrimaryParticipant({
                name: values.name,
                email: values.email,
              })

              setGuests(values.guests)
              setShowModal(false)
            }}
            validationSchema={yup.object().shape({
              name: yup.string().required(),
              email: yup.string().email().required(),
              guests: yup.array().of(
                yup.object().shape({
                  name: yup.string().required(),
                  email: yup.string().email().required(),
                }),
              ),
            })}
          >
            {({ values, submitForm, errors, touched }) => (
              <Form>
                <Modal.Body>
                  <p>
                    Which email address would you like the calendar invite to be
                    sent to?
                  </p>

                  <div className="mb-3">
                    <label>Name</label>

                    <Field
                      type="text"
                      name="name"
                      value={values.name}
                      className="form-control form-control-lg"
                    />

                    {errors.name && (
                      <div className="text-danger">{errors.name}</div>
                    )}
                  </div>

                  <div className="mb-3">
                    <label>Email</label>

                    <Field
                      type="email"
                      name="email"
                      value={values.email}
                      className="form-control form-control-lg"
                    />

                    {errors.email && (
                      <div className="text-danger">{errors.email}</div>
                    )}
                  </div>

                  {values.guests.length > 0 && (
                    <h3 className="pt-3">Additional guests:</h3>
                  )}

                  <FieldArray name="guests">
                    {(arrayHelpers) => (
                      <div>
                        {values.guests.map((_value, i) => {
                          const nameError = getIn(errors, `guests[${i}].name`)
                          const emailError = getIn(errors, `guests[${i}].email`)

                          const nameTouched = getIn(
                            touched,
                            `guests[${i}].name`,
                          )

                          const emailTouched = getIn(
                            touched,
                            `guests[${i}].email`,
                          )

                          return (
                            <fieldset
                              key={i}
                              className="card border p-4 pop-in position-relative"
                            >
                              <Button
                                onClick={() => arrayHelpers.remove(i)}
                                variant="outline-secondary"
                                size="sm"
                                className="position-absolute top-0 end-0 p-2 border-0"
                              >
                                <FontAwesomeIcon
                                  icon={faTimes}
                                  className="fs-3 ratio ratio-1x1"
                                />
                              </Button>

                              <div className="mb-3 flex-1">
                                <label>Name</label>

                                <Field
                                  name={`guests.${i}.name`}
                                  type="text"
                                  className="form-control form-control-lg"
                                />

                                {nameTouched && nameError && (
                                  <div className="text-danger">{nameError}</div>
                                )}
                              </div>

                              <div>
                                <label>Email</label>

                                <Field
                                  name={`guests.${i}.email`}
                                  type="email"
                                  className="form-control form-control-lg"
                                />

                                {emailTouched && emailError && (
                                  <div className="text-danger">
                                    {emailError}
                                  </div>
                                )}
                              </div>
                            </fieldset>
                          )
                        })}

                        <div>
                          <Button
                            onClick={() =>
                              arrayHelpers.push({ name: '', email: '' })
                            }
                          >
                            <FontAwesomeIcon icon={faPlus} /> Add a guest
                          </Button>
                        </div>
                      </div>
                    )}
                  </FieldArray>
                </Modal.Body>

                <Modal.Footer className="sticky-bottom bg-white border-top">
                  <div className="d-flex justify-content-end">
                    <Button
                      size="lg"
                      onClick={() => submitForm()}
                      variant="success"
                    >
                      Save
                    </Button>
                  </div>
                </Modal.Footer>
              </Form>
            )}
          </Formik>
        </Modal>
      </div>

      <NylasScheduler
        schedulerConfigId={schedulerConfigId}
        className={className}
        primaryParticipant={finalPrimaryParticipant}
        guests={guests}
        onBookingComplete={onBookingComplete}
      />
    </div>
  )
}

export default MeetingScheduler
