import React, { Fragment } from 'react'

import { DATE_FORMATS } from '../../../../lib/constants'
import { formatDate } from '../../../../lib/helpers/helperFunctions'
import { ESignatureConsentPdfReferral } from '../ReferralESignatureConsentPdf'

interface Props {
  referral: ESignatureConsentPdfReferral
}

const AddressBlock: React.FC<Props> = ({ referral }) => {
  const fromCompany = referral.fromCompany
  const address = fromCompany.mainAddress

  const lines: string[] = [
    address?.street,
    address?.city,
    address?.county,
    address?.postcode,
  ].filter((line): line is string => !!line && !!line.trim())

  return (
    <div className="text-end" style={{ maxWidth: '320px' }}>
      {fromCompany.presentationName}
      <br />

      {lines.map((line, index) => (
        <Fragment key={index}>
          {line.trim()}
          <br />
        </Fragment>
      ))}

      <div className="mt-3">
        {formatDate(referral.referralDate, DATE_FORMATS.DAY_MONTH_YEAR)}
      </div>
    </div>
  )
}

export default AddressBlock
