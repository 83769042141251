import React from 'react'

import useCurrentCompanyOrFail from '../../../../hooks/useCurrentCompanyOrFail'
import { useIsMinWidthSm } from '../../../../hooks/useIsMinWidthSm'
import { EXISTING_AGREEMENT_MAX_FILE_SIZE_MB } from '../../../../lib/constants'
import { truncateText } from '../../../../lib/helpers/helperFunctions'
import { PanelItemCommercialAgreement } from '../../../../types/responses/panels'
import FilePickerInput from '../../../form/FilePickerInput'
import { REVIEW_COMMERCIAL_AGREEMENT_FORM_FIELDS } from '../constants'

interface Props {
  agreement?: PanelItemCommercialAgreement
  needsReview: boolean
}

const ExistingAgreements: React.FC<Props> = ({ agreement, needsReview }) => {
  const currentCompany = useCurrentCompanyOrFail()
  const files = agreement?.commercialAgreementFiles || []
  const isMinWidthSm = useIsMinWidthSm()

  const hasAlreadyUploadedFile = files.some(
    (file) => file.company.id === currentCompany.id,
  )

  const canUploadFile = needsReview && !hasAlreadyUploadedFile

  if (files.length === 0 && !canUploadFile) {
    return <span className="text-muted">None</span>
  }

  return (
    <section
      className=" d-flex flex-column gap-2 justify-content-end"
      style={{ maxWidth: isMinWidthSm ? '400px' : undefined }}
    >
      {files.map((file) => {
        return (
          <div className="d-flex flex-column" key={file.fileName}>
            <a href={file.fileUrl} target="_blank" rel="noreferrer">
              {truncateText(file.fileName, {
                maxLength: 50,
                truncateLength: 35,
              })}
            </a>

            <span className="text-muted text-xs" style={{ marginTop: '-3px' }}>
              (Added by {file.company.presentationName})
            </span>
          </div>
        )
      })}

      {canUploadFile && (
        <div className="d-flex flex-row justify-content-start justify-content-sm-end gap-1 w-100 w-sm-auto">
          <FilePickerInput
            name={REVIEW_COMMERCIAL_AGREEMENT_FORM_FIELDS.existingAgreementFile}
            buttonLabel="Upload existing agreement"
            size="sm"
            removeButtonClassName="text-sm"
            maxSizeMb={EXISTING_AGREEMENT_MAX_FILE_SIZE_MB}
          />
        </div>
      )}
    </section>
  )
}

export default ExistingAgreements
