import React from 'react'

import ImageWithText from '../../components/ImageWithText'
import screenshotUrl from './screenshot.png'

const ReferralEngineScreen1: React.FC = () => (
  <ImageWithText
    imageUrl={screenshotUrl}
    text={
      <p>
        <strong>Referrals</strong> is the home of your referral activity on RQ.
        From here you can access all your referral activity in one place, dig
        into individual referral cases, and access valuable management
        information on your referral activity by relationship, referred-for
        service, and professional type.
      </p>
    }
  />
)

export default ReferralEngineScreen1
