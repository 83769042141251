import invariant from 'tiny-invariant'

import {
  CreateReferralLinkRequest,
  GetReferralLinksParams,
  UpdateReferralLinkRequest,
} from '../../types/requests/referral-links'
import { ValidateReferralLinkClientDetailsRequest } from '../../types/requests/referrals'
import {
  ReferralLinkCollection,
  ReferralLinkItem,
  ReferralLinkItemService,
} from '../../types/responses/referral-links'
import apiService from './apiService'

class ReferralLinkService {
  private endpoint = '/v1/referral-links'

  async getReferralLinks(
    params: GetReferralLinksParams,
  ): Promise<ReferralLinkCollection> {
    const response = await apiService.get(this.endpoint, {
      params,
    })

    return response.data
  }

  async getReferralLinkByCode(
    referralLinkCode: string,
  ): Promise<ReferralLinkItem> {
    const response = await apiService.get(
      `${this.endpoint}/${referralLinkCode}`,
    )

    return response.data
  }

  async createReferralLink(request: CreateReferralLinkRequest) {
    const response = await apiService.post(this.endpoint, request)

    return response.data
  }

  async updateReferralLink(
    referralLinkCode: string,
    request: UpdateReferralLinkRequest,
  ): Promise<unknown> {
    const response = await apiService.patch(
      `${this.endpoint}/${referralLinkCode}`,
      request,
    )

    return response.data
  }

  async deleteReferralLink(referralLinkCode: string) {
    const response = await apiService.delete(
      `${this.endpoint}/${referralLinkCode}`,
    )

    return response.data
  }

  async validateClientDetails(
    referralLinkCode: string,
    request: ValidateReferralLinkClientDetailsRequest,
  ): Promise<unknown> {
    const response = await apiService.post(
      `${this.endpoint}/${referralLinkCode}/validate-client-details`,
      request,
    )

    return response.data
  }

  hasSelectedRestrictedService(
    referralLink: ReferralLinkItem,
    selectedServiceIris: IRI[],
  ): boolean {
    return this.getServicesFromServiceIris(
      referralLink,
      selectedServiceIris,
    ).some((service) => service.isRestricted)
  }

  getServicesFromServiceIris(
    referralLink: ReferralLinkItem,
    selectedServiceIris: IRI[],
  ): ReferralLinkItemService[] {
    return selectedServiceIris.map((serviceIri) => {
      const service = referralLink.toCompany.services.find(
        (service) => service['@id'] === serviceIri,
      )

      invariant(service, `Failed to find service by IRI: ${serviceIri}`)

      return service
    })
  }

  getServiceAreasFromServices(
    referralLink: ReferralLinkItem,
    selectedServiceIris: IRI[],
  ): ReferralLinkItemService['serviceArea'][] {
    return selectedServiceIris.map((serviceIri) => {
      const service = referralLink.toCompany.services.find(
        (service) => service['@id'] === serviceIri,
      )

      invariant(service, `Failed to find service by IRI: ${serviceIri}`)

      return service.serviceArea
    })
  }
}

const referralLinkService = new ReferralLinkService()

export default referralLinkService
