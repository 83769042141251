import { useMutation } from '@tanstack/react-query'
import debounce from 'lodash/debounce'
import React from 'react'
import { FormatOptionLabelMeta } from 'react-select'
import AsyncSelect from 'react-select/async'
import invariant from 'tiny-invariant'

import { buildMultiSelectStyles } from '../../lib/helpers/reactSelectHelpers'
import companyService from '../../lib/services/companyService'
import ReferToAdviserEmailPrompt from '../../pages/referClient/steps/1_SelectReferralMethod/components/ReferToAdviserEmailPrompt'
import { ReferralIndividual, SelectOption } from '../../types/misc'
import { GetReferralFirmIndividualsParams } from '../../types/requests/companies'
import { ReferralFirmIndividualsCollectionItem } from '../../types/responses/companies'
import ReactSelectDropdownIndicator from '../misc/ReactSelectDropdownIndicator'

interface Props {
  key?: string
  company: {
    id: number
    presentationName: string
  }
  selected: ReferralIndividual[]
  onSelect: (selected: ReferralIndividual[]) => void
  additionalParams?: Omit<GetReferralFirmIndividualsParams, 'fullName'>
  isDisabled?: boolean
}

const SelectCompanyIndividuals: React.FC<Props> = ({
  company,
  selected,
  onSelect,
  additionalParams,
  ...props
}) => {
  const searchIndividualsMutation = useMutation({
    mutationFn: (individualName: string) => {
      const params: GetReferralFirmIndividualsParams = {
        ...additionalParams,
      }

      if (individualName) {
        params.fullName = individualName
      }

      return companyService.getReferralFirmIndividuals(company.id, params)
    },
  })

  function loadOptions(
    individualName: string,
    callback: (options: SelectOption[]) => void,
  ) {
    searchIndividualsMutation.mutateAsync(individualName).then((response) => {
      const options = response['hydra:member'].map((result) =>
        makeOption(result),
      )
      return callback(options)
    })
  }

  const debouncedLoadOptions = debounce(loadOptions, 300)

  const referralFirmIndividualResults =
    searchIndividualsMutation.data?.['hydra:member'] || []

  const isLoading = searchIndividualsMutation.isPending

  const selectedOptions = selected
    ? selected.map((individual) => ({
        label: individual.fullName,
        value: individual.companyUserId.toString(),
      }))
    : null

  function handleChange(options: SelectOption[]) {
    const individuals = options.map((option): ReferralIndividual => {
      const individual = referralFirmIndividualResults.find(
        (individual) => individual.id === Number(option.value),
      )

      invariant(individual, `Individual with id ${option.value} not found`)

      return {
        userId: individual.user.id,
        companyUserId: individual.id,
        email: individual.user.email,
        fullName: individual.user.fullName,
      }
    })

    onSelect(individuals)
  }

  return (
    <AsyncSelect
      isLoading={isLoading}
      styles={buildMultiSelectStyles({})}
      isClearable
      isMulti
      cacheOptions
      defaultOptions
      formatOptionLabel={formatOptionLabel}
      loadOptions={debouncedLoadOptions}
      controlShouldRenderValue
      closeMenuOnSelect={false}
      hideSelectedOptions={false}
      components={{ DropdownIndicator: ReactSelectDropdownIndicator }}
      value={selectedOptions}
      onChange={(options) => handleChange(options as SelectOption[])}
      placeholder={isLoading ? 'Loading...' : 'Search individuals...'}
      noOptionsMessage={({ inputValue }) => {
        if (inputValue) {
          return (
            <>
              <p className="text-start mb-2 pt-1">
                No individuals found with the name '{inputValue}'
              </p>

              <ReferToAdviserEmailPrompt searchInput={inputValue} />
            </>
          )
        }

        return 'No individuals found'
      }}
      {...props}
    />
  )
}

function makeOption(
  referralFirmIndividual: ReferralFirmIndividualsCollectionItem,
): SelectOption {
  return {
    label: referralFirmIndividual.user.fullName,
    value: referralFirmIndividual.id.toString(),
  }
}

function formatOptionLabel(
  option: SelectOption,
  meta: FormatOptionLabelMeta<SelectOption>,
) {
  const { label, value } = option
  const { context, selectValue } = meta

  if (context !== 'menu') {
    return label
  }

  const isSelected = selectValue
    .map((selectedValue) => selectedValue.value)
    .includes(value)

  return (
    <div className="d-flex align-items-start flex-1">
      <input
        type="checkbox"
        className="form-check-input d-block"
        checked={isSelected}
        style={{ minWidth: '16px', minHeight: '16px', marginRight: '9px' }}
      />

      <div>{label}</div>
    </div>
  )
}

export default SelectCompanyIndividuals
