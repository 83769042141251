import autosize from 'autosize'
import { useField, useFormikContext } from 'formik'
import React, { useRef } from 'react'
import { Form } from 'react-bootstrap'
import { useMount } from 'react-use'

import FieldError from './FieldError'
import FieldLabel, { ModalHint } from './FieldLabel'

interface Props {
  children?: React.ReactNode
  name: string
  label?: React.ReactNode
  labelClass?: string
  labelSuffix?: string
  placeholder?: string
  rows?: number
  modalHint?: ModalHint
  autoFocus?: boolean
  isDisabled?: boolean
  className?: string
}

const TextareaInput: React.FC<Props> = ({
  name,
  label,
  labelClass,
  labelSuffix,
  placeholder,
  rows,
  modalHint,
  autoFocus,
  isDisabled,
  className,
}) => {
  const { submitCount } = useFormikContext()
  const [field, { error }] = useField(name)
  const id = `field_${name}`
  const showError = !!(submitCount > 0 && error)
  const textareaRef = useRef<HTMLTextAreaElement | null>(null)

  useMount(() => {
    if (textareaRef.current) {
      autosize(textareaRef.current)
    }
  })

  return (
    <>
      {label && (
        <FieldLabel htmlFor={id} modalHint={modalHint} className={labelClass}>
          {label}
        </FieldLabel>
      )}

      {labelSuffix && <div className="text-sm mb-2">{labelSuffix}</div>}

      <Form.Control
        as="textarea"
        id={id}
        data-field={id}
        autoFocus={autoFocus}
        disabled={isDisabled}
        placeholder={placeholder}
        rows={rows}
        isInvalid={showError}
        ref={textareaRef}
        className={className}
        {...field}
      />

      {showError && <FieldError inputId={id}>{error}</FieldError>}
    </>
  )
}

TextareaInput.defaultProps = {
  rows: 3,
}

export default TextareaInput
