import { useMutation } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { parseISO } from 'date-fns/parseISO'
import invariant from 'tiny-invariant'

import useAppDispatch from '../../../hooks/useAppDispatch'
import {
  DEFAULT_BULK_IMPORT_CIRCUMSTANCES,
  DEFAULT_BULK_IMPORT_REASON,
  REFERRAL_TYPE,
} from '../../../lib/constants'
import { isNotProduction } from '../../../lib/helpers/envHelpers'
import {
  getErrorDebugInfo,
  isConstraintViolationListError,
} from '../../../lib/helpers/helperFunctions'
import clientService from '../../../lib/services/clientService'
import constraintViolationService from '../../../lib/services/constraintViolationService'
import referralService from '../../../lib/services/referralService'
import { ReferralsImportItem } from '../../../redux/slices/referralsImportBase'
import {
  updateImportItem,
  updateImportItemStatus,
} from '../../../redux/slices/referralsImportHistorical'
import { FormErrors } from '../../../types/misc'
import { CreateClientForReferralRequest } from '../../../types/requests/clients'
import { CreateHistoricalReferralRequest } from '../../../types/requests/referrals'

export function useImportHistoricalReferralMutation() {
  const dispatch = useAppDispatch()

  return useMutation({
    // retry mutation up to 4 times with linear backoff delay (last delay 3000ms, total time 7.5s)
    retry: 4,
    retryDelay: (attempt) => attempt * 750,
    mutationFn: async (importItem: ReferralsImportItem) => {
      dispatch(
        updateImportItemStatus({
          refUUID: importItem.refUUID,
          importStatus: 'processing',
        }),
      )
      // create user client if missing
      // autogeneratedClientEmail is only set when source email is not present
      const newClientEmail =
        importItem.autogeneratedClientEmail || importItem.sourceClientEmail
      // - userCompanyClient is needed in all cases
      let userCompanyClient = importItem.userCompanyClient
      if (userCompanyClient === null) {
        // create client if missing
        userCompanyClient =
          await clientService.createClientForReferralOrFindExisting(
            buildCreateClientRequest(
              importItem.sourceClientFirstName,
              importItem.sourceClientLastName,
              newClientEmail,
              importItem.sourceClientTelephone,
              importItem.clientDateOfBirth || undefined,
            ),
            importItem.userCompany.id,
          )
        // update import data state
        dispatch(
          updateImportItem({
            refUUID: importItem.refUUID,
            userCompanyClient: userCompanyClient,
          }),
        )
      } else {
        // eventually update existing client
        if (importItem.onClientDuplicate === 'update') {
          await clientService.updateClientForReferral(
            userCompanyClient.id,
            buildCreateClientRequest(
              importItem.sourceClientFirstName,
              importItem.sourceClientLastName,
              importItem.sourceClientEmail,
              importItem.sourceClientTelephone,
              importItem.clientDateOfBirth || undefined,
            ),
          )
        }
      }
      // import with resolved clients
      const request = buildCreateReferralRequest({
        ...importItem,
        userCompanyClient: userCompanyClient,
      })
      return referralService.createReferral(request)
    },
    onError: async (error, importItem) => {
      let formattedErrors: FormErrors | undefined
      if (isConstraintViolationListError(error)) {
        formattedErrors = constraintViolationService.formatErrors(error)
      } else {
        if (error instanceof AxiosError) {
          formattedErrors = {
            error: error.response?.data?.detail ?? error.message,
          }
        } else if (error.message) {
          formattedErrors = {
            error: error.message.replace('Invariant failed: ', ''),
          }
        } else {
          formattedErrors = {
            error: 'An error occurred',
          }
        }
        if (isNotProduction()) {
          formattedErrors.debug = JSON.stringify(getErrorDebugInfo(error))
        }
      }
      dispatch(
        updateImportItemStatus({
          refUUID: importItem.refUUID,
          importStatus: 'error',
          importErrors: formattedErrors,
        }),
      )
    },
    onSuccess: async (_data, importItem) => {
      dispatch(
        updateImportItemStatus({
          refUUID: importItem.refUUID,
          importStatus: 'imported',
          importErrors: undefined,
        }),
      )
    },
  })
}

function buildCreateClientRequest(
  firstName: string,
  lastName: string,
  email: string,
  telephone: string,
  dateOfBirth?: string,
): CreateClientForReferralRequest {
  return {
    firstName: firstName,
    lastName: lastName,
    email: email,
    telephone: telephone,
    dateOfBirth:
      dateOfBirth && !isNaN(parseISO(dateOfBirth).getTime())
        ? dateOfBirth
        : undefined,
    clientId: '',
  }
}

function buildCreateReferralRequest(
  importItem: ReferralsImportItem,
): CreateHistoricalReferralRequest {
  invariant(importItem?.userCompanyClient?.id, 'Expected client to be created')
  invariant(importItem.serviceArea, 'Expected service area to be set')

  const commonFields = {
    circumstances:
      importItem.circumstances ?? DEFAULT_BULK_IMPORT_CIRCUMSTANCES,
    reason: DEFAULT_BULK_IMPORT_REASON,
    type: REFERRAL_TYPE.indirect, // Direct will often be blocked by logic set out in API getAvailableReferralTypes()
    serviceAreaIds: [importItem.serviceArea?.id ?? 0],
    hasClientConsent: false,
    isImport: true,
    isHistoricalReferral: true as const,
    isEraOverride: importItem.isEraOverride,
    importStatusCode: importItem.status ?? undefined,
    referralDate: importItem.referralDate ?? undefined,
    skipInitialAdvisorInvite: !importItem.inviteAdvisor,
    pausedClientEmails: importItem.pausedClientEmails,
    asMultipleInvites: importItem.multipleInvites,
  }

  if (importItem.otherCompany) {
    // regular referrals
    if (importItem.importDirection === 'FROM') {
      // userCompany received referral from other company
      return {
        ...commonFields,
        fromCompany: `/v1/companies/${importItem.otherCompany.id}`,
        fromCompanyUsers: importItem.otherCompanyUser
          ? [`/v1/users/${importItem.otherCompanyUser.id}`]
          : [],
        fromClient: importItem.otherCompanyClient
          ? importItem.otherCompanyClient['@id']
          : undefined,
        toCompany: `/v1/companies/${importItem.userCompany.id}`,
        toCompanyUsers: [`/v1/users/${importItem.userCompanyUser.id}`],
        toClient: importItem.userCompanyClient['@id'],
        referralInvites: [],
      }
    } else {
      // userCompany send referral to other company
      return {
        ...commonFields,
        fromCompany: `/v1/companies/${importItem.userCompany.id}`,
        fromCompanyUsers: [],
        fromClient: importItem.userCompanyClient['@id'],
        toCompany: `/v1/companies/${importItem.otherCompany.id}`,
        toCompanyUsers: importItem.otherCompanyUser
          ? [`/v1/users/${importItem.otherCompanyUser.id}`]
          : [],
        referralInvites: [],
      }
    }
  } else {
    // invites
    if (importItem.importDirection === 'FROM') {
      // userCompany received referral from other company
      return {
        ...commonFields,
        fromCompany: undefined,
        fromCompanyUsers: [],
        fromClient: undefined,
        toCompany: `/v1/companies/${importItem.userCompany.id}`,
        toCompanyUsers: [`/v1/users/${importItem.userCompanyUser.id}`],
        toClient: importItem.userCompanyClient['@id'],
        referralInvites: [
          {
            email: importItem.advisorEmail,
          },
        ],
      }
    } else {
      // userCompany send referral to other company
      return {
        ...commonFields,
        fromCompany: `/v1/companies/${importItem.userCompany.id}`,
        fromCompanyUsers: [],
        fromClient: importItem.userCompanyClient['@id'],
        toCompany: undefined,
        toCompanyUsers: [],
        referralInvites: [
          {
            email: importItem.advisorEmail,
          },
        ],
      }
    }
  }
}
