import React from 'react'

import { DirectoryFcaEntity } from '../../../../../types/entities'
import { CompanyProfileItemCompany } from '../../../../../types/responses/companies'
import FcaServices from './components/FcaServices'
import FcaServicesAndExpertiseAreas from './components/FcaServicesAndExpertiseAreas'

interface Props {
  companyServices: CompanyProfileItemCompany['services']
  expertiseAreas: CompanyProfileItemCompany['expertiseAreas']
  fcaServices: DirectoryFcaEntity['services']
}

const ServicesAndExpertise: React.FC<Props> = ({
  companyServices,
  expertiseAreas,
  fcaServices,
}) => {
  if (companyServices.length > 0 || expertiseAreas.length > 0) {
    return (
      <FcaServicesAndExpertiseAreas
        companyServices={companyServices}
        expertiseAreas={expertiseAreas}
      />
    )
  }

  return <FcaServices services={fcaServices} />
}

export default ServicesAndExpertise
