import classNames from 'classnames'
import React, { CSSProperties } from 'react'
import Skeleton from 'react-loading-skeleton'

interface Props {
  className?: string
  style?: CSSProperties
  wrapperProps?: React.HTMLAttributes<HTMLDivElement>
}

const SkeletonBox: React.FC<Props> = ({ className, style, wrapperProps }) => {
  return (
    <div
      {...wrapperProps}
      className={classNames('flex-grow-1 w-100', wrapperProps?.className)}
    >
      <Skeleton
        className={classNames(className)}
        style={{ height: '200px', ...style }}
      />
    </div>
  )
}

export default SkeletonBox
