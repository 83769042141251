import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft, faArrowRight } from '@rq-ratings/pro-solid-svg-icons'
import React from 'react'
import { Button } from 'react-bootstrap'

import {
  ReferralsImportItem,
  ReferralsImportItemPartial,
} from '../../../redux/slices/referralsImportBase'

interface Props {
  importItem: ReferralsImportItem
  updateImportItem: (item: ReferralsImportItemPartial) => void
}

const ImportDirectionCell: React.FC<Props> = ({
  importItem,
  updateImportItem,
}) => {
  const handleSwap = () => {
    if (importItem.importDirection == 'TO') {
      updateImportItem({ refUUID: importItem.refUUID, importDirection: 'FROM' })
    } else {
      updateImportItem({ refUUID: importItem.refUUID, importDirection: 'TO' })
    }
  }

  return (
    <>
      <span onClick={() => handleSwap()} className="cursor-pointer d-block">
        <FontAwesomeIcon
          icon={importItem.importDirection == 'TO' ? faArrowRight : faArrowLeft}
          className={`fa-2x ${importItem.importDirection == 'TO' ? 'text-info' : ''}`}
        />
        <br />
        <span className="text-muted text-xs">
          {importItem.importDirection == 'TO' ? 'Sent' : 'Received'}
        </span>
      </span>
      <span className="show-on-row-hover">
        {' '}
        <Button
          variant="link"
          size="sm"
          className="text-xs p-0 m-0"
          onClick={() => handleSwap()}
        >
          change
        </Button>
      </span>
    </>
  )
}

export default ImportDirectionCell
