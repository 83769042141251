import React from 'react'
import { Modal, Offcanvas } from 'react-bootstrap'

import useAppDispatch from '../../../hooks/useAppDispatch'
import useAppSelector from '../../../hooks/useAppSelector'
import { useIsMinWidthMd } from '../../../hooks/useIsMinWidthMd'
import { COMMERCIAL_AGREEMENT_REFERRAL_DIRECTION } from '../../../lib/constants'
import {
  hideCommercialAgreementsModal,
  selectCommercialAgreementReferralDirection,
  selectCommercialAgreementsModalPanelId,
} from '../../../redux/slices/commercialAgreementsModal'
import SkeletonBox from '../../skeleton/SkeletonBox'
import DirectionAgreements from './components/DirectionAgreements'
import { useCommercialAgreementsQuery } from './hooks/useCommercialAgreementsQuery'

export interface CommercialAgreementDetailsProps {
  onConfirmAgreement?: () => Promise<void>
  onClose?: () => Promise<void>
  header?: string
  subText?: React.ReactNode
  showDirectionDescription?: boolean
  variant: 'modal' | 'inline' | 'offcanvas'
}

const CommercialAgreementsDetails: React.FC<
  CommercialAgreementDetailsProps
> = ({
  onConfirmAgreement,
  onClose = async () => null,
  header = 'Review commercial agreements',
  // subText = 'Please review the following commercial agreement(s).',
  variant,
}) => {
  const isMinWidthMd = useIsMinWidthMd()
  const referralDirection = useAppSelector(
    selectCommercialAgreementReferralDirection,
  )
  const panelId =
    useAppSelector(selectCommercialAgreementsModalPanelId) ?? undefined
  const dispatch = useAppDispatch()
  const commercialAgreementsQuery = useCommercialAgreementsQuery(panelId)

  if (!panelId || !referralDirection) {
    return null
  }

  const isLoading = !commercialAgreementsQuery.data

  function renderModalTitle() {
    return <>{isLoading ? 'Commercial agreements' : header}</>
  }

  function renderModalBody() {
    if (isLoading) {
      return <SkeletonBox style={{ height: '52vh' }} />
    }

    const { panel } = commercialAgreementsQuery.data

    return (
      <div className="d-flex flex-column gap-5">
        {[
          COMMERCIAL_AGREEMENT_REFERRAL_DIRECTION.OUTGOING,
          COMMERCIAL_AGREEMENT_REFERRAL_DIRECTION.INCOMING,
        ].map((direction) => (
          <DirectionAgreements
            key={direction}
            panel={panel}
            direction={direction}
            onConfirmAgreement={onConfirmAgreement}
            variant={variant}
          />
        ))}
      </div>
    )
  }

  function hideModal() {
    dispatch(hideCommercialAgreementsModal())
  }

  async function handleClose() {
    await onClose()
    hideModal()
  }

  if (variant === 'inline') {
    return <>{renderModalBody()}</>
  }

  if (variant === 'offcanvas') {
    return (
      <Offcanvas
        show
        placement="end"
        onHide={handleClose}
        style={{ width: isMinWidthMd ? '880px' : '100%' }}
      >
        <Offcanvas.Header
          onHide={onClose}
          className="border-bottom pt-3 d-flex justify-content-between align-items-center"
          closeButton={!!onClose}
        >
          <Offcanvas.Title
            className="fw-bolder text-black pe-3"
            style={{ fontSize: '17px' }}
          >
            {renderModalTitle()}
          </Offcanvas.Title>
        </Offcanvas.Header>

        <Offcanvas.Body className="pb-5">
          <>{renderModalBody()}</>
        </Offcanvas.Body>
      </Offcanvas>
    )
  }

  return (
    <Modal show onHide={handleClose} aria-label={header} size="lg">
      <Modal.Header closeButton>
        <Modal.Title className="mb-0">
          <h3 className="text-black mb-0">{renderModalTitle()}</h3>
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>{renderModalBody()}</Modal.Body>
    </Modal>
  )
}

export default CommercialAgreementsDetails
