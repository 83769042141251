import { AxiosError } from 'axios'
import { KeycloakTokenParsed } from 'keycloak-js'
import { SetRequired } from 'type-fest'

import { TooltipHint } from '../components/form/FieldLabel'
import { HydraMember } from './api'
import { RoleEntity, UserEntity } from './entities'

export type ConsentType = 'ICAEW'
export type ConsentDecisionType = 'OPT_IN' | 'OPT_OUT'

export type CompanyTypeCode =
  | IfaCompanyTypeCode
  | AccountantCompanyTypeCode
  | SolicitorCompanyTypeCode
  | MortgageAdviserCompanyTypeCode
  | BusinessLendingNonFCACompanyTypeCode
  | InsuranceAdviserCompanyTypeCode
  | CorporateFinanceCompanyTypeCode
  | CorporateFinanceNonFCACompanyTypeCode
  | InvestmentManagementTypeCode
  | 'auto_enrolment'
  | 'business_coach'
  | 'conveyancing'
  | 'employee_benefits_provider'
  | 'estates_admin'
  | 'estate_planner'
  | 'estates_agent'
  | 'management_consultant'
  | 'marketing_consultant'
  | 'property_developer'
  | 'software_provider'
  | 'surveyor'
  | 'venture_capital'
  | 'other'

export type IfaCompanyTypeCode = 'ifa'
export type MortgageAdviserCompanyTypeCode = 'mortgage_adviser'
export type BusinessLendingNonFCACompanyTypeCode = 'business_lending_non_fca'
export type InsuranceAdviserCompanyTypeCode = 'insurance_adviser'
export type AccountantCompanyTypeCode = 'accountant'
export type SolicitorCompanyTypeCode = 'solicitor'
export type CorporateFinanceCompanyTypeCode = 'corporate_finance'
export type CorporateFinanceNonFCACompanyTypeCode = 'corporate_finance_non_fca'
export type InvestmentManagementTypeCode = 'investment_management'

export type CommercialAgreementType = 'NO' | 'YFS' | 'YJV' | 'RQP'
export type CommercialAgreementDirectionType = 'FROM_TO' | 'TO_FROM'
export type CommercialAgreementReferralDirection = 'OUTGOING' | 'INCOMING'

export type CompassReportStatus = 'INVITED' | 'IN_PROGRESS' | 'COMPLETE'
export type CompassReportPriorityType = 'HIGH' | 'MEDIUM' | 'LOW'
export type CompassPdfReportSection = '1' | '2' | '3' | '4'

export type CompanyEmailType = 'COMPASS_INVITE'
export type CompanyIdentifierType = 'companyId' | 'frn' | 'sraNumber'
export type CompanyStatus = 'SUSPENDED' | 'CLAIMED'
export type CompassActionImpact = 'high' | 'medium' | 'low'

export type IntegrationService =
  | 'INTELLIFLO'
  | 'NYLAS'
  | 'NYLAS_EMAIL'
  | 'NYLAS_CALENDAR'
  | 'TESTING'

export const INTEGRATION_SERVICE = {
  intelliflo: 'INTELLIFLO',
  nylas: 'NYLAS',
  nylasEmail: 'NYLAS_EMAIL',
  nylasCalendar: 'NYLAS_CALENDAR',
  testing: 'TESTING',
} satisfies Record<string, IntegrationService>

export interface FcaMonitoring {
  flag: boolean | null
  code: string
  message: string
  lastUpdated: string | null
}

export type JwtPayload = KeycloakTokenParsed & {
  rq?: {
    register_funnel?: 'company' | 'compass'
  }
}

export type ObjectMap<T> = Record<keyof T, keyof T>

export type PanelStatus = 'requested' | 'approved' | 'rejected'
export type PanelReferralStats = {
  sent: number
  received: number
  income: number
}
export type PanelPerformanceStats = {
  conversionReferralsOut: number | null
  conversionReferralsIn: number | null
  conversionAllReferrals: number | null
  nps: number | null
  responseTime: number | null
}

export type PanelRequestApprovalStatus = 'pending' | 'approved' | 'rejected'
export type PanelRequestType = 'existing_company' | 'new_company'

export type PaymentDisplayType = 'BLOCK' | 'BANNER'

export type PaymentStatus = 'PENDING' | 'CONFIRMED'

export type PaymentType = 'DIRECT_DEBIT_MANDATE' | 'ONE_OFF_PAYMENT'

export type PendingPanelAction =
  | 'addCommercialAgreement'
  | 'chaseCurrentAgreementReview'
  | 'reviewCurrentAgreement'
  | 'chasePendingAgreementReview'
  | 'reviewPendingAgreement'
  | 'respondToInvite'
  | 'reviewCurrentIncomingAgreementConsentLetter'
  | 'reviewPendingIncomingAgreementConsentLetter'

export type ReviewType = 'written' | 'phone_interview'

export type ReferralStatusCode =
  | 'awaiting_client_consent'
  | 'awaiting_client_meeting'
  | 'awaiting_client_schedule_meeting'
  | 'complete'
  | 'initial_meeting_held'
  | 'initial_meeting_scheduled'
  | 'live'
  | 'won_completed'
  | 'not_progressing'
  | 'unable_contact_client'
  | 'awaiting_client_response'
  | 'client_details_passed_to_pco'

export type ReferralApprovalStatus = 'approved' | 'pending' | 'rejected'

export type ReferralType = 'indirect' | 'direct'

export type ReferralTypeOption =
  | 'indirect'
  | 'direct'
  | 'directCc'
  | 'directPco'
  | 'bookCallForClient'

export type ReferralNoteType =
  | 'referral_created'
  | 'referral_updated'
  | 'referral_imported'
  | 'referral_approved'
  | 'referral_rejected'
  | 'request_client_introduction'
  | 'request_client_fee_consent'
  | 'request_client_bank_details'
  | 'request_client_introduction_consent'
  | 'nothing_to_rebate'
  | 'previously_provided_bank_details'

export type NormalReferralTypeOption = Exclude<
  ReferralTypeOption,
  'bookCallForClient'
>

export type ReferralCommmissionConsent = 'CONSENT' | 'DO_NOT_CONSENT'
export type ReferralCommmissionClientConsentStatus =
  | 'waiting_client_consent'
  | 'waiting_client_bank_details'
  | 'complete'

export type ReferralCommissionRqStatus =
  | 'rq_send_invoice'
  | 'rq_invoice_sent'
  | 'rq_invoice_paid'
  | 'complete'

export type RecommendedPartnerActionType =
  | 'DISMISS'
  | 'ACCEPT_WITH_TERMS'
  | 'ACCEPT_WITHOUT_TERMS'

export interface ReferralCommissionExpectedCommission {
  hasCommercialAgreement?: boolean
  lowerExpectedCommissionRange: number | null
  upperExpectedCommissionRange: number | null
  provisionalRetainAmount: number | null
  provisionalRebateAmount: number | null
}

export interface FcaService {
  category: string
  permissions: string[]
}

export type FeeSharingType = 'PERCENTAGE' | 'SET_AMOUNT'

export type FeePayBackType = 'percentage' | 'set_amount'

export type RqPartnershipType = 'percentage' | 'set_amount' | 'none'

export type RqCertifiedAssessmentStatus =
  | 'NOT_STARTED'
  | 'PENDING_PAYMENT_CONFIRMATION'
  | 'IN_PROGRESS'
  | 'IN_REVIEW'
  | 'PASSED'
  | 'FAILED'

export type RqCertifiedAssessmentStage = 'one' | 'two'

export enum MutationIconState {
  ok,
  mutating,
  success,
  error,
}

export interface MutationIconProps {
  state: MutationIconState
  message: string
}

export type FormErrors = Record<string, string>

export type TypedFormErrors<Values> = {
  [K in keyof Values]?: string
}

export interface SelectOption<TValue = string> {
  label: string
  value: TValue
  tooltipHint?: TooltipHint
  isDisabled?: boolean
}

export type StateValue = string | number | boolean | number[] | null

export type StateKey =
  | 'watched_demo_video'
  | 'interacted_with_rq_certified_announcement'
  | 'interacted_with_rq_roadmap_banner'
  | 'interacted_with_walkthrough_banner'
  | 'interacted_with_first_relationship_request_approved_modal'
  | 'interacted_with_intelliflo_integration_autosave_notice'
  | 'client_reviewed_partners'
  | 'panel_approved_new'
  | 'panel_approved_updated'
  | 'panel_requested_new'
  | 'panel_requested_updated'
  | 'panel_request_pending'
  | 'referral_from_new'
  | 'referral_from_updated'
  | 'referral_to_new'
  | 'referral_to_updated'

export type StateType = 'user' | 'company' | 'companyUser'

export const USER_TYPE = {
  client: 'CLIENT',
  company: 'COMPANY',
  icaew: 'ICAEW',
} satisfies Record<string, UserType>

export type UserType = 'ICAEW' | 'CLIENT' | 'COMPANY'

export type FcaType =
  | 'appointed_representative'
  | 'directly_authorised'
  | 'trading_name'

export type DoughnutChartSize = 'sm' | 'md'

export type NonNullableAxiosError<T> = SetRequired<AxiosError<T>, 'response'>

export interface SerializableFile {
  name: string
  url: string
  base64: string
}

export type ReferralIndividual = Pick<UserEntity, 'email' | 'fullName'> & {
  userId: number
  companyUserId: number
}

export type CompanyRegister = 'FCA' | 'CompaniesHouse' | 'SRA'

export type PosthogAppArea =
  | 'compass'
  | 'referral'
  | 'walkthrough'
  | 'relationship-management'
  | 'add-company'
  | 'complete-registration-modal'

export interface NylasCompanyConfig {
  schedulerConfigId: string
  roundRobinAdministratorId?: number
  companyEmail?: {
    email?: string
    grantId?: string
  }
}

export interface NylasEmailConfig {
  grantId: string
  email: string
}

export interface NylasCalendar {
  id: string
  name: string
}

export type NylasProvider =
  | 'google'
  | 'microsoft'
  | 'imap'
  | 'icloud'
  | 'yahoo'
  | 'ews'
  | 'zoom'

export interface NylasCalendarConfig {
  grantId: string
  email: string
  schedulerConfigId: string
  calendars: NylasCalendar[]
  isOwnerForCompanyConfig: boolean
  provider: NylasProvider
}

export interface IntellifloConfig {
  adviserMapping: IntellifloAdviserMapping
  leadStatusMapping: IntellifloLeadStatusMapping
  feeStatusMapping: IntellifloFeeStatusMapping
  documentMapping: IntellifloDocumentMapping
  import: {
    campaign: IntellifloCampaignMapping
    leadImport: {
      top: number | null
      skip: number | null
      lastId: number | null
    } | null
    clientImport: {
      top: number | null
      skip: number | null
      lastId: number | null
    } | null
  }
  defaultedSectionsPendingConfirmation: {
    [key in IntellifloConfigSection]?: boolean
  }
  enableImportMapping?: boolean
}

export type IntellifloConfigSection = keyof Omit<
  IntellifloConfig,
  'defaultedSectionsPendingConfirmation' | 'enableImportMapping'
>

export interface IntellifloAdviserMapping {
  [companyId: number]: IntellifloCompanyAdviserMapping
}

export interface IntellifloCompanyAdviserMapping {
  [rqUserId: number]: number
}

export type IntellifloLeadStatusMapping = IntellifloStatus[]

export interface IntellifloFeeStatusMapping {
  [rqFeeType: string]: string
}

export interface IntellifloDocumentMapping {
  compassReport: {
    category: string
    subcategory: string
  }

  circumstances: {
    category: string
    subcategory: string
  }
}

export interface IntellifloCampaignMapping {
  [companyId: number]: IntellifloCompanyCampaignMapping[]
}

export interface IntellifloCompanyCampaignMapping {
  type: string
  source: string
}

export type IntellifloStatusType = 'lead' | 'client'

export type IntellifloStatus = {
  rq_status: ReferralStatusCode
  io_status: string
  io_status_type: IntellifloStatusType
  can_convert: boolean
  order: number
}

export type LockedClientDetailsReason =
  | 'AWAITING_CLIENT_CONSENT'
  | 'AWAITING_CLIENT_CALLBACK'
  | 'INCOMPLETE_COMMERCIAL_AGREEMENT'
  | 'UNEMBEDDABLE_SCHEDULE_CALL_URL'

export interface UserCompanyRole {
  companyId: number
  roles: Array<HydraMember<Pick<RoleEntity, 'id' | 'name'>>>
}

export type ReferralLinkScheduler = 'NYLAS' | 'CALENDLY'

export type ReferralDirectionCommercialAgreementStatus =
  | 'CURRENT_AGREEMENT_AWAITING_REVIEW_BY_US'
  | 'CURRENT_AGREEMENT_AWAITING_REVIEW_BY_THEM'
  | 'PENDING_AGREEMENT_AWAITING_REVIEW_BY_US'
  | 'PENDING_AGREEMENT_AWAITING_REVIEW_BY_THEM'
  | 'COMPLETE'

export type CommercialAgreementStatus = 'CURRENT' | 'PENDING'

export type CommercialAgreementReviewStatus =
  | 'AWAITING_REVIEW_BY_US'
  | 'AWAITING_REVIEW_BY_THEM'
  | 'COMPLETE'

export type ModifierKey = 'altKey' | 'ctrlKey' | 'metaKey' | 'shiftKey'
