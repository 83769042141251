import { useIsRoute } from '../../../../hooks/useIsRoute'
import { ROUTES } from '../../../../lib/routes'

export function useShouldShowAdditionalCompanyInfo() {
  const isRoute = useIsRoute()
  const isCompanyProfilePage = isRoute(ROUTES.companyProfile)
  const isAddRelationshipPage = isRoute(ROUTES.addRelationship)

  return isCompanyProfilePage || isAddRelationshipPage
}
