import React from 'react'
import { Container } from 'react-bootstrap'
import { Link } from 'react-router'
import { useMount } from 'react-use'

import { useCaptureErrorShownToUserEvent } from '../../../../hooks/useCaptureErrorShownToUserEvent'
import { currentPath } from '../../../../lib/helpers/routeHelpers'
import { ROUTES } from '../../../../lib/routes'
import InvalidOrExpiredCard from '../../../misc/InvalidOrExpiredCard'
import RqSupportMailLink from '../../../misc/RqSupportMailLink'

interface Props {
  error: unknown
}

const ReferralInviteErrorMessage: React.FC<Props> = ({ error }) => {
  const captureErrorShownToUserEvent = useCaptureErrorShownToUserEvent()

  useMount(() => {
    console.error(error)
    captureErrorShownToUserEvent()
  })

  return (
    <div className="mx-auto mw-md mt-6 pb-5">
      <Container>
        <InvalidOrExpiredCard
          className="mw-md"
          showCta={false}
          title="Problem loading page"
          content={
            <>
              <p className="mb-2">
                There was a problem loading this page. Please try one of the
                following:
              </p>

              <ul>
                <li>
                  If you're previously registered via this invite link, please{' '}
                  <Link to={ROUTES.signIn}>login</Link> to view your referrals.
                </li>

                <li>Refresh the page and try again.</li>

                <li>
                  If the issue persists, please note that our team has been
                  notified and will work to resolve the issue as soon as
                  possible. You can also contact us at{' '}
                  <RqSupportMailLink
                    subject={`Page not working at ${window.origin}${currentPath()}`}
                  />
                  .
                </li>
              </ul>
            </>
          }
        />
      </Container>
    </div>
  )
}

export default ReferralInviteErrorMessage
