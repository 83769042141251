import classNames from 'classnames'
import React from 'react'

import LoadingOverlay from '../../widgets/LoadingOverlay'

type Props = React.HTMLAttributes<HTMLDivElement> & {
  isLoading: boolean
}

const DataTableContainer: React.FC<Props> = ({
  isLoading,
  className,
  children,
  ...props
}) => (
  <div
    className={classNames('position-relative overflow-auto', className)}
    {...props}
  >
    <LoadingOverlay isActive={isLoading} showSpinner={false}>
      {children}
    </LoadingOverlay>
  </div>
)

export default DataTableContainer
