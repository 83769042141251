import React from 'react'
import { Button, Modal, OverlayTrigger, Tooltip } from 'react-bootstrap'

import useAppDispatch from '../../hooks/useAppDispatch'
import useAppSelector from '../../hooks/useAppSelector'
import SubmitESignatureButton from '../../pages/referralESignatureConsent/components/SubmitESignatureButton'
import { YELLOW_BUTTON_PROPS } from '../../pages/referralESignatureConsent/constants'
import {
  selectHasConfirmedESignatureUnderstanding,
  selectIsShowingSubmitModal,
  setIsShowingSubmitModal,
} from '../../redux/slices/referralSignatureConsentPdf'
import ESignatureUnderstandingCheckbox from '../misc/ReferralESignatureLetterPdf/components/ESignatureUnderstandingCheckbox'

interface Props {
  onSubmit?: () => void
  isSubmitting?: boolean
  ctaText?: string
}

const ConfirmESignatureSubmissionModal: React.FC<Props> = ({
  onSubmit,
  isSubmitting = false,
  ctaText = 'Submit',
}) => {
  const isShowingSubmitModal = useAppSelector(selectIsShowingSubmitModal)
  const hasConfirmedESignatureUnderstanding = useAppSelector(
    selectHasConfirmedESignatureUnderstanding,
  )

  const dispatch = useAppDispatch()

  function onHide() {
    dispatch(setIsShowingSubmitModal(false))
  }

  if (!isShowingSubmitModal || !onSubmit) {
    return null
  }

  return (
    <Modal onHide={onHide} show>
      <Modal.Header closeButton>
        <Modal.Title>Almost there</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <div
          className="d-flex align-items-start gap-2 mb-3 p-3 alert alert-info"
          css={{
            input: {
              marginTop: '5px',
            },
          }}
        >
          <ESignatureUnderstandingCheckbox
            isSubmittingSignature={isSubmitting}
          />
        </div>

        <p className="mb-0">
          Almost there: Press <em>{ctaText}</em> to proceed.
        </p>
      </Modal.Body>

      <Modal.Footer className="pt-0">
        <OverlayTrigger
          overlay={
            !hasConfirmedESignatureUnderstanding ? (
              <Tooltip className="tooltip-md">
                Please tick the checkbox above to confirm you understand that a
                electronic signature is as valid and legally binding as a
                handwritten signature.
              </Tooltip>
            ) : (
              <div />
            )
          }
        >
          <div className="btn-fluid">
            <SubmitESignatureButton
              {...YELLOW_BUTTON_PROPS}
              onSubmitConsent={onSubmit}
              isProcessing={isSubmitting}
              isProcessingText="Submitting"
              disabled={!hasConfirmedESignatureUnderstanding}
            >
              {ctaText}
            </SubmitESignatureButton>
          </div>
        </OverlayTrigger>

        <Button
          variant="outline-secondary"
          className="btn-fluid order-sm-first"
          onClick={onHide}
          size="lg"
          disabled={isSubmitting}
        >
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default ConfirmESignatureSubmissionModal
