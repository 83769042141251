import React from 'react'

import ImageWithText from '../../components/ImageWithText'
import screenshotUrl from './screenshot.png'

const ReferralEngineScreen3: React.FC = () => (
  <ImageWithText
    imageUrl={screenshotUrl}
    text={
      <p>
        When you send or receive a referral, a referral case is created. This
        provides the high-level information you need on each referral, and a
        space for you to collaborate with the other adviser on the client’s
        behalf, with messages, documents, and status updates.
      </p>
    }
  />
)

export default ReferralEngineScreen3
