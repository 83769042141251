import classNames from 'classnames'
import React from 'react'

import CardHeading from '../../../typography/CardHeading'

type Props = React.HTMLAttributes<HTMLDivElement> & {
  children: React.ReactNode
}

const TabTitle: React.FC<Props> = ({ className, ...props }) => (
  <CardHeading className={classNames('mt-2 mb-3', className)} {...props} />
)

export default TabTitle
