import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faCheckCircle } from '@rq-ratings/pro-solid-svg-icons'
import classNames from 'classnames'
import React, { useEffect } from 'react'
import { Button, Form, Modal } from 'react-bootstrap'
import { Link } from 'react-router'
import { useMount } from 'react-use'

import useAppDispatch from '../../../hooks/useAppDispatch'
import useAppSelector from '../../../hooks/useAppSelector'
import {
  PAYMENTS_PROCESSING_REFRESH_INTERVAL,
  PAYMENTS_RELOAD_REFRESH_INTERVAL,
} from '../../../lib/constants'
import {
  clearRefreshTimeout,
  dismissPayment,
  selectPayments,
  setIsOutdated,
  setIsProcessing,
  setNextBanner,
  setNextBlock,
  setPrevBanner,
  setPrevBlock,
  setRefreshDelay,
  setRefreshTimeout,
} from '../../../redux/slices/payments'
import ActionButton from '../ActionButton'
import { useLoadPendingPayments } from './hooks/useLoadPendingPayments'
import { useRefreshCurrentPayments } from './hooks/useRefreshCurrentPayments'

const Payments: React.FC = () => {
  const paymentsState = useAppSelector(selectPayments)
  const loadPendingPaymentMutation = useLoadPendingPayments()
  const refreshCurrentPaymentMutation = useRefreshCurrentPayments()
  const dispatch = useAppDispatch()
  // Keep track of the outstanding payments reported in the header (useRef needed for the event handler)
  const outstandingPayments = React.useRef<number>(0)

  const loadPendingPayments = React.useCallback(() => {
    // Load all pending payments
    // If there are any pending refresh (set paymentsState.isOutdated) in PAYMENTS_RELOAD_REFRESH_INTERVAL
    loadPendingPaymentMutation.mutate(undefined, {
      onSuccess: (data) => {
        const items = data['hydra:member']
        if (
          items.length > 0 &&
          items.some((payment) => payment.status === 'PENDING')
        ) {
          dispatch(setRefreshDelay(PAYMENTS_RELOAD_REFRESH_INTERVAL))
          dispatch(
            setRefreshTimeout(
              setTimeout(() => {
                dispatch(setIsOutdated(true))
              }, PAYMENTS_RELOAD_REFRESH_INTERVAL),
            ),
          )
        }
      },
    })
  }, [loadPendingPaymentMutation, dispatch])

  const onOutstandingPayments = React.useCallback(
    (event: Event) => {
      // Handle the outstanding payments header event
      if (event instanceof CustomEvent) {
        const serverOutstandingPayments = Number(event.detail)
        if (serverOutstandingPayments > outstandingPayments.current) {
          // If the outstanding payments have increased, reload the pending payments
          loadPendingPayments()
        }
        outstandingPayments.current = serverOutstandingPayments
      }
    },
    [loadPendingPayments, outstandingPayments],
  )

  useMount(() => {
    // Initialize payments
    loadPendingPayments()
    // Register event listener to keep track of outstanding payments in the header
    window.addEventListener('outstandingPayments', onOutstandingPayments)
    return () => {
      // Unregister event listener
      window.removeEventListener('outstandingPayments', onOutstandingPayments)
    }
  })

  useEffect(() => {
    // Keep track of paymentsState.isOutdated state
    // If true then refresh all currently loaded payments
    // If any is still pending, refresh (set paymentsState.isOutdated) again in predefined time
    if (paymentsState.isOutdated) {
      dispatch(setIsOutdated(false))
      refreshCurrentPaymentMutation.mutate(paymentsState, {
        onSuccess: (data) => {
          const items = data['hydra:member']
          if (
            items.length > 0 &&
            items.some((payment) => payment.status === 'PENDING')
          ) {
            dispatch(
              setRefreshTimeout(
                setTimeout(() => {
                  dispatch(setIsOutdated(true))
                }, paymentsState.refreshDelay),
              ),
            )
          } else {
            dispatch(clearRefreshTimeout())
          }
        },
      })
    }
  }, [
    paymentsState.isOutdated,
    paymentsState.refreshDelay,
    dispatch,
    paymentsState,
    refreshCurrentPaymentMutation,
  ])

  const startProcessing = (paymentId: number) => {
    // Start processing payment, set refresh interval and trigger outdated
    dispatch(setIsProcessing({ paymentId, isProcessing: true }))
    dispatch(setRefreshDelay(PAYMENTS_PROCESSING_REFRESH_INTERVAL))
    dispatch(
      setRefreshTimeout(
        setTimeout(() => {
          dispatch(setIsOutdated(true))
        }, PAYMENTS_PROCESSING_REFRESH_INTERVAL),
      ),
    )
  }

  return (
    <>
      <PaymentsBanner startProcessing={startProcessing} />
      <PaymentsBlock startProcessing={startProcessing} />
    </>
  )
}

function PaymentsBanner({
  startProcessing,
}: {
  startProcessing: (paymentId: number) => void
}) {
  const paymentsState = useAppSelector(selectPayments)
  const dispatch = useAppDispatch()

  if (paymentsState.banner.total > 0 && paymentsState.banner.currentId) {
    const currentPayment = paymentsState.payments.find(
      (payment) => payment.id === paymentsState.banner.currentId,
    )
    const currentPage = paymentsState.banner.ids.indexOf(
      paymentsState.banner.currentId,
    )

    if (currentPayment) {
      let ctaText, bannerMessage
      if (currentPayment.type == 'DIRECT_DEBIT_MANDATE') {
        bannerMessage =
          'To keep enjoying RQ, you’ll need to set up your subscription'
        ctaText = 'Set up'
      } else {
        bannerMessage = 'To keep enjoying RQ, you’ll need to make payment'
        ctaText =
          'Pay ' +
          currentPayment.total.toLocaleString('en-GB', {
            style: 'currency',
            currency: 'GBP',
            minimumFractionDigits: 0,
          })
      }

      if (currentPayment.description) {
        bannerMessage = currentPayment.description
      }

      if (currentPayment.status == 'CONFIRMED') {
        bannerMessage =
          'Thank you, ' +
          (currentPayment.type == 'DIRECT_DEBIT_MANDATE'
            ? 'your subscription has been confirmed'
            : 'your payment has been received')
      }

      return (
        <>
          <section
            className={classNames(
              'd-flex justify-content-between align-items-center gap-3  text-white text-center text-lg p-3',
              currentPayment.status == 'CONFIRMED'
                ? 'bg-success'
                : 'bg-warning',
            )}
          >
            <div className="w-130px me-auto d-flex gap-1 justify-content-between align-items-center">
              {paymentsState.banner.total > 1 && (
                <>
                  <Button
                    variant="outline-light"
                    onClick={() => {
                      dispatch(setPrevBanner())
                    }}
                  >
                    ⟨
                  </Button>
                  <span>
                    {currentPage + 1} of {paymentsState.banner.total}
                  </span>
                  <Button
                    variant="outline-light"
                    onClick={() => {
                      dispatch(setNextBanner())
                    }}
                  >
                    ⟩
                  </Button>
                </>
              )}
            </div>
            <span className="font-weight-500">{bannerMessage}</span>
            <div className="ms-auto">
              <Link
                className="me-2"
                to="https://meetings-eu1.hubspot.com/johnny-ridd/rq-onboarding"
                target="_blank"
              >
                <Button variant="outline-light">Talk to Sales</Button>
              </Link>
              {currentPayment.status == 'PENDING' && (
                <Button
                  variant="light"
                  className=" font-weight-600 ms-auto"
                  onClick={() => {
                    startProcessing(currentPayment.id)
                  }}
                  href={currentPayment.link}
                  target="_blank"
                >
                  {ctaText}
                </Button>
              )}
              {currentPayment.status == 'CONFIRMED' && (
                <Button
                  variant="outline-light"
                  className="font-weight-600 ms-auto"
                  onClick={() => {
                    dispatch(dismissPayment(currentPayment.id))
                  }}
                >
                  Close
                </Button>
              )}
            </div>
          </section>
        </>
      )
    }
  }

  return null
}

function PaymentsBlock({
  startProcessing,
}: {
  startProcessing: (paymentId: number) => void
}) {
  const paymentsState = useAppSelector(selectPayments)
  const dispatch = useAppDispatch()
  const [checkbox1Checked, setCheckbox1Checked] = React.useState(false)
  const [checkbox2Checked, setCheckbox2Checked] = React.useState(false)

  if (paymentsState.block.total > 0 && paymentsState.block.currentId) {
    const currentPayment = paymentsState.payments.find(
      (payment) => payment.id === paymentsState.block.currentId,
    )
    const currentPage = paymentsState.block.ids.indexOf(
      paymentsState.block.currentId,
    )
    if (currentPayment) {
      const totalCurrency = currentPayment.total.toLocaleString('en-GB', {
        style: 'currency',
        currency: 'GBP',
        minimumFractionDigits: 2,
      })
      const netCurrency = currentPayment.net.toLocaleString('en-GB', {
        style: 'currency',
        currency: 'GBP',
        minimumFractionDigits: 2,
      })

      return (
        <Modal
          show={true}
          centered
          size="lg"
          onHide={() => {
            dispatch(dismissPayment(currentPayment.id))
          }}
        >
          <Modal.Header
            closeButton={currentPayment.status == 'CONFIRMED'}
            className="border-bottom-0"
          >
            <Modal.Title className="text-xxl mb-1 mt-2">
              Secure your access to the UK’s best referral platform
              <span className="text-base d-block text-muted">
                To keep enjoying RQ, you’ll need to set up your subscription.{' '}
              </span>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="py-0">
            <div className="bg-info-light d-flex align-items-center rounded-4 py-4">
              <div className="w-50 d-flex align-items-center justify-content-center">
                <div className="text-base text-muted font-weight-300 ">
                  <span
                    className=""
                    style={{
                      fontSize: '4em',
                      color: 'black',
                      lineHeight: '1.1em',
                    }}
                  >
                    {netCurrency}
                  </span>
                  {currentPayment.type == 'DIRECT_DEBIT_MANDATE' && ' / month'}
                  <br />
                  <span className="fst-italic">+VAT</span>
                </div>
              </div>
              <ul className="text-lg w-50">
                <ListItem>Integrated referral management</ListItem>
                <ListItem>Efficient professional collaboration</ListItem>
                <ListItem>Secure data sharing and automation</ListItem>
              </ul>
            </div>
          </Modal.Body>
          <Modal.Footer className="row">
            <div className="row">
              <div className="col-lg-7">
                {currentPayment.type == 'DIRECT_DEBIT_MANDATE' ? (
                  <>
                    <Form.Check
                      className="text-muted"
                      name="checkbox1"
                      id="checkbox1"
                      disabled={currentPayment.isProcessing}
                      label={`I agree to RQ taking a payment of ${totalCurrency} on the first day of each month. `}
                      checked={checkbox1Checked}
                      onChange={() => setCheckbox1Checked(!checkbox1Checked)}
                    />
                    <Form.Check
                      className="mt-2 text-muted"
                      name="checkbox2"
                      id="checkbox2"
                      disabled={currentPayment.isProcessing}
                      label={
                        <>
                          I understand that this price is subject to change
                          based upon my usage. Any changes to pricing will be
                          clearly communicated prior to any billing period. You
                          can see details on how RQ’s pricing works here at{' '}
                          <a
                            href="https://rq.app/about/pricing"
                            target="_blank"
                            rel="noreferrer"
                          >
                            rq.app/about/pricing
                          </a>
                        </>
                      }
                      checked={checkbox2Checked}
                      onChange={() => setCheckbox2Checked(!checkbox2Checked)}
                    />
                  </>
                ) : (
                  <>
                    To learn more about how our pricing works, please visit:{' '}
                    <a
                      href="https://rq.app/about/pricing"
                      target="_blank"
                      rel="noreferrer"
                    >
                      rq.app/about/pricing
                    </a>
                  </>
                )}
              </div>
              <div className="col-lg-5 text-end mt-4 mt-lg-0">
                <Link
                  to="https://meetings-eu1.hubspot.com/johnny-ridd/rq-onboarding"
                  target="_blank"
                >
                  <Button variant="outline-secondary">Talk to Sales</Button>
                </Link>
                {currentPayment.status == 'PENDING' && (
                  <ActionButton
                    variant="primary"
                    className="px-4 ms-2"
                    disabled={
                      (!checkbox1Checked || !checkbox2Checked) &&
                      currentPayment.type == 'DIRECT_DEBIT_MANDATE'
                    }
                    isProcessing={currentPayment.isProcessing}
                    onClick={() => {
                      startProcessing(currentPayment.id)
                    }}
                    href={currentPayment.link}
                    target="_blank"
                  >
                    {currentPayment.type == 'DIRECT_DEBIT_MANDATE' &&
                      'Set up direct debit'}
                    {currentPayment.type == 'ONE_OFF_PAYMENT' &&
                      `Pay ${totalCurrency}`}
                  </ActionButton>
                )}
                {currentPayment.status == 'CONFIRMED' && (
                  <Button
                    variant="success"
                    className="px-4"
                    onClick={() => {
                      dispatch(dismissPayment(currentPayment.id))
                    }}
                  >
                    <FontAwesomeIcon
                      icon={faCheck}
                      style={{ marginRight: '6px' }}
                    />
                    Paid
                  </Button>
                )}
                <div className="w-130px d-flex gap-1 justify-content-between align-items-center mt-3 ms-auto">
                  {/* Pagination */}
                  {paymentsState.block.total > 1 && (
                    <>
                      <Button
                        variant="outline-primary"
                        onClick={() => {
                          dispatch(setPrevBlock())
                        }}
                      >
                        ⟨
                      </Button>
                      <span>
                        {currentPage + 1} of {paymentsState.block.total}
                      </span>
                      <Button
                        variant="outline-primary"
                        onClick={() => {
                          dispatch(setNextBlock())
                        }}
                      >
                        ⟩
                      </Button>
                    </>
                  )}
                </div>
              </div>
            </div>
          </Modal.Footer>
        </Modal>
      )
    }
  }

  return null
}

function ListItem({ children }: { children: React.ReactNode }) {
  return (
    <li className="d-flex align-items-centered my-1">
      <FontAwesomeIcon
        icon={faCheckCircle}
        className="text-success me-2"
        size="xl"
      />
      {children}
    </li>
  )
}

export default Payments
