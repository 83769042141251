import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPencil } from '@rq-ratings/pro-regular-svg-icons'
import classNames from 'classnames'
import React from 'react'
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap'

import useModal from '../../../../hooks/useModal'
import useNotyf from '../../../../hooks/useNotyf'
import { ESIGINATURE_PDF_SECTION_STYLES } from '../constants'
import EditCommissionJustificationModal from './EditCommissionJustificationModal'

interface Props {
  text: string
  onEdit?: (commissionJustification: string) => void
}

const COMMISSION_JUSTIFICATION_DOM_ID = 'js-commission-justification'

const CommissionJustificationText: React.FC<Props> = ({ text, onEdit }) => {
  const notyf = useNotyf()
  const {
    isShowingModal: isShowingEditModal,
    showModal: showEditModal,
    hideModal: hideEditModal,
  } = useModal()
  const isEditable = !!onEdit

  function onSave(text: string) {
    if (!onEdit) {
      throw new Error('Expected onEdit to be defined when onSave is called')
    }

    onEdit(text)

    notyf.success('Text updated')

    hideEditModal()
  }

  return (
    <>
      <section
        id={COMMISSION_JUSTIFICATION_DOM_ID}
        className="d-flex gap-1 align-items-start"
        style={{
          paddingLeft: ESIGINATURE_PDF_SECTION_STYLES.paddingLeft,
          paddingRight: isEditable
            ? '19px'
            : ESIGINATURE_PDF_SECTION_STYLES.paddingRight,
        }}
      >
        <div
          dangerouslySetInnerHTML={{ __html: text }}
          className={classNames('flex-grow-1', {
            'border border border-primary': isEditable,
          })}
        />

        {isEditable && (
          <OverlayTrigger overlay={<Tooltip>Edit text</Tooltip>}>
            <Button
              onClick={showEditModal}
              variant="outline-primary"
              className="d-flex gap-1 align-items-center p-2"
            >
              <FontAwesomeIcon icon={faPencil} />
            </Button>
          </OverlayTrigger>
        )}
      </section>

      {isShowingEditModal && (
        <EditCommissionJustificationModal
          text={text}
          onSave={onSave}
          onHide={hideEditModal}
        />
      )}
    </>
  )
}

export default CommissionJustificationText
