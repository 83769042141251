import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowUpRightFromSquare } from '@rq-ratings/pro-regular-svg-icons'
import React from 'react'

import routeService from '../../../lib/services/routeService'
import { ReferralsImportItem } from '../../../redux/slices/referralsImportBase'
import { COMPANY_PROFILE_TAB } from '../CompanyProfile/constants'

interface Props {
  importItem: ReferralsImportItem
  field?: string
  message: string
}

const ImportErrorMessage: React.FC<Props> = ({
  importItem,
  field = '',
  message,
}) => {
  let fieldPart = <></>
  if (field === 'email') {
    fieldPart = (
      <>
        <span className="font-weight-600">client's email</span>:{' '}
      </>
    )
    message =
      message.substring(0, message.length - 1) +
      ` ("${importItem.sourceClientEmail}").`
  } else if (field != '') {
    fieldPart = (
      <>
        <span className="font-weight-600">{field}</span>:{' '}
      </>
    )
  }

  if (
    message.includes('create a commercial agreement') &&
    importItem.otherCompany
  ) {
    // Replace 'create a commercial agreement' with a link to /professional-network/relationships?...&tab=commercialAgreements page
    const parts = message.split('create a commercial agreement')
    return (
      <>
        {fieldPart}
        {parts[0]}
        <a
          href={routeService.referralPartners({
            identifier: String(importItem.otherCompany.id),
            identifierType: 'companyId',
            tab: COMPANY_PROFILE_TAB.commercialAgreements,
          })}
          target="_blank"
          rel="noreferrer"
          className="text-danger text-decoration-underline"
        >
          create a commercial agreement
          <FontAwesomeIcon
            icon={faArrowUpRightFromSquare}
            size="xs"
            className="ms-1"
          />
        </a>
        {parts[1]}
      </>
    )
  }
  return (
    <>
      {fieldPart}
      {message}
    </>
  )
}

export default ImportErrorMessage
