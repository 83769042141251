import 'react-toggle/style.css'

import { useMutation, useQueryClient } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import classNames from 'classnames'
import React, { useState } from 'react'
import Toggle from 'react-toggle'

import { useCompanyProfileSearchParams } from '../../../../../hooks/useCompanyProfileSearchParams'
import useNotyf from '../../../../../hooks/useNotyf'
import { QUERY_KEYS } from '../../../../../lib/queryKeys'
import errorTypeService from '../../../../../lib/services/errorTypeService'
import panelService from '../../../../../lib/services/panelService'
import { MutationIconState } from '../../../../../types/misc'
import { CompanyProfileItemPanel } from '../../../../../types/responses/companies'
import MutationIcon from '../../../MutationIcon'

type Props = Omit<React.HTMLAttributes<HTMLDivElement>, 'children'> & {
  panel: CompanyProfileItemPanel
}

const ToggleInternalRelationship: React.FC<Props> = ({
  panel,
  className,
  ...props
}) => {
  const searchParams = useCompanyProfileSearchParams().searchParams
  const queryClient = useQueryClient()
  const [internalRelationship, setInternalRelationship] = useState(
    panel.internalRelationship,
  )
  const notyf = useNotyf()
  const [mutationState, setMutationState] = useState({
    state: MutationIconState.ok,
    message: '',
  })

  const toggleInternalRelationshipMutation = useMutation({
    mutationFn: (enableInteralRelationship: boolean) =>
      panelService.updateInternalRelationship(
        panel.id,
        enableInteralRelationship,
      ),
    onSuccess: (updatedPanel) => {
      setInternalRelationship(updatedPanel.internalRelationship)
      setMutationState({ state: MutationIconState.success, message: '' })
      queryClient.invalidateQueries({
        queryKey: QUERY_KEYS.companyProfile({
          identifier: searchParams.identifier,
          identifierType: searchParams.identifierType,
        }),
      })
    },
    onError: (error: unknown) => {
      let message = ''

      if (errorTypeService.isConstraintViolationListError(error)) {
        message =
          error instanceof AxiosError
            ? (error?.response?.data?.violations[0]?.message ?? '')
            : message

        notyf.error(message)
      }

      setInternalRelationship(panel.internalRelationship)
      setMutationState({ state: MutationIconState.error, message: message })
    },
    onMutate: () => {
      setMutationState({ state: MutationIconState.mutating, message: '' })
    },
  })

  return (
    <div
      className={classNames('d-flex align-items-center', className)}
      {...props}
    >
      <label className="d-flex align-items-center gap-2">
        <span id="toggle-internal-relationship">Internal Relationship</span>

        <Toggle
          aria-labelledby="toggle-internal-relationship"
          defaultChecked={internalRelationship}
          checked={internalRelationship}
          onChange={() =>
            toggleInternalRelationshipMutation.mutate(!internalRelationship)
          }
        />
      </label>

      <span className="flex mx-2 mutation-icon">
        <MutationIcon state={mutationState} />
      </span>
    </div>
  )
}

export default ToggleInternalRelationship
