import { useMutation } from '@tanstack/react-query'

import useAppDispatch from '../../../../hooks/useAppDispatch'
import paymentService from '../../../../lib/services/paymentService'
import { loadPayments, setIsLoading } from '../../../../redux/slices/payments'

export function useLoadPendingPayments() {
  const dispatch = useAppDispatch()

  return useMutation({
    mutationFn: () => {
      dispatch(setIsLoading(true))
      return paymentService.getPendingPayments()
    },
    onSuccess: (data) => {
      dispatch(loadPayments(data['hydra:member']))
      dispatch(setIsLoading(false))
    },
    onError: () => {
      dispatch(setIsLoading(false))
      // TODO: handle errors
    },
  })
}
