import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCalendar,
  faCheckCircle,
  faUser,
} from '@rq-ratings/pro-solid-svg-icons'
import React from 'react'

import { DATE_FORMATS } from '../../../lib/constants'
import { formatDate } from '../../../lib/helpers/helperFunctions'
import { BookingDetails } from './NylasScheduler'

interface Props {
  bookingDetails: BookingDetails
  toCompanyName: string
}

const ConfirmedBookingDetails: React.FC<Props> = ({
  bookingDetails,
  toCompanyName,
}) => {
  // Example format: 9:45am - 10:45am, Thursday, December 26, 2025
  const endTime = formatDate(bookingDetails.endTime, 'EEEE, MMMM d, yyyy')

  return (
    <>
      <header className="mb-3 text-center">
        <div
          className="d-flex justify-content-center align-items-center mb-3"
          style={{ gap: '10px' }}
        >
          <FontAwesomeIcon
            icon={faCheckCircle}
            className="text-success"
            style={{ fontSize: '26px' }}
          />

          <h2 className="mb-0 text-black fw-bold" style={{ fontSize: '26px' }}>
            Scheduled
          </h2>
        </div>

        {/* Not saying who it's been sent to here, because it
          could be client or toCompany doing the booking */}
        <p className="text-lg mb-0">A calendar invitation has been sent.</p>
      </header>

      <section className="border-box rounded-3 py-3 px-4">
        <h4 className="text-black mb-3">Meeting with {toCompanyName}</h4>

        <div className="d-flex flex-column gap-2">
          <div className="d-flex align-items-center gap-2">
            <FontAwesomeIcon icon={faUser} />{' '}
            {bookingDetails.clientDetails.name}
          </div>

          {bookingDetails.guests.map((guest, i) => (
            <div key={i} className="d-flex align-items-center gap-2">
              <FontAwesomeIcon icon={faUser} /> {guest.name}
            </div>
          ))}

          <div className="d-flex align-items-center gap-2">
            <FontAwesomeIcon icon={faUser} /> {bookingDetails.bookedWith.name}
          </div>

          <div className="d-flex align-items-center gap-2">
            <FontAwesomeIcon icon={faCalendar} />

            <div className="d-flex flex-column gap-2">
              {formatDate(bookingDetails.startTime, DATE_FORMATS.TIME)} -{' '}
              {formatDate(bookingDetails.endTime, DATE_FORMATS.TIME)}, {endTime}
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default ConfirmedBookingDetails
