import React from 'react'

import { REFERRAL_LINKS_TABLE } from '../../constants'

const ReferralLinksTableHeaders: React.FC = () => (
  <tr>
    <th style={{ width: REFERRAL_LINKS_TABLE.columnWidths.introduceTo }}>
      Introduce to
    </th>

    <th style={{ width: REFERRAL_LINKS_TABLE.columnWidths.activity }}>
      Activity
    </th>

    <th
      className="sticky-column-end"
      style={{
        width: REFERRAL_LINKS_TABLE.columnWidths.actions,
      }}
    >
      Actions
    </th>
  </tr>
)

export default ReferralLinksTableHeaders
