import { useMutation } from '@tanstack/react-query'

import useAppDispatch from '../../../../hooks/useAppDispatch'
import paymentService from '../../../../lib/services/paymentService'
import {
  loadPayments,
  PaymentsState,
  setIsRefreshing,
} from '../../../../redux/slices/payments'

export function useRefreshCurrentPayments() {
  const dispatch = useAppDispatch()

  return useMutation({
    mutationFn: (state: PaymentsState) => {
      dispatch(setIsRefreshing(true))
      const ids = state.payments.map((payment) => payment.id)
      return paymentService.getPaymentsByIds(ids)
    },
    onSuccess: (data) => {
      dispatch(loadPayments(data['hydra:member']))
      dispatch(setIsRefreshing(false))
    },
    onError: () => {
      dispatch(setIsRefreshing(false))
      // TODO: handle errors
    },
  })
}
