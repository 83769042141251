import React from 'react'
import { Helmet } from 'react-helmet-async'

import SkeletonBox from './SkeletonBox'

const PdfSkeleton: React.FC = () => (
  <main className="overflow-auto">
    <Helmet
      htmlAttributes={{ class: 'pdf-report' }}
      bodyAttributes={{ class: 'bg-white A4' }}
    />
    <div
      className="sheet"
      css={{
        '*': {
          minHeight: 'inherit',
        },
      }}
    >
      <SkeletonBox />
    </div>
  </main>
)

export default PdfSkeleton
