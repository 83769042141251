import { useMutation } from '@tanstack/react-query'
import React from 'react'
import { Button, Modal } from 'react-bootstrap'
import { useSearchParams } from 'react-router'

import usePosthogCapture from '../../../../../hooks/posthogCapture/usePosthogCapture'
import useAppDispatch from '../../../../../hooks/useAppDispatch'
import { useInvalidatePanelQueries } from '../../../../../hooks/useInvalidatePanelQueries'
import useNotyf from '../../../../../hooks/useNotyf'
import panelService from '../../../../../lib/services/panelService'
import { setShouldShowFirstRelationshipRequestApprovedModal } from '../../../../../redux/slices/panel'
import { CompanyProfileItemPanel } from '../../../../../types/responses/companies'
import ActionButton from '../../../ActionButton'
import {
  COMPANY_PROFILE_SEARCH_PARAMS,
  COMPANY_PROFILE_TAB,
} from '../../constants'

interface Props {
  panel: CompanyProfileItemPanel
  onAccept: () => void
  onHide: () => void
}

const AcceptInviteModal: React.FC<Props> = ({ panel, onAccept, onHide }) => {
  const acceptPanelInviteMutation = useAcceptPanelInviteMutation()

  return (
    <Modal show onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>
          <h3 className="text-black mb-0">Accept relationship invitation</h3>
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <div className="mb-0">
          <p>
            <strong>{panel.fromCompany.presentationName}</strong> has invited
            you to join their professional network.
          </p>

          <p className="mb-0">
            If you accept their invite you will be able to refer clients between
            each other.
          </p>
        </div>
      </Modal.Body>

      <Modal.Footer>
        <Button variant="outline-secondary" onClick={onHide}>
          Close
        </Button>

        <ActionButton
          variant="success"
          onClick={() =>
            acceptPanelInviteMutation.mutate(panel, {
              onSuccess: onAccept,
            })
          }
          isProcessing={acceptPanelInviteMutation.isPending}
          isProcessingText="Accepting invite"
        >
          Accept invitation
        </ActionButton>
      </Modal.Footer>
    </Modal>
  )
}

function useAcceptPanelInviteMutation() {
  const notyf = useNotyf()
  const invalidatePanelQueries = useInvalidatePanelQueries()
  const dispatch = useAppDispatch()
  const { posthogCapture } = usePosthogCapture()
  const [searchParams, setSearchParams] = useSearchParams()

  return useMutation({
    mutationFn: (panel: CompanyProfileItemPanel) =>
      panelService.acceptPanelInvite(panel.id),

    onSuccess: async (response, panel) => {
      posthogCapture({
        appArea: 'relationship-management',
        action: 'relationship-accepted',
      })

      await invalidatePanelQueries()

      if (response.isFirstPanelApprovedByToCompany) {
        dispatch(setShouldShowFirstRelationshipRequestApprovedModal(true))
      }

      notyf.success(
        `${panel.fromCompany.presentationName} was added to your approved professional network`,
      )

      const needsToReviewAgreements = panel.commercialAgreements.some(
        (agreement) => agreement.needsReview,
      )

      if (needsToReviewAgreements) {
        searchParams.set(
          COMPANY_PROFILE_SEARCH_PARAMS.tab,
          COMPANY_PROFILE_TAB.commercialAgreements,
        )
        setSearchParams(searchParams)
      }
    },
    onError: () => {
      notyf.error('Could not accept invite')
    },
  })
}

export default AcceptInviteModal
