import React, { Suspense } from 'react'
import { Navigate, useLocation } from 'react-router'
import { useMount } from 'react-use'

import useAppDispatch from '../../../hooks/useAppDispatch'
import { useAppStateQuery } from '../../../hooks/useAppStateQuery'
import { useCompanyConsentQuery } from '../../../hooks/useCompanyConsentQuery'
import { useCompanyDashboardSidebarLinks } from '../../../hooks/useCompanyDashboardSidebarLinks'
import { useCurrentUserOrFail } from '../../../hooks/useCurrentUserOrFail'
import { matchesPaths } from '../../../lib/helpers/routeHelpers'
import { ROUTES } from '../../../lib/routes'
import RecommendedPartnerModalContainer from '../../../pages/professionalNetworkRelationships/components/RecommendedPartnerModal/RecommendedPartnerModalContainer'
import { LAYOUTS, setLayout } from '../../../redux/slices/layout'
import Banners from '../../banners/Banners'
import CompanyProfileModal from '../../misc/CompanyProfile/components/CompanyProfileModal'
import Main from '../../misc/Main'
import PageContent from '../../misc/PageContent'
import Payments from '../../misc/Payments/Payments'
import SentryErrorBoundary from '../../misc/SentryErrorBoundary'
import BookMeetingModal from '../../modals/BookMeetingModal'
import CompanyConsentModal from '../../modals/CompanyConsentModal'
import VideoModal from '../../modals/VideoModal'
import LoadingWidget from '../../widgets/LoadingWidget'
import Footer from './components/Footer'
import Navbar from './components/Navbar/Navbar'
import Sidebar from './components/sidebar/Sidebar'

interface Props {
  children: React.ReactNode
  padded?: boolean
}

const CompanyDashboardLayout: React.FC<Props> = ({
  children,
  padded = true,
}) => {
  const currentUser = useCurrentUserOrFail()
  const location = useLocation()
  const companyConsentQuery = useCompanyConsentQuery()
  const sidebarLinks = useCompanyDashboardSidebarLinks()
  const dispatch = useAppDispatch()

  useMount(() => {
    dispatch(setLayout(LAYOUTS.companyDashboard))
  })

  const appStateQuery = useAppStateQuery()

  if (currentUser.needsToCompleteWalkthrough) {
    return <Navigate to={ROUTES.walkthrough} replace />
  }

  if (appStateQuery.isLoading) {
    return <LoadingWidget />
  }

  // Some routes have dedicated loading skeletons, so prefer to show them
  // instead of the default loading widget
  function renderLoadingFallback() {
    const routesWithOwnFallback = [
      ROUTES.companyCompassSnapshots,
      ROUTES.contacts,
      ROUTES.professionalNetworkRelationships,
      ROUTES.referrals,
    ]

    return matchesPaths(location.pathname, routesWithOwnFallback) ? null : (
      <LoadingWidget />
    )
  }

  return (
    <>
      <Payments />

      <Banners />

      {companyConsentQuery.data && !companyConsentQuery.isFetching && (
        <CompanyConsentModal />
      )}

      <div className="wrapper">
        <Sidebar items={[{ pages: sidebarLinks }]} />

        <Main>
          <Navbar />

          <PageContent padded={padded}>
            <SentryErrorBoundary key={location.pathname}>
              <Suspense fallback={renderLoadingFallback()}>{children}</Suspense>
            </SentryErrorBoundary>
          </PageContent>

          <Footer />
        </Main>

        <VideoModal />
        <BookMeetingModal />
        <RecommendedPartnerModalContainer />
        <CompanyProfileModal />
      </div>
    </>
  )
}

export default CompanyDashboardLayout
