import React, { useState } from 'react'

import { CompanyProfileItem } from '../../../../../types/responses/companies'
import { useIsCompanyProfileModal } from '../../hooks/useIsCompanyProfileModal'
import { useIsCompanyProfilePage } from '../../hooks/useIsCompanyProfilePage'
import Map from './Map'
import SectionHeading from './SectionHeading'

interface Props {
  companyProfile: CompanyProfileItem
}

const Locations: React.FC<Props> = ({ companyProfile }) => {
  const [hasValidMarkers, setHasValidMarkers] = useState(true) // Must start as true as calc logic is executed in nested component
  const isCompanyProfileModal = useIsCompanyProfileModal()
  const isCompanyProfilePage = useIsCompanyProfilePage()
  const { directory, company } = companyProfile
  const isTradingName = !!directory?.fca?.frnTradingNameId
  const postcodes =
    directory && directory.postcode && directory.county && !isTradingName
      ? [{ postcode: directory.postcode, name: directory.county }]
      : []

  if (company) {
    for (const address of company.addresses) {
      const listing = { postcode: address.postcode, name: address.city }
      postcodes.push(listing)
    }
  }

  const locations = postcodes
    .filter(
      (location, index, self) =>
        index === self.findIndex((t) => t.name === location.name),
    )
    .map((location) => location.name)

  if (!locations.length) {
    return null
  }

  const clientLocations = companyProfile.company?.clientLocations

  return (
    <section>
      {isCompanyProfilePage && hasValidMarkers && (
        <Map
          postcodes={postcodes}
          className="my-1 w-100"
          style={{ height: '400px' }}
          setHasValidMarkers={setHasValidMarkers}
        />
      )}

      <div className="row">
        {hasValidMarkers && (
          <div className="col-md-6 pe-md-4 mt-3">
            <SectionHeading>Office locations</SectionHeading>
            <p className="mb-2">{locations.join(', ')}</p>

            {isCompanyProfileModal && (
              <Map
                postcodes={postcodes}
                className="w-100"
                style={{ height: '250px' }}
                setHasValidMarkers={setHasValidMarkers}
              />
            )}
          </div>
        )}

        {clientLocations && (
          <div className="col-md-6 mt-3">
            <SectionHeading>Client locations</SectionHeading>
            <p>{clientLocations}</p>
          </div>
        )}
      </div>
    </section>
  )
}

export default Locations
