import React from 'react'

import ImageWithText from '../../components/ImageWithText'
import screenshotUrl from './screenshot.png'

const ProfessionalNetworkScreen1: React.FC = () => (
  <ImageWithText
    imageUrl={screenshotUrl}
    text={
      <p>
        <strong>Professional network</strong> is a home for your relationships
        with other professionals. Access them all in one place, identify and
        fill gaps in your network, and easily invite firms to join you on RQ.
        It's free for them to use the platform with you when you invite them.
      </p>
    }
  />
)

export default ProfessionalNetworkScreen1
