import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashCan } from '@rq-ratings/pro-light-svg-icons'
import {
  faCheckCircle,
  faPlusCircle,
  faTriangleExclamation,
} from '@rq-ratings/pro-solid-svg-icons'
import React from 'react'
import { Spinner } from 'react-bootstrap'

import { ReferralsImportItem } from '../../../redux/slices/referralsImportBase'

interface Props {
  importItem: ReferralsImportItem
  removeImportItem: (item: ReferralsImportItem) => void
}

const ImportStatusCell: React.FC<Props> = ({
  importItem,
  removeImportItem,
}) => {
  return (
    <>
      {importItem.importStatus === 'invalid' && (
        <FontAwesomeIcon
          icon={faTriangleExclamation}
          className="text-danger"
          size="2x"
        />
      )}
      {importItem.importStatus === 'ready' && (
        <FontAwesomeIcon icon={faPlusCircle} className="text-info" size="2x" />
      )}
      {importItem.importStatus === 'processing' && (
        <Spinner size="sm" className="text-info" />
      )}
      {importItem.importStatus === 'imported' && (
        <FontAwesomeIcon
          icon={faCheckCircle}
          className="text-success"
          size="2x"
        />
      )}
      {importItem.importStatus === 'error' && (
        <FontAwesomeIcon icon={faPlusCircle} className="text-info" size="2x" />
      )}
      {importItem.importStatus !== 'imported' &&
        importItem.importStatus !== 'processing' && (
          <>
            <a
              href="#"
              className="hover-text-danger text-muted position-absolute"
              style={{ right: '5px', bottom: '5px' }}
              onClick={() => {
                if (confirm('Are you sure you want to remove this item?')) {
                  removeImportItem(importItem)
                }
              }}
            >
              <FontAwesomeIcon icon={faTrashCan} />
            </a>
          </>
        )}
    </>
  )
}

export default ImportStatusCell
