import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSuitcase } from '@rq-ratings/pro-solid-svg-icons'
import React from 'react'

import useAppDispatch from '../../../../../hooks/useAppDispatch'
import {
  setOpen,
  setStep,
} from '../../../../../redux/slices/referralsImportHistorical'
import ImportHistoricalReferralsModal from '../../../../modals/ImportHistoricalReferralsModal/ImportHistoricalReferralsModal'

const ImportHistoricalReferrals: React.FC = () => {
  const dispatch = useAppDispatch()

  return (
    <>
      <button
        onClick={() => {
          // Make sure the modal's showing (sometimes it's ont yet)
          dispatch(setOpen(true))
          // Actually go to the step
          dispatch(setStep('upload'))
        }}
        type="button"
        className="w-100 invite-type-button px-4 py-5 rounded-3 col d-flex flex-column align-items-center gap-2"
      >
        <FontAwesomeIcon icon={faSuitcase} size="3x" />
        Import your historical referrals
      </button>

      <ImportHistoricalReferralsModal />
    </>
  )
}

export default ImportHistoricalReferrals
