import React from 'react'
import { Button, Card } from 'react-bootstrap'
import { Link } from 'react-router'

import { EMAIL_ADDRESSES } from '../../lib/constants'
import { ROUTES } from '../../lib/routes'

interface Props {
  title?: string
}

const NotFound: React.FC<Props> = ({ title = 'Page not found' }) => (
  <Card className="border-box mx-auto py-4" style={{ maxWidth: '640px' }}>
    <Card.Body className="text-center">
      <h1 className="text-black mb-4" style={{ fontSize: '38px' }}>
        {title}
      </h1>

      <div className="mb-4 text-lg">
        <p className="mb-2">
          Sorry, the page or resource you were looking for could not be found.{' '}
        </p>

        <p>
          If you need any help, please contact us at{' '}
          <a href={`mailto:${EMAIL_ADDRESSES.rqSupport}`}>
            {EMAIL_ADDRESSES.rqSupport}
          </a>
        </p>
      </div>

      <Link to={ROUTES.index}>
        <Button variant="primary" size="lg">
          Return to the homepage
        </Button>
      </Link>
    </Card.Body>
  </Card>
)

export default NotFound
