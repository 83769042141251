import { PayloadAction } from '@reduxjs/toolkit'

import { validateEmailAddress } from '../../lib/helpers/emailAddressHelpers'
import { HydraMember } from '../../types/api'
import { ImportDirection } from '../../types/dtos/bulk-import-referral-details'
import { FormErrors, ModifierKey } from '../../types/misc'
import { BulkImportReferralCollectionItem } from '../../types/responses/referral-import'

export type ReferralsImportStep = 'upload' | 'resolve-import'
export type ReferralsImportOnClientDuplicate = 'keep-original' | 'update'

export type ReferralsImportItem =
  HydraMember<BulkImportReferralCollectionItem> & {
    refUUID: string
    onClientDuplicate: ReferralsImportOnClientDuplicate
    importStatus: 'invalid' | 'ready' | 'processing' | 'imported' | 'error'
    importErrors?: FormErrors
    pausedClientEmails: boolean
    multipleInvites: boolean
    bulkAllCount: number
    bulkCompanyCount: number
    selected: boolean
  }

export const initialReferralsImportItemState: Pick<
  ReferralsImportItem,
  | 'importStatus'
  | 'pausedClientEmails'
  | 'multipleInvites'
  | 'bulkAllCount'
  | 'bulkCompanyCount'
  | 'selected'
> = {
  importStatus: 'ready',
  pausedClientEmails: false,
  multipleInvites: false,
  bulkAllCount: 0,
  bulkCompanyCount: 0,
  selected: false,
}

export type ReferralsImportItemPartial = {
  refUUID: string
} & Partial<ReferralsImportItem>

export interface ReferralsImportState {
  open: boolean
  multiSelect: boolean
  step: ReferralsImportStep
  initialStep: ReferralsImportStep
  onClientDuplicate: ReferralsImportOnClientDuplicate
  hasInvites: boolean
  hasSelectedMultiple: boolean
  hasUpdatableClients: boolean
  canBulkUpdateSelectedOnly: boolean
  canPauseClientEmails: boolean
  selectedCount: number
  prevSelectedItemIndex: number
  importItems: ReferralsImportItem[]
}

export const initialReferralsImportState: ReferralsImportState = {
  open: false,
  multiSelect: false,
  step: 'upload',
  initialStep: 'upload',
  onClientDuplicate: 'keep-original',
  hasInvites: false,
  hasSelectedMultiple: false,
  hasUpdatableClients: false,
  canBulkUpdateSelectedOnly: false,
  canPauseClientEmails: false,
  selectedCount: 0,
  prevSelectedItemIndex: -1,
  importItems: [],
}

// Reducers
export const setOpenReducer = (
  state: ReferralsImportState,
  action: PayloadAction<boolean>,
) => {
  state.open = action.payload
}

export const setMultiSelectReducer = (
  state: ReferralsImportState,
  action: PayloadAction<boolean>,
) => {
  state.multiSelect = action.payload
  updateSelectedState(state)
}

export const setStepReducer = (
  state: ReferralsImportState,
  action: PayloadAction<ReferralsImportState['step']>,
) => {
  state.step = action.payload
}

export const setOnClientDuplicateReducer = (
  state: ReferralsImportState,
  action: PayloadAction<ReferralsImportState['onClientDuplicate']>,
) => {
  state.onClientDuplicate = action.payload
}

export const setImportItemsReducer = (
  state: ReferralsImportState,
  action: PayloadAction<ReferralsImportState['importItems']>,
) => {
  state.importItems = action.payload
  state.importItems.forEach((item) => {
    validate(item)
  })
  updateState(state)
}

export const updateImportItemReducer = (
  state: ReferralsImportState,
  action: PayloadAction<ReferralsImportItemPartial>,
) => {
  const item = state.importItems.find(
    (i) => i.refUUID === action.payload.refUUID,
  )
  if (item) {
    Object.assign(item, action.payload)
    validate(item)
  }
  updateState(state)
}

export const updateImportItemStatusReducer = (
  state: ReferralsImportState,
  action: PayloadAction<
    Pick<ReferralsImportItem, 'refUUID' | 'importStatus' | 'importErrors'>
  >,
) => {
  const item = state.importItems.find(
    (i) => i.refUUID === action.payload.refUUID,
  )
  if (item) {
    item.importStatus = action.payload.importStatus
    item.importErrors = action.payload.importErrors
  }
  updateState(state)
}

export const selectItemsReducer = (
  state: ReferralsImportState,
  action: PayloadAction<{
    refUUID: string
    modifier: null | ModifierKey
  }>,
) => {
  if (action.payload.modifier === 'shiftKey') {
    let currentlySelectedItemIndex = -1
    state.importItems.forEach((item, index) => {
      if (item.refUUID === action.payload.refUUID) {
        currentlySelectedItemIndex = index
      }
    })
    const [min, max] =
      state.prevSelectedItemIndex < currentlySelectedItemIndex
        ? [state.prevSelectedItemIndex, currentlySelectedItemIndex]
        : [currentlySelectedItemIndex, state.prevSelectedItemIndex]
    state.importItems.forEach((item, index) => {
      item.selected = item.selected || (index >= min && index <= max)
    })
    state.prevSelectedItemIndex = currentlySelectedItemIndex
  } else {
    state.importItems.forEach((item, index) => {
      if (item.refUUID === action.payload.refUUID) {
        item.selected = !item.selected
        state.prevSelectedItemIndex = index
      }
    })
  }
  updateSelectedState(state)
}

export type BulkUpdatePayload = {
  filter: {
    selected?: boolean
    importDirection?: ImportDirection
    otherCompany?: number
  }
  data: Partial<
    Pick<
      ReferralsImportItem,
      | 'circumstances'
      | 'inviteAdvisor'
      | 'onClientDuplicate'
      | 'otherCompanyUser'
      | 'otherCompanyUserDefaultUsed'
      | 'pausedClientEmails'
      | 'referralDate'
      | 'selected'
      | 'serviceArea'
      | 'serviceAreaDefaultUsed'
      | 'status'
    >
  >
}

export const bulkUpdateImportItemsReducer = (
  state: ReferralsImportState,
  action: PayloadAction<BulkUpdatePayload>,
) => {
  let anyChanged = false
  state.importItems.forEach((item) => {
    if (item.importStatus != 'imported') {
      let assign = true
      if (action.payload.filter?.selected === true) {
        assign = assign && item.selected
      }
      if (action.payload.filter?.importDirection) {
        assign =
          assign &&
          item.importDirection === action.payload.filter.importDirection
      }
      if (action.payload.filter?.otherCompany) {
        assign =
          assign && item.otherCompany?.id === action.payload.filter.otherCompany
      }
      if (assign) {
        Object.assign(item, action.payload.data)
        anyChanged = true
        validate(item)
      }
    }
  })
  if (anyChanged) {
    updateState(state)
  }
}

export const removeImportItemReducer = (
  state: ReferralsImportState,
  action: PayloadAction<string>,
) => {
  state.importItems = state.importItems.filter(
    (i) => i.refUUID !== action.payload,
  )
  if (state.importItems.length === 0) {
    state.open = false
    state.step = state.initialStep
  }
  updateState(state)
}

function updateState(state: ReferralsImportState): void {
  state.hasInvites = false
  state.hasUpdatableClients = false
  state.canPauseClientEmails = false
  const advisorEmailMap = new Map<string, number>()
  const companiesMap = new Map<number, number>()
  let bulkAllCount = 0
  let selectedCount = 0
  // first run, set general state flags and collect maps
  state.importItems.forEach((item) => {
    // - is selected
    if (item.selected) {
      selectedCount++
    }
    // - has invites
    if (!item.otherCompany) {
      state.hasInvites = true
    }
    // - has updatable clients
    if (item.userCompanyClient) {
      state.hasUpdatableClients = true
    }
    // - can pause client emails
    if (item.canPauseClientEmails) {
      state.canPauseClientEmails = true
    }
    // - multiple invites
    if (item.advisorEmail) {
      advisorEmailMap.set(
        item.advisorEmail.toLowerCase(),
        (advisorEmailMap.get(item.advisorEmail.toLowerCase()) || 0) + 1,
      )
    }
    // - bulk counts
    if (item.importStatus === 'ready' || item.importStatus === 'error') {
      if (item.otherCompany && item.importDirection == 'TO') {
        companiesMap.set(
          item.otherCompany.id,
          (companiesMap.get(item.otherCompany.id) || 0) + 1,
        )
      } else if (item.importDirection == 'FROM') {
        companiesMap.set(
          item.userCompany.id,
          (companiesMap.get(item.userCompany.id) || 0) + 1,
        )
      }
      bulkAllCount++
      item.bulkCompanyCount = 1
    } else {
      item.bulkCompanyCount = 0
    }
  })
  // second run, apply maps
  state.importItems.forEach((item) => {
    // - multiple invites
    item.multipleInvites =
      advisorEmailMap.get(item.advisorEmail.toLowerCase())! > 1
    // - bulk counts
    if (item.importStatus === 'ready' || item.importStatus === 'error') {
      if (item.otherCompany && item.importDirection == 'TO') {
        item.bulkCompanyCount = companiesMap.get(item.otherCompany.id) || 0
        item.bulkAllCount = bulkAllCount
      } else if (item.importDirection == 'FROM') {
        item.bulkCompanyCount = companiesMap.get(item.userCompany.id) || 0
        item.bulkAllCount = bulkAllCount
      } else {
        item.bulkCompanyCount = 0
        item.bulkAllCount = 0
      }
    } else {
      item.bulkCompanyCount = 0
      item.bulkAllCount = 0
    }
  })
  // apply general state flags
  updateSelectedState(state, selectedCount)
}

function updateSelectedState(
  state: ReferralsImportState,
  selectedCount?: number,
) {
  if (selectedCount === undefined) {
    selectedCount = state.importItems.filter((item) => item.selected).length
  }
  state.hasSelectedMultiple = selectedCount > 1
  state.canBulkUpdateSelectedOnly = selectedCount > 0 && state.multiSelect
  state.selectedCount = selectedCount
}

function validate(item: ReferralsImportItem) {
  let isValid = true
  if (!item.otherCompany) {
    // Validate advisor email for invitations purposes
    item.advisorEmailValid = validateEmailAddress(item.advisorEmail)
    // Add other conditions here
    isValid = item.advisorEmailValid
  }
  if (!item.canPauseClientEmails) {
    item.pausedClientEmails = false
  }
  if (!isValid && item.importStatus !== 'invalid') {
    item.importStatus = 'invalid'
  } else if (isValid && item.importStatus === 'invalid') {
    item.importStatus = 'ready'
  }
}

export const reducers = {
  setOpen: setOpenReducer,
  setMultiSelect: setMultiSelectReducer,
  setStep: setStepReducer,
  setOnClientDuplicate: setOnClientDuplicateReducer,
  setImportItems: setImportItemsReducer,
  updateImportItem: updateImportItemReducer,
  updateImportItemStatus: updateImportItemStatusReducer,
  selectItems: selectItemsReducer,
  bulkUpdateImportItems: bulkUpdateImportItemsReducer,
  removeImportItem: removeImportItemReducer,
}
