import React from 'react'

type Props = React.HTMLAttributes<HTMLHeadingElement> & {
  children: React.ReactNode
}

const SectionHeading: React.FC<Props> = ({ className, style, ...props }) => (
  <strong
    className={className}
    style={{
      marginTop: '22px',
      marginBottom: '5px',
      ...style,
    }}
    {...props}
  />
)

export default SectionHeading
