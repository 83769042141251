import React from 'react'

import { usePermissions } from '../../../hooks/usePermissions'
import InviteReferralPartners from './InviteReferralPartners'
import RequestReferralPartners from './RequestReferralPartners'

interface Props {
  className?: string
}

const InviteOrRequestReferralPartners: React.FC<Props> = ({ className }) => {
  const permissions = usePermissions()
  if (permissions.canAddPanelDirectly) {
    return <InviteReferralPartners className={className} />
  } else {
    return <RequestReferralPartners className={className} />
  }
}

export default InviteOrRequestReferralPartners
