import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { WritableDraft } from 'immer'

import { PAYMENTS_RELOAD_REFRESH_INTERVAL } from '../../lib/constants'
import { HydraMember } from '../../types/api'
import { PaymentCollectionItem } from '../../types/responses/payments'
import { RootState } from '../store'

export type PaymentStateItem = HydraMember<PaymentCollectionItem> & {
  net: number
  total: number
  isProcessing: boolean // used to track if payment is being processed
}
export type PaymentsPaginator = {
  ids: number[]
  currentId: number | null
  total: number
}

export interface PaymentsState {
  payments: PaymentStateItem[]
  loading: boolean
  banner: PaymentsPaginator
  block: PaymentsPaginator
  lastUpdated: Date | null
  isOutdated: boolean // track triggering refreshments of currently loaded payments (it won't load new payments)
  isLoading: boolean // used to track if payment is being loaded for the first time or fully reloaded
  isRefreshing: boolean // used to track if currently loaded payments are being refreshed
  isProcessing: boolean // used to track if any of payment is being processed (e.g. payment is being made)
  refreshDelay: number // delay between refreshments, it is longer for regular refreshments and shorter when payment is being processed
  refreshTimeout?: NodeJS.Timeout
}

export const INITIAL_STATE: PaymentsState = {
  payments: [],
  loading: false,
  banner: { ids: [], currentId: null, total: 0 },
  block: { ids: [], currentId: null, total: 0 },
  lastUpdated: null,
  isOutdated: false,
  isLoading: false,
  isRefreshing: false,
  isProcessing: false,
  refreshDelay: PAYMENTS_RELOAD_REFRESH_INTERVAL,
}

const payments = createSlice({
  name: 'payments',
  initialState: INITIAL_STATE,
  reducers: {
    loadPayments(
      state,
      action: PayloadAction<HydraMember<PaymentCollectionItem>[]>,
    ) {
      const payments: PaymentStateItem[] = []
      action.payload.forEach((payment) => {
        // find if already in state
        const existingPayment = state.payments.find((p) => p.id === payment.id)
        // preserve isProcessing state
        const loadedPayment = {
          ...payment,
          net: payment.amount / 100,
          total: (payment.amount / 100) * 1.2,
          isProcessing: existingPayment ? existingPayment.isProcessing : false,
        }
        if (
          existingPayment &&
          existingPayment.status === 'PENDING' &&
          loadedPayment.status === 'CONFIRMED'
        ) {
          // payment has been processed since last load
          loadedPayment.isProcessing = false
        }
        payments.push(loadedPayment)
      })
      // add "old" payments
      state.payments.forEach((payment) => {
        if (!payments.find((p) => p.id === payment.id)) {
          payments.push(payment)
        }
      })
      // sort by ids
      payments.sort((a, b) => a.id - b.id)
      state.payments = payments
      // update lastUpdated
      state.lastUpdated = new Date()
      state.isOutdated = false
      // refresh state
      refreshState(state)
    },
    dismissPayment(state, action: PayloadAction<number>) {
      // remove (not pending) payment from the state
      state.payments = state.payments.filter(
        (p) => p.id !== action.payload || p.status === 'PENDING',
      )
      // refresh state
      refreshState(state)
    },
    setNextBanner(state) {
      if (state.banner.ids.length > 0) {
        const currentIndex = state.banner.ids.indexOf(
          state.banner.currentId || 0,
        )
        const nextIndex = currentIndex + 1
        state.banner.currentId =
          state.banner.ids[nextIndex] || state.banner.ids[0] || null
      }
    },
    setPrevBanner(state) {
      if (state.banner.ids.length > 0) {
        const currentIndex = state.banner.ids.indexOf(
          state.banner.currentId || 0,
        )
        const prevIndex = currentIndex - 1
        state.banner.currentId =
          state.banner.ids[prevIndex] ||
          state.banner.ids[state.banner.ids.length - 1] ||
          null
      }
    },
    setNextBlock(state) {
      if (state.block.ids.length > 0) {
        const currentIndex = state.block.ids.indexOf(state.block.currentId || 0)
        const nextIndex = currentIndex + 1
        state.block.currentId =
          state.block.ids[nextIndex] || state.block.ids[0] || null
      }
    },
    setPrevBlock(state) {
      if (state.block.ids.length > 0) {
        const currentIndex = state.block.ids.indexOf(state.block.currentId || 0)
        const prevIndex = currentIndex - 1
        state.block.currentId =
          state.block.ids[prevIndex] ||
          state.block.ids[state.block.ids.length - 1] ||
          null
      }
    },
    setIsOutdated(state, action: PayloadAction<boolean>) {
      state.isOutdated = action.payload
    },
    setIsLoading(state, action: PayloadAction<boolean>) {
      state.isLoading = action.payload
    },
    setIsRefreshing(state, action: PayloadAction<boolean>) {
      state.isRefreshing = action.payload
    },
    setIsProcessing(
      state,
      action: PayloadAction<{ paymentId: number; isProcessing: boolean }>,
    ) {
      const payment = state.payments.find(
        (p) => p.id === action.payload.paymentId,
      )
      if (payment) {
        payment.isProcessing = action.payload.isProcessing
      }
      state.isProcessing = state.payments.some((p) => p.isProcessing)
    },
    setRefreshDelay(state, action: PayloadAction<number>) {
      state.refreshDelay = action.payload
    },
    setRefreshTimeout(state, action: PayloadAction<NodeJS.Timeout>) {
      if (state.refreshTimeout) {
        clearTimeout(state.refreshTimeout)
      }
      state.refreshTimeout = action.payload
    },
    clearRefreshTimeout(state) {
      if (state.refreshTimeout) {
        clearTimeout(state.refreshTimeout)
        state.refreshTimeout = undefined
      }
    },
  },
})

function refreshState(state: WritableDraft<PaymentsState>) {
  state.banner.ids = []
  state.block.ids = []
  // distribute ids
  state.payments.forEach((payment) => {
    if (payment.displayType == 'BANNER') {
      state.banner.ids.push(payment.id)
    } else if (payment.displayType == 'BLOCK') {
      state.block.ids.push(payment.id)
    }
  })
  // update paginator
  state.banner.total = state.banner.ids.length
  state.block.total = state.block.ids.length
  // update currentId (fallback to first id)
  if (state.banner.ids.length > 0) {
    if (!state.banner.ids.includes(state.banner.currentId || 0)) {
      state.banner.currentId = state.banner.ids[0] || null
    }
  } else {
    state.banner.currentId = null
  }
  if (state.block.ids.length > 0) {
    if (!state.block.ids.includes(state.block.currentId || 0)) {
      state.block.currentId = state.block.ids[0] || null
    }
  } else {
    state.block.currentId = null
  }
  // update isProcessing
  state.isProcessing = state.payments.some((p) => p.isProcessing)
}

export const paymentsReducer = payments.reducer

export const selectPayments = (state: RootState) => state.payments

export const {
  loadPayments,
  dismissPayment,
  setNextBanner,
  setPrevBanner,
  setNextBlock,
  setPrevBlock,
  setIsOutdated,
  setIsLoading,
  setIsRefreshing,
  setIsProcessing,
  setRefreshDelay,
  setRefreshTimeout,
  clearRefreshTimeout,
} = payments.actions
