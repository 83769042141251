import React from 'react'

import ImageWithText from '../../components/ImageWithText'
import screenshotUrl from './screenshot.png'

const CompassScreen2: React.FC = () => (
  <ImageWithText
    imageUrl={screenshotUrl}
    text={
      <p>
        Clients complete a short series of questions and generate actions and
        insights as they progress. Walk through <strong>Compass</strong> as
        yourself, as a real client, or as a made up client. You’ll experience it
        as your clients will, see the questions involved - and the insights it
        generates.
      </p>
    }
  />
)

export default CompassScreen2
