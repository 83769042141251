import { useSearchParams } from 'react-router'

import { CompanyProfileSearchParams } from '../components/misc/CompanyProfile/CompanyProfile'
import { COMPANY_PROFILE_SEARCH_PARAMS } from '../components/misc/CompanyProfile/constants'

// TODO: Remove this hook. Don't need the unnecessary abstraction.
export function useCompanyProfileSearchParams() {
  const [searchParams, setSearchParams] = useSearchParams()

  const params: CompanyProfileSearchParams = {
    identifier:
      searchParams.get(COMPANY_PROFILE_SEARCH_PARAMS.identifier) || '',
    identifierType:
      searchParams.get(COMPANY_PROFILE_SEARCH_PARAMS.identifierType) || '',
    tab: searchParams.get(COMPANY_PROFILE_SEARCH_PARAMS.tab) || '',
  }

  function setParams(newParams: CompanyProfileSearchParams) {
    Object.entries(newParams).forEach(([key, value]) => {
      searchParams.set(key, value.toString())
    })

    setSearchParams(searchParams)
  }

  function setTab(tab: string) {
    searchParams.set(COMPANY_PROFILE_SEARCH_PARAMS.tab, tab)
    setSearchParams(searchParams)
  }

  function clearParams() {
    searchParams.delete(COMPANY_PROFILE_SEARCH_PARAMS.identifier)
    searchParams.delete(COMPANY_PROFILE_SEARCH_PARAMS.identifierType)

    setSearchParams(searchParams)
  }

  return {
    searchParams: params,
    setParams,
    clearParams,
    setTab,
  }
}
