import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@rq-ratings/pro-regular-svg-icons'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import React from 'react'

import useNotyf from '../../../../../../hooks/useNotyf'
import { QUERY_KEYS } from '../../../../../../lib/queryKeys'
import referralLinkService from '../../../../../../lib/services/referralLinkService'
import { CompanyProfileItemCompany } from '../../../../../../types/responses/companies'
import ActionButton from '../../../../ActionButton'

interface Props {
  company: CompanyProfileItemCompany
  numReferralLinks: number
}

const CreateReferralLinkToWholeFirmButton: React.FC<Props> = ({
  company,
  numReferralLinks,
}) => {
  const notyf = useNotyf()
  const queryClient = useQueryClient()

  const createLinkMutation = useMutation({
    mutationFn: () =>
      referralLinkService.createReferralLink({
        toCompany: company['@id'],
        userIds: [],
      }),
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: QUERY_KEYS.referralLinks({ ['toCompany.id']: company.id }),
      })

      notyf.success('Calendar link created successfully')
    },
    onError: () => {
      notyf.error('Failed to create calendar link.')
    },
  })

  return (
    <ActionButton
      variant="success"
      className="d-inline-flex align-items-center gap-2"
      size="lg"
      onClick={() => createLinkMutation.mutate()}
      isProcessing={createLinkMutation.isPending}
      isProcessingText="Creating calendar link"
    >
      <FontAwesomeIcon icon={faPlus} />
      Create {numReferralLinks === 0 ? 'a' : 'another'} calendar link
    </ActionButton>
  )
}

export default CreateReferralLinkToWholeFirmButton
