import React from 'react'
import { Modal } from 'react-bootstrap'

import { useIsAuthenticatedClient } from '../../../../hooks/useIsAuthenticatedClient'
import { useIsCompanyUser } from '../../../../hooks/useIsCompanyUser'
import { buildUrl } from '../../../../lib/helpers/helperFunctions'
import authService from '../../../../lib/services/authService'
import PlainButton from '../../../misc/PlainButton'
import { useClaimReferralModal } from '../../../misc/ReferralDetails/hooks/useClaimReferralModal'
import { REFERRAL_INVITE_LAYOUT_PARAMS } from '../constants'
import { useHasMultipleReferralInvites } from '../hooks/useHasMultipleReferralInvites'
import AlreadyLoggedInAsClientUserModal from './AlreadyLoggedInAsClientUserModal'
import AlreadyLoggedInAsCompanyUserModal from './AlreadyLoggedInAsCompanyUserModal'
import CreateAccountButton from './CreateAccountButton'

const ClaimReferralModal: React.FC = () => {
  const isCompanyUser = useIsCompanyUser()
  const isAuthenticatedClient = useIsAuthenticatedClient()
  const claimReferralModal = useClaimReferralModal()
  const hasMultipleInvites = useHasMultipleReferralInvites()

  if (isCompanyUser) {
    return <AlreadyLoggedInAsCompanyUserModal />
  }

  if (isAuthenticatedClient) {
    return <AlreadyLoggedInAsClientUserModal />
  }

  return (
    <>
      <Modal
        onHide={claimReferralModal.hide}
        show={claimReferralModal.isShowing}
        css={{
          '.modal-dialog': {
            maxWidth: '460px',
          },
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Create free account to access</Modal.Title>
        </Modal.Header>

        <Modal.Body className="text-cenxter mx-auto">
          <p className="text-lg" style={{ marginBottom: '26px' }}>
            To access your {hasMultipleInvites ? 'referrals' : 'referral'},
            please create your free account.
          </p>

          <CreateAccountButton />

          <PlainButton
            className="d-block text-center text-reset mx-auto hover-underline"
            style={{ fontSize: '12px' }}
            onClick={() =>
              authService.signInAsCompanyUser({
                redirectUri: buildUrl(window.location.href, {
                  [REFERRAL_INVITE_LAYOUT_PARAMS.showClaimReferralModal]:
                    'true',
                }),
              })
            }
          >
            Already have an account? Login
          </PlainButton>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default ClaimReferralModal
