import React from 'react'

import { ESIGINATURE_PDF_SECTION_STYLES } from '../constants'

type Props = React.HTMLAttributes<HTMLDivElement>

const PdfSectionContainer: React.FC<Props> = ({ style, ...props }) => (
  <div {...props} style={{ ...ESIGINATURE_PDF_SECTION_STYLES, ...style }} />
)

export default PdfSectionContainer
