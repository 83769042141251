import { onlineManager } from '@tanstack/react-query'
import get from 'lodash/get'
import React from 'react'

import errorTypeService from '../../../lib/services/errorTypeService'
import InvalidUserContextErrorMessage from './components/InvalidUserContextErrorMessage'
import NewUpdateAvailableMessage from './components/NewUpdateAvailableMessage'
import OfflineMessage from './components/OfflineMessage'
import UnhandledErrorMessage from './components/UnhandledErrorMessage'

interface Props {
  error?: unknown
}

const ErrorMessage: React.FC<Props> = ({ error }) => {
  const isOffline = !onlineManager.isOnline()

  if (isOffline) {
    return <OfflineMessage />
  }

  if (isOutdatedAssetsError(error)) {
    return <NewUpdateAvailableMessage />
  }

  if (errorTypeService.isInvalidUserContextError(get(error, 'error'))) {
    return <InvalidUserContextErrorMessage />
  }

  return <UnhandledErrorMessage error={error} />
}

function isOutdatedAssetsError(error: unknown): boolean {
  const errorMessage = get(error, 'error.message', '') || ''

  return (
    errorMessage.includes('Failed to fetch dynamically imported module') ||
    errorMessage.includes('Importing a module script failed') ||
    errorMessage.includes('Unable to preload CSS')
  )
}

export default ErrorMessage
