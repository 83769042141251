import classNames from 'classnames'
import React from 'react'
import { Link, LinkProps } from 'react-router'

type Props = LinkProps & BackLinkProps

export interface BackLinkProps {
  label: string
  to: string
}

const BackLink: React.FC<Props> = ({ className, label, to, ...props }) => (
  <Link
    to={to}
    className={classNames('mb-3 d-block text-black', className)}
    {...props}
  >
    {`<`} {label}
  </Link>
)

export default BackLink
