import React from 'react'
import { Link } from 'react-router'

import {
  COMPANY_IDENTIFIER,
  COMPANY_TYPE_CODE,
} from '../../../../../lib/constants'
import {
  bool2Human,
  extractURL,
} from '../../../../../lib/helpers/helperFunctions'
import { currentPath } from '../../../../../lib/helpers/routeHelpers'
import routeService from '../../../../../lib/services/routeService'
import { CompanyProfileItem } from '../../../../../types/responses/companies'
import CollapsibleList from '../../../CollapsibleList'
import SectionHeading from './SectionHeading'

interface Props {
  companyProfile: CompanyProfileItem
}

interface Detail {
  label: string
  value: React.ReactNode | (() => React.ReactNode)
}

const AtAGlance: React.FC<Props> = ({ companyProfile }) => {
  const { directory, company } = companyProfile
  const isRegisteredAsIfa =
    companyProfile.company?.companyType.code === COMPANY_TYPE_CODE.ifa
  const websiteUrl = company?.url
    ? extractURL(company.url)
    : directory?.website
      ? extractURL(directory.website)
      : null
  const companyDetail = company?.companyDetail
  const companyNumber = company
    ? company.companyNumber
    : directory?.companyNumber
  const isFcaTradingName = !!directory?.fca?.frnTradingNameId
  const isFca = directory?.companyIdentifierType === COMPANY_IDENTIFIER.frn
  const isSra =
    directory?.companyIdentifierType === COMPANY_IDENTIFIER.sraNumber

  const details: Detail[] = [
    {
      label: 'Company #',
      value: companyNumber ? (
        <a
          target="_blank"
          href={`https://find-and-update.company-information.service.gov.uk/company/${companyNumber}`}
          rel="noreferrer"
        >
          {companyNumber}
        </a>
      ) : (
        'N/A'
      ),
    },
  ]

  if (websiteUrl) {
    details.push({
      label: 'Website',
      value: () => (
        <a target="_blank" href={websiteUrl.href} rel="noreferrer">
          {websiteUrl.hostname}
        </a>
      ),
    })
  }

  if (!isFcaTradingName && companyProfile.companiesHouse) {
    details.push({
      label: 'Years operational',
      value: (
        <>
          {Number(
            (new Date().getTime() -
              new Date(
                companyProfile.companiesHouse.dateIncorporated,
              ).getTime()) /
              31536000000,
          ).toFixed(0)}
        </>
      ),
    })
  }

  if (isSra) {
    details.push({
      label: 'SRA #',
      value: directory.sra?.number,
    })
  }

  if (isFca) {
    details.push({
      label: 'Status',
      value: (
        <>
          {isFcaTradingName ? (
            <>
              Trading name of{' '}
              <Link
                className="text-muted"
                to={routeService.companyProfile({
                  identifier: directory.fca?.frn ?? '',
                  identifierType: COMPANY_IDENTIFIER.frn,
                  backLabel: `Back to ${directory.name}`,
                  backUrl: currentPath(),
                })}
              >
                {directory.fca?.name}
              </Link>
            </>
          ) : (
            directory.fca?.status
          )}
        </>
      ),
    })

    details.push({
      label: 'FCA #',
      value: (
        <a
          target="_blank"
          href={'https://register.fca.org.uk/s/firm?id=' + directory.fca?.fcaId}
          rel="noreferrer"
        >
          {directory.fca?.frn}
        </a>
      ),
    })
  }

  if (companyProfile.isRegistered && companyDetail?.ifa) {
    if (typeof companyDetail?.ifa.isTransactionalWork === 'boolean') {
      details.push({
        label: 'Does one-off, transactional work',
        value: bool2Human(companyDetail?.ifa.isTransactionalWork),
      })
    }

    if (isRegisteredAsIfa) {
      details.push({
        label: 'Minimum investable assets',
        value: (
          <>
            {companyDetail?.ifa.minInvestableAssets
              ? companyDetail.ifa.minInvestableAssets.toLocaleString('en-GB', {
                  style: 'currency',
                  currency: 'GBP',
                  minimumFractionDigits: 0,
                })
              : 'No'}
          </>
        ),
      })
    }
  }

  if (!isFcaTradingName && directory && directory.postcode) {
    details.push({
      label: 'HQ',
      value:
        directory.address + ' ' + directory.county + ' ' + directory.postcode,
    })
  }

  if (
    (isSra && directory.tradingNames.length > 0) ||
    (isFca && !isFcaTradingName && directory.tradingNames.length > 1)
  ) {
    details.push({
      label: 'Trading Names',
      value: (
        <CollapsibleList
          collapseThreshold={5}
          items={directory.tradingNames.map((tradingName) => tradingName.name)}
        />
      ),
    })
  }

  return (
    <>
      <SectionHeading>At a glance</SectionHeading>

      <div className="table-responsive">
        <table className="table table-borderless table-sm table-ps-0 table-stacked mt-3 mt-sm-0">
          <tbody>
            {details.map((detail, index) => (
              <tr key={index}>
                <td style={{ minWidth: '130px' }}>
                  <span className="font-weight-500 text-black">
                    {detail.label}
                  </span>
                </td>

                <td>
                  {typeof detail.value === 'function'
                    ? detail.value()
                    : detail.value}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  )
}

export default AtAGlance
