import { useMutation } from '@tanstack/react-query'
import invariant from 'tiny-invariant'

import useAppDispatch from '../../../hooks/useAppDispatch'
import useAppSelector from '../../../hooks/useAppSelector'
import { MUTATION_KEYS } from '../../../lib/mutationKeys'
import referClientService from '../../../lib/services/referClientService'
import referralService from '../../../lib/services/referralService'
import {
  selectConsentType,
  selectReferralFirm,
  selectReferralTypeOption,
  selectServiceAreaIds,
  setReferClientMetadata,
} from '../../../redux/slices/referClient'
import { CreateNewReferralMetadataRequest } from '../../../types/requests/referrals'
import { CreateNewReferralMetadataMutationVariables } from '../types'

export function useCreateNewReferralMetadataMutation() {
  const serviceAreaIds = useAppSelector(selectServiceAreaIds)
  const referralFirm = useAppSelector(selectReferralFirm)
  const referralTypeOption = useAppSelector(selectReferralTypeOption)
  const consentType = useAppSelector(selectConsentType)
  const dispatch = useAppDispatch()

  return useMutation({
    mutationKey: MUTATION_KEYS.referClient.createReferralMetadata(),
    mutationFn: (
      variables?: CreateNewReferralMetadataMutationVariables | void,
    ) => {
      invariant(
        referralFirm,
        'Referral firm must be selected before calling this mutation',
      )

      const referralType = variables?.referralType || referralTypeOption

      const request: CreateNewReferralMetadataRequest = {
        toCompany: `/v1/companies/${referralFirm.id}`,
        serviceAreaIds,
        referralType: referralType
          ? referClientService.normalizeReferralType(referralType)
          : undefined,
        consentType,
      }

      return referralService.createNewReferralMetadata(request)
    },
    onSuccess: (metadata) => {
      dispatch(setReferClientMetadata(metadata))
    },
  })
}
