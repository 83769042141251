import { useMutation } from '@tanstack/react-query'
import { KeycloakRegisterOptions } from 'keycloak-js'
import posthog from 'posthog-js'
import invariant from 'tiny-invariant'

import { COMPANY_TYPE_CODE, POSTHOG_EVENT } from '../lib/constants'
import { isOfficeParent } from '../lib/helpers/officeHelpers'
import { ROUTES } from '../lib/routes'
import authService from '../lib/services/authService'
import userService from '../lib/services/userService'
import {
  selectCompanies,
  selectCurrentCompany,
  selectCurrentUser,
  selectIsAuthenticated,
  selectIsAuthenticatedClient,
  selectIsInitialized,
  selectIsUpdatingContext,
  selectUserTypes,
  setIsUpdatingContext,
  signOutSuccess,
} from '../redux/slices/session'
import { USER_TYPE } from '../types/misc'
import useAppDispatch from './useAppDispatch'
import useAppSelector from './useAppSelector'
import useNotyf from './useNotyf'
import {
  CLIENT_CHANGED,
  COMPANY_CHANGED,
  useUserContextChanged,
} from './useUserContextChanged'

interface UpdateCompanyContextMutationVariables {
  company: IRI
  redirectUri?: string
}

// TODO: Rename to useSession
export function useAuth() {
  const { postContextChange } = useUserContextChanged()
  const userTypes = useAppSelector(selectUserTypes)
  const currentCompany = useAppSelector(selectCurrentCompany)
  const companies = useAppSelector(selectCompanies)
  const isAuthenticatedClient = useAppSelector(selectIsAuthenticatedClient)
  const isInitialized = useAppSelector(selectIsInitialized)
  const isAuthenticated = useAppSelector(selectIsAuthenticated)
  const currentUser = useAppSelector(selectCurrentUser)
  const isUpdatingContext = useAppSelector(selectIsUpdatingContext)
  const notyf = useNotyf()
  const dispatch = useAppDispatch()

  const updateCompanyContextMutation = useMutation({
    mutationFn: (variables: UpdateCompanyContextMutationVariables) => {
      invariant(currentUser, 'Expected currentUser to be defined')

      dispatch(setIsUpdatingContext(true))

      return userService.updateUserContext(currentUser.id, {
        company: variables.company,
        scope: USER_TYPE.company,
      })
    },
    onSuccess: (response, variables) => {
      postContextChange(COMPANY_CHANGED, {
        company: { id: response.company.id },
      })
      authService.setUserSelectedCompanyId(response.company.id)
      authService.setUserSelectedScope(USER_TYPE.company)
      posthog.capture(POSTHOG_EVENT.switchCompany, {
        $set: { lastCompany: response.company.name },
      })

      if (isOfficeParent()) {
        window.location.reload()
      } else {
        window.location.assign(variables.redirectUri || ROUTES.index)
      }
    },
    onError: () => {
      notyf.error('Could not switch company')
    },
    onSettled: () => {
      dispatch(setIsUpdatingContext(false))
    },
  })

  const updateClientContextMutation = useMutation({
    mutationFn: () => {
      invariant(currentUser, 'Expected currentUser to be defined')
      dispatch(setIsUpdatingContext(true))

      return userService.updateUserContext(currentUser.id, {
        company: null,
        scope: USER_TYPE.client,
      })
    },
    onSuccess: () => {
      postContextChange(CLIENT_CHANGED, {})
      authService.setUserSelectedScope(USER_TYPE.client)
      posthog.capture(POSTHOG_EVENT.switchClient, { $set: {} })
      window.location.assign(ROUTES.index)
    },
    onError: () => {
      notyf.error('Could not switch to client account')
    },
    onSettled: () => {
      dispatch(setIsUpdatingContext(false))
    },
  })

  function register(options?: KeycloakRegisterOptions) {
    authService.registerAsCompanyUser(options)
  }

  function signOut(redirectUri?: string) {
    dispatch(signOutSuccess())
    authService.logout(redirectUri)
    posthog.reset()
    posthog.capture(POSTHOG_EVENT.logout)
  }

  function updateCompanyContext(
    options: UpdateCompanyContextMutationVariables,
  ) {
    updateCompanyContextMutation.mutate(options)
  }

  function updateClientContext() {
    updateClientContextMutation.mutate()
  }

  const companyCode = currentCompany?.companyType.code
  const isIcaewUser = currentUser?.permissions.includes('icaew/view') || false

  return {
    currentUser,
    companies,
    userTypes,
    isInitialized,
    isAuthenticated,
    isGuest: !isAuthenticated,
    currentCompany,
    isCompanyUser: !!currentCompany,
    register,
    signOut,
    updateCompanyContext,
    isUpdatingContext,
    updateClientContext,
    isFcaFirm: currentCompany?.companyType.fca || false,
    isIfa: companyCode === COMPANY_TYPE_CODE.ifa,
    isAccountant: companyCode === COMPANY_TYPE_CODE.accountant,
    isSolicitor: companyCode === COMPANY_TYPE_CODE.solicitor,
    isRegulatedByIcaew: currentCompany?.isRegulatedByIcaew || false,
    isIcaewUser,
    isClientUser: isAuthenticatedClient,
  }
}
