import classNames from 'classnames'
import React from 'react'

const Main: React.FC<React.HTMLAttributes<HTMLDivElement>> = (props) => (
  <main {...props} className={classNames('main', props.className)}>
    {props.children}
  </main>
)

export default Main
