import './dumpStyles.scss'

import React from 'react'
import { ObjectInspector } from 'react-inspector'

interface Props {
  label?: string
  data: unknown
}

const Dump: React.FC<Props> = ({ label, data }) => {
  return (
    <div className="border-box box-shadow-lg p-2 my-2">
      <div className="mb-1">{label}</div>
      <ObjectInspector data={data} />
    </div>
  )
}

export default Dump
