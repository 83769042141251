import React from 'react'

import Markers from '../../components/Markers'

type Props = React.HTMLAttributes<HTMLDivElement> & {
  postcodes: { postcode: string; name: string }[]
  setHasValidMarkers: (value: boolean) => void
}

const Map: React.FC<Props> = ({
  style,
  postcodes,
  setHasValidMarkers,
  ...props
}) => {
  if (!postcodes.length) {
    return null
  }

  return (
    <div style={style} {...props}>
      <Markers postcodes={postcodes} setHasValidMarkers={setHasValidMarkers} />
    </div>
  )
}

export default Map
