import React from 'react'

import { ReferralIndividual } from '../../../../../../types/misc'
import { CompanyProfileItemCompany } from '../../../../../../types/responses/companies'
import SelectCompanyIndividuals from '../../../../../select/SelectCompanyIndividuals'
import HelpText from '../../../../HelpText'

interface Props {
  company: CompanyProfileItemCompany
  referralIndividuals: ReferralIndividual[]
  onSelectReferralIndividuals: (individuals: ReferralIndividual[]) => void
  isDisabled?: boolean
}

const CreateOrUpdateReferralLink: React.FC<Props> = ({
  company,
  referralIndividuals,
  onSelectReferralIndividuals,
  isDisabled,
}) => {
  return (
    <>
      <div className="mb-2">
        <p className="text-lg mb-0">
          Choose the individuals at {company.presentationName} that you want to
          refer to
        </p>
      </div>

      <SelectCompanyIndividuals
        company={company}
        selected={referralIndividuals}
        onSelect={onSelectReferralIndividuals}
        additionalParams={{ hasCalendarConnection: true }}
        isDisabled={isDisabled}
      />

      <HelpText>
        Please note: You can only select advisers from{' '}
        {company.presentationName} who have connected their calendars.
      </HelpText>
    </>
  )
}

export default CreateOrUpdateReferralLink
