import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { Helmet } from 'react-helmet-async'

import usePosthogCapture from '../../../hooks/posthogCapture/usePosthogCapture'
import StepContent from './components/StepContent'
import StepControls from './components/StepControls'
import StepList from './components/StepList'
import { useWalkthroughSteps } from './hooks/useWalkthroughSteps'

export interface WalkthroughFlowProps {
  title?: React.ReactNode
  variant: 'page' | 'component'
  completeButtonText: string
  onComplete: () => void
  isCompleting?: boolean
}

const WalkthroughFlow: React.FC<WalkthroughFlowProps> = ({
  title,
  variant,
  completeButtonText,
  onComplete,
  isCompleting = false,
}) => {
  const { currentStep } = useWalkthroughSteps()

  usePosthogCapture({
    dependencyListener: {
      dataProvider: () => ({
        appArea: 'walkthrough',
        action: 'step-visited',
        stepName: currentStep.key,
      }),
      dependencyList: [currentStep],
    },
  })

  const isPageVariant = variant === 'page'

  return (
    <>
      <Helmet title={currentStep.label} />

      <StepControls
        variant={variant}
        title={title}
        completeButtonText={completeButtonText}
        onComplete={onComplete}
        isCompleting={isCompleting}
      />

      <Container className={isPageVariant ? 'py-8' : 'py-5 py-sm-6'}>
        <Row>
          <Col className="mb-4 mb-lg-0" lg={3}>
            <StepList />
          </Col>

          <Col className="d-flex flex-column ps-lg-4" lg={9}>
            <StepContent />
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default WalkthroughFlow
