import React from 'react'

interface Props {
  children: React.ReactNode
}

const CardTitle: React.FC<Props> = ({ children }) => (
  <h1 className="h3 mb-3 fw-bolder text-black">{children}</h1>
)

export default CardTitle
