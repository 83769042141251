import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@rq-ratings/pro-regular-svg-icons'
import React from 'react'
import { Link } from 'react-router'

import useAppSelector from '../../../hooks/useAppSelector'
import { useIsCompanyUser } from '../../../hooks/useIsCompanyUser'
import { useServiceAreas } from '../../../hooks/useServiceAreas'
import { THEME_PALETTE } from '../../../lib/constants'
import serviceFeeService from '../../../lib/services/serviceFeeService'
import {
  CommercialAgreementTypeOption,
  selectServiceFeeErrors,
} from '../../../redux/slices/commercialAgreementsForm'
import ServiceFeeCategory from './columns/ServiceFeeCategory'
import ServiceFeeExample from './columns/ServiceFeeExample'
import ServiceFeeNotes from './columns/ServiceFeeNotes'
import ServiceFeePassBackNotes from './columns/ServiceFeePassBackNotes'
import ServiceFeeShareType from './columns/ServiceFeeShareType'
import ServiceFeeShareValue from './columns/ServiceFeeShareValue'

export interface ServiceFeesTableRowsProps {
  serviceFees: ServiceFeeDetail[]
  commercialAgreementId?: number
  addAgreementLink?: string
  agreementType: CommercialAgreementTypeOption
  renderActionsColumn?: (
    serviceFee: ServiceFeeDetail,
    index: number,
  ) => React.ReactNode
  onRowClick?: (serviceFee: ServiceFeeDetail, index: number) => void
  isRecommendedPartnerTerms?: boolean
  isPreviewLetter: boolean
}

export interface ServiceFeeDetail {
  serviceArea?: string | null
  serviceAreaOther?: string | null
  feeCategory?: string | null
  feeCategoryOther?: string | null
  shareType?: string | null
  shareValue?: number | null
  duration?: number | string | null
  notes?: string | null
  passBackFee?: boolean | null
  passBackFeeNotes?: string | null
  example?: string | null
}

const ServiceFeesTableRows: React.FC<ServiceFeesTableRowsProps> = ({
  commercialAgreementId,
  serviceFees,
  addAgreementLink,
  agreementType,
  renderActionsColumn,
  onRowClick,
  isRecommendedPartnerTerms,
  isPreviewLetter,
}) => {
  serviceFeeService.validateServiceFeesOrFail(serviceFees)
  const isCompanyUser = useIsCompanyUser()
  const serviceAreas = useServiceAreas()
  const serviceFeeErrors = useAppSelector(selectServiceFeeErrors)

  const shouldAllowAddingServiceFees =
    !commercialAgreementId && addAgreementLink

  const orderedServiceFees = serviceFeeService.orderServiceFeesByName(
    serviceFees,
    serviceAreas,
  )

  const serviceFeeGroups = Object.entries(
    serviceFeeService.getServiceFeeGroupsForDisplay({
      serviceFees,
      serviceAreas,
    }),
  )

  if (serviceFeeGroups.length === 0) {
    return (
      <tr>
        <td colSpan={6} className="table-striped-bg">
          <div
            className="d-flex justify-content-center align-items-center"
            style={{ height: addAgreementLink ? '130px' : '30px' }}
          >
            {shouldAllowAddingServiceFees && addAgreementLink ? (
              <Link
                to={addAgreementLink}
                className="btn btn-success btn-lg d-flex align-items-center gap-2"
              >
                <FontAwesomeIcon icon={faPlus} />
                Add terms
              </Link>
            ) : (
              <p>No commercial terms</p>
            )}
          </div>
        </td>
      </tr>
    )
  }

  return (
    <>
      {/* Iterate over service fee groups */}
      {serviceFeeGroups.map((serviceFeeGroup, serviceFeeGroupIndex) => {
        const [serviceAreaName, serviceAreaFees] = serviceFeeGroup

        // Iterate over each service fee within the group
        return serviceAreaFees.map((serviceFee, serviceFeeGroupItemIndex) => {
          const isFirstServiceAreaFee = serviceFeeGroupItemIndex === 0

          // Note: it's important to use serviceFee.index here to ensure the
          // correct index is passed to any callbacks like `onRowClick`
          // or `renderActionsColumn`
          const serviceFeeIndex = serviceFee.index

          // Use a striped background for every other row (starting from the first)
          const shouldUseStripedBg = serviceFeeGroupIndex % 2 === 0

          const hasError =
            serviceFeeErrors &&
            Object.keys(serviceFeeErrors)
              .map(Number)
              .includes(serviceFeeIndex + 1)

          return (
            <tr
              css={{
                td: {
                  color: hasError ? THEME_PALETTE.danger : undefined,
                },
              }}
              key={`${serviceFeeGroupIndex}[${serviceFeeGroupItemIndex}]`}
              style={{
                backgroundColor: shouldUseStripedBg
                  ? THEME_PALETTE.tableStripedBg
                  : undefined,
              }}
              onClick={() => {
                if (onRowClick) {
                  onRowClick(serviceFee, serviceFeeIndex)
                }
              }}
            >
              {isFirstServiceAreaFee && (
                <td
                  className=" sticky-column-start align-top"
                  rowSpan={serviceAreaFees.length}
                  style={{
                    fontWeight: 'normal',
                    backgroundColor: shouldUseStripedBg
                      ? THEME_PALETTE.tableStripedBg
                      : undefined,
                  }}
                >
                  {serviceAreaName}
                </td>
              )}

              <TableCell stripedBg={shouldUseStripedBg}>
                <ServiceFeeCategory serviceFee={serviceFee} />
              </TableCell>

              {serviceFeeService.shouldShowShareTypeAndValueColumns({
                isCompanyUser,
                isPreviewLetter,
              }) && (
                <>
                  <TableCell stripedBg={shouldUseStripedBg}>
                    <ServiceFeeShareType serviceFee={serviceFee} />
                  </TableCell>

                  <TableCell stripedBg={shouldUseStripedBg}>
                    <ServiceFeeShareValue serviceFee={serviceFee} />
                  </TableCell>
                </>
              )}

              {serviceFeeService.shouldShowFeePassBackColumn({
                serviceFees: orderedServiceFees,
                isRecommendedPartnerTerms,
              }) && (
                <TableCell stripedBg={shouldUseStripedBg}>
                  <ServiceFeePassBackNotes serviceFee={serviceFee} />
                </TableCell>
              )}

              {serviceFeeService.shouldShowNotesColumn(orderedServiceFees) && (
                <TableCell stripedBg={shouldUseStripedBg}>
                  <ServiceFeeNotes serviceFee={serviceFee} />
                </TableCell>
              )}

              {serviceFeeService.shouldShowExampleColumn(
                agreementType,
                orderedServiceFees,
              ) && (
                <TableCell stripedBg={shouldUseStripedBg}>
                  <ServiceFeeExample serviceFee={serviceFee} />
                </TableCell>
              )}

              {renderActionsColumn &&
                renderActionsColumn(serviceFee, serviceFeeIndex)}
            </tr>
          )
        })
      })}
    </>
  )
}

const TableCell: React.FC<{
  children: React.ReactNode
  stripedBg: boolean
}> = ({ children, stripedBg }) => (
  <td
    className="align-top"
    style={
      stripedBg ? { backgroundColor: THEME_PALETTE.tableStripedBg } : undefined
    }
  >
    {children}
  </td>
)

export default ServiceFeesTableRows
