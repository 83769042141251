import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft, faArrowRight } from '@rq-ratings/pro-regular-svg-icons'
import classNames from 'classnames'
import React from 'react'

type Props = Omit<React.HTMLAttributes<HTMLDivElement>, 'children'>

const ScrollHelpText: React.FC<Props> = ({ className, ...props }) => (
  <div
    className={classNames(
      'd-flex d-sm-none gap-2 justify-content-end align-items-center text-muted text-sm mt-2 position-sticky text-center',
      className,
    )}
    {...props}
  >
    <FontAwesomeIcon
      icon={faArrowLeft}
      style={{ position: 'relative', top: '1px' }}
    />
    Scroll to view more
    <FontAwesomeIcon
      icon={faArrowRight}
      style={{ position: 'relative', top: '1px' }}
    />
  </div>
)
export default ScrollHelpText
