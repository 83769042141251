import { useSearchParams } from 'react-router'

import { REFERRAL_INVITE_LAYOUT_PARAMS } from '../../../layouts/ReferralInviteLayout/constants'

export function useClaimReferralModal() {
  const [searchParams, setSearchParams] = useSearchParams()

  const isShowing =
    searchParams.get(REFERRAL_INVITE_LAYOUT_PARAMS.showClaimReferralModal) ===
    'true'

  function show() {
    searchParams.set(
      REFERRAL_INVITE_LAYOUT_PARAMS.showClaimReferralModal,
      'true',
    )
    setSearchParams(searchParams)
  }

  function hide() {
    searchParams.delete(REFERRAL_INVITE_LAYOUT_PARAMS.showClaimReferralModal)
    setSearchParams(searchParams)
  }

  return {
    isShowing,
    show,
    hide,
  }
}
