import { ErrorBoundary } from '@sentry/react'
import React, { Suspense } from 'react'
import { Offcanvas } from 'react-bootstrap'

import { useBackLinkParams } from '../../../../hooks/useBackLinkParams'
import { useCompanyProfileSearchParams } from '../../../../hooks/useCompanyProfileSearchParams'
import { useIsRoute } from '../../../../hooks/useIsRoute'
import useMinWidth from '../../../../hooks/useMinWidth'
import { ROUTES } from '../../../../lib/routes'
import errorTypeService from '../../../../lib/services/errorTypeService'
import { CompanyIdentifierType } from '../../../../types/misc'
import BackLink from '../../BackLink'
import FullPageErrorFallback from '../../FullPageErrorFallback'
import CompanyProfile, { CompanyProfileSkeleton } from '../CompanyProfile'
import DetailsNotFound from './DetailsNotFound'

const CompanyProfileModal: React.FC = () => {
  const { clearParams, searchParams } = useCompanyProfileSearchParams()
  const isMinWidthMd = useMinWidth('md')
  const { backUrl, backLabel } = useBackLinkParams()
  const { identifier, identifierType } = searchParams
  const isRoute = useIsRoute()

  if (!identifier || !identifierType) {
    return null
  }

  if (isRoute(ROUTES.companyProfile)) {
    return null
  }

  return (
    <>
      <Offcanvas
        show
        placement="end"
        onHide={clearParams}
        style={{ width: isMinWidthMd ? '880px' : '100%' }}
      >
        <Offcanvas.Header closeButton className="px-4">
          <Offcanvas.Title>
            {backLabel && backUrl && (
              <BackLink
                label={backLabel}
                to={backUrl}
                className="fw-normal mb-none"
              />
            )}
          </Offcanvas.Title>
        </Offcanvas.Header>

        <Offcanvas.Body className="pt-0 pb-5">
          <ErrorBoundary
            fallback={(error) =>
              errorTypeService.is404Error(error.error) ? (
                <DetailsNotFound className="px-4" />
              ) : (
                <FullPageErrorFallback
                  error={error}
                  className="mt-none "
                  cardProps={{ className: 'box-shadow-lg p-2' }}
                />
              )
            }
          >
            <Suspense fallback={<CompanyProfileSkeleton />}>
              <CompanyProfile
                identifier={identifier}
                identifierType={identifierType as CompanyIdentifierType}
              />
            </Suspense>
          </ErrorBoundary>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  )
}

export default CompanyProfileModal
