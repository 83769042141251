import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@rq-ratings/pro-regular-svg-icons'
import { faQuestionCircle } from '@rq-ratings/pro-solid-svg-icons'
import React from 'react'
import { Container } from 'react-bootstrap'

import rqLogo from '../../../../assets/img/logo.svg'
import useModal from '../../../../hooks/useModal'
import { EXTERNAL_LINKS } from '../../../../lib/constants'
import PlainButton from '../../../misc/PlainButton'
import ClaimAccountButton from './ClaimAccountButton'
import WalkthroughModal from './WalkthroughModal'

const Header: React.FC = () => {
  const {
    isShowingModal: isShowingWalkthroughModal,
    showModal: showWalkthroughModal,
    hideModal: hideWalkthroughModal,
  } = useModal()

  return (
    <header
      className="bg-white py-3 position-sticky border-bottom"
      style={{ minHeight: '80px' }}
    >
      <Container className="d-flex flex-column flex-sm-row align-items-center justify-content-sm-between gap-3">
        <a
          href={EXTERNAL_LINKS.marketingWebsite}
          target="_blank"
          rel="opener noreferrer"
        >
          <img src={rqLogo} alt="RQ" style={{ maxWidth: '70px' }} />
        </a>

        <div
          className="w-100 w-sm-auto d-flex flex-column flex-sm-row row-gap-2"
          style={{ columnGap: '30px' }}
        >
          <ClaimAccountButton className="btn btn-success btn-lg d-flex align-items-center justify-content-center gap-2 btn-fluid order-sm-last">
            Claim your free account
            <FontAwesomeIcon icon={faArrowRight} />
          </ClaimAccountButton>

          <PlainButton
            onClick={showWalkthroughModal}
            className="d-flex align-items-center justify-content-center hover-underline"
            style={{ gap: '6px' }}
          >
            <FontAwesomeIcon
              icon={faQuestionCircle}
              className="position-relative"
              style={{ fontSize: '16px' }}
            />
            How does RQ work?
          </PlainButton>
        </div>
      </Container>

      {isShowingWalkthroughModal && (
        <WalkthroughModal onHide={hideWalkthroughModal} />
      )}
    </header>
  )
}

export default Header
