import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle } from '@rq-ratings/pro-regular-svg-icons'
import React from 'react'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import Select from 'react-select'
import invariant from 'tiny-invariant'

import { useUserCompanies } from '../../hooks/useUserCompanies'
import { getTooltipClassName } from '../../lib/helpers/helperFunctions'
import { SessionMetadataCompany } from '../../types/responses/session-metadata'

export type SelectUserCompanyProps = Omit<
  React.ComponentProps<typeof Select>,
  'onChange' | 'isOptionDisabled'
> & {
  isOptionDisabled?: (option: SessionMetadataCompany) => boolean
  renderDisabledOptionTooltip?: (
    option: SessionMetadataCompany,
  ) => React.ReactElement | string
  onChange: (company: SessionMetadataCompany | null) => void
}

export interface SelectUserCompanyOption {
  label: string
  value: number
  isDisabled: boolean
}

const SelectUserCompany: React.FC<SelectUserCompanyProps> = ({
  onChange,
  isOptionDisabled,
  renderDisabledOptionTooltip,
  ...props
}) => {
  const companies = useUserCompanies()

  const options: SelectUserCompanyOption[] = companies.map(
    (company): SelectUserCompanyOption => ({
      label: company.presentationName,
      value: company.id,
      isDisabled: isOptionDisabled ? isOptionDisabled(company) : false,
    }),
  )

  function formatOptionLabel(option: SelectUserCompanyOption) {
    const company = companies.find((company) => company.id === option.value)

    invariant(company, `Failed to find company with ID: ${option.value}`)

    const disabledOptionTooltip = renderDisabledOptionTooltip
      ? renderDisabledOptionTooltip(company)
      : undefined

    const isDisabled = isOptionDisabled ? isOptionDisabled(company) : false

    return (
      <>
        {company.presentationName}

        {isDisabled && disabledOptionTooltip && (
          <OverlayTrigger
            overlay={
              <Tooltip className={getTooltipClassName(disabledOptionTooltip)}>
                {disabledOptionTooltip}
              </Tooltip>
            }
          >
            <FontAwesomeIcon
              icon={faInfoCircle}
              className="text-disabled d-inline-block ms-2 position-relative"
              style={{ fontSize: '14px', top: '1px' }}
            />
          </OverlayTrigger>
        )}
      </>
    )
  }

  return (
    <Select
      options={options}
      formatOptionLabel={(option) =>
        // TODO: Not sure how to avoid the type assertion here
        formatOptionLabel(option as SelectUserCompanyOption)
      }
      onChange={(option) => {
        if (!option) {
          onChange(null)

          return
        }

        // TODO: Not sure how to avoid the type assertion here
        const typedOption = option as SelectUserCompanyOption

        const company = companies.find(
          (company) => company.id === typedOption.value,
        )
        invariant(
          company,
          `Failed to find company with ID: ${typedOption.value}`,
        )

        onChange(company)
      }}
      {...props}
    />
  )
}

export default SelectUserCompany
