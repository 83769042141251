import { useSuspenseQuery } from '@tanstack/react-query'

import { useParamOrFail } from '../../../../hooks/useParamOrFail'
import { isDevelopment } from '../../../../lib/helpers/envHelpers'
import { QUERY_KEYS } from '../../../../lib/queryKeys'
import referralService from '../../../../lib/services/referralService'
import { REFERRAL_INVITES_PARAMS } from '../../../../pages/referralInvites/constants'

export function useReferralInvitesQuery() {
  const token = useParamOrFail(REFERRAL_INVITES_PARAMS.token)

  return useSuspenseQuery({
    queryKey: QUERY_KEYS.referralInvites({ token }),
    queryFn: () => referralService.getReferralInvites({ token, pageSize: 6 }),
    staleTime: 30000,
    retry: isDevelopment() ? false : undefined,
  })
}
