import { useMutation, useQueryClient } from '@tanstack/react-query'
import React, { useRef, useState } from 'react'
import { Button, Card, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { useMount } from 'react-use'

import useNotyf from '../../../../../../hooks/useNotyf'
import { QUERY_KEYS } from '../../../../../../lib/queryKeys'
import referralLinkService from '../../../../../../lib/services/referralLinkService'
import { ReferralIndividual } from '../../../../../../types/misc'
import { CompanyProfileItemCompany } from '../../../../../../types/responses/companies'
import CardTitle from '../../../../../typography/CardTitle'
import ActionButton from '../../../../ActionButton'
import { useHandleCreateOrUpdateReferralLinkError } from '../useHandleCreateOrUpdateReferralLinkError'
import CreateOrUpdateReferralLink from './CreateOrUpdateReferralLink'

interface Props {
  company: CompanyProfileItemCompany
  hasReferralLink: boolean
  onCancel: () => void
  onCreate: () => void
}

const CreateReferralLinkCard: React.FC<Props> = ({
  company,
  hasReferralLink,
  onCancel,
  onCreate,
}) => {
  const queryClient = useQueryClient()
  const [referralIndividuals, setReferralIndividuals] = useState<
    ReferralIndividual[]
  >([])
  const notyf = useNotyf()
  const containerRef = useRef<HTMLDivElement | null>(null)
  const { handleCreateOrUpdateReferralLinkError } =
    useHandleCreateOrUpdateReferralLinkError()

  const createLinkMutation = useMutation({
    mutationFn: () =>
      referralLinkService.createReferralLink({
        toCompany: company['@id'],
        userIds: referralIndividuals.map((individual) => individual.userId),
      }),
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: QUERY_KEYS.referralLinks({ ['toCompany.id']: company.id }),
      })

      setReferralIndividuals([])
      onCreate()
      notyf.success('Calendar link created successfully')
    },
    onError: handleCreateOrUpdateReferralLinkError,
  })

  useMount(() => {
    containerRef.current?.scrollIntoView({
      block: 'start',
      inline: 'nearest',
      behavior: 'smooth',
    })
  })

  const isProcessing = createLinkMutation.isPending
  const canCreateReferralLink = referralIndividuals.length > 0 && !isProcessing

  return (
    <div ref={containerRef}>
      <Card className="box-shadow-lg border-box">
        <Card.Body className="pb-0">
          <CardTitle>
            Create {hasReferralLink ? 'another' : ''} calendar link
          </CardTitle>

          <CreateOrUpdateReferralLink
            company={company}
            referralIndividuals={referralIndividuals}
            onSelectReferralIndividuals={setReferralIndividuals}
          />
        </Card.Body>

        <Card.Footer className="d-flex flex-col flex-sm-row gap-2">
          <div className="btn-fluid">
            <OverlayTrigger
              overlay={
                canCreateReferralLink ? (
                  <div />
                ) : (
                  <Tooltip>Please select at least one individual</Tooltip>
                )
              }
            >
              <div>
                <ActionButton
                  onClick={() => createLinkMutation.mutate()}
                  isProcessing={isProcessing}
                  isProcessingText="Creating link"
                  size="lg"
                  variant="success"
                  className="btn-fluid"
                  disabled={!canCreateReferralLink}
                >
                  Create
                </ActionButton>
              </div>
            </OverlayTrigger>
          </div>

          <Button
            size="lg"
            variant="outline-secondary"
            className="btn-fluid"
            onClick={onCancel}
          >
            Cancel
          </Button>
        </Card.Footer>
      </Card>
    </div>
  )
}

export default CreateReferralLinkCard
