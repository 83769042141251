import React from 'react'
import { Card } from 'react-bootstrap'
import { useMount } from 'react-use'

import { useCaptureErrorShownToUserEvent } from '../../../hooks/useCaptureErrorShownToUserEvent'

const NylasSchedulerError: React.FC = () => {
  const captureErrorShownToUserEvent = useCaptureErrorShownToUserEvent()

  useMount(() => {
    captureErrorShownToUserEvent()
  })

  return (
    <Card
      className="border-box box-shadow-lg mx-auto text-center"
      style={{ maxWidth: '600px' }}
    >
      <Card.Body>
        <h3 className="mb-3">Problem loading scheduler</h3>

        <p className="mb-0">
          Sorry, there was a problem loading the meeting scheduler. Please try
          again later.
        </p>
      </Card.Body>
    </Card>
  )
}

export default NylasSchedulerError
