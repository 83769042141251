import React, { useState } from 'react'

import { useIsMinWidthLg } from '../../../../../hooks/useIsMinWidthLg'
import { useIsMinWidthMd } from '../../../../../hooks/useIsMinWidthMd'
import VimeoEmbed from '../../../../misc/VideoEmbed'
import { WALKTHROUGH_STEP } from '../../constants'
import { useWalkthroughSteps } from '../../hooks/useWalkthroughSteps'
import thumbnailImageUrl from './thumbnail.png'

const IntroducingRq: React.FC = () => {
  const { currentStep } = useWalkthroughSteps()
  const [isPlayingVideo, setIsPlayingVideo] = useState(false)
  const isMinWidthMd = useIsMinWidthMd()
  const isMinWidthLg = useIsMinWidthLg()

  function getVideoHeight() {
    if (isMinWidthLg) {
      return 460
    }

    if (isMinWidthMd) {
      return 400
    }

    return 300
  }

  // Don't play the video if the user proceeds to another step
  const shouldPlayVideo =
    currentStep.key === WALKTHROUGH_STEP.introducingRq && isPlayingVideo

  if (isPlayingVideo) {
    return (
      <VimeoEmbed
        videoUrl="https://player.vimeo.com/video/1016079540"
        height={getVideoHeight()}
        autoplay={shouldPlayVideo}
      />
    )
  }

  return (
    <img
      src={thumbnailImageUrl}
      alt="Introducing RQ"
      className="img-fluid hover-dim cursor-pointer"
      onClick={() => setIsPlayingVideo(true)}
    />
  )
}

export default IntroducingRq
