import { useQueryClient } from '@tanstack/react-query'
import React from 'react'
import { HelpCircle } from 'react-feather'

import compassIcon from '../assets/img/icons/compass.svg'
import contactsIcon from '../assets/img/icons/contacts.svg'
import professionalNetworkIcon from '../assets/img/icons/professional-network.svg'
import referClientIcon from '../assets/img/icons/refer-client.svg'
import referralsIcon from '../assets/img/icons/referrals.svg'
import { SidebarPage } from '../components/layouts/DashboardLayout/components/sidebar/Sidebar'
import SvgImage from '../components/misc/SvgImage'
import { COMPANY_DASHBOARD_SIDEBAR_LINKS } from '../lib/constants'
import { ROUTES } from '../lib/routes'
import routeService from '../lib/services/routeService'
import { FETCH_REFERRAL_SETUP_DATA_QUERY_OPTIONS } from '../pages/referClient/constants'
import { selectIsOnboardingChecklistIncomplete } from '../redux/slices/onboardingChecklist'
import { REFER_CLIENT_STEP } from '../redux/slices/referClient'
import useAppSelector from './useAppSelector'
import { useNotificationsCount } from './useNotificationsCount'

export function useCompanyDashboardSidebarLinks() {
  const shouldShowOnboardingChecklist = useAppSelector(
    selectIsOnboardingChecklistIncomplete,
  )
  const notificationsCount = useNotificationsCount()
  const queryClient = useQueryClient()

  const links: SidebarPage[] = []

  if (shouldShowOnboardingChecklist) {
    links.push({
      title: 'Getting started with RQ',
      href: ROUTES.gettingStarted,
      icon: <HelpCircle style={{ color: '#0D96FF' }} />,
    })
  }

  const professionalNetworkLink: SidebarPage = {
    href: ROUTES.professionalNetworkRelationships,
    icon: <SvgImage src={professionalNetworkIcon} alt="Professional network" />,
    title: COMPANY_DASHBOARD_SIDEBAR_LINKS.yourRelationships,
    badge:
      notificationsCount.professionalNetwork > 0
        ? `${notificationsCount.professionalNetwork}`
        : undefined,
  }

  const contactsLink: SidebarPage = {
    title: COMPANY_DASHBOARD_SIDEBAR_LINKS.contacts,
    href: ROUTES.contacts,
    icon: <SvgImage src={contactsIcon} alt="Contacts" />,
  }

  const referralsLink: SidebarPage = {
    title: COMPANY_DASHBOARD_SIDEBAR_LINKS.referrals,
    href: ROUTES.referrals,
    icon: <SvgImage src={referralsIcon} alt="Referrals" />,
    badge:
      notificationsCount.referrals > 0
        ? `${notificationsCount.referrals}`
        : undefined,
  }

  const referYourClientLink: SidebarPage = {
    title: COMPANY_DASHBOARD_SIDEBAR_LINKS.referYourClient,
    href: routeService.referClient({
      step: Object.keys(REFER_CLIENT_STEP)[0],
    }),
    onMouseOver: () => {
      queryClient.prefetchQuery(FETCH_REFERRAL_SETUP_DATA_QUERY_OPTIONS)
    },
    icon: <SvgImage src={referClientIcon} alt="Refer a client" />,
  }

  const compassLink: SidebarPage = {
    title: COMPANY_DASHBOARD_SIDEBAR_LINKS.compass,
    href: ROUTES.companyCompassDashboard,
    icon: <SvgImage src={compassIcon} alt="Compass" />,
  }

  links.push(
    professionalNetworkLink,
    contactsLink,
    referralsLink,
    referYourClientLink,
    compassLink,
  )

  return links
}
