import React from 'react'

import { DATE_FORMATS } from '../../../../lib/constants'
import { formatDate } from '../../../../lib/helpers/helperFunctions'
import { SIGNATURE_BOX_ID } from '../constants'
import { ESignatureConsentPdfReferral } from '../ReferralESignatureConsentPdf'
import ESignatureUnderstandingCheckbox from './ESignatureUnderstandingCheckbox'
import SignatureBox from './SignatureBox'

interface Props {
  referral: ESignatureConsentPdfReferral
}

const Footer: React.FC<Props> = ({ referral }) => {
  const { client, fromCompany } = referral
  const isPreview = !referral.id

  return (
    <section className="avoid-page-break">
      <div className="mb-3 mt-4">
        <p className="mb-1">Yours</p>
        <p className="font-weight-bold">{fromCompany.presentationName}</p>
      </div>

      <div id={SIGNATURE_BOX_ID}>
        <div className="d-flex align-items-center mb-2" style={{ gap: '6px' }}>
          <ESignatureUnderstandingCheckbox />
        </div>

        <div className="d-flex gap-4 justify-content-md-between align-items-end">
          <div>
            <div style={{ marginBottom: '6px' }}>
              <SignatureBox />
            </div>

            <p className="font-weight-bold mb-0">{client.fullName}</p>
          </div>

          <div
            className="rounded-3 px-3 py-2 text-center"
            style={{
              backgroundColor: 'rgb(13, 150, 255, 0.1)',
              width: '165px',
            }}
          >
            {isPreview
              ? 'Date Signed'
              : formatDate(new Date(), DATE_FORMATS.DAY_MONTH_YEAR)}
          </div>
        </div>
      </div>
    </section>
  )
}

export default Footer
