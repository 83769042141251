import React from 'react'

import useAppDispatch from '../../../../hooks/useAppDispatch'
import useAppSelector from '../../../../hooks/useAppSelector'
import { ESIGNATURE_UNDERSTANDING_CHECKBOX_LABEL } from '../../../../pages/referralESignatureConsent/constants'
import {
  selectHasConfirmedESignatureUnderstanding,
  selectHasSubmittedSignature,
  selectIsSignable,
  setHasConfirmedESignatureUnderstanding,
} from '../../../../redux/slices/referralSignatureConsentPdf'

interface Props {
  isSubmittingSignature?: boolean
}

const ESignatureUnderstandingCheckbox: React.FC<Props> = ({
  isSubmittingSignature = false,
}) => {
  const isSignable = useAppSelector(selectIsSignable)
  const hasSubmittedESignature = useAppSelector(selectHasSubmittedSignature)
  const hasConfirmedESignatureUnderstanding = useAppSelector(
    selectHasConfirmedESignatureUnderstanding,
  )
  const dispatch = useAppDispatch()

  function toggleCheckbox() {
    dispatch(
      setHasConfirmedESignatureUnderstanding(
        !hasConfirmedESignatureUnderstanding,
      ),
    )
  }

  const isDisabled =
    !isSignable || hasSubmittedESignature || isSubmittingSignature

  return (
    <>
      <input
        type="checkbox"
        disabled={isDisabled}
        checked={hasConfirmedESignatureUnderstanding}
        onChange={toggleCheckbox}
        className="form-check-input position-relative"
        style={{ top: '-1px' }}
        id="agreeToESignatureValidity"
      />

      <label htmlFor="agreeToESignatureValidity">
        {ESIGNATURE_UNDERSTANDING_CHECKBOX_LABEL}
      </label>
    </>
  )
}
export default ESignatureUnderstandingCheckbox
