import { addDays } from 'date-fns/addDays'
import queryString from 'query-string'

import { DATE_FORMATS } from '../../lib/constants'
import {
  formatDate,
  getCommissionRange,
} from '../../lib/helpers/helperFunctions'
import { ROUTES } from '../../lib/routes'
import { ReferralCollectionParams } from '../../lib/services/referralService'
import { ReferralsTableFilters, SortBy } from './components/ReferralsTable'
import { REFERRALS_TABLE_PAGE_SIZE } from './constants'
import { ReferralsTab } from './ReferralsPage'

export function buildSearchParams(options: {
  tab: ReferralsTab
  filters: ReferralsTableFilters
  sortBy: SortBy
  page: number
  currentCompanyId: ID
}): ReferralCollectionParams {
  const { tab, filters, sortBy, page, currentCompanyId } = options

  const params: ReferralCollectionParams = {
    page,
    pageSize: REFERRALS_TABLE_PAGE_SIZE,
  }

  // Set search filters
  if (filters.referralDate) {
    const nextDate = addDays(new Date(filters.referralDate), 1)

    params['referralDate[after]'] = filters.referralDate
    params['referralDate[before]'] = formatDate(
      nextDate,
      DATE_FORMATS.YEAR_MONTH_DAY,
    )
  }

  if (filters.referredBy) {
    params['user.fullName'] = filters.referredBy
  }

  if (filters.clientName) {
    params['client.fullName'] = filters.clientName
  }

  if (filters.clientEmail) {
    params['fromClient.email'] = filters.clientEmail
  }

  if (filters.introducedTo) {
    params['introducedTo'] = filters.introducedTo
  }

  if (tab === 'sent') {
    params['fromCompany.id'] = currentCompanyId
  } else {
    params['toCompany.id'] = currentCompanyId
  }

  if (tab === 'received') {
    // force to show only approved referrals
    params['approvalStatus'] = 'approved'
  }

  if (filters.firm) {
    if (tab === 'sent') {
      params['toCompany.name'] = filters.firm
    } else {
      params['fromCompany.name'] = filters.firm
    }
  }

  if (filters.status) {
    params['status.id'] = filters.status
  }

  if (filters.dateConsented) {
    const nextDate = addDays(new Date(filters.dateConsented), 1)

    params['dateConsented[after]'] = filters.dateConsented
    params['dateConsented[before]'] = formatDate(
      nextDate,
      DATE_FORMATS.YEAR_MONTH_DAY,
    )
  }

  if (filters.activities) {
    params['services.serviceArea.id'] = filters.activities
  }

  if (filters.networks) {
    if (tab === 'sent') {
      params['toCompany.networks.id'] = filters.networks
    } else {
      params['fromCompany.networks.id'] = filters.networks
    }
  }

  if (filters.clientId) {
    params['client.clientId'] = filters.clientId
  }

  if (filters.isEra) {
    params['isEra'] = filters.isEra === 'yes'
  }

  if (filters.reason) {
    params['reason'] = filters.reason
  }

  // Set order filter
  if (sortBy.field && sortBy.direction) {
    const { field, direction } = sortBy

    if (field === 'referralDate') {
      params['order[referralDate]'] = direction
    }

    if (field === 'referredBy') {
      params['order[user.firstName]'] = direction
    }

    if (field === 'clientName') {
      params['order[client.firstName]'] = direction
    }

    if (field === 'clientId') {
      params['order[client.clientId]'] = direction
    }

    if (field === 'firm') {
      params[
        tab === 'sent' ? 'order[toCompany.name]' : 'order[fromCompany.name]'
      ] = direction
    }

    if (field === 'status') {
      params['order[status.id]'] = direction
    }

    if (field === 'dateConsented') {
      params['order[dateConsented]'] = direction
    }

    if (field === 'activities') {
      params['order[services.serviceArea.name]'] = direction
    }

    if (field === 'networks') {
      params[
        tab === 'sent'
          ? 'order[toCompany.networks.id]'
          : 'order[fromCompany.networks.id]'
      ] = direction
    }

    if (field === 'isEra') {
      params['order[isEra]'] = direction
    }
  }

  return params
}

export function buildReferralsUrl(params: { tab: ReferralsTab }): string {
  return `${ROUTES.referrals}?${queryString.stringify(params)}`
}

export function displayExpectedCommission(
  isExpectingCommission: boolean | null,
  expectedCommission: string | null,
): string {
  if (isExpectingCommission === false || isExpectingCommission === null) {
    return 'Commission not expected'
  } else if (expectedCommission === null) {
    return 'Yes - Unknown value'
  }

  return getCommissionRange(parseFloat(expectedCommission))
}

export default buildSearchParams
