import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLocationDot } from '@rq-ratings/pro-light-svg-icons'
import React, { useMemo } from 'react'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'

import { COMPANY_IDENTIFIER } from '../../../../../lib/constants'
import { isDevelopment } from '../../../../../lib/helpers/envHelpers'
import routeService from '../../../../../lib/services/routeService'
import { CompanyProfileItem } from '../../../../../types/responses/companies'
import CompanyLogoOrName from '../../../CompanyLogoOrName'
import ExternalLink from '../../../ExternalLink'
import RqCertifiedLogo from '../../../RqCertifiedLogo/RqCertifiedLogo'
import { useShouldShowAdditionalCompanyInfo } from '../../hooks/useShouldShowAdditionalCompanyInfo'

interface Props {
  companyProfile: CompanyProfileItem
}

const CompanyInfo: React.FC<Props> = ({ companyProfile }) => {
  const isRqRated = companyProfile?.company?.isRqRated
  const { directory, name, company } = companyProfile
  const shouldShowAdditionalCompanyInfo = useShouldShowAdditionalCompanyInfo()
  const shouldShowCompanyName =
    shouldShowAdditionalCompanyInfo || !company?.logoUrl

  const mainAddress = useMemo(() => {
    if (
      directory &&
      !directory.fca?.frnTradingNameId &&
      directory.postcode &&
      directory.county
    ) {
      return { postcode: directory.postcode, county: directory.county }
    }

    if (company) {
      for (const address of company.addresses) {
        if (address.isMainAddress) {
          return { postcode: address.postcode, county: address.city }
        }
      }
    }

    return null
  }, [directory, company])

  return (
    <>
      <div className="d-flex flex-row flex-wrap align-items-center gap-2">
        {/* Logo */}
        <OverlayTrigger overlay={<Tooltip>{name}</Tooltip>}>
          {/* Need the div wrapper for the tooltip to render properly */}
          <div
            title={
              isDevelopment() && companyProfile.panel?.id
                ? `Panel ID: ${companyProfile.panel.id}`
                : undefined
            }
          >
            <CompanyLogoOrName
              name={companyProfile.name}
              logoUrl={company?.logoUrl}
              wrapperProps={{ className: 'd-flex pe-3' }}
              imageStyle={{ maxHeight: '55px', maxWidth: '350px' }}
              companyTypeIconStyle={{
                width: '34px',
                height: '34px',
                maxHeight: '34px',
              }}
              showNameFallback={false}
            />
          </div>
        </OverlayTrigger>

        {/* Company name & address */}
        <section className="d-flex flex-grow-1 justify-content-sm-between flex-row align-items-center gap-4">
          <div className="d-flex flex-column gap-1">
            {shouldShowCompanyName && (
              <h2 className="text-black mb-0">{companyProfile.name}</h2>
            )}

            {shouldShowAdditionalCompanyInfo && (
              <div className="d-flex flex-column gap-1">
                {/* Trading name */}
                {directory && directory.fca?.frnTradingNameId && (
                  <div className="d-inline-block text-lg me-3 text-muted">
                    Trading name of{' '}
                    <ExternalLink
                      href={routeService.companyProfile({
                        identifierType: COMPANY_IDENTIFIER.frn,
                        identifier: directory.fca?.frn,
                      })}
                      className="text-reset text-decoration-underline"
                    >
                      {directory.fca?.name}
                    </ExternalLink>
                  </div>
                )}

                {mainAddress && (
                  <div className="d-inline-block text-lg">
                    <FontAwesomeIcon
                      icon={faLocationDot}
                      className="me-2"
                      size="lg"
                    />
                    {mainAddress.county}, {mainAddress.postcode}
                  </div>
                )}
              </div>
            )}
          </div>

          {/* RQ Certified */}
          <div>
            {isRqRated && (
              <OverlayTrigger
                overlay={
                  <Tooltip>
                    <strong>{name}</strong> is RQ Certified
                  </Tooltip>
                }
              >
                <div>
                  <RqCertifiedLogo
                    style={{ maxWidth: '60px' }}
                    title="RQ Certified"
                  />
                </div>
              </OverlayTrigger>
            )}
          </div>
        </section>
      </div>
    </>
  )
}

export default CompanyInfo
