import React from 'react'
import { Spinner } from 'react-bootstrap'
import { useMount } from 'react-use'

import authService from '../../../../lib/services/authService'

const InvalidUserContextErrorMessage: React.FC = () => {
  useMount(() => {
    authService.logout()
  })

  return (
    <>
      <h2>Session expired</h2>

      <div className="d-flex gap-2 align-items-center text-lg">
        <p className="mb-0">Your session has expired. Logging you out...</p>
        <Spinner size="sm" />
      </div>
    </>
  )
}

export default InvalidUserContextErrorMessage
