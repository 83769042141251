import React from 'react'
import { Button, Modal } from 'react-bootstrap'

import WalkthroughFlow from '../../../flows/WalkthroughFlow/WalkthroughFlow'

interface Props {
  onHide: () => void
}

const WalkthroughModal: React.FC<Props> = ({ onHide }) => (
  <Modal show onHide={onHide} size="xl">
    <Modal.Body>
      <WalkthroughFlow
        variant="component"
        title="How does RQ work?"
        completeButtonText="Close"
        onComplete={onHide}
      />
    </Modal.Body>

    <Modal.Footer>
      <Button variant="outline-secondary" onClick={onHide}>
        Close
      </Button>
    </Modal.Footer>
  </Modal>
)

export default WalkthroughModal
