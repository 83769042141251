import { AxiosError } from 'axios'
import { format } from 'date-fns/format'
import { intervalToDuration } from 'date-fns/intervalToDuration'
import { isValid } from 'date-fns/isValid'
import { parse } from 'date-fns/parse'
import { parseISO } from 'date-fns/parseISO'
import { flatten } from 'flat'
import { FormikContextType, FormikHelpers } from 'formik'
import { StatusCodes } from 'http-status-codes'
import numWords from 'num-words'
import queryString from 'query-string'
import React from 'react'
import invariant from 'tiny-invariant'
import { objectKeys } from 'ts-extras'

import { makeOption } from '../../components/flows/CompassFlow/utils/helpers'
import { SelectRegulatorOption } from '../../components/form/SelectRegulatorInput'
import {
  ConstraintViolation,
  ConstraintViolationList,
  ConstraintViolationListError,
  HydraCollection,
} from '../../types/api'
import { FormErrors, SelectOption, SerializableFile } from '../../types/misc'
import { RegulatorItem } from '../../types/responses/common-data'
import { DATE_FORMATS, PDF_PATHS } from '../constants'
import { ROUTES } from '../routes'
import { scrollToFirstError } from './formHelpers'
import { matchesPaths } from './routeHelpers'

export function extractURL(url?: string | null): URL | null {
  if (url) {
    if (!url.match(/^[a-zA-Z]+:\/\//)) {
      url = 'http://' + url
    }
    return new URL(url)
  }
  return null
}

export function startsWithVowel(word: string): boolean {
  return /^[aeiou]/i.test(word)
}

export function pluralize(
  count: number,
  noun: string,
  suffix = 's',
  displayCount = true,
): string {
  return `${displayCount ? count : ''} ${noun}${count !== 1 ? suffix : ''}`
}

export function slugify(str: string) {
  return str
    .toLowerCase()
    .replace(/\s/g, '-')
    .replace(/[^\w-]/g, '')
}

export function capitalizeFirstLetter(string: string): string {
  if (!string) {
    return ''
  }

  return string.charAt(0).toUpperCase() + string.slice(1)
}

export function bool2Human(bool: boolean | undefined): string {
  return bool ? 'Yes' : 'No'
}

export function bool2Machine(bool: boolean): number {
  return bool === true ? 1 : 0
}

// TODO: Move to dateTimeHelpers.ts
// See https://date-fns.org/docs/format for date formats.
export function formatDate(
  date: Date | string,
  dateFormat = DATE_FORMATS.DAY_MONTH_YEAR,
): string {
  return date instanceof Date
    ? format(date, dateFormat)
    : format(parseISO(date), dateFormat)
}

// TODO: Move to dateTimeHelpers.ts
export function parseDate(dateString: string, dateFormat: string) {
  return parse(dateString, dateFormat, new Date())
}

export function isConstraintViolationListError(
  error: unknown,
): error is ConstraintViolationListError {
  return (
    error instanceof AxiosError &&
    error.response?.status === StatusCodes.UNPROCESSABLE_ENTITY &&
    error.response.data['@type'] === 'ConstraintViolation' &&
    Array.isArray(error.response.data['violations'])
  )
}

// TODO: Move to errorTypeService.ts
export function isUnauthorizedError(error: unknown): boolean {
  return (
    error instanceof AxiosError &&
    error.response?.status === StatusCodes.UNAUTHORIZED
  )
}

export function getErrorDebugInfo(error: unknown) {
  let debugInfo: { [key: string]: unknown } = {}
  if (error instanceof AxiosError) {
    const responseData = error.response?.data
    responseData['trace'] = undefined
    debugInfo = {
      name: error.name,
      message: error.message,
      responseData: responseData,
    }
  } else if (error instanceof Error) {
    debugInfo = {
      name: error.name,
      message: error.message,
    }
  } else {
    debugInfo = JSON.parse(
      JSON.stringify(error, Object.getOwnPropertyNames(error)),
    )
  }

  return debugInfo
}

export function getFormLevelViolationError(error: unknown): string | null {
  const isAxiosError = error instanceof AxiosError
  if (!isAxiosError) {
    return null
  }

  const constraintViolations = (error.response?.data?.violations ||
    []) as ConstraintViolationList

  // We assume that any violation that doesn't have `propertyPath` set is a
  // form-level error (i.e., doesn't apply to any specific field).
  const formLevelError = constraintViolations.find(
    (violation: ConstraintViolation) => violation.propertyPath === '',
  )

  return formLevelError ? formLevelError.message : null
}

// Get text content of React element
// Inspired by https://stackoverflow.com/a/60564620/2302835
export function getNodeText(
  node: React.ReactElement | string | number,
): string {
  if (!node) {
    return ''
  }

  if (typeof node === 'string' || typeof node === 'number') {
    return node.toString()
  }

  if (Array.isArray(node)) {
    return node.map(getNodeText).join('')
  }

  return getNodeText(node.props.children)
}

export function getTooltipClassName(text: React.ReactElement | string): string {
  const length = getNodeText(text).length

  switch (true) {
    case length > 900:
      return 'tooltip-xl'
    case length > 200:
      return 'tooltip-lg'
    case length > 20:
      return 'tooltip-md'
    default:
      return ''
  }
}

export function scrollToTop(options?: ScrollToOptions) {
  window.scrollTo({ top: 0, left: 0, behavior: 'auto', ...options })
}

export function scrollToBottom() {
  const scrollingElement = document.scrollingElement || document.body

  scrollingElement.scrollTop = scrollingElement.scrollHeight
}

export function getCurrentAgeFromDob(dateOfBirth?: string): number | undefined {
  if (!dateOfBirth) {
    return
  }

  try {
    return intervalToDuration({
      start: parseDate(dateOfBirth, DATE_FORMATS.DAY_MONTH_YEAR),
      end: new Date(),
    }).years
  } catch (_err) {
    return
  }
}

// https://stackoverflow.com/a/8831937/2302835
export function hashCode(str: string) {
  let hash = 0

  for (let i = 0, len = str.length; i < len; i++) {
    const chr = str.charCodeAt(i)
    hash = (hash << 5) - hash + chr
    hash |= 0 // Convert to 32bit integer
  }

  return hash.toString()
}

export function formatMoney(
  moneyValue: number,
  numberFormatOptions?: Intl.NumberFormatOptions,
): string {
  const formattedAmount = Intl.NumberFormat('en-GB', {
    notation: 'standard',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    ...numberFormatOptions,
  }).format(moneyValue)

  return `£${formattedAmount}`
}

export function formatNumber(
  num: number,
  numberFormatOptions?: Intl.NumberFormatOptions,
): string {
  return Intl.NumberFormat('en-GB', {
    notation: 'standard',
    maximumFractionDigits: 2,
    ...numberFormatOptions,
  }).format(num)
}

export function formatMoneyCompact(
  moneyValue: number,
  shouldRound?: boolean,
): string {
  const valueToFormat = shouldRound ? Math.round(moneyValue) : moneyValue

  const formattedAmount = Intl.NumberFormat('en-GB', {
    notation: 'compact',
    maximumFractionDigits: 1,
  }).format(valueToFormat)

  return `£${formattedAmount}`
}

export function getMonthNameFromNumber(monthNumber: number): string {
  const months = [
    '',
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ]

  const month = months[monthNumber]

  if (!month) {
    throw new Error('Invalid month number. Month must be between 1-12')
  }

  return month
}

// Can be extended to include other URLS in the future.
export function isPdfReport() {
  return matchesPaths(window.location.pathname, PDF_PATHS)
}

// Not sure about this approach. Maybe there's a cleaner way to check if we're
// in the compass flow (maybe React Context?).
export function isCompassFlow() {
  const flowPaths = [
    ROUTES.userCompass,
    ROUTES.companyCompass,
    ROUTES.compassStart,
    ROUTES.compassUpdate,
  ]

  return flowPaths.includes(window.location.pathname)
}

export function arrayToCommaSeparatedString(
  arr: Array<string | number>,
): string {
  if (arr.length === 0) {
    return ''
  }

  if (arr.length === 1 && arr[0]) {
    return arr[0].toString()
  }

  if (arr.length === 2) {
    return `${arr[0]} and ${arr[1]}`
  }

  return (
    arr.slice(0, arr.length - 1).join(', ') + ', and ' + arr[arr.length - 1]
  )
}

// TODO: See if there's a well-maintained library that does this
// https://stackoverflow.com/a/52311051/2302835
export function fileToBase64(file: File): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => {
      let encoded = (reader.result || '').toString().replace(/^data:(.*,)?/, '')
      if (encoded.length % 4 > 0) {
        encoded += '='.repeat(4 - (encoded.length % 4))
      }
      resolve(encoded)
    }
    reader.onerror = (error) => reject(error)
  })
}

export function getNextPageParamFromResponse(
  response: HydraCollection<unknown>,
): number | undefined {
  const nextPageEndpoint = response['hydra:view']
    ? response['hydra:view']['hydra:next']
    : undefined

  return nextPageEndpoint
    ? Number(queryString.parse(nextPageEndpoint)['page'])
    : undefined
}

export function padDatePartWithZero(datePart: string | number): string {
  return String(datePart).padStart(2, '0')
}

export function getNormalizedFileType(file: File): string {
  const fileType = file?.type ? file.type.split('/')[1] || '' : 'file'

  if (fileType.includes('ms-powerpoint')) {
    return 'PPT'
  }

  if (/ms-?word/.test(fileType)) {
    return 'DOC'
  }

  if (fileType.includes('ms-excel')) {
    return 'SPREADSHEET'
  }

  return fileType.toUpperCase()
}

export function convertDate(
  dateString: string,
  fromFormat: string,
  toFormat: string,
): string {
  const parsedDate = parseDate(dateString, fromFormat)
  return formatDate(parsedDate, toFormat)
}

export function isValidDateFormat(
  dateString: string,
  dateFormat: string,
): boolean {
  const parsedDate = parse(dateString, dateFormat, new Date())

  return isValid(parsedDate)
}

export function prettify(str?: string): string {
  if (!str) {
    return ''
  }

  let prettified = str.replaceAll('_', ' ')
  prettified = capitalizeFirstLetter(prettified)
  return prettified
}

export function getIdFromIri(iri: IRI): number {
  const urlSegments = (iri || '').split('/')

  if (urlSegments.length <= 1) {
    throw new Error('Invalid IRI')
  }

  return Number(urlSegments[urlSegments.length - 1])
}

export function getNextStep<Steps extends string[]>(
  steps: Steps,
  currentStep: Steps[number],
): Steps[number] {
  if (!steps.includes(currentStep)) {
    console.warn(
      'Invalid step: %s. Valid steps are %s',
      currentStep,
      steps.join(', '),
    )
  }

  const stepIndex = steps.indexOf(currentStep)
  const hasNextStep = stepIndex <= steps.length - 2

  const nextStep = hasNextStep ? steps[stepIndex + 1] : steps[stepIndex]

  invariant(nextStep, 'Next step not found')

  return nextStep
}

export function getPreviousStep<Steps extends string[]>(
  steps: Steps,
  currentStep: Steps[number],
): Steps[number] {
  if (!steps.includes(currentStep)) {
    console.warn(
      'Invalid step: %s. Valid steps are %s',
      currentStep,
      steps.join(', '),
    )
  }

  const stepIndex = steps.indexOf(currentStep)
  const hasPreviousStep = stepIndex >= 1

  return (hasPreviousStep ? steps[stepIndex - 1] : steps[stepIndex]) || ''
}

export function setFieldErrors<Values = unknown>(
  formattedErrors: FormErrors,
  formikHelpers: FormikHelpers<Values>,
) {
  Object.entries(formattedErrors).forEach(([fieldName, errorMessage]) => {
    formikHelpers.setFieldError(fieldName, errorMessage)

    // We must explicitly mark fields as touched if they weren't
    // given any initial value or the initial value was undefined.
    // https://github.com/jaredpalmer/formik/issues/445#issuecomment-551133369
    formikHelpers.setFieldTouched(fieldName, true, false)
  })
}

// Use this helper when you need to explicitly mark some fields as touched so
// that Yup-based validation works for fields without an initial value or with
// an initial value of undefined.
// https://github.com/jaredpalmer/formik/issues/445#issuecomment-551133369
export function markFieldsAsTouched(
  fieldNames: string[],
  setFieldTouched: FormikContextType<unknown>['setFieldTouched'],
) {
  fieldNames.forEach((fieldName) => {
    setFieldTouched(fieldName, true)
  })
}

export function extractTokenFromUrl(): string | undefined {
  const { token } = queryString.parse(window.location.search)
  return typeof token === 'string' && token.length > 0 ? token : undefined
}

export function calculateUpperBoundCommission(
  commissionAmount: number,
): number {
  return commissionAmount * 1.2
}

export function getCommissionRange(commissionAmount: number): string {
  const lowerBound = formatMoneyCompact(commissionAmount * 0.8, true)
  const upperBound = formatMoneyCompact(
    calculateUpperBoundCommission(commissionAmount),
    true,
  ).replace('£', '')

  return `${lowerBound}-${upperBound}`
}

export function replaceObjectKeys(
  object: Record<string, string>,
  replacements: Record<string, string>,
) {
  const newObject: Record<string, string> = {}

  for (const [key, value] of Object.entries(object)) {
    const newKey = replacements[key] ?? key
    newObject[newKey] = value
  }

  return newObject
}

// Convert date of birth from DD/MM/YYYY to YYYY-MM-DD
export function normalizeDateOfBirth(dateOfBirth: string): string {
  const parsedDate = parseDate(dateOfBirth, DATE_FORMATS.DAY_MONTH_YEAR)
  return formatDate(parsedDate, DATE_FORMATS.YEAR_MONTH_DAY)
}

/**
 * Convert date of birth from YYYY-MM-DD to DD/MM/YYYY
 * @deprecated Use convertDate instead
 */
export function denormalizeDateOfBirth(dateOfBirth: string): string {
  const parsedDate = parseISO(dateOfBirth)
  return formatDate(parsedDate, DATE_FORMATS.DAY_MONTH_YEAR)
}

export function handleSubmitFormError(
  formikContext: FormikContextType<unknown>,
) {
  const { setFieldTouched, errors } = formikContext

  // Mark all fields that have an error as touched so that we can show their
  // corresponding error message. By default, Formik only marks a field as
  // touched after submission if the field had an initial value set in the
  // initialValues prop that was not `undefined`. But some values in this
  // form don't have an initial value set so this is a workaround.
  // https://github.com/jaredpalmer/formik/issues/445#issuecomment-677592750
  for (const errorPath of Object.keys(flatten(errors))) {
    setFieldTouched(errorPath, true, false)
  }

  setTimeout(scrollToFirstError, 200)
}

export function extractIdentifierFromIri(iri: IRI): string {
  const urlSegments = (iri || '').split('/')

  if (urlSegments.length <= 1) {
    throw new Error(`Invalid IRI: ${iri}`)
  }

  return urlSegments[urlSegments.length - 1] || ''
}

export function formatAsPercentage(decimal: number): string {
  const percentage = decimal > 0 ? (decimal * 100).toFixed(2) : 0

  return `${percentage}%`
}

export async function makeFileSerializable(
  file?: File | null,
): Promise<SerializableFile | null> {
  return file
    ? {
        name: file.name,
        url: URL.createObjectURL(file),
        base64: await fileToBase64(file),
      }
    : null
}

export function isValidInteger(str: string): boolean {
  const num = parseFloat(str)
  return !isNaN(num) && Number.isInteger(num)
}

export function truncateText(
  text: string,
  options?: TruncateFileNameOptions,
): string {
  const maxLength = options?.maxLength || 40
  const truncateLength = options?.maxLength || 30

  if (text.length < maxLength) {
    return text
  }

  return text.slice(0, truncateLength) + '...'
}

interface TruncateFileNameOptions {
  maxLength?: number
  truncateLength?: number
}

export function numToWord(num: number): string {
  return capitalizeFirstLetter(numWords(num))
}

/**
 * Get the `value` but throw an error if it's null or undefined.
 */
export function getOrFail<T>(value: T, message?: string): NonNullable<T> {
  invariant(value, message)

  return value
}

export function isBottomOfDomElementVisible(element: HTMLElement): boolean {
  // Get the element's bounding rectangle
  const rect = element.getBoundingClientRect()

  // Check if the bottom of the element is above the bottom edge of the viewport
  // window.innerHeight is the height of the viewport
  return rect.bottom <= window.innerHeight
}

export function isElementOverflowingHorizontally(
  element: HTMLElement,
): boolean {
  return element.scrollWidth > element.clientWidth
}

export function isCalendlyUrl(url: string) {
  return url.includes('calendly.com')
}

export function makeYearOptions(numYears: number): SelectOption[] {
  const yearOptions = new Array(numYears - 1).fill(0).map((_, index) => {
    const year = index + 1
    const yearLabel = year === 1 ? '1 year' : `${year} years`

    return makeOption(yearLabel)
  })

  return [
    { label: 'Less than a year', value: '0 years' },
    ...yearOptions,
    { label: `${numYears}+ years`, value: `${numYears}+` },
  ]
}

export function getTextFromHtmlString(html: string): string {
  const element = document.createElement('div')
  element.innerHTML = html

  return (element.textContent || '').trim()
}

export function openUrl(url: string) {
  const downloadLink = document.createElement('a')
  downloadLink.href = url
  downloadLink.click()
}

export function makeWidthAndHeight(dimension: number) {
  return {
    width: dimension,
    height: dimension,
  }
}

export function repeatArray<T>(arr: T[], count: number): T[] {
  const result: T[] = []

  for (let index = 0; index < count; index++) {
    result.push(...arr)
  }

  return result
}

export function removeEmptyObjectValues<T extends object>(obj: T): T {
  const newObj: Partial<T> = {}

  for (const key of objectKeys(obj) as (keyof T)[]) {
    const value = obj[key]
    if (value) {
      newObj[key] = value
    }
  }

  return newObj as T
}

// TODO: Use TypeScript to infer the type of queryParams based on the URL.
export function buildUrl(
  url: string,
  queryParams: Record<string, string> & {
    backLabel?: string
    backUrl?: string
  },
) {
  return queryString.stringifyUrl({ url, query: queryParams })
}

export function formatSelectRegulatorOption(
  regulator: RegulatorItem,
): SelectRegulatorOption {
  return {
    label: regulator.name,
    value: regulator.id.toString(),
    tooltipHint: regulator.hasDpb
      ? { text: 'This is a Designated Professional Body (DPB)' }
      : undefined,
  }
}

export function getFirstAndLastName(fullName: string) {
  const nameParts = fullName.split(/\s+/)
  const firstName = nameParts[0]

  // Treat everything after the first name as the last name
  const lastName = nameParts.slice(1).join(' ')

  return { firstName, lastName }
}

export function hasBothFirstAndLastName(fullName: string) {
  const { firstName, lastName } = getFirstAndLastName(fullName)

  return !!firstName && !!lastName
}

export function scrollToElement(element: HTMLElement | null) {
  if (!element) {
    console.error('element is not set so cannot be scrolled to')
    return
  }

  element.scrollIntoView({ behavior: 'smooth', block: 'start' })
}
