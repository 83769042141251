import React from 'react'

import { WALKTHROUGH_STEPS, WALKTHROUGH_STEPS_LIST } from '../constants'
import { useWalkthroughSteps } from '../hooks/useWalkthroughSteps'

const StepContent: React.FC = () => {
  const { currentStep } = useWalkthroughSteps()

  const activeStep =
    WALKTHROUGH_STEPS[currentStep.key] || WALKTHROUGH_STEPS_LIST[0]

  return (
    <>
      {Object.values(WALKTHROUGH_STEPS).map((step) => {
        const isActive = step.key === activeStep.key

        return (
          <div key={step.key} className={isActive ? 'd-block' : 'd-none'}>
            {step.content}
          </div>
        )
      })}
    </>
  )
}

export default StepContent
