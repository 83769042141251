import React from 'react'
import { Button, Modal } from 'react-bootstrap'

import { useCurrentUserOrFail } from '../../../../hooks/useCurrentUserOrFail'
import authService from '../../../../lib/services/authService'
import { useClaimReferralModal } from '../../../misc/ReferralDetails/hooks/useClaimReferralModal'
import { useHasMultipleReferralInvites } from '../hooks/useHasMultipleReferralInvites'

const AlreadyLoggedInAsClientUserModal: React.FC = () => {
  const claimReferralModal = useClaimReferralModal()
  const currentUser = useCurrentUserOrFail()
  const hasMultipleInvites = useHasMultipleReferralInvites()

  return (
    <Modal onHide={claimReferralModal.hide} show={claimReferralModal.isShowing}>
      <Modal.Header closeButton>
        <Modal.Title className="text-xxl">
          Claim {hasMultipleInvites ? 'referrals' : 'referral'}
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <p>
          You're currently logged in as a Compass portal user (
          <em>{currentUser.email}</em>). Please sign out from your current
          account, revisit this link and create a separate account (with a
          separate email address) to claim this referral.
        </p>

        <Button onClick={() => authService.logout()}>
          Click here to sign out
        </Button>
      </Modal.Body>
    </Modal>
  )
}

export default AlreadyLoggedInAsClientUserModal
