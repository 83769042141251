import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@rq-ratings/pro-light-svg-icons'
import {
  faCheckCircle,
  faCircleEllipsis,
  faPlusCircle,
  faTriangleExclamation,
} from '@rq-ratings/pro-solid-svg-icons'
import { cloneDeep } from 'lodash'
import React, { useState } from 'react'
import { Button, Form, FormCheck, FormControl } from 'react-bootstrap'

import { ReferralsImportItem } from '../../../redux/slices/referralsImportBase'

interface Props {
  importItem: ReferralsImportItem
  updateImportItem: (item: ReferralsImportItem) => void
  isHistorical?: boolean
}

const InviteNewAdvisorCell: React.FC<Props> = ({
  importItem,
  updateImportItem,
  isHistorical = false,
}) => {
  const [change, setChange] = useState(false)
  const formRef = React.createRef<HTMLFormElement>()

  const handleOnSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    const newImportItem = cloneDeep(importItem)
    newImportItem.advisorEmail = event.currentTarget.advisorEmail.value
    if (isHistorical) {
      newImportItem.inviteAdvisor = event.currentTarget.inviteAdvisor.checked
    }
    updateImportItem(newImportItem)
    setChange(false)
  }
  const handleInputChange = () => {
    formRef.current?.requestSubmit()
  }
  const handleInviteToggle = () => {
    const newImportItem = cloneDeep(importItem)
    newImportItem.inviteAdvisor = !newImportItem.inviteAdvisor
    updateImportItem(newImportItem)
  }

  if (change) {
    return (
      <>
        <Form ref={formRef} onSubmit={handleOnSubmit}>
          <FormControl
            placeholder="Email address"
            defaultValue={importItem.advisorEmail}
            name="advisorEmail"
            type="email"
            onBlur={handleInputChange}
            required
          />
          {isHistorical && (
            <FormCheck
              defaultChecked={importItem.inviteAdvisor}
              name="inviteAdvisor"
              type="switch"
              label="Invite advisor to join you on RQ"
              onBlur={handleInputChange}
              className="mt-1"
            />
          )}
          <span className="d-block text-end mt-1">
            <Button type="submit" size="sm" variant="outline-primary">
              <FontAwesomeIcon icon={faCheck} />
            </Button>
          </span>
        </Form>
      </>
    )
  } else if (importItem.advisorEmail && importItem.advisorEmailValid) {
    const isInvite = !isHistorical || importItem.inviteAdvisor
    return (
      <>
        <span className="cursor-pointer" onClick={() => setChange(true)}>
          {isInvite && importItem.importStatus === 'imported' && (
            <FontAwesomeIcon
              icon={faCheckCircle}
              className="me-1 text-success"
            />
          )}
          {isInvite && importItem.importStatus !== 'imported' && (
            <FontAwesomeIcon icon={faPlusCircle} className="me-1 text-info" />
          )}
          {!isInvite && importItem.importStatus === 'imported' && (
            <FontAwesomeIcon icon={faCheckCircle} className="me-1" />
          )}
          {!isInvite && importItem.importStatus !== 'imported' && (
            <FontAwesomeIcon icon={faCircleEllipsis} className="me-1" />
          )}
          {importItem.advisorEmail}
        </span>
        <div className="text-muted text-xs">
          {isHistorical && (
            <FormCheck
              checked={importItem.inviteAdvisor}
              type="switch"
              className="d-inline-block text-base"
              onChange={handleInviteToggle}
            />
          )}
          {isInvite ? (
            <>
              Advisor with email “{importItem.advisorEmail}” will be invited to
              your panel.
            </>
          ) : (
            <>
              This referral will be logged against the email “
              {importItem.advisorEmail}”, but advisor will not be invited. You
              can invite them to join you on RQ later.
            </>
          )}
          <span className="show-on-row-hover">
            {' '}
            <Button
              variant="link"
              size="sm"
              className="text-xs p-0 m-0"
              onClick={() => setChange(true)}
            >
              change
            </Button>
          </span>
        </div>
      </>
    )
  } else if (importItem.advisorEmail) {
    return (
      <>
        <span
          className="text-danger cursor-pointer"
          onClick={() => setChange(true)}
        >
          <FontAwesomeIcon icon={faTriangleExclamation} className="me-1" />
          {importItem.advisorEmail}
        </span>
        <div className="text-danger text-xs mt-1">
          Advisor email is not valid
          <span className="show-on-row-hover">
            ,{' '}
            <Button
              variant="link"
              size="sm"
              className="text-xs p-0 m-0"
              onClick={() => setChange(true)}
            >
              change
            </Button>
          </span>
        </div>
      </>
    )
  } else {
    return (
      <>
        <span
          className="text-danger cursor-pointer"
          onClick={() => setChange(true)}
        >
          <FontAwesomeIcon icon={faTriangleExclamation} className="me-1" />
          No advisor email provided
        </span>
        <span className="show-on-row-hover">
          ,{' '}
          <Button
            variant="link"
            size="sm"
            className="text-xs p-0 m-0"
            onClick={() => setChange(true)}
          >
            change
          </Button>
        </span>
      </>
    )
  }
}

export default InviteNewAdvisorCell
