import './CompleteRegistrationFlow.scss'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@rq-ratings/pro-solid-svg-icons'
import { useQuery } from '@tanstack/react-query'
import React, { useState } from 'react'
import { Image, ProgressBar } from 'react-bootstrap'
import { useMount } from 'react-use'
import { objectKeys } from 'ts-extras'

import logoWhite from '../../../assets/img/logo-inverse.svg'
import usePosthogCapture from '../../../hooks/posthogCapture/usePosthogCapture'
import useAppSelector from '../../../hooks/useAppSelector'
import { useCompanyProfileQuery } from '../../../hooks/useCompanyProfileQuery'
import { useCurrentCompanyServicesQuery } from '../../../hooks/useCompanyServicesQuery'
import useCurrentCompanyOrFail from '../../../hooks/useCurrentCompanyOrFail'
import useMaxWidth from '../../../hooks/useMaxWidth'
import { COMPANY_IDENTIFIER, POSTHOG_APP_AREA } from '../../../lib/constants'
import { QUERY_KEYS } from '../../../lib/queryKeys'
import addressService from '../../../lib/services/addressService'
import companyExpertiseAreaService from '../../../lib/services/companyExpertiseAreaService'
import companyService from '../../../lib/services/companyService'
import { selectExpertiseAreasByCompanyTypeIri } from '../../../redux/slices/commonData'
import CompleteRegistrationNavigation from './components/CompleteRegistrationNavigation'
import HomepageLink from './components/HomepageLink'
import CompanyServices from './steps/1_CompanyServices/CompanyServices'
import CompanyExpertiseAreas from './steps/2_CompanyExpertiseAreas/CompanyExpertiseAreas'
import InviteReferralNetworkStep from './steps/2_InviteReferralNetwork/InviteReferralNetworkStep'
import CompanyOfficeLocations from './steps/4_CompanyOfficeLocations/CompanyOfficeLocations'
import ImportHistoricalReferrals from './steps/6_ImportHistoricalReferrals.tsx/ImportHistoricalReferrals'
import MeetingLink from './steps/6_MeetingLink/MeetingLink'
import UploadLogo from './steps/7_UploadLogo/UploadLogo'
import UploadVideo from './steps/8_UploadVideo/UploadVideo'

interface Props {
  /** Optionally do something when this flow is completed */
  onComplete?: () => void
}

export type CompleteRegistrationFlowStepKey =
  | 'companyServices'
  | 'companyExpertiseAreas'
  | 'companyOfficeLocations'
  | 'inviteReferralPartners'
  | 'importHistoricalReferrals'
  | 'meetingLink'
  | 'uploadLogo'
  | 'uploadVideo'

export interface CompleteRegistrationFlowStepDetails {
  title: string
  name: string
  render: () => React.ReactNode
  isComplete: () => boolean
  isRequired: boolean
  isNecessary: boolean
}

const CompleteRegistrationFlow: React.FC<Props> = ({ onComplete }) => {
  const currentCompany = useCurrentCompanyOrFail()
  const isMaxWidthMd = useMaxWidth('md')
  const { posthogCapture } = usePosthogCapture()

  const companyQuery = useQuery({
    queryKey: QUERY_KEYS.company(currentCompany.id),
    refetchOnWindowFocus: false,
    retry: false,
    queryFn: () => companyService.getCompany(currentCompany.id),
  })

  const company = companyQuery.data

  const companyServicesQuery = useCurrentCompanyServicesQuery()

  const companyServices = companyServicesQuery.data

  const expertiseAreas = useAppSelector(
    selectExpertiseAreasByCompanyTypeIri(currentCompany.companyType['@id']),
  )

  const companyExpertiseAreasQuery = useQuery({
    queryKey: ['company-expertise-areas', currentCompany.id],
    queryFn: () => companyExpertiseAreaService.getCollectionForCurrentCompany(),
  })

  const addressesQuery = useQuery({
    queryKey: QUERY_KEYS.addresses(),
    queryFn: () => addressService.getCurrentCompanyAddresses(),
  })

  const companyProfileQuery = useCompanyProfileQuery({
    identifier: currentCompany.id.toString(),
    identifierType: COMPANY_IDENTIFIER.companyId,
  })

  const { companiesHouse, directory } = companyProfileQuery.data
  const isTradingName = !!directory?.fca?.frnTradingNameId
  const hasDefaultHq =
    !isTradingName && companiesHouse && !!companiesHouse.address

  useMount(() => {
    posthogCapture({
      appArea: POSTHOG_APP_AREA.completeRegistrationModal,
      action: 'started',
    })
  })

  const flowSteps: Record<
    CompleteRegistrationFlowStepKey,
    CompleteRegistrationFlowStepDetails
  > = {
    companyServices: {
      title: 'What services does your firm offer?',
      name: 'List your services',
      render: () => <CompanyServices />,
      // Probably always true, since companies get default services and can't remove them
      isComplete: () =>
        !!company?.otherServices ||
        (companyServices !== undefined && companyServices.length > 0),
      isRequired: true,
      isNecessary: true,
    },

    companyExpertiseAreas: {
      title: "What are your firm's areas of expertise?",
      name: 'Select your areas of expertise',
      render: () => <CompanyExpertiseAreas />,
      isComplete: () =>
        !!companyExpertiseAreasQuery.data &&
        companyExpertiseAreasQuery.data.length > 0,
      isRequired: false,
      isNecessary: expertiseAreas.length > 0,
    },

    companyOfficeLocations: {
      title: "Where are your firm's office locations?",
      name: 'Enter your office locations',
      render: () => <CompanyOfficeLocations />,
      isComplete: () =>
        hasDefaultHq ||
        (addressesQuery.data !== undefined && addressesQuery.data.length > 0),
      isRequired: false,
      isNecessary: true,
    },

    inviteReferralPartners: {
      title: 'Invite your referral partners to RQ',
      name: 'Invite your referral partners to RQ',
      render: () => <InviteReferralNetworkStep />,
      // Not too worried about whether this has really been completed
      isComplete: () => true,
      isRequired: false,
      isNecessary: true,
    },

    importHistoricalReferrals: {
      title: 'Upload your existing referrals data',
      name: 'Upload your existing referrals data',
      render: () => <ImportHistoricalReferrals />,
      // Not too worried about whether this has really been completed
      isComplete: () => true,
      isRequired: false,
      isNecessary: true,
    },

    meetingLink: {
      title: 'Your meeting links',
      name: 'Provide your meeting links',
      render: () => <MeetingLink />,
      isComplete: () =>
        !!currentCompany.scheduleClientCallUrl &&
        !!currentCompany.scheduleCompanyCallUrl,
      isRequired: false,
      isNecessary: true,
    },

    uploadLogo: {
      title: "Upload your firm's logo",
      name: "Upload your firm's logo",
      render: () => <UploadLogo />,
      isComplete: () => !!currentCompany.logoUrl,
      isRequired: false,
      isNecessary: true,
    },

    uploadVideo: {
      title: 'Share a video about you or your firm',
      name: 'Upload video about your firm',
      render: () => <UploadVideo />,
      isComplete: () =>
        !!currentCompany.videoUrl || currentCompany.hasUnprocessedVideo,
      isRequired: false,
      isNecessary: true,
    },
  }

  const [currentStepKey, setCurrentStepKey] =
    useState<CompleteRegistrationFlowStepKey>(
      objectKeys(flowSteps)[0] as CompleteRegistrationFlowStepKey,
    )

  /**
   * Previously visited steps
   */
  const [visitedSteps, setVisitedSteps] = useState<
    CompleteRegistrationFlowStepKey[]
  >([currentStepKey])

  function goToStep(newStepKey: CompleteRegistrationFlowStepKey) {
    setVisitedSteps(
      // No duplicates
      [...visitedSteps, newStepKey].filter(
        (step, i, array) => array.indexOf(step) === i,
      ),
    )

    setCurrentStepKey(newStepKey)
  }

  const currentStep = flowSteps[currentStepKey]

  return (
    <div
      id="complete-registration-modal"
      className="d-flex flex-column align-items-stretch justify-content-between"
      style={{ minHeight: '100vh', maxWidth: '46rem', marginInline: 'auto' }}
    >
      <div className="position-absolute top-0 start-0 end-0 d-flex justify-content-between">
        <HomepageLink>
          <Image
            style={{ height: isMaxWidthMd ? '1.5rem' : '2rem' }}
            src={logoWhite}
          />
        </HomepageLink>

        <HomepageLink>
          <div className="text-light d-flex gap-2 align-items-center">
            <span>Leave this for now</span>
            <FontAwesomeIcon icon={faTimes} className="fs-2" />
          </div>
        </HomepageLink>
      </div>

      <ProgressBar
        variant="success"
        className="position-fixed top-0 start-0 w-100 rounded-0"
        style={{ height: '.25rem', backgroundColor: 'transparent', zIndex: 2 }}
        now={
          1 +
          (
            Object.entries(flowSteps) as [
              CompleteRegistrationFlowStepKey,
              CompleteRegistrationFlowStepDetails,
            ][]
          ).filter(
            ([key, step]) => visitedSteps.includes(key) && step.isComplete(),
          ).length
        }
        min={0}
        max={
          1 + Object.values(flowSteps).filter((step) => step.isNecessary).length
        }
      />

      <div>
        <div className="d-flex flex-column align-items-center mb-5 mt-6">
          <h1
            className="px-3 text-center fw-bolder text-white"
            style={{
              textWrap: 'balance',
            }}
          >
            {currentStep.title}
          </h1>

          {!currentStep.isRequired && (
            <span className="px-2 rounded-pill text-bg-info">
              <small>Optional</small>
            </span>
          )}
        </div>

        <main
          // Key to trigger the animation each time the step changes
          key={currentStepKey}
          className="pop-in card p-3 d-flex flex-column justify-content-between text-center mx-3"
        >
          <div className="flex-grow-1">{currentStep.render()}</div>
        </main>
      </div>

      <CompleteRegistrationNavigation
        key={currentStepKey}
        currentStepKey={currentStepKey}
        flowSteps={flowSteps}
        visitedSteps={visitedSteps}
        onComplete={onComplete}
        onStepChange={goToStep}
      />
    </div>
  )
}

export default CompleteRegistrationFlow
