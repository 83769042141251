import { AxiosProgressEvent } from 'axios'
import { ReactNode } from 'react'
import invariant from 'tiny-invariant'

import FcaSearchLink from '../../components/misc/FcaSearchLink'
import { CompanyEntity } from '../../types/entities'
import { USER_TYPE } from '../../types/misc'
import { CreateCompanyBankDetailsRequest } from '../../types/requests/clients'
import {
  CompanyRequestCallRequest,
  CreateCompanyRequest,
  CreateCompanySelfReferralRequest,
  GetCompanyProfileParams,
  GetCompanyUsersParams,
  GetReferralFirmIndividualsParams,
  GetReferralFirmsQuery,
  GetShareableLinksParams,
  UpdateCompanySmtpDetailRequest,
  UpdateIfaDetailsRequest,
  UpdatePanelApprovalRequiredRequest,
  UpdateScheduleCallUrlRequest,
  UploadLogoRequest,
} from '../../types/requests/companies'
import { CompanyTypeItem } from '../../types/responses/common-data'
import {
  CheckEnqueuedCompaniesResponse,
  CompanyAvailableRecommendedPartnerCollection,
  CompanyBankDetailsItem,
  CompanyCollectionItem,
  CompanyCompassContentItem,
  CompanyDetailsItem,
  CompanyItem,
  CompanyPanelApprovalRequiredItem,
  CompanyProfileItem,
  CompanyRequestCallResponse,
  CompanyScheduleCallUrlsItem,
  CompanyServiceCollectionItem,
  CompanySmtpDetailItem,
  CompanyUserCollection,
  CompassPdfGuideDataItem,
  CreateCompanyResponse,
  CreateCompanySelfReferralResponse,
  ReferralFirmCollection,
  ReferralFirmIndividualsCollection,
  ReferringCompanyItem,
  ShareableReferralLinkCollection,
  UpdateCompanyLogoResponse,
} from '../../types/responses/companies'
import { ReferralStatsItem } from '../../types/responses/referral-stats'
import apiService from './apiService'
import userService from './userService'

class CompanyService {
  private endpoint = '/v1/companies'

  async getCompany(companyId: number): Promise<CompanyItem> {
    const response = await apiService.get(`${this.endpoint}/${companyId}`)

    return response.data
  }

  async getCompanyDetails(companyId: number): Promise<CompanyDetailsItem> {
    const response = await apiService.get(
      `${this.endpoint}/${companyId}/details`,
    )

    return response.data
  }

  async getUsers(
    companyId: number,
    params: GetCompanyUsersParams,
  ): Promise<CompanyUserCollection> {
    const response = await apiService.get(
      `${this.endpoint}/${companyId}/users`,
      { params },
    )

    return response.data
  }

  async getCompanyServices(
    companyId: number,
  ): Promise<CompanyServiceCollectionItem> {
    const response = await apiService.get(
      `${this.endpoint}/${companyId}/services`,
    )

    return response.data['hydra:member']
  }

  async getReferralStats(companyId: ID): Promise<ReferralStatsItem> {
    const response = await apiService.get<ReferralStatsItem>(
      `${this.endpoint}/${companyId}/referral-stats`,
    )

    return response.data
  }

  async getCompanyByReferralCode(
    referralCode: string,
  ): Promise<ReferringCompanyItem> {
    const response = await apiService.get(
      `${this.endpoint}/${referralCode}/referring-company`,
    )

    return response.data
  }

  async getCompanyReferralFirms(
    currentCompanyId: number,
    params: GetReferralFirmsQuery,
  ): Promise<ReferralFirmCollection> {
    const response = await apiService.get(
      `${this.endpoint}/${currentCompanyId}/referral-firms`,
      {
        params: { ...params, status: 'all' },
      },
    )

    return response.data
  }

  async getReferralFirmIndividuals(
    referralFirmId: number,
    params?: GetReferralFirmIndividualsParams,
  ): Promise<ReferralFirmIndividualsCollection> {
    const response = await apiService.get(
      `${this.endpoint}/${referralFirmId}/referral-firm-individuals`,
      { params },
    )

    return response.data
  }

  async checkEnqueuedCompanies(
    indexIds: string[],
  ): Promise<CheckEnqueuedCompaniesResponse> {
    const response = await apiService.get(
      `${this.endpoint}/enqueued-companies`,
      { params: { indexIds } },
    )

    return response.data
  }

  async createCompanyAndUpdateContext(
    userId: number,
    request: CreateCompanyRequest,
  ): Promise<CreateCompanyResponse> {
    const createCompanyResponse = await apiService.post<CreateCompanyResponse>(
      this.endpoint,
      request,
    )

    await userService.updateUserContext(userId, {
      company: createCompanyResponse.data['@id'],
      scope: USER_TYPE.company,
    })

    return createCompanyResponse.data
  }

  async completeRegistration(companyId: number): Promise<unknown> {
    const response = await apiService.patch(
      `${this.endpoint}/${companyId}/complete-registration`,
      {},
    )

    return response.data
  }

  async requestAccess(companyId: number): Promise<unknown> {
    const response = await apiService.post(
      `${this.endpoint}/${companyId}/request-access`,
      {},
    )

    return response.data
  }

  async getCompassContent(
    companyId: number,
  ): Promise<CompanyCompassContentItem> {
    const response = await apiService.get<CompanyCompassContentItem>(
      `${this.endpoint}/${companyId}/compass-content`,
    )

    const { heroIntroText, sectionOneIntroText } = response.data

    invariant(heroIntroText, 'Expected heroIntroText to be defined')
    invariant(sectionOneIntroText, 'Expected sectionOneIntroText to be defined')

    return response.data
  }

  async getCompassPdfGuideData(
    companyId: number,
  ): Promise<CompassPdfGuideDataItem> {
    const response = await apiService.get(
      `${this.endpoint}/${companyId}/compass-pdf-guide-data`,
    )

    return response.data
  }

  async downloadCompassPdfGuide(companyId: number): Promise<{
    downloadUrl: string
  }> {
    const response = await apiService.post(
      `${this.endpoint}/${companyId}/compass-pdf-guide`,
      {},
    )

    return response.data
  }

  async submitRqCertifiedFeePaymentClaim(companyId: number): Promise<{
    downloadUrl: string
  }> {
    const response = await apiService.patch(
      `${this.endpoint}/${companyId}/rq-certified-fee-payment-claim`,
      {},
    )
    return response.data
  }

  async updateScheduleCallUrls(
    companyId: number,
    request: UpdateScheduleCallUrlRequest,
  ): Promise<CompanyScheduleCallUrlsItem> {
    const response = await apiService.patch(
      `${this.endpoint}/${companyId}/schedule-call-url`,
      request,
    )

    return response.data
  }

  async updatePanelApprovalRequired(
    companyId: number,
    request: UpdatePanelApprovalRequiredRequest,
  ): Promise<CompanyPanelApprovalRequiredItem> {
    const response = await apiService.patch(
      `${this.endpoint}/${companyId}/panel-approval-required`,
      request,
    )

    return response.data
  }

  async createCompanySelfReferralRequest(
    companyId: number,
    request: CreateCompanySelfReferralRequest,
  ): Promise<CreateCompanySelfReferralResponse> {
    const response = await apiService.post(
      `${this.endpoint}/${companyId}/self-referral`,
      request,
    )

    return response.data
  }

  async createCompanyRequestCallRequest(
    companyId: number,
    request: CompanyRequestCallRequest,
  ): Promise<CompanyRequestCallResponse> {
    const response = await apiService.post(
      `${this.endpoint}/${companyId}/request-call`,
      request,
    )

    return response.data
  }

  async getCompanyProfile(
    params: GetCompanyProfileParams,
  ): Promise<CompanyProfileItem> {
    const response = await apiService.get(`${this.endpoint}/profile`, {
      params,
    })

    return response.data
  }

  getInvitationLink(
    companyReferralCode: CompanyEntity['referralCode'],
  ): string {
    if (!companyReferralCode) {
      throw new Error(
        'Attempted to get an invitation link without a referral code ' +
          'being set for a company. All companies should have a referral code.',
      )
    }

    return `${window.location.origin}/invitation/${companyReferralCode}`
  }

  async getBankDetails(companyId: number): Promise<CompanyBankDetailsItem> {
    const response = await apiService.get<CompanyBankDetailsItem>(
      `${this.endpoint}/${companyId}/bank-details`,
    )

    return response.data
  }

  async submitBankDetails(
    companyId: number,
    request: CreateCompanyBankDetailsRequest,
  ): Promise<CompanyBankDetailsItem> {
    const response = await apiService.post<CompanyBankDetailsItem>(
      `${this.endpoint}/${companyId}/bank-details`,
      request,
    )

    return response.data
  }

  // TODO: Move logic to API
  getDpbLicenceMessage(
    company: CompanyCollectionItem,
    companyType: CompanyTypeItem,
  ): string | ReactNode {
    const foundDpbLicence = !!company.dpbReference
    const isDpbMember = company.isDpbMember
    const couldSelectRegulator = companyType.canSelectRegulatorAtSignUp
    const didSelectDpbRegulator = company.isDpbMember
    const dpbHref = <FcaSearchLink query={company.dpbReference} />

    // Has licence and is DPB member or has licence but couldn't select regulator
    // at sign-up
    if (foundDpbLicence && (isDpbMember || !couldSelectRegulator)) {
      return <>It looks like your firm has a DPB licence ({dpbHref}).</>
    }

    // Has licence but no DPB regulator selected at sign-up
    if (foundDpbLicence && couldSelectRegulator && !didSelectDpbRegulator) {
      return (
        <>
          It looks like your firm has a DPB licence ({dpbHref}), however a
          professional body regulator was not selected from the list at sign up.
        </>
      )
    }

    // No licence found though the company selected a DPB regulator at sign-up
    if (!foundDpbLicence && couldSelectRegulator && didSelectDpbRegulator) {
      return "It looks like your firm doesn't have a DPB licence."
    }

    // No message to show
    return ''
  }

  getIri(otherCompanyId: number): IRI {
    return `${this.endpoint}/${otherCompanyId}`
  }

  async updateLogo(
    companyId: number,
    request: UploadLogoRequest,
  ): Promise<UpdateCompanyLogoResponse> {
    const response = await apiService.post(
      `${this.endpoint}/${companyId}/upload-logo`,
      request,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    )

    return response.data
  }

  async getSmtpDetail(companyId: number): Promise<CompanySmtpDetailItem> {
    const response = await apiService.get<CompanySmtpDetailItem>(
      `${this.endpoint}/${companyId}/email-integration`,
    )

    return response.data
  }

  public async uploadVideo(
    companyId: number,
    videoFile: File,
    onUploadProgress: (data: AxiosProgressEvent) => void,
  ) {
    const response = await apiService.post(
      `/v1/companies/${companyId}/upload-video`,
      { videoFile: videoFile },
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        onUploadProgress,
      },
    )

    return response.data
  }

  async updateSmtpDetail(
    companyId: number,
    request: UpdateCompanySmtpDetailRequest,
  ): Promise<CompanySmtpDetailItem> {
    const response = await apiService.patch<CompanySmtpDetailItem>(
      `${this.endpoint}/${companyId}/email-integration`,
      request,
    )

    return response.data
  }

  async updateOtherServices(companyId: number, otherServices: string) {
    const response = await apiService.patch(`${this.endpoint}/${companyId}`, {
      otherServices,
    })

    return response.data
  }

  async updateIfaDetails(
    companyId: number,
    request: UpdateIfaDetailsRequest,
  ): Promise<unknown> {
    const response = await apiService.patch(`${this.endpoint}/${companyId}`, {
      companyDetail: {
        ifa: request,
      },
    })

    return response.data
  }

  async getAvailableRecommendedPartners(
    companyId: number,
  ): Promise<CompanyAvailableRecommendedPartnerCollection> {
    const response = await apiService.get(
      `${this.endpoint}/${companyId}/available-recommended-partners`,
    )

    return response.data
  }

  async getShareableReferralLinks(
    companyId: number,
    params: GetShareableLinksParams,
  ): Promise<ShareableReferralLinkCollection> {
    const response = await apiService.get(
      `${this.endpoint}/${companyId}/shareable-referral-links`,
      { params },
    )

    return response.data
  }
}

const companyService = new CompanyService()

export default companyService
