import { ClaimReferralInviteRequest } from '../../types/requests/referral-invites'
import apiService from './apiService'

class ReferralInviteService {
  private endpoint = '/v1/referral-invites'

  async claimReferralInvite(request: ClaimReferralInviteRequest) {
    const response = await apiService.post(`${this.endpoint}/claim`, request)

    return response.data
  }
}

const referralInviteService = new ReferralInviteService()

export default referralInviteService
