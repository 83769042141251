import React from 'react'
import { Container } from 'react-bootstrap'

import LandingDarkLayout from '../components/layouts/LandingDarkLayout/LandingDarkLayout'
import MinimalLayout from '../components/layouts/MinimalLayout'
import InvalidOrExpiredCard, {
  InvalidOrExpiredCardProps,
} from '../components/misc/InvalidOrExpiredCard'

const InvalidOrExpiredPage: React.FC<InvalidOrExpiredCardProps> = (props) => (
  <LandingDarkLayout>
    <MinimalLayout>
      <Container className="bg-white">
        <InvalidOrExpiredCard {...props} />
      </Container>
    </MinimalLayout>
  </LandingDarkLayout>
)

export default InvalidOrExpiredPage
