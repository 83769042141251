import {
  COMMERCIAL_AGREEMENT_TAB_KEY,
  CommercialAgreementTabKey,
} from './constants'

export function isValidCommercialAgreementTabKey(
  tab: unknown,
): tab is CommercialAgreementTabKey {
  return (
    typeof tab === 'string' &&
    Object.keys(COMMERCIAL_AGREEMENT_TAB_KEY).includes(tab)
  )
}
