import {
  PERSONAL_SITUATION_MARRIED,
  PERSONAL_SITUATION_RELATIONSHIP_WITH_SHARED_FINANCES,
} from '../steps/5_ClientDetails/constants'
import { DEFINED_BENEFIT_STATUS } from '../steps/8_PersonalAssets/constants'
import { BUSINESS_SALE_PLANNING_AMOUNT } from '../steps/9_BusinessAssets/constants'
import { MORTGAGE_TERM_TYPE } from '../steps/10_Liabilities/constants'
import { MONTHLY_OUTGOING_TYPE } from '../steps/11_Income/constants'
import { PENSION_CONTRIBUTION_TYPE } from '../steps/12_Interests/constants'
import {
  NONE_OF_THE_ABOVE_OPTION,
  NOT_EMPLOYED_OPTION,
  OTHER_GOAL_CATEGORY_OPTION,
  PROFESSIONAL_SITUATION_OPTIONS,
  WILL_FAMILY_PROTECTION_OPTION,
} from './constants'
import { makeOption } from './helpers'

export const OPTIONS = {
  // Shared
  yesNo: [
    { label: 'Yes', value: true },
    { label: 'No', value: false },
  ],

  yesNoOrIDontKnow: [
    makeOption('Yes'),
    makeOption('No'),
    makeOption("I don't know"),
  ],

  // Step 1: About your client
  user_private_situation: [
    makeOption('Single'),
    makeOption(PERSONAL_SITUATION_MARRIED),
    makeOption(PERSONAL_SITUATION_RELATIONSHIP_WITH_SHARED_FINANCES),
    makeOption('In a relationship without shared finances'),
    makeOption('Divorced / divorcing'),
    makeOption('Other'),
  ],

  user_professional_situation: [
    makeOption(PROFESSIONAL_SITUATION_OPTIONS.businessOwner),
    makeOption(PROFESSIONAL_SITUATION_OPTIONS.partner),
    makeOption('Executive'),
    makeOption('Director'),
    makeOption('Employee'),
    makeOption('Self-Employed'),
    makeOption(NOT_EMPLOYED_OPTION),
  ],

  // Step 3: Business assets
  business_sale_planning_amount: BUSINESS_SALE_PLANNING_AMOUNT.map((amount) =>
    makeOption(amount),
  ),

  business_sale_want_to_sell_shares: [
    { label: 'Yes', value: true },
    { label: 'No', value: false },
    { label: 'N/A', value: 'N/A' },
  ],

  business_insurances: [
    makeOption('Professional Indemnity'),
    makeOption('Cyber'),
    makeOption('Crime / Fidelity / Theft by Employees'),
    makeOption('Directors and Officers / Management Liability'),
    makeOption(
      'Office combined (property / contents / business interruption / employers & public liability)',
    ),
    makeOption('Motor fleet'),
    makeOption('Personal accident and travel'),
    makeOption(
      "I'm not entirely sure what I have in place and whether it is appropriate / effective",
    ),
    makeOption('I have none of the above in place'),
  ],

  defined_benefit_status: [
    makeOption(DEFINED_BENEFIT_STATUS.active, {
      text: (
        <>
          You're still an employee / scheme member and are actively accruing
          additional benefits.
        </>
      ),
    }),
    makeOption(DEFINED_BENEFIT_STATUS.deferred, {
      text: (
        <>
          You're no longer an employee / scheme member and are not actively
          accruing additional benefits; you are simply entitled to the preserved
          benefits you accrued to the point you left the scheme.
        </>
      ),
    }),
    makeOption(DEFINED_BENEFIT_STATUS.activeAndDeferred, {
      text: (
        <>
          You're continuing to accrue some benefits, while you also have some
          preserved benefits, from another scheme or section.
        </>
      ),
    }),
    makeOption(DEFINED_BENEFIT_STATUS.dontKnow),
  ],

  // Step 4: Liabilities
  family_protection: [
    makeOption(WILL_FAMILY_PROTECTION_OPTION, {
      text: (
        <>
          <p>
            Many people wrongly assume 'it’ll all be fine' if they pass away
            without a Will in place. They also often think: 'my affairs are
            pretty straightforward, so I don’t really need to worry about a
            Will'.
          </p>

          <p>
            However, it is important to note a couple of things: firstly, the
            Laws of Intestacy (which apply when there is no valid Will in place)
            may not deliver the outcomes you and your family would like or
            assume, and secondly, people’s affairs and wishes are frequently
            more complex than they imagine.
          </p>

          <p>
            By way of a common example, don't be so sure about the assumption:
            "it will all just go to my Wife and then to my children when I pass
            away"; what you think will happen and what the law says will happen
            may well be two very different thing...
          </p>
        </>
      ),
    }),
    makeOption('Power(s) of Attorney', {
      text: (
        <>
          <p>
            A Lasting Power of Attorney (LPA) is a legal document that allows
            you (the ‘donor’) to appoint one or more people (‘attorneys’) to
            help you make decisions, or to make decisions on your behalf, if and
            when the times comes when this would be either helpful or necessary.
          </p>

          <p>
            This gives you more control over what happens to you if you cannot
            make your own decisions (you ‘lack mental capacity’).
          </p>

          <p>
            There are 2 types of LPA:
            <ul>
              <li>Health and Welfare</li>
              <li>Property and Financial Affairs</li>
            </ul>
          </p>

          <p>You can choose to make one type or both.</p>

          <p>
            You may assume that those closest to you – particularly if you’re
            married or in a civil partnership – would automatically be able to
            deal with your bank accounts, pensions, make decisions relating to
            your health and medical care and so on, if you lose the ability to
            do so. However, this is not the case.
          </p>

          <p>
            In addition, if you speak to anyone who has ever had to deal with
            the implications of Powers of Attorney not being in place when
            circumstances meant they would have been helpful or necessary, they
            will virtually certainly tell you they wish they had have been.
          </p>

          <p>
            It’s a relatively simple and inexpensive process to establish
            Lasting Powers of Attorney, so please do consider doing so if you
            haven’t already.
          </p>
        </>
      ),
    }),
    makeOption('Family Financial Protection', {
      text: (
        <>
          <p>
            <p>
              Protecting your family from the worst financial consequences of
              adverse events is foundational to good financial planning.
            </p>

            <p>
              Remember: a plan has to cater for what could go wrong, as well as
              what could – and you hope will – go right.
            </p>

            <p>
              Family financial protection can take many forms, but having
              appropriate protection against your passing, in respect of
              liabilities and loss of earnings (and the consequences for those
              you care about), is crucial. In this regard, having ‘some
              protection’ is frequently mistaken as an adequate alternative to
              having the right protection and unfortunately, it simply isn’t.
            </p>

            <p>
              The right protection will not only often be less expensive than
              the inappropriate alternative, but also comfortably affordable for
              most people.
            </p>
          </p>
        </>
      ),
    }),
    makeOption('Income Protection', {
      text: (
        <>
          <p>
            Many people, if asked what their most valuable asset is, would say
            their house. Some, if they are lucky, would say their business, or
            perhaps their pension. However, the reality is that for a
            significant number of people, their most valuable asset is their
            human capital – their ability to work and earn money.
          </p>

          <p>
            For example, a 45 year old earning £100,000 pa, planning on working
            until she is 65, will earn £2m during that period. In reality, with
            inflation rises of even just 2% per annum, it’s actually more like
            £2.5m and at 4% pa inflation, it’s £3m.
          </p>

          <p>
            Clearly, this is almost certainly considerably more than the value
            of her house – and, of course, it goes without saying she wouldn’t
            dream of not insuring her house. Indeed, more people insure their
            pets and take out extended warranties on electrical items than
            insure their human capital, which, fairly clearly, is rather back to
            front.
          </p>

          <p>
            Income protection – not to be confused with the vastly inferior and
            deservingly frowned upon payment protection insurance – should be
            one of your absolute priorities from a financial planning
            perspective.
          </p>
        </>
      ),
    }),
    makeOption(
      "I'm not entirely sure what I have in place and/or whether it is appropriate/effective",
    ),
    makeOption(NONE_OF_THE_ABOVE_OPTION),
  ],

  professional_protection: [
    makeOption('Shareholders / Partners Agreement', {
      text: (
        <>
          <p>
            A shareholders’ / partnership agreement goes beyond a company’s
            articles of association, or basic partnership responsibilities, and
            codifies the relationship between shareholders / partners, the way
            the company / partnership should be managed and what should happen
            in certain situations that may arise.
          </p>
          <p>
            It is good practice to have a written agreement in place, partly to
            help deal with any disputes, disagreements and unfortunate
            circumstances (such as a business partner passing away), but also
            simply to provide important clarity and peace of mind.
          </p>
          <p>
            A document drafted and agreed in times of calm can be hugely
            valuable, versus trying to deal with difficult, unexpected and
            unfamiliar matters in times of stress and where different, or
            changed preferences, priorities and interests emerge.
          </p>
          <p>
            It is very much a document you hope you never need, but you may well
            be very glad you have, should you ever need it.
          </p>
        </>
      ),
    }),
    makeOption('Shareholders / Partners Protection', {
      text: (
        <>
          <p>
            Shareholder / Partner protection provides the means for business
            partners to buy out a deceased or unwell fellow business partner.
          </p>

          <p>
            Sad and difficult stories of the right business protection not being
            in place abound; the consequences for the owners / partners of a
            previously thriving business losing a key person and then having to
            find the funds to buy their shares / interest can be catastrophic –
            even fatal.
          </p>

          <p>
            Protecting the business and all those who rely on it – including
            family members, team members, clients etc. – is vital, not only for
            the business itself, but also, ultimately, for your own personal
            finances.
          </p>
        </>
      ),
    }),
    makeOption('Cross-Option Agreements', {
      text: (
        <>
          <p>
            In the event of a business owner / partner passing away, a
            cross-option agreement enables:
          </p>

          <ul>
            <li>
              The surviving shareholders / partners to buy the deceased’s shares
              / interest from their personal representatives, should they wish
              to exercise this option (they would hopefully have adequate
              resources or Shareholder / Partner Protection in place to be able
              to finance this).
            </li>

            <li>
              Those to whom the deceased business partner’s shares have passed,
              to exercise their option to have the surviving shareholders /{' '}
              partners buy their shares / interest from them.
            </li>
          </ul>

          <p>
            Being stuck with a new business partner who can add no value, or who
            would be a disruption or distraction, or indeed, being stuck with
            the shares of a company / an interest in a partnership the new owner
            doesn’t understand, isn’t interested in and has no real control
            over, is not a situation any of the relevant parties would want.
          </p>

          <p>
            A Cross-Option Agreement – particularly if paired with appropriate
            Shareholder / Partner Protection – helps everyone concerned to
            sensibly and effectively deal with this scenario.
          </p>
        </>
      ),
    }),
    makeOption('Key Person Protection', {
      text: (
        <>
          <p>
            Key Person Protection protects a company against the financial
            impact of the passing, or critical illness, of a person who is very
            important to the business they work in, be that in respect of
            operations, sales, profitability, contacts, leadership, customer /
            supplier confidence, experience etc.
          </p>

          <p>
            Most business owners will instinctively know how important certain
            people are to their business – frequently, this will include
            themselves, of course – but to place into a little more perspective
            how important this protection can be, research has indicated that
            following the death or critical illness of a key individual, over
            half of SME businesses believe they would have to stop trading in
            less than a year.
          </p>

          <p>
            As with other forms of business protection, then, Key Person
            Protection can be essential, not only for the business itself, but
            ultimately, for your own personal finances and accordingly, should
            be given due consideration where appropriate.
          </p>
        </>
      ),
    }),
    makeOption(
      "I'm not entirely sure what I have in place and/or whether it is appropriate/effective",
    ),
    makeOption(NONE_OF_THE_ABOVE_OPTION),
  ],

  mortgage_type: [makeOption('Interest only'), makeOption('Capital repayment')],
  mortgage_fixed_or_tracker: [
    makeOption(MORTGAGE_TERM_TYPE.tracker, {
      text: (
        <>
          <p>
            When your tracker rate period comes to an end, you will revert to
            your lender's 'Standard Variable Rate' (SVR).
          </p>

          <p>
            This will almost certainly mean you will be paying a higher rate of
            interest moving forward. As a result, you may also have to make
            notably higher monthly payments.
          </p>

          <p>
            Alternatively, you may wish to seek a new mortgage deal, either with
            your current lender or an alternative one, in order to improve upon
            the SVR being offered.
          </p>

          <p>
            However, please bear in mind that even if you are able to improve on
            the SVR, you may not find such a competitive tracker (or fixed rate)
            deal as you have previously enjoyed, which, accordingly, may have
            meaningful implications for your monthly outgoings and wider /
            longer-term financial position.
          </p>
        </>
      ),
    }),
    makeOption(MORTGAGE_TERM_TYPE.fixedRate, {
      text: (
        <>
          <p>
            When your fixed rate period comes to an end, you will revert to your
            lender's 'Standard Variable Rate' (SVR).
          </p>

          <p>
            This will almost certainly mean you will be paying a higher rate of
            interest moving forward. As a result, you may also have to make
            notably higher monthly payments.
          </p>

          <p>
            Alternatively, you may wish to seek a new mortgage deal, either with
            your current lender or an alternative one, in order to improve upon
            the SVR being offered.
          </p>

          <p>
            However, please bear in mind that even if you are able to improve on
            the SVR, you may not find such a competitive fixed rate (or tracker)
            deal as you have previously enjoyed, which, accordingly, may have
            meaningful implications for your monthly outgoings and wider /
            longer-term financial position.
          </p>
        </>
      ),
    }),
  ],

  // Step 5: Income
  pension_contribution_type: [
    { label: '£ value', value: PENSION_CONTRIBUTION_TYPE.total },
    { label: '% of salary', value: PENSION_CONTRIBUTION_TYPE.share },
  ],

  monthly_outgoings_type: [
    makeOption(MONTHLY_OUTGOING_TYPE.totalAmount),
    makeOption(MONTHLY_OUTGOING_TYPE.breakdown),
  ],

  // Step 6: Interests
  interests: [
    {
      label: 'Life and lifestyle',
      options: [
        makeOption(
          'Will I have enough money by the time I reach my desired financial independence age?',
        ),
        makeOption(
          "I'd like to improve my life and lifestyle as soon as possible; how can I bring the future closer to the present?",
        ),
        makeOption(
          "Life transitions - I'm at or approaching a crossroads in life; what are the real issues and my best options?",
          {
            text: (
              <>
                <p>
                  Changes in circumstances often have significant financial
                  implications and mean difficult decisions are required. In
                  addition, however, they often bring emotional stresses, which
                  can be just as tough to deal with.
                </p>

                <p>
                  Divorce or bereavement, for example, may mean not only the sad
                  end of a relationship or loss of a loved one, but perhaps also
                  for the first time a daunting responsibility for family wealth
                  and financial decision-making.
                </p>

                <p>
                  Sudden wealth – perhaps as a result of a business sale,
                  personal injury compensation award, lottery or premium bonds
                  win – can not only bring a raft of unfamiliar and
                  significantly more consequential decisions and necessary
                  actions, but also affect perspectives, relationships,
                  priorities etc. Stepping away from a senior position, or from
                  a business built over many years, can have several subtle
                  consequences in addition to the more obvious financial ones.
                  These might include anxiety and uncertainty over a loss of
                  authority, status, purpose and identity, for example.
                </p>

                <p>
                  The non-financial implications of major life events are every
                  bit as real and important as the financial ones and, of
                  course, they go hand in hand. These need to considered and
                  understood ahead of time, and they need to be managed in a
                  thoughtful manner during the journey that lies the other side
                  of them.
                </p>
              </>
            ),
          },
        ),
        makeOption(
          'Inflation - what does it really mean for me over the long-term and how can I protect against it?',
          {
            text: (
              <>
                <p>
                  A frequent and very damaging financial mistake people make is
                  imagining that the number of units of currency they possess
                  indicates their financial health. Indeed, that if they possess
                  the same number of units of currency tomorrow – or perhaps
                  even a slightly greater number – then all is well and they can
                  sleep soundly at night.
                </p>

                <p>
                  However, while plausible-sounding, this is incorrect and as
                  such, you must be completely clear on the absolutely
                  foundational financial planning principle, that:
                </p>

                <ul>
                  <li>
                    <strong>
                      The only sane long-term definition of money is as
                      purchasing power.
                    </strong>
                  </li>

                  <li>
                    With inflation running at 4% pa, for example, the real value
                    of your wealth – your purchasing power – will half in less
                    than 20 years. £100 – 100 units of currency – that has
                    ‘safely’ been sitting in cash and earning a nil or negative
                    real return during that time will have had a catastrophic
                    hole blown in its purchasing power.
                  </li>
                </ul>

                <p>
                  Inflation is your number one long-term financial threat thus,
                  particularly if you are approaching the ‘distribution phase’
                  of your life (as opposed to the ‘accumulation phase’) and
                  accordingly, you simply must develop – and crucially, stick to
                  – a financial strategy that gives you the best possible chance
                  of fending off this threat, preserving the real value of your
                  wealth and providing you with a life of security, comfort and
                  good choices.
                </p>
              </>
            ),
          },
        ),
        makeOption(
          'Running out of life before running out of money - how can I efficiently and sustainably draw from my pensions and investments?',
          {
            text: (
              <>
                <p>
                  When you reach financial independence (when paid work becomes
                  permanently optional) or retirement / semi-retirement (when
                  you choose to cease, or materially reduce, paid work), looking
                  ahead, from a financial viewpoint, much comes down to one
                  binary question:
                </p>
                <p>
                  <strong>
                    Will your money outlive you, or will you outlive your money?
                  </strong>
                </p>
                <p>
                  Most people not only don’t know what the answer is, they are
                  unaware that this is the question.. A closely related
                  consideration is how best you can sustainably withdraw from
                  the assets you’ve accumulated through life’s journey to that
                  point, to help support your life and lifestyle in the future.
                  And the key word here is <strong>‘sustainably’</strong> .
                </p>

                <p>
                  The level of your withdrawals each year, the rate at which
                  they increase with inflation (to preserve your purchasing
                  power), the long-term returns you achieve on your pensions and
                  investments, whether the early years of the ‘distribution
                  phase’ are favourable or unfavourable with regard to the
                  returns the capital markets are generous enough to offer etc.,
                  all very significantly impact on how long your withdrawals can
                  continue for – how long your money will last.
                </p>

                <p>
                  Professor William Sharpe, a Nobel Prize winner for Economic
                  Sciences, described this as “
                  <strong>
                    the nastiest, hardest problem in finance”,{` `}
                  </strong>
                  so the question and the problem outlined here will be central
                  – indeed, critical – to your own retirement plans.
                </p>

                <p>
                  If you’re not comfortable that you can navigate the relevant
                  variables and considerations here, please do consider getting
                  professional support, as these issues – and the life outcomes
                  associated with them – really are too important to leave to
                  chance or luck.
                </p>
              </>
            ),
          },
        ),
      ],
    },
    {
      label: 'Tax',
      options: [
        makeOption('Income Tax'),
        makeOption('Capital Gains Tax'),
        makeOption('Inheritance Tax'),
      ],
    },
    {
      label: 'Pensions',
      options: [
        makeOption('Pension Contributions'),
        makeOption('Pension Annual Allowance'),
        makeOption('Pension Lifetime Allowance'),
        makeOption(
          'Pension Benefits (understanding and maximising Defined Benefit and State Pension entitlements)',
        ),
      ],
    },
    {
      label: 'Investments',
      options: [
        makeOption('How are my investments performing and behaving?'),
        makeOption('Are my investments appropriate for me and my objectives?'),
      ],
    },
    {
      label: 'Business',
      options: [
        makeOption('Business Growth / Profitability'),
        makeOption('Business Sale / Exit / Succession / Continuity'),
      ],
    },
    {
      label: 'Impact, Philanthropy, Wealth Transfer and Legacy',
      options: [
        makeOption(
          'Positive impact investment (environment, society and financial return)',
        ),
        makeOption('Philanthropy'),
        makeOption('Inter-Generational Wealth Transfer'),
        makeOption('Legacy', {
          text: 'How would you like to impart your time, energy, money, wisdom and values, both now and in the future?',
        }),
      ],
    },
    {
      label: 'Getting started',
      options: [
        makeOption(
          "Some / several of the above feel important to me; I'd like to get going (and to do so with confidence)",
        ),
      ],
    },
  ],

  outflow_category: [
    { label: 'Special holiday', value: 'holiday' },
    {
      label: 'Help children on to property ladder',
      value: 'help_children_on_property_ladder',
    },
    { label: 'Buy bigger home', value: 'bigger_home' },
    { label: 'Buy second home', value: 'second_home' },
    {
      label: 'Put children through private school',
      value: 'private_school_for_children',
    },
    { label: 'Other', value: OTHER_GOAL_CATEGORY_OPTION },
  ],

  outflow_frequency_type: [makeOption('One-off'), makeOption('Ongoing')],

  outflow_frequency: [
    makeOption('Daily'),
    makeOption('Weekly'),
    makeOption('Monthly'),
    makeOption('Annual'),
  ],
}
