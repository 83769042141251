import { useCurrentUser } from './useCurrentUser'

export function useShouldRedirectToWalkthroughOnSidebarLinkClick() {
  const currentUser = useCurrentUser()

  return (
    !!currentUser &&
    currentUser.registeredViaReferralInvite &&
    !currentUser.hasCompletedWalkthrough
  )
}
