import { useMutation } from '@tanstack/react-query'
import { useSearchParams } from 'react-router'
import invariant from 'tiny-invariant'

import usePosthogCapture from '../../../hooks/posthogCapture/usePosthogCapture'
import useAppDispatch from '../../../hooks/useAppDispatch'
import useAppSelector from '../../../hooks/useAppSelector'
import useNotyf from '../../../hooks/useNotyf'
import { LONG_MESSAGE_DURATION } from '../../../lib/constants'
import { scrollToTop } from '../../../lib/helpers/helperFunctions'
import referralService from '../../../lib/services/referralService'
import { REFER_CLIENT_SEARCH_PARAMS } from '../../../lib/services/routeService'
import {
  ReferClientStep,
  selectCurrentStep,
  selectReferralFirm,
  selectSelectedServiceAreas,
  selectSteps,
} from '../../../redux/slices/referClient'
import {
  goToStep as goToStepAction,
  selectNextStep,
  selectPreviousStep,
} from '../../../redux/slices/referClient'
import { CreateNewReferralMetadataMutationVariables } from '../types'
import { useCreateNewReferralMetadataMutation } from './useCreateNewReferralMetadataMutation'

export function useReferClientSteps() {
  const [searchParams, setSearchParams] = useSearchParams()
  const createMetadataMutation = useCreateNewReferralMetadataMutation()
  const dispatch = useAppDispatch()
  const currentStep = useAppSelector(selectCurrentStep)
  const nextStep = useAppSelector(selectNextStep)
  const referralFirm = useAppSelector(selectReferralFirm)
  const previousStep = useAppSelector(selectPreviousStep)
  const steps = useAppSelector(selectSteps)
  const { posthogCapture } = usePosthogCapture()
  const serviceAreas = useAppSelector(selectSelectedServiceAreas)
  const notyf = useNotyf()

  function goToNextStep() {
    posthogCapture({
      appArea: 'referral',
      action: 'referral-page-submitted',
      pageName: currentStep,
    })

    return goToStep(nextStep)
  }

  function goToPreviousStep() {
    return goToStep(previousStep)
  }

  function goToStep(step: ReferClientStep) {
    dispatch(goToStepAction(step))
    searchParams.set(REFER_CLIENT_SEARCH_PARAMS.step, step)
    setSearchParams(searchParams)

    scrollToTop()
  }

  const refreshMetadataAndProceedMutation = useMutation({
    mutationFn: async (
      variables: CreateNewReferralMetadataMutationVariables | void,
    ) => {
      if (variables?.checkCanRefer) {
        invariant(
          referralFirm,
          'Referral firm must be selected before using the checkCanRefer option',
        )

        const response = await referralService.checkCanRefer({
          toCompany: referralFirm['@id'],
          serviceAreas: serviceAreas.map((serviceArea) => serviceArea['@id']),
        })

        if (!response.canRefer && response.cannotReferReason) {
          notyf.error({
            message: response.cannotReferReason,
            duration: LONG_MESSAGE_DURATION,
          })

          return
        }
      }

      await createMetadataMutation.mutateAsync(variables)

      goToNextStep()
    },
  })

  return {
    currentStep,
    goToNextStep,
    goToPreviousStep,
    previousStep,
    goToStep,
    nextStep,
    steps,
    refreshMetadataAndProceedMutation,
  }
}
