import { useMutation, useQueryClient } from '@tanstack/react-query'
import React, { useState } from 'react'
import { Button, Modal, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { useMount } from 'react-use'

import useNotyf from '../../../../../../hooks/useNotyf'
import { QUERY_KEYS } from '../../../../../../lib/queryKeys'
import companyService from '../../../../../../lib/services/companyService'
import referralLinkService from '../../../../../../lib/services/referralLinkService'
import { ReferralIndividual } from '../../../../../../types/misc'
import { CompanyProfileItemCompany } from '../../../../../../types/responses/companies'
import { ReferralLinkCollectionItem } from '../../../../../../types/responses/referral-links'
import ActionButton from '../../../../ActionButton'
import { useHandleCreateOrUpdateReferralLinkError } from '../useHandleCreateOrUpdateReferralLinkError'
import CreateOrUpdateReferralLink from './CreateOrUpdateReferralLink'

interface Props {
  company: CompanyProfileItemCompany
  referralLink: ReferralLinkCollectionItem
  onHide: () => void
}

const EditReferralLinkModal: React.FC<Props> = ({
  company,
  referralLink,
  onHide,
}) => {
  const notyf = useNotyf()
  const { handleCreateOrUpdateReferralLinkError } =
    useHandleCreateOrUpdateReferralLinkError()
  const queryClient = useQueryClient()
  const [referralIndividuals, setReferralIndividuals] = useState<
    ReferralIndividual[]
  >([])

  const searchReferralFirmIndividualsMutation = useMutation({
    mutationFn: async () => {
      if (referralLink.isReferralToIndividuals) {
        const response = await companyService.getReferralFirmIndividuals(
          company.id,
          {
            userIds: referralLink.users.map((user) => user.id),
            hasCalendarConnection: true,
          },
        )

        return response['hydra:member'].map(
          (result): ReferralIndividual => ({
            email: result.user.email,
            fullName: result.user.fullName,
            userId: result.user.id,
            companyUserId: result.id,
          }),
        )
      }

      return null
    },
    onSuccess: (individuals) => {
      if (individuals) {
        setReferralIndividuals(individuals)
      }
    },
  })

  const updateLinkMutation = useMutation({
    mutationFn: async () =>
      referralLinkService.updateReferralLink(referralLink.code, {
        userIds: referralIndividuals.map((individual) => individual.userId),
      }),
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: QUERY_KEYS.companyProfile(),
      })

      await queryClient.invalidateQueries({
        queryKey: QUERY_KEYS.referralLinks({ ['toCompany.id']: company.id }),
      })

      onHide()

      notyf.success('Link updated successfully')
    },
    onError: handleCreateOrUpdateReferralLinkError,
  })

  useMount(() => {
    searchReferralFirmIndividualsMutation.mutate()
  })

  const isLoadingIndividuals = searchReferralFirmIndividualsMutation.isPending
  const isProcessing = updateLinkMutation.isPending

  const canUpdate = !isProcessing && referralIndividuals.length > 0

  return (
    <Modal onHide={onHide} show size="lg">
      <Modal.Header>
        <Modal.Title>Edit calendar link</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <CreateOrUpdateReferralLink
          company={company}
          referralIndividuals={referralIndividuals}
          onSelectReferralIndividuals={setReferralIndividuals}
          isDisabled={isLoadingIndividuals || isProcessing}
        />
      </Modal.Body>

      <Modal.Footer>
        <Button variant="outline-secondary" onClick={onHide}>
          Cancel
        </Button>

        <OverlayTrigger
          overlay={
            canUpdate ? (
              <div />
            ) : (
              <Tooltip>Please select at least one individual</Tooltip>
            )
          }
        >
          <div>
            <ActionButton
              variant="primary"
              onClick={() => updateLinkMutation.mutate()}
              disabled={!canUpdate}
              isProcessing={isProcessing}
            >
              Update
            </ActionButton>
          </div>
        </OverlayTrigger>
      </Modal.Footer>
    </Modal>
  )
}

export default EditReferralLinkModal
