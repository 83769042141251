import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPaperclip } from '@rq-ratings/pro-light-svg-icons'
import classNames from 'classnames'
import React, { useRef, useState } from 'react'
import { Button, ButtonProps } from 'react-bootstrap'
import invariant from 'tiny-invariant'

import { truncateText } from '../../lib/helpers/helperFunctions'
import { SerializableFile } from '../../types/misc'
import PlainButton from '../misc/PlainButton'

export interface FilePickerProps {
  defaultSelectedFile?: SerializableFile | null
  buttonLabel?: React.ReactNode | string
  filePickerClassName?: string
  removeFileClassName?: string
  removeButtonClassName?: string
  size?: ButtonProps['size']
  onChange?: (file: File | null) => void
  maxSizeMb: number
  onFileSizeError?: (limitInMb: number) => void
}

const FilePicker: React.FC<FilePickerProps> = ({
  defaultSelectedFile,
  buttonLabel,
  filePickerClassName,
  removeFileClassName,
  removeButtonClassName,
  size,
  onChange,
  maxSizeMb,
  onFileSizeError,
}) => {
  const [fileDownloadLink, setFileDownloadLink] = useState<string | undefined>(
    defaultSelectedFile?.url,
  )
  const fileInputRef = useRef<HTMLInputElement | null>(null)
  const [selectedFile, setSelectedFile] = useState<File | null>(
    determineInitialSelectedFile,
  )

  function determineInitialSelectedFile(): File | null {
    return defaultSelectedFile
      ? new File([defaultSelectedFile.url], defaultSelectedFile.name)
      : null
  }

  async function handleFileChange(event: React.ChangeEvent<HTMLInputElement>) {
    const files = event.target.files
    const file = files && files[0] ? files[0] : null

    const isFileSizeError =
      maxSizeMb &&
      onFileSizeError &&
      file?.size &&
      file.size > maxSizeMb * 1024 * 1024 // Convert MB to bytes

    if (isFileSizeError) {
      onFileSizeError(maxSizeMb)
      return
    }

    setSelectedFile(file)

    if (file) {
      setFileDownloadLink(window.URL.createObjectURL(file))
    }

    if (onChange) {
      onChange(file)
    }
  }

  function handleRemoveFile() {
    if (fileInputRef.current?.value) {
      fileInputRef.current.value = ''
    }

    setSelectedFile(null)

    if (fileDownloadLink) {
      window.URL.revokeObjectURL(fileDownloadLink)
    }

    if (onChange) {
      onChange(null)
    }
  }

  function renderAttachButton() {
    return (
      <Button
        variant="outline-primary"
        size={size}
        className={classNames(
          filePickerClassName,
          'd-flex align-items-center justify-content-center gap-2',
        )}
        style={{ minWidth: '170px' }}
        onClick={() => {
          fileInputRef.current?.click()
        }}
      >
        <FontAwesomeIcon
          role="button"
          icon={faPaperclip}
          className="text-gray-300"
          style={{ fontSize: '14px' }}
        />
        {buttonLabel}
      </Button>
    )
  }

  function renderFile() {
    invariant(selectedFile, 'Expected selectedFile to be defined')

    return (
      <div
        className={classNames(removeFileClassName, 'd-flex align-items-center')}
      >
        <a
          href={fileDownloadLink}
          target="_blank"
          rel="noreferrer"
          className="text-primary"
        >
          {truncateText(selectedFile.name)}
        </a>

        <PlainButton
          className={classNames(removeButtonClassName, 'text-muted ms-2')}
          onClick={handleRemoveFile}
          style={{ fontSize: '11px' }}
        >
          (Remove)
        </PlainButton>
      </div>
    )
  }

  return (
    <>
      {selectedFile ? renderFile() : renderAttachButton()}

      <input
        ref={fileInputRef}
        type="file"
        name="file"
        onChange={handleFileChange}
        className="d-none"
      />
    </>
  )
}

export default FilePicker
