import classNames from 'classnames'
import React from 'react'

import { useIsMinWidthLg } from '../../../../hooks/useIsMinWidthLg'
import { THEME_PALETTE } from '../../../../lib/constants'
import { makeWidthAndHeight } from '../../../../lib/helpers/helperFunctions'
import { WALKTHROUGH_STEPS } from '../constants'
import { useWalkthroughSteps } from '../hooks/useWalkthroughSteps'

const StepList: React.FC = () => {
  const { currentStep } = useWalkthroughSteps()
  const isMinWidthLg = useIsMinWidthLg()

  const ICON_SIZE = {
    large: 36,
    medium: 32,
  }

  const steps: {
    label: string
    isActive: boolean
    icon: {
      url: string
      size: number
    }
  }[] = [
    {
      label: WALKTHROUGH_STEPS.introducingRq.label,
      isActive: currentStep.key === WALKTHROUGH_STEPS.introducingRq.key,
      icon: {
        url: WALKTHROUGH_STEPS.introducingRq.icon,
        size: ICON_SIZE.large,
      },
    },
    {
      label: WALKTHROUGH_STEPS.professionalNetwork1.label,
      isActive: [
        WALKTHROUGH_STEPS.professionalNetwork1.key,
        WALKTHROUGH_STEPS.professionalNetwork2.key,
        WALKTHROUGH_STEPS.professionalNetwork3.key,
      ].includes(currentStep.key),
      icon: {
        url: WALKTHROUGH_STEPS.professionalNetwork1.icon,
        size: ICON_SIZE.medium,
      },
    },
    {
      label: WALKTHROUGH_STEPS.referralEngine1.label,
      isActive: [
        WALKTHROUGH_STEPS.referralEngine1.key,
        WALKTHROUGH_STEPS.referralEngine2.key,
        WALKTHROUGH_STEPS.referralEngine3.key,
      ].includes(currentStep.key),
      icon: {
        url: WALKTHROUGH_STEPS.referralEngine1.icon,
        size: ICON_SIZE.large,
      },
    },
    {
      label: WALKTHROUGH_STEPS.compass1.label,
      isActive: [
        WALKTHROUGH_STEPS.compass1.key,
        WALKTHROUGH_STEPS.compass2.key,
      ].includes(currentStep.key),
      icon: {
        url: WALKTHROUGH_STEPS.compass1.icon,
        size: ICON_SIZE.large,
      },
    },
  ]

  if (currentStep.key === WALKTHROUGH_STEPS.letsGo.key) {
    steps.push({
      label: WALKTHROUGH_STEPS.letsGo.label,
      isActive: currentStep.key === WALKTHROUGH_STEPS.letsGo.key,
      icon: {
        url: WALKTHROUGH_STEPS.letsGo.icon,
        size: ICON_SIZE.medium,
      },
    })
  }

  return (
    <aside className="bg-white rounded-3 border-box border-bottom-0 overflow-hidden">
      {steps.map((step) => (
        <div
          key={step.label}
          className={classNames(
            'd-flex align-items-center gap-3 border-bottom',
            isMinWidthLg ? 'text-lg' : 'text-base',
          )}
          style={{
            padding: isMinWidthLg ? '11px 16px' : '8px 12px',
            backgroundColor: step.isActive ? '#F6F6F6' : THEME_PALETTE.white,
          }}
        >
          <div
            className="d-flex justify-content-center align-items-center"
            style={{
              ...makeWidthAndHeight(isMinWidthLg ? ICON_SIZE.large + 2 : 22),
            }}
          >
            <img
              src={step.icon.url}
              alt={step.label}
              className="img-fluid"
              style={{
                ...makeWidthAndHeight(isMinWidthLg ? step.icon.size : 22),
              }}
            />
          </div>

          <div
            className={classNames(
              {
                'font-weight-500 text-black': step.isActive,
              },
              'font-weight-300',
            )}
          >
            {step.label}
          </div>
        </div>
      ))}
    </aside>
  )
}

export default StepList
