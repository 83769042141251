import React from 'react'

import { THEME_PALETTE } from '../../../../lib/constants'
import { WALKTHROUGH_STEPS_LIST } from '../constants'
import { useWalkthroughSteps } from '../hooks/useWalkthroughSteps'

const StepIndicators: React.FC = () => {
  const { currentStepIndex } = useWalkthroughSteps()

  return (
    <div className="d-flex gap-1">
      {WALKTHROUGH_STEPS_LIST.map((step, index) => {
        const isComplete = currentStepIndex >= index

        return (
          <div
            key={step.key}
            className="rounded-circle"
            style={{
              backgroundColor: isComplete
                ? THEME_PALETTE.primary
                : THEME_PALETTE['gray-500'],
              width: '8px',
              height: '8px',
            }}
          ></div>
        )
      })}
    </div>
  )
}

export default StepIndicators
