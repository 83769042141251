import React from 'react'
import { Button, Modal } from 'react-bootstrap'

import useModal from '../../hooks/useModal'
import { arrayToCommaSeparatedString } from '../../lib/helpers/helperFunctions'
import PlainButton from './PlainButton'

interface Props {
  title: string
  users: { fullName: string; email: string }[]
  toCompany: { isUnrealizedCompany?: boolean }
}

const ReferralIntroducedTo: React.FC<Props> = ({ title, toCompany, users }) => {
  const { isShowingModal, showModal, hideModal } = useModal()
  const userNames = users.map((user) => user.fullName)

  if (toCompany?.isUnrealizedCompany) {
    const userEmails = users.map((user) => user.email)

    return <>{arrayToCommaSeparatedString(userEmails)}</>
  }

  if (userNames.length <= 2) {
    return <>{arrayToCommaSeparatedString(userNames)}</>
  }

  const firstTwoNames = userNames.slice(0, 2)

  return (
    <>
      <PlainButton onClick={showModal}>
        {firstTwoNames.join(', ')}...
      </PlainButton>

      <Modal show={isShowingModal} onHide={hideModal}>
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="table-responsive">
            <table className="table table-striped">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Email</th>
                </tr>
              </thead>

              <tbody>
                {users.map((user) => (
                  <tr key={user.email}>
                    <td>{user.fullName}</td>
                    <td>{user.email}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="outline-secondary" onClick={hideModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default ReferralIntroducedTo
