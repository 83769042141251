import React from 'react'

import { useIsRoute } from '../../../../../hooks/useIsRoute'
import { ROUTES } from '../../../../../lib/routes'
import ImageWithText from '../../components/ImageWithText'
import screenshotUrl from './screenshot.png'

const LetsGo: React.FC = () => {
  const isRoute = useIsRoute()
  const willRedirectOnComplete = isRoute(ROUTES.walkthrough)

  return (
    <ImageWithText
      imageUrl={screenshotUrl}
      text={
        <>
          <h4>You're ready to go!</h4>

          <p>
            {willRedirectOnComplete
              ? 'Get started by completing the actions on the next page. '
              : ''}
            If you want any more guidance, you’ll find our tips and FAQs
            throughout the platform, or you can contact us at any time on the
            support chat.
          </p>
        </>
      }
    />
  )
}

export default LetsGo
