import { PaymentCollection } from '../../types/responses/payments'
import apiService from './apiService'

class PaymentService {
  private endpoint = '/v1/payments'

  async getPendingPayments() {
    const response = await apiService.get<PaymentCollection>(this.endpoint, {
      params: { status: 'PENDING' },
    })
    return response.data
  }

  async getPaymentsByIds(ids: number[]) {
    if (ids.length === 0) {
      throw new Error('No payment ids provided')
    }
    const response = await apiService.get<PaymentCollection>(this.endpoint, {
      params: { id: ids },
    })
    return response.data
  }
}

const paymentService = new PaymentService()

export default paymentService
