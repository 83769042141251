import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle } from '@rq-ratings/pro-regular-svg-icons'
import { faTriangleExclamation } from '@rq-ratings/pro-solid-svg-icons'
import classNames from 'classnames'
import React, { ReactNode, useEffect, useState, useTransition } from 'react'
import { Button, Dropdown, DropdownButton, Form, Modal } from 'react-bootstrap'

import useAppDispatch from '../../../hooks/useAppDispatch'
import useAppSelector from '../../../hooks/useAppSelector'
import useNotyf from '../../../hooks/useNotyf'
import { IMPORT_FILE_NOTIFICATION_DURATION } from '../../../lib/constants'
import queryClient from '../../../lib/queryClient'
import { ReferralsImportItem } from '../../../redux/slices/referralsImportBase'
import {
  bulkUpdateImportItems,
  removeImportItem,
  selectItems,
  selectReferralsImportHistorical,
  setMultiSelect,
  updateImportItem,
} from '../../../redux/slices/referralsImportHistorical'
import ActionButton from '../../misc/ActionButton'
import CircumstancesCell from '../../misc/ImportReferralsComponents/CircumstancesCell'
import ClientCell from '../../misc/ImportReferralsComponents/ClientCell'
import ClientHeaderDropdown from '../../misc/ImportReferralsComponents/ClientHeaderDropdown'
import ImportDirectionCell from '../../misc/ImportReferralsComponents/ImportDirectionCell'
import ImportErrorMessage from '../../misc/ImportReferralsComponents/ImportErrorMessage'
import ImportStatusCell from '../../misc/ImportReferralsComponents/ImportStatusCell'
import InviteNewAdvisorCell from '../../misc/ImportReferralsComponents/InviteNewAdvisorCell'
import MultiSelectSwitch from '../../misc/ImportReferralsComponents/MultiSelectSwitch'
import OtherCompanyAdvisorCell from '../../misc/ImportReferralsComponents/OtherCompanyAdvisorCell'
import ReferralDateCell from '../../misc/ImportReferralsComponents/ReferralDateCell'
import SelectCell from '../../misc/ImportReferralsComponents/SelectCell'
import SelectHeader from '../../misc/ImportReferralsComponents/SelectHeader'
import ServiceCell from '../../misc/ImportReferralsComponents/ServiceCell'
import StatusCell from '../../misc/ImportReferralsComponents/StatusCell'
import Step from '../../misc/Step'
import { useImportHistoricalReferralMutation } from './useImportHistoricalReferralMutation'

interface Props {
  darkMode?: boolean
  startOverButton: ReactNode
  handleFinish: () => void
  handleAutomaticFinish: () => void
  className?: string
}

const ResolveImportHistorical: React.FC<Props> = ({
  darkMode = false,
  startOverButton,
  handleFinish,
  handleAutomaticFinish,
  className = '',
}) => {
  const notyf = useNotyf()
  const referralsImportHistoricalState = useAppSelector(
    selectReferralsImportHistorical,
  )
  const { mutateAsync: importHistoricalReferralsAsyncMutation } =
    useImportHistoricalReferralMutation()
  const [notifyStatus, setNotifyStatus] = useState<boolean>(false)
  const [isImporting, startImport] = useTransition()
  const dispatch = useAppDispatch()

  const importedCount = referralsImportHistoricalState.importItems.filter(
    (importItem) => importItem.importStatus === 'imported',
  ).length
  const totalCount = referralsImportHistoricalState.importItems.length

  useEffect(() => {
    if (notifyStatus) {
      notyf.success({
        message:
          importedCount === 1
            ? `Referral successfully processed`
            : `${importedCount} referrals successfully processed (of ${totalCount})`,
        duration: IMPORT_FILE_NOTIFICATION_DURATION,
      })
      setNotifyStatus(false)
      if (importedCount === totalCount) {
        setTimeout(() => {
          handleAutomaticFinish()
        }, 500)
      }
    }
  }, [notifyStatus, importedCount, notyf, totalCount, handleAutomaticFinish])

  const isFinished = importedCount == totalCount

  if (referralsImportHistoricalState.importItems.length === 0) {
    return <div>No import items</div>
  }

  const processImport = async () => {
    const mutations = []
    for (const importItem of referralsImportHistoricalState.importItems) {
      if (
        importItem.importStatus === 'ready' ||
        importItem.importStatus === 'error'
      ) {
        mutations.push(
          importHistoricalReferralsAsyncMutation(importItem, {
            // It looks like TanStackQuery is firing onSuccess once, not on every item.
            // It works well in this case of invalidating referrals cache.
            onSuccess: () => {
              queryClient.invalidateQueries({
                queryKey: ['referrals'],
              })
            },
          }),
        )
      }
    }
    try {
      await Promise.all(mutations)
    } catch (error) {
      notyf.errorWithoutReporting({
        message: 'Some referrals failed to import',
        duration: IMPORT_FILE_NOTIFICATION_DURATION,
      })
    }
    setNotifyStatus(true)
  }

  return (
    <>
      <Modal.Body className={' ' + className}>
        <Step step="3">
          <p className="mb-2">Review import data.</p>
          <MultiSelectSwitch
            multiSelect={referralsImportHistoricalState.multiSelect}
            setMultiSelect={(state) => dispatch(setMultiSelect(state))}
          />
          <table
            className={classNames(
              'DataTable table table-bordered table-align-top DataTable mb-0 text-sm mb-0 ',
              darkMode ? 'table-dark' : '',
            )}
            style={{ height: 'fit-content' }}
          >
            <thead>
              <tr>
                <th
                  className={classNames(
                    'p-0',
                    !referralsImportHistoricalState.multiSelect ? 'd-none' : '',
                  )}
                >
                  <SelectHeader
                    hasSelected={
                      referralsImportHistoricalState.selectedCount > 0
                    }
                    bulkUpdateItems={(payload) =>
                      dispatch(bulkUpdateImportItems(payload))
                    }
                  />
                </th>
                <th style={{ width: '75px' }}>Direction</th>
                <th style={{ position: 'relative' }}>
                  Client
                  <ClientHeaderDropdown
                    hasUpdatableClients={
                      referralsImportHistoricalState.hasUpdatableClients
                    }
                    canPauseClientEmails={
                      referralsImportHistoricalState.canPauseClientEmails
                    }
                    bulkUpdateItems={(payload) =>
                      dispatch(bulkUpdateImportItems(payload))
                    }
                    bulkUpdateSelectedOnly={
                      referralsImportHistoricalState.canBulkUpdateSelectedOnly
                    }
                  />
                </th>
                <th
                  style={{
                    width: '15%',
                    minWidth: '200px',
                    position: 'relative',
                  }}
                >
                  Partner / Advisor
                  {referralsImportHistoricalState.hasInvites && (
                    <PartnerAdvisorDropdown
                      bulkUpdateSelectedOnly={
                        referralsImportHistoricalState.canBulkUpdateSelectedOnly
                      }
                    />
                  )}
                </th>
                <th style={{ width: '15%' }}>Primary Service</th>
                <th style={{ minWidth: '150px' }}>Circumstances</th>
                <th style={{ width: '15%' }}>Status</th>
                <th style={{ width: '140px' }}>Date Referred</th>
                <th style={{ width: '70px' }}>Import Status</th>
              </tr>
            </thead>
            <tbody>
              {referralsImportHistoricalState.importItems.map(
                (importItem, index) => (
                  <ImportRow
                    importItem={importItem}
                    key={importItem.refUUID}
                    index={index}
                    multiSelect={referralsImportHistoricalState.multiSelect}
                    bulkUpdateSelectedOnly={
                      referralsImportHistoricalState.canBulkUpdateSelectedOnly
                    }
                  />
                ),
              )}
            </tbody>
          </table>
          <p className="mt-2 text-muted text-sm">
            <FontAwesomeIcon icon={faInfoCircle} /> You can update some fields
            above, move your mouse over the field and click the value or
            "change" button.
          </p>
        </Step>
        <Step step="4">
          <p className="mb-2">Import referrals.</p>
        </Step>
      </Modal.Body>
      <Modal.Footer>
        {startOverButton}
        <div className="flex-grow-1"></div>
        <span>
          {importedCount} / {totalCount} imported
        </span>
        {isFinished ? (
          <Button variant="success" onClick={() => handleFinish()}>
            Finish
          </Button>
        ) : (
          <ActionButton
            isProcessing={isImporting}
            variant="primary"
            onClick={() => {
              startImport(() => {
                processImport()
              })
            }}
          >
            Import
          </ActionButton>
        )}
      </Modal.Footer>
    </>
  )
}

export default ResolveImportHistorical

function PartnerAdvisorDropdown({
  bulkUpdateSelectedOnly,
}: {
  bulkUpdateSelectedOnly: boolean
}) {
  const dispatch = useAppDispatch()

  const scope = bulkUpdateSelectedOnly ? 'selected' : 'all'

  return (
    <DropdownButton
      variant="outline-primary"
      title=""
      align="end"
      size="sm"
      style={{ position: 'absolute', right: '10px', top: '16px' }}
    >
      <Dropdown.Item
        onClick={() =>
          dispatch(
            bulkUpdateImportItems({
              filter: { selected: bulkUpdateSelectedOnly },
              data: { inviteAdvisor: true },
            }),
          )
        }
      >
        <Form.Switch checked readOnly className="d-inline-block" /> Invite{' '}
        {scope} advisors automatically
      </Dropdown.Item>
      <Dropdown.Item
        onClick={() =>
          dispatch(
            bulkUpdateImportItems({
              filter: { selected: bulkUpdateSelectedOnly },
              data: { inviteAdvisor: false },
            }),
          )
        }
      >
        <Form.Switch readOnly className="d-inline-block" /> Invite {scope}{' '}
        advisors later manually
      </Dropdown.Item>
    </DropdownButton>
  )
}

function ImportRow({
  importItem,
  index,
  multiSelect,
  bulkUpdateSelectedOnly,
}: {
  importItem: ReferralsImportItem
  index: number
  multiSelect: boolean
  bulkUpdateSelectedOnly: boolean
}) {
  const dispatch = useAppDispatch()

  const isError = importItem.importStatus === 'error'
  const cellClassName = isError
    ? 'table-danger'
    : index % 2
      ? 'table-striped-bg'
      : ''

  return (
    <>
      <tr
        className={classNames(
          importItem.selected && multiSelect ? 'table-active' : '',
        )}
      >
        <td
          className={classNames(
            cellClassName,
            'p-0',
            multiSelect ? '' : 'd-none',
          )}
          rowSpan={isError ? 2 : 1}
        >
          <SelectCell
            importItem={importItem}
            handleSelect={(refUUID, modifier) => {
              dispatch(selectItems({ refUUID, modifier }))
            }}
          />
        </td>
        <td className={cellClassName}>
          <ImportDirectionCell
            importItem={importItem}
            updateImportItem={(item) => dispatch(updateImportItem(item))}
          />
        </td>
        <td className={cellClassName}>
          <ClientCell
            importItem={importItem}
            updateImportItem={(item) => dispatch(updateImportItem(item))}
          />
        </td>
        <td className={cellClassName}>
          {importItem.otherCompany ? (
            <>
              {importItem.otherCompany.name}
              <br />
              <OtherCompanyAdvisorCell
                importItem={importItem}
                updateImportItem={(item) => dispatch(updateImportItem(item))}
                bulkUpdateItems={(payload) =>
                  dispatch(bulkUpdateImportItems(payload))
                }
                bulkUpdateSelectedOnly={bulkUpdateSelectedOnly}
              />
            </>
          ) : (
            <InviteNewAdvisorCell
              importItem={importItem}
              updateImportItem={(item) => dispatch(updateImportItem(item))}
              isHistorical
            />
          )}
        </td>
        <td className={cellClassName}>
          <ServiceCell
            importItem={importItem}
            updateImportItem={(item) => dispatch(updateImportItem(item))}
            bulkUpdateItems={(payload) =>
              dispatch(bulkUpdateImportItems(payload))
            }
            bulkUpdateSelectedOnly={bulkUpdateSelectedOnly}
            withExempt
          />
        </td>
        <td className={cellClassName}>
          <CircumstancesCell
            importItem={importItem}
            updateImportItem={(item) => dispatch(updateImportItem(item))}
            bulkUpdateItems={(payload) =>
              dispatch(bulkUpdateImportItems(payload))
            }
            bulkUpdateSelectedOnly={bulkUpdateSelectedOnly}
          />
        </td>
        <td className={cellClassName}>
          <StatusCell
            importItem={importItem}
            updateImportItem={(item) => dispatch(updateImportItem(item))}
            bulkUpdateItems={(payload) =>
              dispatch(bulkUpdateImportItems(payload))
            }
            bulkUpdateSelectedOnly={bulkUpdateSelectedOnly}
          />
        </td>
        <td className={cellClassName}>
          <ReferralDateCell
            importItem={importItem}
            updateImportItem={(item) => dispatch(updateImportItem(item))}
            bulkUpdateItems={(payload) =>
              dispatch(bulkUpdateImportItems(payload))
            }
            bulkUpdateSelectedOnly={bulkUpdateSelectedOnly}
          />
        </td>
        <td
          className={`${cellClassName} position-relative`}
          rowSpan={isError ? 2 : 1}
        >
          <ImportStatusCell
            importItem={importItem}
            removeImportItem={(item) => {
              dispatch(removeImportItem(item.refUUID))
            }}
          />
        </td>
      </tr>
      {isError && (
        <tr>
          <td
            colSpan={7}
            className="table-danger text-danger position-relative"
          >
            <FontAwesomeIcon
              icon={faTriangleExclamation}
              className="position-absolute"
              size="2x"
              style={{ top: '10px', left: '15px' }}
            />
            {importItem.importErrors ? (
              <ul className="ms-3">
                {Object.entries(importItem.importErrors).map(
                  ([key, value], index) => (
                    <li key={`${index}-${key}-${value}`}>
                      <ImportErrorMessage
                        importItem={importItem}
                        field={key}
                        message={value}
                      />
                    </li>
                  ),
                )}
              </ul>
            ) : (
              <p>Unknown error</p>
            )}
          </td>
        </tr>
      )}
    </>
  )
}
