import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSquare } from '@rq-ratings/pro-regular-svg-icons'
import {
  faCheckCircle,
  faPlusCircle,
  faSquareCheck,
  faWarning,
} from '@rq-ratings/pro-solid-svg-icons'
import React from 'react'
import { Button, FormCheck } from 'react-bootstrap'
import FormCheckLabel from 'react-bootstrap/FormCheckLabel'

import { formatDate } from '../../../lib/helpers/helperFunctions'
import {
  ReferralsImportItem,
  ReferralsImportItemPartial,
  ReferralsImportOnClientDuplicate,
} from '../../../redux/slices/referralsImportBase'

interface Props {
  importItem: ReferralsImportItem
  updateImportItem: (item: ReferralsImportItemPartial) => void
}

const TELEPHONE_PREFIX = 'tel.: '
const DATE_OF_BIRTH_PREFIX = 'dob: '

const ClientCell: React.FC<Props> = ({ importItem, updateImportItem }) => {
  const handleChange = (value: ReferralsImportOnClientDuplicate) => {
    updateImportItem({ refUUID: importItem.refUUID, onClientDuplicate: value })
  }

  const handlePauseEmailsToggle = () => {
    if (importItem.canPauseClientEmails) {
      updateImportItem({
        refUUID: importItem.refUUID,
        pausedClientEmails: !importItem.pausedClientEmails,
      })
    }
  }

  const isFirstNameDiff: boolean =
    !!importItem.userCompanyClient &&
    !!importItem.sourceClientFirstName &&
    importItem.userCompanyClient.firstName !== importItem.sourceClientFirstName
  const isLastNameDiff: boolean =
    !!importItem.userCompanyClient &&
    !!importItem.sourceClientLastName &&
    importItem.userCompanyClient.lastName !== importItem.sourceClientLastName
  const isTelephoneDiff: boolean =
    !!importItem.userCompanyClient &&
    !!importItem.sourceClientTelephone &&
    importItem.userCompanyClient.telephone !== importItem.sourceClientTelephone
  const isDateOfBirthDiff: boolean =
    !!importItem.userCompanyClient &&
    !!importItem.clientDateOfBirth &&
    importItem.userCompanyClient.dateOfBirth !== importItem.clientDateOfBirth

  const isDiff: boolean =
    isFirstNameDiff || isLastNameDiff || isTelephoneDiff || isDateOfBirthDiff
  const isUpdate = importItem.onClientDuplicate == 'update'

  const notificationsToggleElement = importItem.canPauseClientEmails ? (
    <FormCheckLabel className="d-block text-xs text-muted">
      <FormCheck
        checked={!importItem.pausedClientEmails}
        type="switch"
        onChange={handlePauseEmailsToggle}
        className="d-inline-block text-base"
      />
      Email notifications
    </FormCheckLabel>
  ) : (
    <br />
  )

  return (
    <>
      {importItem.userCompanyClient ? (
        <>
          <FontAwesomeIcon
            icon={faCheckCircle}
            className={`me-1 ${isDiff && isUpdate ? 'text-info' : 'text-success'}`}
          />
          <DiffValue
            isDiff={isFirstNameDiff && isUpdate}
            oldValue={importItem.userCompanyClient.firstName}
            newValue={importItem.sourceClientFirstName}
          />
          {', '}
          <DiffValue
            isDiff={isLastNameDiff && isUpdate}
            oldValue={importItem.userCompanyClient.lastName}
            newValue={importItem.sourceClientLastName}
          />
          {notificationsToggleElement}
          <span className="text-muted text-xs">
            {importItem.userCompanyClient.email}
          </span>
          {(importItem.userCompanyClient.telephone ||
            importItem.sourceClientTelephone) && (
            <>
              <br />
              <span className="text-muted text-xs">
                {TELEPHONE_PREFIX}
                <DiffValue
                  isDiff={isTelephoneDiff && isUpdate}
                  oldValue={
                    importItem.userCompanyClient.telephone || 'undefined'
                  }
                  newValue={importItem.sourceClientTelephone || 'undefined'}
                />
              </span>
            </>
          )}
          {(importItem.userCompanyClient.dateOfBirth ||
            importItem.clientDateOfBirth) && (
            <>
              <br />
              <span className="text-muted text-xs">
                {DATE_OF_BIRTH_PREFIX}
                <DiffValue
                  isDiff={isDateOfBirthDiff && isUpdate}
                  oldValue={
                    importItem.userCompanyClient.dateOfBirth
                      ? formatDate(importItem.userCompanyClient.dateOfBirth)
                      : 'undefined'
                  }
                  newValue={
                    importItem.clientDateOfBirth
                      ? formatDate(importItem.clientDateOfBirth)
                      : 'undefined'
                  }
                />
              </span>
            </>
          )}
          {isDiff && (
            <span className="d-block show-on-row-hover">
              <Button
                variant="link"
                size="sm"
                className="text-xs p-0 m-0 me-1"
                onClick={() =>
                  handleChange(
                    importItem.onClientDuplicate == 'keep-original'
                      ? 'update'
                      : 'keep-original',
                  )
                }
              >
                <FontAwesomeIcon
                  icon={isUpdate ? faSquareCheck : faSquare}
                  className="me-1"
                />
                update
              </Button>
              <span className="text-xs text-muted">
                {importItem.onClientDuplicate == 'keep-original'
                  ? '(keep existing)'
                  : ''}
              </span>
            </span>
          )}
        </>
      ) : (
        <>
          <FontAwesomeIcon icon={faPlusCircle} className="me-1 text-info" />
          {importItem.sourceClientFirstName}
          {', '}
          {importItem.sourceClientLastName}
          {notificationsToggleElement}
          <span className="text-muted text-xs">
            {!importItem.sourceClientEmailValid && (
              <>
                <FontAwesomeIcon icon={faWarning} className="text-danger" />{' '}
              </>
            )}
            {importItem.autogeneratedClientEmail ? (
              <>
                {importItem.autogeneratedClientEmail}
                <span className="text-warning"> (generated)</span>
              </>
            ) : (
              <>{importItem.sourceClientEmail}</>
            )}
            {!importItem.sourceClientEmailValid && (
              <span className="text-danger"> (invalid)</span>
            )}
          </span>
          {importItem.sourceClientTelephone && (
            <>
              <br />
              <span className="text-muted text-xs">
                {TELEPHONE_PREFIX}
                {importItem.sourceClientTelephone}
              </span>
            </>
          )}
          {importItem.clientDateOfBirth && (
            <>
              <br />
              <span className="text-muted text-xs">
                {DATE_OF_BIRTH_PREFIX}
                {formatDate(importItem.clientDateOfBirth)}
              </span>
            </>
          )}
        </>
      )}
    </>
  )
}

function DiffValue({
  isDiff,
  oldValue,
  newValue,
}: {
  isDiff: boolean
  oldValue: string
  newValue: string
}) {
  return (
    <>
      {isDiff ? (
        <>
          <span className="text-decoration-line-through opacity-75">
            {oldValue}
          </span>{' '}
          {newValue}
        </>
      ) : (
        oldValue
      )}
    </>
  )
}

export default ClientCell
