import React, { CSSProperties } from 'react'
import { Button, Container } from 'react-bootstrap'

import ActionButton from '../../../misc/ActionButton'
import { useWalkthroughSteps } from '../hooks/useWalkthroughSteps'
import { WalkthroughFlowProps } from '../WalkthroughFlow'
import StepIndicators from './StepIndicators'

type Props = Pick<
  WalkthroughFlowProps,
  'title' | 'variant' | 'completeButtonText' | 'onComplete' | 'isCompleting'
>

const StepControls: React.FC<Props> = ({
  title,
  variant,
  completeButtonText,
  onComplete,
  isCompleting = false,
}) => {
  const {
    canGoToNextStep,
    canGoToPreviousStep,
    goToNextStep,
    goToPreviousStep,
  } = useWalkthroughSteps()

  const isPageVariant = variant === 'page'

  const styles: CSSProperties = {
    minWidth: '100px',
  }

  function renderBackButton() {
    if (!canGoToPreviousStep) {
      return null
    }

    return (
      <Button
        variant="outline-secondary"
        size="lg"
        style={styles}
        onClick={goToPreviousStep}
      >
        Back
      </Button>
    )
  }

  function renderNextButton() {
    return canGoToNextStep ? (
      <Button variant="primary" size="lg" style={styles} onClick={goToNextStep}>
        Next
      </Button>
    ) : (
      <ActionButton
        isProcessing={isCompleting}
        style={{ minWidth: '100px' }}
        variant="primary"
        size="lg"
        onClick={onComplete}
      >
        {completeButtonText}
      </ActionButton>
    )
  }

  function renderControls() {
    return (
      <Container className="d-flex flex-column-reverse flex-sm-row align-items-center justify-content-end gap-3">
        <StepIndicators />
        {renderBackButton()}
        {renderNextButton()}
      </Container>
    )
  }

  function renderAsPage() {
    return (
      <header className="position-fixed top-0 bg-white w-100 box-shadow-lg border-box start-0 end-0 p-3">
        {renderControls()}
      </header>
    )
  }

  function renderAsComponent() {
    return (
      <Container className="d-flex flex-column flex-sm-row align-items-center justify-content-between gap-3">
        <h2 className="m-0 text-center text-black text-xxl flex-shrink-0">
          {title}
        </h2>
        {renderControls()}
      </Container>
    )
  }

  return isPageVariant ? renderAsPage() : renderAsComponent()
}

export default StepControls
