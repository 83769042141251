import React from 'react'
import { Button } from 'react-bootstrap'

import authService from '../../../../lib/services/authService'

const CreateAccountButton: React.FC = () => (
  <Button
    variant="primary"
    onClick={() => authService.registerAsCompanyUser()}
    className="w-100"
    style={{ marginBottom: '8px' }}
  >
    Create account
  </Button>
)

export default CreateAccountButton
