import { ButtonProps } from 'react-bootstrap'

import { THEME_PALETTE } from '../../lib/constants'

export const YELLOW_BUTTON_PROPS: ButtonProps = {
  variant: 'warning',
  size: 'lg',
  className: 'text-black px-4 btn-fluid',
  style: {
    backgroundColor: THEME_PALETTE.yellowDocuSign,
    borderColor: THEME_PALETTE.yellowDocuSign,
  },
}

export const ESIGNATURE_UNDERSTANDING_CHECKBOX_LABEL =
  'I agree that my electronic signature is as valid and legally binding as a handwritten signature.'
