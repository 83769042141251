import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@rq-ratings/pro-regular-svg-icons'
import React from 'react'
import { Button } from 'react-bootstrap'

import { useHandleReferToAdviserEmail } from '../../../hooks/useHandleReferToAdviserEmail'

interface Props {
  searchInput: string
}

const ReferToAdviserEmailPrompt: React.FC<Props> = ({ searchInput }) => {
  const handleReferToAdviserEmail = useHandleReferToAdviserEmail()

  return (
    <Button
      variant="primary"
      className="d-flex gap-2 align-items-center hover-bg-success hover-text-white hover-border-success"
      onClick={() => handleReferToAdviserEmail(searchInput)}
    >
      Click here to refer to someone not on RQ
      <FontAwesomeIcon icon={faArrowRight} />
    </Button>
  )
}

export default ReferToAdviserEmailPrompt
