import React from 'react'

import ImageWithText from '../../components/ImageWithText'
import screenshotUrl from './screenshot.png'

const ProfessionalNetworkScreen3: React.FC = () => (
  <ImageWithText
    imageUrl={screenshotUrl}
    text={
      <p>
        From the stats page, you can dig into invaluable data on your
        professional connections. Understand the health of your relationships,
        the revenue you’ve generated over time, and understand the experience
        your clients have enjoyed.
      </p>
    }
  />
)

export default ProfessionalNetworkScreen3
