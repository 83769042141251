import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft } from '@rq-ratings/pro-light-svg-icons'
import { faClockTwo, faInfoCircle } from '@rq-ratings/pro-regular-svg-icons'
import { faArrowRotateLeft } from '@rq-ratings/pro-solid-svg-icons'
import React from 'react'
import { Button, Modal } from 'react-bootstrap'
import { useNavigate } from 'react-router'

import { ImportReferralsProvider } from '../../../contexts/ImportReferralsContext'
import useAppDispatch from '../../../hooks/useAppDispatch'
import useAppSelector from '../../../hooks/useAppSelector'
import useMaxWidth from '../../../hooks/useMaxWidth'
import { generateUUID } from '../../../lib/helpers/uuid'
import { ROUTES } from '../../../lib/routes'
import { initialReferralsImportItemState } from '../../../redux/slices/referralsImportBase'
import {
  selectReferralsImportHistorical,
  setImportItems,
  setOpen,
  setStep,
} from '../../../redux/slices/referralsImportHistorical'
import { BulkImportReferralCollection } from '../../../types/responses/referral-import'
import ResolveImportHistorical from './ResolveImportHistorical'
import UploadHistorical from './UploadHistorical'

const ImportHistoricalReferralsModal: React.FC = () => {
  const isMaxWidthMd = useMaxWidth('md')
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const referralsImportHistoricalState = useAppSelector(
    selectReferralsImportHistorical,
  )

  const handleSuccessfulUpload = (data: BulkImportReferralCollection) => {
    dispatch(
      setImportItems(
        data['hydra:member'].map((item) => ({
          ...initialReferralsImportItemState,
          ...item,
          refUUID: generateUUID(),
          onClientDuplicate: referralsImportHistoricalState.onClientDuplicate,
        })),
      ),
    )
    dispatch(setStep('resolve-import'))
  }

  const handleClose = () => {
    dispatch(setOpen(false))
  }
  const handleStartOver = () => {
    dispatch(setStep(referralsImportHistoricalState.initialStep))
    dispatch(setImportItems([]))
  }
  const handleFinish = () => {
    dispatch(setStep(referralsImportHistoricalState.initialStep))
    dispatch(setImportItems([]))
    dispatch(setOpen(false))
  }

  const handleAutomaticFinish = () => {
    dispatch(setStep(referralsImportHistoricalState.initialStep))
    dispatch(setImportItems([]))
    dispatch(setOpen(false))
    // Redirect to referrals list
    navigate(ROUTES.referrals)
  }

  const FinishButton = (
    <Button variant="success" onClick={handleFinish}>
      Finish
    </Button>
  )
  const StartOverButton = (
    <Button
      variant="link"
      onClick={handleStartOver}
      className="ps-0 d-flex gap-1 align-items-center"
    >
      <FontAwesomeIcon icon={faArrowRotateLeft} />
      Start over
    </Button>
  )
  const fullscreen =
    isMaxWidthMd ||
    (referralsImportHistoricalState.step === 'resolve-import'
      ? true
      : undefined)

  return (
    <Modal
      show={referralsImportHistoricalState.open}
      fullscreen={fullscreen}
      centered
      onHide={handleClose}
    >
      {referralsImportHistoricalState.step == 'upload' && (
        <>
          <Modal.Header closeButton className="position-relative">
            <Modal.Title className="align-items-center d-flex gap-3">
              <FontAwesomeIcon icon={faClockTwo} size="2x" />
              Import historical referrals
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            {referralsImportHistoricalState.importItems.length > 0 && (
              <div className="alert alert-warning p-2 gap-2 align-items-center">
                <FontAwesomeIcon icon={faInfoCircle} className="text-warning" />

                <span>
                  You have{' '}
                  {referralsImportHistoricalState.importItems.length == 1
                    ? '1 referral'
                    : `${referralsImportHistoricalState.importItems.length} referrals`}{' '}
                  in progress.{' '}
                  <a
                    href="#"
                    onClick={() => dispatch(setStep('resolve-import'))}
                  >
                    Continue
                  </a>{' '}
                  or upload new file below to start over.
                </span>
              </div>
            )}
            <ImportReferralsProvider>
              <UploadHistorical
                onSuccessfulUpload={handleSuccessfulUpload}
                className="mt-3"
              />
            </ImportReferralsProvider>
          </Modal.Body>

          <Modal.Footer>
            {StartOverButton}
            <div className="flex-grow-1"></div>
            {FinishButton}
          </Modal.Footer>
        </>
      )}
      {referralsImportHistoricalState.step == 'resolve-import' && (
        <>
          <Modal.Header closeButton className="position-relative">
            <Modal.Title className="align-items-center d-flex gap-3">
              <FontAwesomeIcon icon={faClockTwo} size="2x" />
              Import historical referrals - Review
            </Modal.Title>

            <Button
              variant="link"
              className="position-absolute"
              style={{ left: '-30px' }}
              onClick={() => dispatch(setStep('upload'))}
            >
              <FontAwesomeIcon icon={faChevronLeft} size="2x" />
            </Button>
          </Modal.Header>

          <ResolveImportHistorical
            startOverButton={StartOverButton}
            handleFinish={handleFinish}
            handleAutomaticFinish={handleAutomaticFinish}
          />

          {fullscreen && (
            <div
              style={{
                height: 82,
                borderTop:
                  'var(--bs-modal-header-border-width) solid var(--bs-modal-header-border-color)',
              }}
            />
          )}
        </>
      )}
    </Modal>
  )
}

export default ImportHistoricalReferralsModal
