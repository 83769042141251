import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@rq-ratings/pro-regular-svg-icons'
import { useSuspenseQuery } from '@tanstack/react-query'
import React, { useState } from 'react'
import { Button } from 'react-bootstrap'
import { useSearchParams } from 'react-router'

import {
  REFERRALS_PAGE_SEARCH_PARAMS,
  REFERRALS_TAB,
} from '../../../../../lib/constants'
import { buildUrl } from '../../../../../lib/helpers/helperFunctions'
import { QUERY_KEYS } from '../../../../../lib/queryKeys'
import { ROUTES } from '../../../../../lib/routes'
import referralLinkService from '../../../../../lib/services/referralLinkService'
import { GetReferralLinksParams } from '../../../../../types/requests/referral-links'
import { CompanyProfileItemCompany } from '../../../../../types/responses/companies'
import DataTableContainer from '../../../../datatable/components/DataTableContainer'
import TablePagination from '../../../TablePagination'
import CreateReferralLinkCard from './components/CreateReferralLinkCard'
import CreateReferralLinkToWholeFirmButton from './components/CreateReferralLinkToWholeFirmButton'
import ReferralLinksTable from './components/ReferralLinksTable/ReferralLinksTable'
import { REFERRAL_LINKS_TABLE } from './constants'

interface Props {
  company: CompanyProfileItemCompany
}

const CalendarReferrals: React.FC<Props> = ({ company }) => {
  const [isCreatingLink, setIsCreatingLink] = useState(false)
  const [searchParams, setSearchParams] = useSearchParams()
  const page = Number(
    searchParams.get(REFERRAL_LINKS_TABLE.searchParams.page) || 1,
  )

  const params: GetReferralLinksParams = {
    'toCompany.id': company.id,
    page,
    pageSize: REFERRAL_LINKS_TABLE.pageSize,
  }

  const referralLinksQuery = useSuspenseQuery({
    queryKey: QUERY_KEYS.referralLinks(params),
    queryFn: () => referralLinkService.getReferralLinks(params),
    staleTime: 5000,
  })

  const referralLinksData = referralLinksQuery.data
  const referralLinks = referralLinksData['hydra:member']
  const totalNumReferralLinks = referralLinksData['hydra:totalItems']

  return (
    <div className="mw-md">
      <p>
        Create compliant shareable calendar links and enable your clients to
        book meetings with {company.presentationName} directly. Once a client
        schedules a meeting, the referral will become available from your{' '}
        <a
          href={buildUrl(ROUTES.referrals, {
            [REFERRALS_PAGE_SEARCH_PARAMS.tab]: REFERRALS_TAB.sent,
          })}
          target="_blank"
          rel="noreferrer"
        >
          referrals page
        </a>
        .
      </p>

      {company.hasUserWithCalendarConnection ? (
        <>
          {isCreatingLink ? (
            <CreateReferralLinkCard
              hasReferralLink={referralLinks.length > 0}
              company={company}
              onCreate={() => setIsCreatingLink(false)}
              onCancel={() => setIsCreatingLink(false)}
            />
          ) : (
            <Button
              variant="success"
              className="d-inline-flex align-items-center gap-2"
              onClick={() => setIsCreatingLink(true)}
              size="lg"
            >
              <FontAwesomeIcon icon={faPlus} />
              Create {referralLinks.length === 0 ? 'a' : 'another'} calendar
              link
            </Button>
          )}
        </>
      ) : (
        <CreateReferralLinkToWholeFirmButton
          company={company}
          numReferralLinks={totalNumReferralLinks}
        />
      )}

      {totalNumReferralLinks > 0 && (
        <DataTableContainer isLoading={referralLinksQuery.isRefetching}>
          <ReferralLinksTable company={company} referralLinks={referralLinks} />

          {totalNumReferralLinks > REFERRAL_LINKS_TABLE.pageSize && (
            <TablePagination
              activePage={page}
              totalItemsCount={totalNumReferralLinks}
              numItemsPerPage={REFERRAL_LINKS_TABLE.pageSize}
              onChange={(page) => {
                searchParams.set(
                  REFERRAL_LINKS_TABLE.searchParams.page,
                  page.toString(),
                )
                setSearchParams(searchParams, { replace: true })
              }}
            />
          )}
        </DataTableContainer>
      )}
    </div>
  )
}

export default CalendarReferrals
