import { useMutation, useQueryClient } from '@tanstack/react-query'
import React from 'react'
import { Button, Modal } from 'react-bootstrap'

import useNotyf from '../../../../../../hooks/useNotyf'
import { QUERY_KEYS } from '../../../../../../lib/queryKeys'
import referralLinkService from '../../../../../../lib/services/referralLinkService'
import { CompanyProfileItemCompany } from '../../../../../../types/responses/companies'
import { ReferralLinkCollectionItem } from '../../../../../../types/responses/referral-links'
import ActionButton from '../../../../ActionButton'

interface Props {
  company: CompanyProfileItemCompany
  referralLink: ReferralLinkCollectionItem
  onHide: () => void
}

const ConfirmDeleteModal: React.FC<Props> = ({
  company,
  referralLink,
  onHide,
}) => {
  const notyf = useNotyf()
  const queryClient = useQueryClient()

  const deleteLinkMutation = useMutation({
    mutationFn: () => referralLinkService.deleteReferralLink(referralLink.code),
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: QUERY_KEYS.companyProfile(),
      })

      await queryClient.invalidateQueries({
        queryKey: QUERY_KEYS.referralLinks({ ['toCompany.id']: company.id }),
      })

      onHide()

      notyf.success('Link deleted successfully')
    },
    onError: () => {
      notyf.error('Failed to delete link')
    },
  })

  return (
    <Modal onHide={onHide} show>
      <Modal.Header>
        <Modal.Title>Are you sure?</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <p>
          Are you sure you want to delete this link? If you've shared the link
          with anyone, the link will no longer work for them.
        </p>
      </Modal.Body>

      <Modal.Footer>
        <Button variant="outline-secondary" onClick={onHide}>
          Cancel
        </Button>

        <ActionButton
          variant="danger"
          onClick={() => deleteLinkMutation.mutate()}
          isProcessing={deleteLinkMutation.isPending}
        >
          Yes, delete
        </ActionButton>
      </Modal.Footer>
    </Modal>
  )
}

export default ConfirmDeleteModal
