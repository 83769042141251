import { ERROR_MESSAGES } from '../lib/constants'
import useNotyf from './useNotyf'

export function useShowGenericServerError() {
  const notyf = useNotyf()

  return function () {
    notyf.open({
      type: 'error',
      message: ERROR_MESSAGES.genericServerError,
      duration: 9000,
    })
  }
}
