import { NylasSchedulerBookingParticipant } from '@nylas/core'
import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'

import LoadingOverlay from '../../widgets/LoadingOverlay'
import ConfirmedBookingDetails from '../NylasScheduler/ConfirmedBookingDetails'
import MeetingScheduler from '../NylasScheduler/MeetingScheduler'
import { BookingDetails } from '../NylasScheduler/NylasScheduler'

interface Props {
  schedulerConfigId: string
  primaryParticipant: NylasSchedulerBookingParticipant
  onComplete: (bookingDetails: BookingDetails) => void
  onHide: () => void
  isProcessing: boolean
  toCompanyName: string
}

const SchedulerModal: React.FC<Props> = ({
  schedulerConfigId,
  primaryParticipant,
  onHide,
  onComplete,
  isProcessing,
  toCompanyName,
}) => {
  const [bookingDetails, setBookingDetails] = useState<BookingDetails | null>(
    null,
  )

  return (
    <Modal show onHide={onHide} size="lg" fullscreen="lg-down">
      <Modal.Header closeButton>
        <Modal.Title>Schedule a meeting</Modal.Title>
      </Modal.Header>

      <LoadingOverlay isActive={isProcessing} opaque>
        <Modal.Body>
          <div className="d-flex flex-column align-items-center">
            {bookingDetails ? (
              <ConfirmedBookingDetails
                bookingDetails={bookingDetails}
                toCompanyName={toCompanyName}
              />
            ) : (
              <MeetingScheduler
                schedulerConfigId={schedulerConfigId}
                primaryParticipant={primaryParticipant}
                onBookingComplete={(completedBooking: BookingDetails) => {
                  setBookingDetails(completedBooking)
                  onComplete(completedBooking)
                }}
              />
            )}
          </div>
        </Modal.Body>
      </LoadingOverlay>
    </Modal>
  )
}

export default SchedulerModal
