import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@rq-ratings/pro-light-svg-icons'
import { faTriangleExclamation } from '@rq-ratings/pro-solid-svg-icons'
import React, { useMemo, useRef, useState } from 'react'
import { Button, Form } from 'react-bootstrap'

import {
  BulkUpdatePayload,
  ReferralsImportItem,
  ReferralsImportItemPartial,
} from '../../../redux/slices/referralsImportBase'

interface Props {
  importItem: ReferralsImportItem
  updateImportItem: (item: ReferralsImportItemPartial) => void
  bulkUpdateItems: (payload: BulkUpdatePayload) => void
  bulkUpdateSelectedOnly: boolean
}

const OtherCompanyAdvisorCell: React.FC<Props> = ({
  importItem,
  updateImportItem,
  bulkUpdateItems,
  bulkUpdateSelectedOnly,
}) => {
  const [change, setChange] = useState(false)
  const selectRef = useRef<HTMLSelectElement | null>(null)

  const advisorsOptions: SelectableOption[] =
    useMemo((): SelectableOption[] => {
      return (
        importItem?.otherCompany?.users
          .map((user) => {
            return {
              label:
                user.firstName + ' ' + user.lastName + ' (' + user.email + ')',
              value: user.id,
            }
          })
          .filter(
            (value, index, array) =>
              array.findIndex((a) => a.value == value.value) === index,
          ) || []
      )
    }, [importItem])

  const handleChange = (value: number) => {
    const otherCompanyUser =
      importItem?.otherCompany?.users.find((user) => user.id === value) || null
    updateImportItem({
      refUUID: importItem.refUUID,
      otherCompanyUser: otherCompanyUser,
      otherCompanyUserDefaultUsed: false,
    })
    setChange(false)
  }

  const handleBulkApply = () => {
    bulkUpdateItems({
      filter: {
        selected: bulkUpdateSelectedOnly,
        otherCompany: importItem.otherCompany?.id,
      },
      data: {
        otherCompanyUser: importItem.otherCompanyUser,
        otherCompanyUserDefaultUsed: false,
      },
    })
  }

  const bulkUpdateScope =
    importItem.bulkCompanyCount > 1
      ? !bulkUpdateSelectedOnly
        ? 'all'
        : importItem.selected
          ? 'selected'
          : 'none'
      : 'none'

  if (!importItem.otherCompany) {
    return null
  } else if (change) {
    return (
      <>
        <Form.Select
          ref={selectRef}
          onChange={(event) => handleChange(+event.target.value)}
          onBlur={() => setChange(false)}
          defaultValue={importItem.otherCompanyUser?.id}
        >
          {advisorsOptions.map((option) => {
            return (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            )
          })}
        </Form.Select>
        <span className="d-block text-end mt-1">
          <Button
            size="sm"
            variant="outline-primary"
            onClick={() => {
              if (selectRef.current) {
                handleChange(+selectRef.current.value)
              }
            }}
          >
            <FontAwesomeIcon icon={faCheck} />
          </Button>
        </span>
      </>
    )
  } else if (importItem.otherCompanyUser) {
    return (
      <span className="text-muted text-xs">
        <span
          onClick={() => setChange(true)}
          className="font-weight-600 cursor-pointer"
        >
          {importItem.otherCompanyUser.firstName}{' '}
          {importItem.otherCompanyUser.lastName}
        </span>
        , {importItem.otherCompanyUser.email}
        {importItem.otherCompanyUserDefaultUsed && (
          <>
            <span className="text-warning"> (default)</span>
          </>
        )}
        {importItem.otherCompanyUserDefaultUsed &&
          importItem.sourceAdvisorEmail != '' && (
            <>
              ,<br />
              <span className="text-warning " style={{ maxWidth: '300px' }}>
                <FontAwesomeIcon
                  icon={faTriangleExclamation}
                  className="me-1"
                />
                advisor with email “{importItem.sourceAdvisorEmail}” not found,
                select from available advisors or leave default
              </span>
            </>
          )}
        <span className="show-on-row-hover">
          ,{' '}
          <Button
            variant="link"
            size="sm"
            className="text-xs p-0 m-0"
            onClick={() => setChange(true)}
          >
            change
          </Button>
          {bulkUpdateScope == 'all' && (
            <>
              {' or '}
              <Button
                variant="link"
                size="sm"
                className="text-xs p-0 m-0"
                onClick={handleBulkApply}
                title="Apply this advisor for all referrals for this company"
              >
                apply&nbsp;to&nbsp;all&nbsp;for&nbsp;this&nbsp;company&nbsp;(
                {importItem.bulkCompanyCount})
              </Button>
            </>
          )}
          {bulkUpdateScope == 'selected' && (
            <>
              {' or '}
              <Button
                variant="link"
                size="sm"
                className="text-xs p-0 m-0"
                onClick={handleBulkApply}
                title="Apply this advisor for selected referrals for this company"
              >
                apply&nbsp;to&nbsp;selected&nbsp;for&nbsp;this&nbsp;company
              </Button>
            </>
          )}
        </span>
      </span>
    )
  } else if (advisorsOptions.length > 0) {
    return (
      <span className="text-muted text-xs">
        No specific advisor selected
        <span className="show-on-row-hover">
          ,{' '}
          <Button
            variant="link"
            size="sm"
            className="text-xs p-0 m-0"
            onClick={() => setChange(true)}
          >
            choose
          </Button>
        </span>
      </span>
    )
  } else {
    return (
      <>
        <div className="text-muted text-xs mt-1">
          <FontAwesomeIcon icon={faTriangleExclamation} className="me-1" />
          Not possible to select advisor. No advisors available for this
          company.
        </div>
      </>
    )
  }
}

interface SelectableOption {
  label: string
  value: number
}

export default OtherCompanyAdvisorCell
