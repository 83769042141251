import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle, faPenField } from '@rq-ratings/pro-regular-svg-icons'
import React from 'react'
import { Button, Card } from 'react-bootstrap'

import { DOM_IDS } from '../../lib/constants'
import { CommercialAgreementTypeOption } from '../../redux/slices/commercialAgreementsForm'
import { CommercialAgreementStatus } from '../../types/misc'
import FieldError from '../form/FieldError'
import ReviewESignatureLetterModal from '../modals/ReviewESignatureLetterModal/ReviewESignatureLetterModal'
import { useReviewESignatureLetterModal } from '../modals/ReviewESignatureLetterModal/useReviewESignatureLetterModal'
import CardHeading from './CommercialAgreementsForm/components/CardHeading'
import ReviewActions from './CommercialAgreementsForm/components/ReviewActions'
import PlainButton from './PlainButton'
import { ServiceFeeDetail } from './ServiceFeesTable/ServiceFeesTableRows'

interface Props {
  fromCompanyName: string
  fromCompanyId: number
  toCompanyId: number
  serviceFees: ServiceFeeDetail[]
  onApprove: () => void
  hasApproved: boolean
  onAmend?: (amendRequest: string) => void
  onClearAction?: () => void
  hasAddedAmendmentRequest: boolean
  amendRequest?: string
  error?: string
  className?: string
  agreementType?: CommercialAgreementTypeOption
  agreementStatus: CommercialAgreementStatus
}

const ReviewESignatureLetterBox: React.FC<Props> = ({
  fromCompanyName,
  fromCompanyId,
  toCompanyId,
  serviceFees,
  hasApproved,
  hasAddedAmendmentRequest,
  onApprove,
  onAmend,
  amendRequest,
  error,
  className,
  agreementType,
  agreementStatus,
}) => {
  const { showModal } = useReviewESignatureLetterModal()

  function renderCta() {
    if (hasAddedAmendmentRequest) {
      return (
        <Button
          variant="success"
          disabled
          className="d-flex align-items-center gap-2"
        >
          <FontAwesomeIcon icon={faCheckCircle} style={{ fontSize: '16px' }} />
          Amendment request added
        </Button>
      )
    }

    if (hasApproved) {
      return (
        <Button
          variant="success"
          disabled
          className="d-flex align-items-center gap-2"
        >
          <FontAwesomeIcon icon={faCheckCircle} style={{ fontSize: '16px' }} />
          eSignature letter approved
        </Button>
      )
    }

    return (
      <Button
        variant="outline-success"
        onClick={showModal}
        id={DOM_IDS.reviewConsentLetterBox}
      >
        <FontAwesomeIcon icon={faPenField} /> Preview & approve eSignature
        letter
      </Button>
    )
  }

  const hasReviewed = hasApproved || hasAddedAmendmentRequest

  const reviewMessage = (
    <>
      As the FCA-regulated party, you are required to approve the wording of{' '}
      {fromCompanyName}'s introductory letter as a financial promotion. Please
      view and amend / approve as appropriate.
    </>
  )

  return (
    <>
      <Card className={className}>
        <Card.Body>
          <CardHeading>Client consent letter</CardHeading>

          <p className="mb-2">{reviewMessage}</p>

          <div className="d-flex gap-2">
            {renderCta()}

            {hasReviewed && (
              <PlainButton onClick={showModal} style={{ fontSize: '12px' }}>
                (View/amend)
              </PlainButton>
            )}
          </div>

          {error && <FieldError className="mt-1 text-base">{error}</FieldError>}
        </Card.Body>
      </Card>

      <ReviewESignatureLetterModal
        title="Approve client eSignature letter"
        fromCompanyId={fromCompanyId}
        toCompanyId={toCompanyId}
        serviceFees={serviceFees}
        agreementType={agreementType}
        agreementStatus={agreementStatus}
        renderHeader={() => (
          <>
            <p className="text-lg">{reviewMessage}</p>

            <ReviewActions
              onApprove={onApprove}
              onAmend={onAmend}
              amendRequest={amendRequest}
            />
          </>
        )}
      />
    </>
  )
}

export default ReviewESignatureLetterBox
