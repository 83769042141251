import classNames from 'classnames'
import React from 'react'

import { CompanyProfileItem } from '../../../../../types/responses/companies'
import { useIsCompanyProfileModal } from '../../hooks/useIsCompanyProfileModal'
import CompanyActions from './CompanyActions'
import CompanyInfo from './CompanyInfo'
import DataDisclaimer from './DataDisclaimer'

interface Props {
  companyProfile: CompanyProfileItem
}

const CompanyProfileHeader: React.FC<Props> = ({ companyProfile }) => {
  const isCompanyProfileModal = useIsCompanyProfileModal()

  return (
    <section
      className={classNames('card mb-2', {
        'box-shadow-none border-0': isCompanyProfileModal,
      })}
    >
      <div
        className={classNames('card-body text-black', {
          'pt-0': isCompanyProfileModal,
        })}
      >
        <header className="d-flex flex-column gap-3 column-gap-sm-4">
          <CompanyInfo companyProfile={companyProfile} />
          <CompanyActions companyProfile={companyProfile} />
        </header>

        {!companyProfile.isRegistered && <DataDisclaimer />}
      </div>
    </section>
  )
}

export default CompanyProfileHeader
