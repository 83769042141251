import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@rq-ratings/pro-light-svg-icons'
import { cloneDeep } from 'lodash'
import React, { useState } from 'react'
import { Button, Form, FormControl } from 'react-bootstrap'

import { DEFAULT_BULK_IMPORT_CIRCUMSTANCES } from '../../../lib/constants'
import {
  BulkUpdatePayload,
  ReferralsImportItem,
} from '../../../redux/slices/referralsImportBase'

interface Props {
  importItem: ReferralsImportItem
  updateImportItem: (item: ReferralsImportItem) => void
  bulkUpdateItems: (payload: BulkUpdatePayload) => void
  bulkUpdateSelectedOnly: boolean
}

const CircumstancesCell: React.FC<Props> = ({
  importItem,
  updateImportItem,
  bulkUpdateItems,
  bulkUpdateSelectedOnly,
}) => {
  const [change, setChange] = useState(false)
  const formRef = React.createRef<HTMLFormElement>()

  const handleOnSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    const newImportItem = cloneDeep(importItem)
    newImportItem.circumstances = event.currentTarget.circumstances.value
    updateImportItem(newImportItem)
    setChange(false)
  }
  const handleInputChange = () => {
    formRef.current?.requestSubmit()
  }
  const handleBulkApply = () => {
    bulkUpdateItems({
      filter: {
        selected: bulkUpdateSelectedOnly,
      },
      data: {
        circumstances: importItem.circumstances,
      },
    })
  }
  const bulkUpdateScope = !bulkUpdateSelectedOnly
    ? 'all'
    : importItem.selected
      ? 'selected'
      : 'none'

  if (change) {
    return (
      <>
        <Form ref={formRef} onSubmit={handleOnSubmit}>
          <FormControl
            as="textarea"
            placeholder="Circumstances"
            defaultValue={importItem.circumstances}
            name="circumstances"
            type="text"
            rows={5}
            onBlur={handleInputChange}
          />
          <span className="d-block text-end mt-1">
            <Button type="submit" size="sm" variant="outline-primary">
              <FontAwesomeIcon icon={faCheck} />
            </Button>
          </span>
        </Form>
      </>
    )
  } else {
    return (
      <>
        <span className="cursor-pointer" onClick={() => setChange(true)}>
          {importItem.circumstances ?? DEFAULT_BULK_IMPORT_CIRCUMSTANCES}
        </span>
        <span className="show-on-row-hover text-xs">
          {' '}
          <Button
            variant="link"
            size="sm"
            className="text-xs p-0 m-0"
            onClick={() => setChange(true)}
          >
            change
          </Button>
          {bulkUpdateScope == 'all' && (
            <>
              {' or '}
              <Button
                variant="link"
                size="sm"
                className="text-xs p-0 m-0"
                onClick={handleBulkApply}
              >
                apply&nbsp;to&nbsp;all
              </Button>
            </>
          )}
          {bulkUpdateScope == 'selected' && (
            <>
              {' or '}
              <Button
                variant="link"
                size="sm"
                className="text-xs p-0 m-0"
                onClick={handleBulkApply}
              >
                apply&nbsp;to&nbsp;selected
              </Button>
            </>
          )}
        </span>
      </>
    )
  }
}

export default CircumstancesCell
