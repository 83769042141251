import classNames from 'classnames'
import React from 'react'
import { Button, Card } from 'react-bootstrap'
import { Link } from 'react-router'

import { useHomeRoute } from '../../hooks/useHomeRoute'

export interface InvalidOrExpiredCardProps {
  title?: React.ReactNode
  content?: React.ReactElement
  className?: string
  showCta?: boolean
}

const InvalidOrExpiredCard: React.FC<InvalidOrExpiredCardProps> = ({
  title,
  content,
  className,
  showCta,
}) => {
  const homeRoute = useHomeRoute()

  return (
    <Card className={classNames('my-4', className)}>
      <Card.Body>
        <Card.Title>
          <h1 className="h2">{title || 'Invalid link'}</h1>
        </Card.Title>

        {content || <p>This link is either invalid or has been revoked.</p>}

        {showCta && (
          <Link to={homeRoute}>
            <Button variant="primary" size="lg">
              Return to home
            </Button>
          </Link>
        )}
      </Card.Body>
    </Card>
  )
}

export default InvalidOrExpiredCard
