import { onlineManager } from '@tanstack/react-query'
import posthog from 'posthog-js'
import React, { useState } from 'react'
import { Button } from 'react-bootstrap'

import { useAuth } from '../../hooks/useAuth'
import { useCurrentCompany } from '../../hooks/useCurrentCompany'
import useModal from '../../hooks/useModal'
import { useSessionMetadata } from '../../hooks/useSessionMetadata'
import {
  CLIENT_CHANGED,
  COMPANY_CHANGED,
  ContextChangeMessage,
  useUserContextChanged,
} from '../../hooks/useUserContextChanged'
import { POSTHOG_EVENT } from '../../lib/constants'
import sentryService from '../../lib/services/sentryService'
import OverlayCard from './OverlayCard'

const UserChangedContextWarning: React.FC = () => {
  const sessionMetadata = useSessionMetadata()
  const isOnline = onlineManager.isOnline()
  const { isClientUser } = useAuth()
  const currentCompany = useCurrentCompany()
  const { isShowingModal, showModal, hideModal } = useModal()
  const [activeMessage, setActiveMessage] =
    useState<ContextChangeMessage | null>(null)

  useUserContextChanged((message) => {
    switch (message.type) {
      case COMPANY_CHANGED:
        if (currentCompany?.id === message.payload.company?.id) {
          setActiveMessage(null)
          hideModal()
        } else {
          setActiveMessage(message)
          showModal()
        }
        break

      case CLIENT_CHANGED:
        if (isClientUser) {
          setActiveMessage(null)
          hideModal()
        } else {
          setActiveMessage(message)
          showModal()
        }
        break

      default:
        showModal()
        console.error('Unknown context change')
    }
  })

  if (isShowingModal) {
    const logData: Record<string, unknown> = {
      isOnline,
      isClientUser,
      currentCompany,
      activeMessage,
      timestamp: new Date().toISOString(),
      userAgent: navigator.userAgent,
      currentUrl: window.location.href,
    }

    console.log('Capturing session conflict shown to user event', logData)

    sentryService.captureMessage({
      messageId: 'session-conflict-shown-to-user',
      message: 'Session conflict shown to user',
      extra: { sessionMetadata, ...logData },
    })

    posthog.capture(POSTHOG_EVENT.sessionConflictShownToUser, {
      isOnline,
    })

    return (
      <OverlayCard className="mt-3 mt-sm-6" variant="dark" fullScreen>
        <h2>Session conflict detected</h2>

        <p>
          It appears that you are logged in as a different company or user in
          another browser window. Please refresh the page to proceed.
        </p>

        <Button
          variant="primary"
          size="lg"
          onClick={() => {
            window.location.reload()
          }}
        >
          Refresh
        </Button>
      </OverlayCard>
    )
  }

  return null
}

export default UserChangedContextWarning
