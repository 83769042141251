import { useMutation } from '@tanstack/react-query'

import clientService from '../lib/services/clientService'
import companyService from '../lib/services/companyService'
import referralService from '../lib/services/referralService'
import { CommercialAgreementStatus, ReferralType } from '../types/misc'
import { CreateReferralESignatureConsentPreviewRequest } from '../types/requests/referrals'

interface MutationVariables {
  fromCompanyId: number
  toCompanyId: number
  type?: ReferralType
  clientId?: number
  serviceAreaIds: number[]
  referralLinkCode?: string
  clientDetails?: CreateReferralESignatureConsentPreviewRequest['clientDetails']
  agreementStatus: CommercialAgreementStatus
}

export function useCreateReferralESignatureConsentPreviewMutation() {
  return useMutation({
    mutationFn: ({
      fromCompanyId,
      toCompanyId,
      clientId,
      serviceAreaIds,
      type,
      referralLinkCode,
      clientDetails,
      agreementStatus,
    }: MutationVariables) => {
      const request: CreateReferralESignatureConsentPreviewRequest = {
        fromCompany: companyService.getIri(fromCompanyId),
        toCompany: companyService.getIri(toCompanyId),
        serviceAreaIds,
        referralLinkCode,
        clientDetails,
        commercialAgreementStatus: agreementStatus,
      }

      if (clientId) {
        request.client = clientService.getIri(clientId)
      }

      if (type) {
        request.type = type
      }

      return referralService.createReferralESignatureConsentPreview(request)
    },
  })
}
