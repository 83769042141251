import classNames from 'classnames'
import React from 'react'

interface Props {
  className?: string
  message: string
}

const HRMessage: React.FC<Props> = ({ className, message }) => (
  <div className={classNames('strike', className)}>
    <span>{message}</span>
  </div>
)

export default HRMessage
