import React from 'react'

import { useWalkthroughSteps } from '../hooks/useWalkthroughSteps'

interface Props {
  imageUrl: string
  imageAlt?: string
  text: React.ReactNode
}

const ImageWithText: React.FC<Props> = ({ imageUrl, imageAlt, text }) => {
  const { currentStep } = useWalkthroughSteps()

  return (
    <div className="border-box bg-white" style={{ borderRadius: '5px' }}>
      <img
        src={imageUrl}
        alt={imageAlt || currentStep?.label}
        className="img-fluid border-box-bottom"
      />

      <div
        className="text-lg font-weight-300"
        css={{
          strong: {
            fontWeight: '400',
          },
          lineHeight: 1.7,
          padding: '20px 24px 24px',
          'p:last-of-type': {
            marginBottom: '0',
          },
        }}
      >
        {text}
      </div>
    </div>
  )
}
export default ImageWithText
