import React from 'react'

import ActionButton, {
  ActionButtonProps,
} from '../../../components/misc/ActionButton'

type Props = ActionButtonProps & {
  onSubmitConsent: () => void
}

const SubmitESignatureButton: React.FC<Props> = ({
  onSubmitConsent,
  isProcessing,
  ...props
}) => {
  return (
    <ActionButton
      {...props}
      onClick={onSubmitConsent}
      disabled={isProcessing || props.disabled}
      isProcessing={isProcessing}
      isProcessingText="Submitting"
    />
  )
}

export default SubmitESignatureButton
