import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPencilAlt, faTrashAlt } from '@rq-ratings/pro-light-svg-icons'
import { faExclamationTriangle } from '@rq-ratings/pro-regular-svg-icons'
import classNames from 'classnames'
import React from 'react'
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap'

import { DATE_FORMATS } from '../../../../../../../lib/constants'
import { formatDate } from '../../../../../../../lib/helpers/helperFunctions'
import { ROUTES } from '../../../../../../../lib/routes'
import {
  ReferralLinkCollection,
  ReferralLinkCollectionItem,
} from '../../../../../../../types/responses/referral-links'
import ExternalLink from '../../../../../ExternalLink'
import CopyLink from '../../../../../PanelInviteEmailTemplate/CopyLink'
import ReferralIntroducedTo from '../../../../../ReferralIntroducedTo'

interface Props {
  referralLinks: ReferralLinkCollection['hydra:member']
  onEditClick: (referralLink: ReferralLinkCollectionItem) => void
  onDeleteClick: (referralLink: ReferralLinkCollectionItem) => void
}

const ReferralLinksTableRows: React.FC<Props> = ({
  referralLinks,
  onEditClick,
  onDeleteClick,
}) => {
  function renderActivity(link: ReferralLinkCollectionItem) {
    if (!link.createdAt || link.isCompanySchedulerLink) {
      return '-'
    }

    return (
      <>
        <div>
          {link.createdAt
            ? `Created by ${link.createdByUser.fullName} at ${formatDate(link.createdAt, DATE_FORMATS.DAY_MONTH_YEAR)}`
            : '-'}
        </div>

        {link.updatedAt && link.updatedByUser && (
          <div>
            Updated by {link.updatedByUser.fullName} at{' '}
            {formatDate(link.updatedAt, DATE_FORMATS.DAY_MONTH_YEAR)}
          </div>
        )}
      </>
    )
  }

  function renderIntroduceTo(link: ReferralLinkCollectionItem) {
    const toCompanyName = link.toCompany.presentationName

    // Nylas round-robin links
    if (link.isCompanySchedulerLink) {
      return toCompanyName
    }

    // Calendly links
    if (link.hasCalendlyScheduler) {
      return (
        <>
          {toCompanyName}
          <div className="text-sm text-muted">(Calendly)</div>
        </>
      )
    }

    // Nylas individual or multi-individual links
    return (
      <ReferralIntroducedTo
        title="List of individuals the client will be introduced to"
        users={link.users}
        toCompany={link.toCompany}
      />
    )
  }

  return (
    <>
      {referralLinks.map((link) => {
        const referralLinkPath = ROUTES.referralLink.replace(':code', link.code)
        const referralLinkUrl = window.location.origin + referralLinkPath

        return (
          <tr key={link.id}>
            {/* Introduce to */}
            <td>
              <div
                className={classNames('d-flex align-items-center gap-2', {
                  'text-danger': link.warningMessage,
                })}
              >
                {link.warningMessage && (
                  <OverlayTrigger
                    overlay={<Tooltip>{link.warningMessage}</Tooltip>}
                  >
                    <FontAwesomeIcon
                      icon={faExclamationTriangle}
                      className="text-xl"
                    />
                  </OverlayTrigger>
                )}

                <div>{renderIntroduceTo(link)}</div>
              </div>
            </td>

            {/* Activity */}
            <td>
              <div className="d-flex flex-column gap-2">
                {renderActivity(link)}
              </div>
            </td>

            {/* Actions */}
            <td className="sticky-column-end">
              <div className="d-flex flex-column flex-wrap gap-2">
                <ExternalLink
                  iconPlacement="start"
                  className="btn btn-sm btn-outline-secondary"
                  href={referralLinkPath}
                >
                  Preview
                </ExternalLink>

                <CopyLink
                  textToCopy={referralLinkUrl}
                  buttonText="Copy link"
                  variant="outline-success"
                  className="btn-fluid btn-sm"
                  style={{ minWidth: '110px' }}
                />

                {link.canBeEdited && (
                  <Button
                    variant="outline-info"
                    size="sm"
                    onClick={() => onEditClick(link)}
                  >
                    <FontAwesomeIcon icon={faPencilAlt} className="me-2" />
                    Edit
                  </Button>
                )}

                {link.canBeDeleted && (
                  <Button
                    variant="outline-danger"
                    size="sm"
                    onClick={() => {
                      onDeleteClick(link)
                    }}
                  >
                    <FontAwesomeIcon icon={faTrashAlt} className="me-2" />
                    Delete
                  </Button>
                )}
              </div>
            </td>
          </tr>
        )
      })}
    </>
  )
}

export default ReferralLinksTableRows
