import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationTriangle } from '@rq-ratings/pro-regular-svg-icons'
import classNames from 'classnames'
import React from 'react'
import { Button } from 'react-bootstrap'

import { useCompanyProfileSearchParams } from '../../../../../hooks/useCompanyProfileSearchParams'
import { PendingPanelAction } from '../../../../../types/misc'
import { CompanyProfileItemPanel } from '../../../../../types/responses/companies'
import { COMPANY_PROFILE_TAB } from '../../constants'
import RespondToInviteButtons from './RespondToInviteButtons'

interface Props {
  panel: CompanyProfileItemPanel
  pendingActions: PendingPanelAction[]
}

const PendingActionNote: React.FC<Props> = ({ panel, pendingActions }) => {
  const { searchParams, setTab } = useCompanyProfileSearchParams()

  const messages: Record<
    PendingPanelAction,
    {
      label: string
      action?: React.ReactNode
    } | null
  > = {
    respondToInvite: {
      label: 'Respond to invite',
      action: <RespondToInviteButtons panel={panel} />,
    },
    addCommercialAgreement: { label: 'No commercial agreements added' },
    reviewCurrentAgreement: {
      label: 'Commercial agreement(s) pending review',
      action: (
        <Button
          className={classNames('align-self-start', {
            'd-none':
              searchParams.tab === COMPANY_PROFILE_TAB.commercialAgreements,
          })}
          variant="success"
          size="sm"
          onClick={() => setTab(COMPANY_PROFILE_TAB.commercialAgreements)}
        >
          Review agreement(s)
        </Button>
      ),
    },
    chaseCurrentAgreementReview: {
      label: 'Commercial agreement(s) pending review',
    },
    reviewPendingAgreement: {
      label: 'Commercial agreement(s) pending review',
      action: (
        <Button
          className={classNames('align-self-start', {
            'd-none':
              searchParams.tab === COMPANY_PROFILE_TAB.commercialAgreements,
          })}
          variant="success"
          size="sm"
          onClick={() => setTab(COMPANY_PROFILE_TAB.commercialAgreements)}
        >
          Review agreement(s)
        </Button>
      ),
    },
    chasePendingAgreementReview: {
      label: 'Commercial agreement(s) pending review',
    },
    reviewCurrentIncomingAgreementConsentLetter: null,
    reviewPendingIncomingAgreementConsentLetter: null,
  }

  const message = pendingActions[0] ? messages[pendingActions[0]] : undefined

  if (!message) {
    return null
  }

  return (
    <div className="d-flex flex-column gap-2 justify-content-start">
      <div className="d-flex align-items-center text-lg text-warning gap-2">
        <FontAwesomeIcon icon={faExclamationTriangle} />
        {message.label}
      </div>

      {message.action}
    </div>
  )
}

export default PendingActionNote
