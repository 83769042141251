import { createSlice } from '@reduxjs/toolkit'

import { RootState } from '../store'
import {
  initialReferralsImportState,
  reducers,
  ReferralsImportState,
} from './referralsImportBase'

const INITIAL_STATE: ReferralsImportState = {
  ...initialReferralsImportState,
}

const referralsImportHistorical = createSlice({
  name: 'referralsImportHistorical',
  initialState: INITIAL_STATE,
  reducers,
})

export const referralsImportHistoricalReducer =
  referralsImportHistorical.reducer

export const selectReferralsImportHistorical = (state: RootState) =>
  state.referralsImportHistorical

export const {
  setOpen,
  setMultiSelect,
  setImportItems,
  updateImportItem,
  removeImportItem,
  setStep,
  setOnClientDuplicate,
  updateImportItemStatus,
  selectItems,
  bulkUpdateImportItems,
} = referralsImportHistorical.actions
