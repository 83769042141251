import classNames from 'classnames'
import React from 'react'

import CompanyLogoOrName from '../../../components/misc/CompanyLogoOrName'
import { ESignatureConsentPdfReferralCompany } from '../../../components/misc/ReferralESignatureLetterPdf/ReferralESignatureConsentPdf'

interface Props {
  company: ESignatureConsentPdfReferralCompany
  className?: string
}

const FromCompanyLogoOrName: React.FC<Props> = ({ company, className }) => (
  <CompanyLogoOrName
    name={company.presentationName}
    truncateName={false}
    wrapperProps={{
      className: classNames('font-weight-500 text-black', className),
      style: { fontSize: '28px', lineHeight: '1.5' },
    }}
    showLogoFallback={false}
    logoUrl={company.logoUrl}
    imageStyle={{ maxHeight: '85px', maxWidth: '240px' }}
  />
)

export default FromCompanyLogoOrName
