import {
  CreatePanelRequestRequest,
  UpdatePanelRequestStatusRequest,
} from '../../types/requests/panel-requests'
import {
  PanelRequestCollection,
  PanelRequestCollectionItem,
} from '../../types/responses/panel-requests'
import { REFERRAL_APPROVAL_STATUS } from '../constants'
import apiService from './apiService'

class PanelRequestService {
  private endpoint = '/v1/panel-requests'

  async getPanelRequests(params: {
    fromCompany?: ID
    page?: number
    pageSize?: number
  }): Promise<PanelRequestCollection> {
    const response = await apiService.get(this.endpoint, {
      params: {
        order: REFERRAL_APPROVAL_STATUS.pending,
        ...params,
      },
    })

    return response.data
  }

  async createPanelRequest(
    request: CreatePanelRequestRequest,
  ): Promise<PanelRequestCollectionItem> {
    const response = await apiService.post(this.endpoint, request)
    return response.data
  }

  async updateReferralApprovalStatus(
    panelId: ID,
    request: UpdatePanelRequestStatusRequest,
  ) {
    const response = await apiService.patch(
      `${this.endpoint}/${panelId}/update-approval-status`,
      request,
    )

    return response.data
  }
}

const panelRequestService = new PanelRequestService()

export default panelRequestService
