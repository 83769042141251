import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@rq-ratings/pro-light-svg-icons'
import { parseISO } from 'date-fns/parseISO'
import { cloneDeep } from 'lodash'
import React from 'react'
import { Button } from 'react-bootstrap'
import DatePicker from 'react-datepicker'

import { formatDate } from '../../../lib/helpers/helperFunctions'
import {
  BulkUpdatePayload,
  ReferralsImportItem,
} from '../../../redux/slices/referralsImportBase'

interface Props {
  importItem: ReferralsImportItem
  updateImportItem: (item: ReferralsImportItem) => void
  bulkUpdateItems: (payload: BulkUpdatePayload) => void
  bulkUpdateSelectedOnly: boolean
}

const ReferralDateCell: React.FC<Props> = ({
  importItem,
  updateImportItem,
  bulkUpdateItems,
  bulkUpdateSelectedOnly,
}) => {
  const [isEdit, setEdit] = React.useState(false)
  const isSet = !!importItem.referralDate
  const handleChange = (newDate: Date | null) => {
    const newImportItem = cloneDeep(importItem)
    newImportItem.referralDate = newDate ? newDate.toISOString() : null
    updateImportItem(newImportItem)
    setEdit(false)
  }
  const handleBulkApply = () => {
    bulkUpdateItems({
      filter: {
        selected: bulkUpdateSelectedOnly,
      },
      data: {
        referralDate: importItem.referralDate,
      },
    })
  }
  const bulkUpdateScope = !bulkUpdateSelectedOnly
    ? 'all'
    : importItem.selected
      ? 'selected'
      : 'none'

  return (
    <>
      {!isEdit && isSet ? (
        <>
          <span className="cursor-pointer" onClick={() => setEdit(true)}>
            {importItem.referralDate ? formatDate(importItem.referralDate) : ''}
          </span>
          <span className="show-on-row-hover text-xs">
            {', '}
            <Button
              variant="link"
              size="sm"
              className="text-xs p-0 m-0"
              onClick={() => setEdit(true)}
            >
              change
            </Button>
            {bulkUpdateScope == 'all' && (
              <>
                {' or '}
                <Button
                  variant="link"
                  size="sm"
                  className="text-xs p-0 m-0"
                  onClick={handleBulkApply}
                >
                  apply&nbsp;to&nbsp;all
                </Button>
              </>
            )}
            {bulkUpdateScope == 'selected' && (
              <>
                {' or '}
                <Button
                  variant="link"
                  size="sm"
                  className="text-xs p-0 m-0"
                  onClick={handleBulkApply}
                >
                  apply&nbsp;to&nbsp;selected
                </Button>
              </>
            )}
          </span>
        </>
      ) : (
        <>
          <DatePicker
            selected={
              importItem.referralDate
                ? parseISO(importItem.referralDate)
                : undefined
            }
            onChange={handleChange}
            onBlur={() => setEdit(false)}
            placeholderText="Select date"
            className="mw-100px form-control"
          />
          <span className="d-block text-end mt-1">
            <Button
              size="sm"
              variant="outline-primary"
              onClick={() => setEdit(false)}
            >
              <FontAwesomeIcon icon={faCheck} />
            </Button>
          </span>
        </>
      )}
    </>
  )
}

export default ReferralDateCell
