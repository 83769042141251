import React from 'react'
import { components, DropdownIndicatorProps } from 'react-select'

import { SelectOption } from '../../types/misc'
import { GroupedSelectOption } from '../form/MultiSelectInput'

const ReactSelectDropdownIndicator: React.FC<
  DropdownIndicatorProps<SelectOption, true, GroupedSelectOption>
> = (props) => {
  const menuIsOpen = props.selectProps.menuIsOpen

  function renderIndicator() {
    if (!menuIsOpen) {
      return <components.DropdownIndicator {...props} />
    }

    return (
      <components.DropdownIndicator {...props}>
        <span className="text-primary cursor-pointer px-1">Done</span>
      </components.DropdownIndicator>
    )
  }

  return <>{renderIndicator()}</>
}

export default ReactSelectDropdownIndicator
