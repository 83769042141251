import { useAppState } from './useAppState'

export function useNotificationsCount() {
  const appState = useAppState()

  const panels = Array.from(
    new Set([
      ...appState.panelRequestedNew,
      ...appState.panelRequestedUpdated,
      ...appState.panelApprovedNew,
      ...appState.panelApprovedUpdated,
    ]),
  ).length

  const panelRequests = Array.from(
    new Set([...appState.panelRequestPending]),
  ).length

  return {
    panels,
    panelRequests,
    professionalNetwork: panels + panelRequests,
    referrals: Array.from(
      new Set([
        ...appState.referralFromNew,
        ...appState.referralFromUpdated,
        ...appState.referralToNew,
        ...appState.referralToUpdated,
      ]),
    ).length,
  }
}
