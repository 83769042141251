import React from 'react'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import { Info } from 'react-feather'
import { FormatOptionLabelMeta, StylesConfig } from 'react-select'

import { GroupedSelectOption } from '../../components/form/MultiSelectInput'
import { SelectOption } from '../../types/misc'
import {
  THEME_PALETTE,
  THEME_PALETTE_DARK,
  THEME_PALETTE_LIGHT,
} from '../constants'
import { getTooltipClassName } from './helperFunctions'

interface BuildMultiStylesOptions {
  isDisabled?: boolean
  isPdf?: boolean
  isOffCanvas?: boolean
  showOnlyFirstValue?: boolean
  maxMenuHeight?: number
}

export type MultiSelectStyles = StylesConfig<
  SelectOption,
  true,
  GroupedSelectOption
>

export function buildMultiSelectStyles(
  options?: BuildMultiStylesOptions,
): MultiSelectStyles {
  const { isDisabled, isPdf, isOffCanvas, maxMenuHeight, showOnlyFirstValue } =
    options || {}

  return {
    menu: (provided) => ({
      ...provided,
      marginTop: 0,
      zIndex: 2,
    }),
    control: (provided) => ({
      ...provided,
      color: THEME_PALETTE_DARK.secondary,
      backgroundColor: isDisabled ? THEME_PALETTE.disabled : undefined,
      borderColor: THEME_PALETTE.borderColor,
      minHeight: isPdf ? 'auto' : provided.minHeight,
    }),
    menuList: (provided) => {
      return {
        ...provided,
        paddingTop: 0,
        display: isOffCanvas ? 'none' : undefined,
        maxHeight: maxMenuHeight ? `${maxMenuHeight}px` : '400px',
      }
    },
    multiValueLabel: (provided, { getValue, index }) => {
      if (!showOnlyFirstValue) {
        return provided
      }

      const isFirstValue = index === 0
      const numOptionsSelected = getValue().length

      return {
        display: isFirstValue ? 'block' : 'none',
        ':after': {
          fontWeight: 'bold',
          color: THEME_PALETTE_LIGHT.black,
          content:
            numOptionsSelected > 1
              ? `" (${numOptionsSelected} selected)"`
              : '""',
        },
      }
    },
    multiValue: (provided) => (showOnlyFirstValue ? {} : provided),
    multiValueRemove: (provided) =>
      showOnlyFirstValue ? { display: 'none' } : provided,
    group: (provided) => ({
      ...provided,
      margin: 0,
      paddingTop: 0,
      paddingBottom: '6px',
    }),
    groupHeading: (provided) => ({
      ...provided,
      borderTop: `1px solid ${THEME_PALETTE_DARK['gray-800']}`,
      color: THEME_PALETTE_DARK.secondary,
      fontSize: '15px',
      fontWeight: 'bold',
      marginTop: 0,
      paddingTop: '12px',
      textTransform: 'none',
    }),
    clearIndicator: (provided, props) => {
      return props.selectProps.menuIsOpen ? { display: 'none' } : provided
    },
    option: (provided) => ({
      ...provided,
      backgroundColor: 'transparent',
      color: 'inherit',
      ':active': {
        backgroundColor: 'transparent',
      },
    }),
  }
}

export function formatOptionLabel(
  option: SelectOption,
  meta: FormatOptionLabelMeta<SelectOption>,
) {
  const { label, tooltipHint, value } = option
  const { context, selectValue } = meta

  if (context !== 'menu') {
    return label
  }

  const isSelected = selectValue
    .map((selectedValue) => selectedValue.value)
    .includes(value)

  return (
    <div className="d-flex align-items-start flex-1">
      <input
        type="checkbox"
        className="form-check-input d-block"
        checked={isSelected}
        style={{ minWidth: '16px', minHeight: '16px', marginRight: '9px' }}
      />

      <div>
        {label}

        {tooltipHint && (
          <OverlayTrigger
            overlay={
              <Tooltip className={getTooltipClassName(tooltipHint.text)}>
                {tooltipHint?.text}
              </Tooltip>
            }
          >
            <Info
              className="text-primary"
              size={16}
              style={{ marginTop: '-2px', marginLeft: '6px' }}
            />
          </OverlayTrigger>
        )}
      </div>
    </div>
  )
}
