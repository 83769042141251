import React from 'react'

import { LOCKED_CLIENT_DETAILS_REASON } from '../../../lib/constants'
import { truncateText } from '../../../lib/helpers/helperFunctions'
import queryClient from '../../../lib/queryClient'
import { QUERY_KEYS } from '../../../lib/queryKeys'
import { ROUTES } from '../../../lib/routes'
import { HydraMember } from '../../../types/api'
import { ReferralEntity, ReferralStatusEntity } from '../../../types/entities'
import {
  ReferralInviteItem,
  ReferralItem,
} from '../../../types/responses/referrals'
import { SessionMetadataCompany } from '../../../types/responses/session-metadata'

export function isReferralItem(
  referral: ReferralItem | ReferralInviteItem,
): referral is ReferralItem {
  return referral.isInvite === false
}

export function isReferralInvite(
  referral: ReferralItem | ReferralInviteItem,
): referral is ReferralInviteItem {
  return referral.isInvite === true
}

export async function invalidateReferralSummaryQuery(referralId: number) {
  await queryClient.invalidateQueries({
    queryKey: QUERY_KEYS.referralItem(referralId),
  })
}

export function buildReferralSummaryLink(referralId: number) {
  return ROUTES.referralSummary.replace(':referralId', referralId.toString())
}

export function shouldDisableStatusUpdate(
  currentCompany: SessionMetadataCompany,
  referral: HydraMember<
    Pick<ReferralEntity, 'isCommercialAgreementComplete'> & {
      status: Pick<ReferralStatusEntity, 'locked'>
      toCompany: { '@id': IRI }
    }
  >,
): boolean {
  return (
    currentCompany['@id'] !== referral.toCompany['@id'] ||
    referral.status.locked ||
    !referral.isCommercialAgreementComplete
  )
}

export function getLockedClientDetailsMessage(
  referral: ReferralItem,
): React.ReactNode | null {
  const unembeddableScheduleCallUrl = referral.unembeddableScheduleCallUrl || ''

  switch (referral.lockedClientDetailsReason) {
    case LOCKED_CLIENT_DETAILS_REASON.awaitingClientConsent:
      return 'Information hidden until client has consented to introduction'
    case LOCKED_CLIENT_DETAILS_REASON.awaitingClientCallback:
      return 'Information hidden until client has requested call back'
    case LOCKED_CLIENT_DETAILS_REASON.incompleteCommercialAgreement:
      return 'Information hidden until commercial agreement has been confirmed'
    case LOCKED_CLIENT_DETAILS_REASON.unembeddableScheduleCallUrl:
      return (
        <>
          See meeting scheduled via the link:
          <a
            href={unembeddableScheduleCallUrl}
            target="_blank"
            rel="noreferrer"
          >
            {truncateText(unembeddableScheduleCallUrl, {
              maxLength: 40,
            })}
          </a>{' '}
          for client’s contact details
        </>
      )

    default:
      return null
  }
}
