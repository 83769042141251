import { useMutation } from '@tanstack/react-query'
import React from 'react'
import { Button, Modal } from 'react-bootstrap'

import { useInvalidatePanelQueries } from '../../../../../hooks/useInvalidatePanelQueries'
import useNotyf from '../../../../../hooks/useNotyf'
import panelService from '../../../../../lib/services/panelService'
import { CompanyProfileItemPanel } from '../../../../../types/responses/companies'
import ActionButton from '../../../ActionButton'

interface Props {
  onHide: () => void
  panel: CompanyProfileItemPanel
}

const DeclineInviteModal: React.FC<Props> = ({ onHide, panel }) => {
  const notyf = useNotyf()
  const invalidatePanelsQuery = useInvalidatePanelQueries()

  const declinePanelInviteMutation = useMutation({
    mutationFn: () => panelService.declinePanelInvite(panel.id),
    onSuccess: async () => {
      await invalidatePanelsQuery()
      notyf.success(
        `You have declined the relationship invite from ${panel.fromCompany.presentationName}`,
      )
      onHide()
    },
    onError: () => {
      notyf.error('Failed to decline relationship invite')
    },
  })

  return (
    <Modal show onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Decline relationship invitation</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <p className="mb-0">
          <strong>{panel.fromCompany.presentationName}</strong> has invited you
          to join their professional network.
          <br />
          If you decline their invite, you will not be able to refer clients
          between each other.
        </p>
      </Modal.Body>

      <Modal.Footer>
        <Button variant="outline-secondary" onClick={onHide}>
          Close
        </Button>

        <ActionButton
          variant="danger"
          onClick={() => declinePanelInviteMutation.mutate()}
          isProcessing={declinePanelInviteMutation.isPending}
          isProcessingText="Declining invite"
        >
          Decline invitation
        </ActionButton>
      </Modal.Footer>
    </Modal>
  )
}

export default DeclineInviteModal
