import React from 'react'
import { Badge, Table } from 'react-bootstrap'

import { CompanyProfileItemCompany } from '../../../../../../types/responses/companies'
import TabTitle from '../../../components/TabTitle'

interface Props {
  companyServices: CompanyProfileItemCompany['services']
  expertiseAreas: CompanyProfileItemCompany['expertiseAreas']
}

const FcaServicesAndExpertiseAreas: React.FC<Props> = ({
  companyServices,
  expertiseAreas,
}) => (
  <>
    <div className="mb-5">
      <TabTitle>This firm offers advice in the following areas:</TabTitle>
      <Table striped>
        <thead>
          <tr>
            <th>Area</th>
            <th>Independence</th>
            <th>Notes</th>
          </tr>
        </thead>
        <tbody>
          {companyServices.map((service, key) => (
            <tr key={key}>
              <td>{service.serviceAreaReadable}</td>
              <td>{service.serviceTypeReadable}</td>
              <td>{service.notes}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>

    {expertiseAreas.length > 0 && (
      <div className="mb-5">
        <h4>Additional areas of expertise</h4>
        {expertiseAreas.map((area, key) => (
          <Badge
            key={key}
            bg="light"
            className="mt-2 me-2 badge-pill p-2 text-lg text-black"
          >
            {area.name}
          </Badge>
        ))}
      </div>
    )}
  </>
)

export default FcaServicesAndExpertiseAreas
