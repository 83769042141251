import { useSearchParams } from 'react-router'

import { SEARCH_PARAMS } from '../lib/constants'

export function useBackLinkParams() {
  const [searchParams] = useSearchParams()

  return {
    backLabel: searchParams.get(SEARCH_PARAMS.backLabel) || 'Back',
    backUrl: searchParams.get(SEARCH_PARAMS.backUrl),
  }
}
