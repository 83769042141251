import posthog from 'posthog-js'
import { useEffect } from 'react'
import { useLocation } from 'react-router'

export function usePageViewListener() {
  const location = useLocation()

  useEffect(() => {
    posthog.capture('$pageview')
  }, [location])
}
