import { useIntersectionObserver } from '@uidotdev/usehooks'
import classNames from 'classnames'
import React, { useEffect, useRef, useState } from 'react'
import { useWindowSize } from 'react-use'

import useMinWidth from '../../hooks/useMinWidth'
import { isElementOverflowingHorizontally } from '../../lib/helpers/helperFunctions'
import LoadingOverlay, { LoadingOverlayProps } from '../widgets/LoadingOverlay'
import ScrollHelpText from './components/ScrollHelpText'
import VirtualScrollbar from './components/VirtualScrollbar'

interface Props {
  tableContainerProps?: React.HTMLAttributes<HTMLDivElement>
  tableProps?: React.HTMLAttributes<HTMLTableElement>
  loadingOverlayProps?: Partial<LoadingOverlayProps>
  isLoading?: boolean
  children: React.ReactNode
  darkMode?: boolean
  striped?: boolean
  showScrollHint?: boolean
}

const DataTable: React.FC<Props> = ({
  tableContainerProps,
  tableProps,
  loadingOverlayProps,
  isLoading = false,
  children,
  darkMode = false,
  striped = true,
  showScrollHint = false,
}) => {
  const [ref, intersectionObserverEntry] = useIntersectionObserver({
    threshold: 0,
    root: null,
  })
  const tableContainerRef = useRef<HTMLDivElement | null>(null)
  const tableRef = useRef<HTMLTableElement | null>(null)
  const [isTableReady, setIsTableReady] = useState(false)
  const isMinWidthSm = useMinWidth('sm')
  const isInView = !!intersectionObserverEntry?.isIntersecting
  const shouldShowVirtualScrollbar = isInView && isMinWidthSm && isTableReady
  const { width: windowWidth } = useWindowSize()

  useEffect(() => {
    const tableContainer = tableContainerRef.current
    const table = tableRef.current

    if (
      tableContainer &&
      isElementOverflowingHorizontally(tableContainer) &&
      table
    ) {
      table.classList.add('table--is-overflowing-horizontally')
    }
  }, [windowWidth])

  return (
    <div ref={ref}>
      {showScrollHint && <ScrollHelpText className="mb-2" />}

      <div
        {...tableContainerProps}
        className={classNames(
          'table-responsive table-responsive-bordered position-relative',
          tableContainerProps?.className,
        )}
        ref={tableContainerRef}
      >
        <LoadingOverlay isActive={isLoading} {...loadingOverlayProps}>
          {shouldShowVirtualScrollbar && (
            <VirtualScrollbar
              key={windowWidth}
              elementContainerRef={tableContainerRef}
              elementRef={tableRef}
            />
          )}

          <table
            ref={(ref) => {
              tableRef.current = ref
              setIsTableReady(true)
            }}
            className={classNames(
              'DataTable table table-bordered table-vertically-aligned mb-0 text-sm mb-0',
              tableProps?.className,
              darkMode ? 'table-dark DataTable--dark' : '',
              { 'table-striped': !darkMode && striped },
            )}
            style={{ height: 'fit-content', ...tableProps?.style }}
          >
            {children}
          </table>
        </LoadingOverlay>
      </div>
    </div>
  )
}

export default DataTable
