import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle } from '@rq-ratings/pro-regular-svg-icons'
import { faCheck, faWarning } from '@rq-ratings/pro-solid-svg-icons'
import { useMutation } from '@tanstack/react-query'
import React from 'react'
import { Alert, Button, Form, Modal, Stack } from 'react-bootstrap'
import { Link } from 'react-router'

import useCurrentCompanyOrFail from '../../../hooks/useCurrentCompanyOrFail'
import useNotyf from '../../../hooks/useNotyf'
import { ROUTES } from '../../../lib/routes'
import companyService from '../../../lib/services/companyService'
import errorTypeService from '../../../lib/services/errorTypeService'
import panelRequestService from '../../../lib/services/panelRequestService'
import { ConstraintViolation } from '../../../types/api'
import { CreatePanelRequestRequest } from '../../../types/requests/panel-requests'
import InviteReferralPartnerEmailPreview from '../../modals/InviteReferralPartnerEmailPreview'
import ActionButton from '../ActionButton'
import EmailAddresses from '../EmailAddresses/EmailAddresses'
import CopyLink from '../PanelInviteEmailTemplate/CopyLink'

interface Props {
  className?: string
}

interface PartnerRequest {
  email: string
  comment: string
  errorMessages: string[]
  state: 'pending' | 'success' | 'error'
}

const RequestReferralPartners: React.FC<Props> = ({ className }) => {
  const currentCompany = useCurrentCompanyOrFail()
  const [partnersRequestsMap, setPartnersRequestsMap] = React.useState<
    Map<string, PartnerRequest>
  >(new Map())
  const [hasEmailError, setHasEmailError] = React.useState<boolean>(false)
  const notyf = useNotyf()

  // Collect partner requests into more friendly arrays
  const allPartnersRequests = Array.from(partnersRequestsMap).map(
    ([, partnerRequest]) => partnerRequest,
  )
  const pendingPartnersRequests = allPartnersRequests.filter(
    (partnerRequest) => partnerRequest.state !== 'success',
  )
  const pendingPartnersRequestsEmails: string[] = pendingPartnersRequests.map(
    (partnerRequest) => partnerRequest.email,
  )
  const successfulPartnersRequests = allPartnersRequests.filter(
    (partnerRequest) => partnerRequest.state === 'success',
  )
  const unsuccessfulPartnersRequests = allPartnersRequests.filter(
    (partnerRequest) => partnerRequest.state === 'error',
  )

  const isValid =
    !pendingPartnersRequests.some(
      (partnerRequest) => partnerRequest.comment.length < 3,
    ) &&
    !hasEmailError &&
    pendingPartnersRequests.length > 0
  const shareLink = companyService.getInvitationLink(
    currentCompany.referralCode,
  )

  const createPanelRequestMutation = useMutation({
    mutationFn: async (request: CreatePanelRequestRequest) => {
      return panelRequestService.createPanelRequest(request)
    },
    onSuccess: (data) => {
      if (data.email) {
        const key = data.email
        setPartnersRequestsMap((prevState) => {
          const newPartners = new Map(prevState)
          const partnerRequest = newPartners.get(key)
          if (partnerRequest != undefined) {
            partnerRequest.state = 'success'
          }
          return newPartners
        })
      }
    },
    onError: async (error, { email }) => {
      if (errorTypeService.isConstraintViolationListError(error) && email) {
        const key = email
        const violationMessages =
          error.response?.data.violations.map(
            (violation: ConstraintViolation): string => violation.message,
          ) ?? []
        if (violationMessages.length > 0) {
          setPartnersRequestsMap((prevState) => {
            const newPartners = new Map(prevState)
            const partnerRequest = newPartners.get(key)
            if (partnerRequest != undefined) {
              partnerRequest.state = 'error'
              partnerRequest.errorMessages = violationMessages
            }
            return newPartners
          })
        }
      } else {
        notyf.error('Failed to send request')
      }
    },
  })

  const handleRequest = async () => {
    const request: CreatePanelRequestRequest = {
      fromCompany: currentCompany['@id'],
      type: 'new_company',
      email: null,
      comment: '',
      toCompany: null,
      toCompanyName: null,
    }
    allPartnersRequests.forEach((partnerRequest) => {
      if (partnerRequest.state !== 'success') {
        createPanelRequestMutation.mutate({
          ...request,
          email: partnerRequest.email,
          comment: partnerRequest.comment,
        })
      }
    })
  }

  return (
    <div className={className}>
      {successfulPartnersRequests.length > 0 && (
        <Alert variant="success" className="p-3 d-block fade-in">
          <h3 className="h4 d-flex gap-2 align-items-center">
            <span
              className="rounded-circle bg-success text-white d-inline-flex align-items-center justify-content-center fs-5"
              style={{ height: '1.5rem', width: '1.5rem' }}
            >
              <FontAwesomeIcon icon={faCheck} />
            </span>
            Request(s) sent
          </h3>

          <ul className="mb-0 list-style-type-none">
            {successfulPartnersRequests.map((partnerRequest) => (
              <li key={partnerRequest.email} className="fade-in">
                {partnerRequest.email}
              </li>
            ))}
          </ul>

          <p className="mt-4">
            Once they have been approved, you will receive a notification. You
            can track the status of your approval requests here:
            <br />
            <Link to={ROUTES.professionalNetworkRequests}>
              {`${window.location.protocol}//${window.location.host}${ROUTES.professionalNetworkRequests}`}
            </Link>
            .
          </p>
        </Alert>
      )}
      {unsuccessfulPartnersRequests.length > 0 && (
        <Alert variant="danger" className="p-3 d-block fade-in">
          <h3 className="h4 d-flex gap-2 align-items-center">
            <span
              className="rounded-circle bg-danger text-white d-inline-flex align-items-center justify-content-center fs-5"
              style={{ height: '1.5rem', width: '1.5rem' }}
            >
              <FontAwesomeIcon icon={faWarning} />
            </span>
            These requests couldn't be sent
          </h3>

          <ul className="mb-0">
            {unsuccessfulPartnersRequests.map((partnerRequest) => (
              <li key={partnerRequest.email} className="fade-in">
                <strong>{partnerRequest.email}</strong>
                {` - ${partnerRequest.errorMessages?.join(' | ')}`}
              </li>
            ))}
          </ul>
        </Alert>
      )}
      {successfulPartnersRequests.length > 0 && <h3>Send more requests</h3>}

      <Form.Group className="mb-3">
        <EmailAddresses
          values={pendingPartnersRequestsEmails}
          onChange={(newValues: string[], hasErrors: boolean) => {
            setPartnersRequestsMap((prevState) => {
              const newPartners = new Map<string, PartnerRequest>(prevState)
              // - filter out removed not successful requests
              Array.from(prevState).forEach(([email, partnerRequest]) => {
                if (
                  !newValues.includes(email) &&
                  partnerRequest.state !== 'success'
                ) {
                  newPartners.delete(email)
                }
              })
              // - add new requests
              newValues.forEach((email) => {
                if (!newPartners.has(email)) {
                  newPartners.set(email, {
                    email,
                    comment: '',
                    errorMessages: [],
                    state: 'pending',
                  })
                }
              })
              return newPartners
            })
            setHasEmailError(hasErrors)
          }}
        />
      </Form.Group>
      <div className="my-3">
        <span className="fw-bolder">Please note:</span> Your company has network
        approval enabled. This means an administrator must first approve a firm
        before it can be added to the platform.
      </div>
      {allPartnersRequests.length === 0 && (
        <>
          <Form.Control
            as="textarea"
            className="mb-3"
            rows={7}
            placeholder={`Enter your message to your network approver, explaining why you would like to invite a partner.\r\rConsider including details such as how you know them, whether you have any commercial agreements (e.g., receiving income for referrals), and the reasons for adding them to the network. Providing clear context will help ensure a smooth approval process.`}
            disabled={true}
          />
        </>
      )}
      {allPartnersRequests
        .filter((partnerRequest) => partnerRequest.state != 'success')
        .map((partnerRequest) => (
          <Form.Label key={partnerRequest.email} className="d-block mb-3">
            {allPartnersRequests.length > 1 ? partnerRequest.email : ''}
            <Form.Control
              as="textarea"
              rows={7}
              value={partnerRequest.comment}
              minLength={3}
              maxLength={1024}
              placeholder={`Enter your message to your network approver, explaining why you would like to invite ${partnerRequest.email}.\r\rConsider including details such as how you know them, whether you have any commercial agreements (e.g., receiving income for referrals), and the reasons for adding them to the network. Providing clear context will help ensure a smooth approval process.`}
              onChange={(e) =>
                setPartnersRequestsMap((prevState) => {
                  const newPartners = new Map(prevState)
                  const newPartnerRequest = newPartners.get(
                    partnerRequest.email,
                  )
                  if (newPartnerRequest) {
                    newPartnerRequest.comment = e.target.value
                  }
                  return newPartners
                })
              }
            />
          </Form.Label>
        ))}
      <Stack direction="horizontal" gap={2} className="mt-4">
        <CopyLink
          variant="link"
          textToCopy={shareLink}
          buttonText="Or copy your unique invite link"
          className="p-0"
        />
        <div className="flex-grow-1" />
        <ActionButton
          isProcessing={createPanelRequestMutation.isPending}
          disabled={!isValid}
          className="btn btn-primary"
          size="lg"
          onClick={handleRequest}
        >
          Send {pendingPartnersRequests.length > 1 ? 'requests' : 'request'}
        </ActionButton>
      </Stack>
      <div className="d-flex justify-content-center justify-content-sm-end mt-3">
        <PreviewEmail />
      </div>
    </div>
  )
}

function PreviewEmail() {
  const [showEmailPreview, setShowEmailPreview] = React.useState<boolean>(false)
  return (
    <>
      <Button
        variant="link"
        className="p-0"
        onClick={() => setShowEmailPreview(true)}
      >
        Preview invite
      </Button>

      {showEmailPreview && (
        <Modal
          show
          centered
          fullscreen="sm-down"
          onHide={() => setShowEmailPreview(false)}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <strong className="fs-2">Preview of invitation email</strong>
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <Alert variant="info">
              <div className="alert-icon my-auto">
                <FontAwesomeIcon icon={faInfoCircle} fixedWidth size="lg" />
              </div>
              <div className="alert-message ">
                Once this invite request has been approved, this is the
                invitation email this firm will receive.
              </div>
            </Alert>

            <InviteReferralPartnerEmailPreview />

            <div className="mt-3 d-flex justify-content-end">
              <Button
                variant="outline-secondary"
                onClick={() => setShowEmailPreview(false)}
              >
                Close
              </Button>
            </div>
          </Modal.Body>
        </Modal>
      )}
    </>
  )
}

export default RequestReferralPartners
