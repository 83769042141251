import { FetchQueryOptions } from '@tanstack/react-query'

import { QUERY_KEYS } from '../../lib/queryKeys'
import referralService from '../../lib/services/referralService'
import { ReferralSetupDataItem } from '../../types/responses/referrals'

export const STEP_CONTROLS_DOM_ID = 'StepControls'

export const FETCH_REFERRAL_SETUP_DATA_QUERY_OPTIONS: FetchQueryOptions<ReferralSetupDataItem> =
  {
    queryKey: QUERY_KEYS.referralSetupData(),
    queryFn: () => referralService.getReferralSetupData(),
    staleTime: 5000,
  }
