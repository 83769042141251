import React, { useState } from 'react'

import { CompanyProfileItemCompany } from '../../../../../../../types/responses/companies'
import {
  ReferralLinkCollection,
  ReferralLinkCollectionItem,
} from '../../../../../../../types/responses/referral-links'
import DataTable from '../../../../../../datatable/DataTable'
import { REFERRAL_LINKS_TABLE } from '../../constants'
import ConfirmDeleteModal from '../ConfirmDeleteModal'
import EditReferralLinkModal from '../EditReferralLinkModal'
import ReferralLinksTableHeaders from './ReferralLinksTableHeaders'
import ReferralLinksTableRows from './ReferralLinksTableRows'

interface Props {
  company: CompanyProfileItemCompany
  referralLinks: ReferralLinkCollection['hydra:member']
}

const ReferralLinksTable: React.FC<Props> = ({ company, referralLinks }) => {
  const [referralLinkBeingEdited, setReferralLinkBeingEdited] =
    useState<ReferralLinkCollectionItem | null>(null)
  const [referralLinkBeingDeleted, setReferralLinkBeingDeleted] =
    useState<ReferralLinkCollectionItem | null>(null)

  return (
    <>
      <DataTable
        tableContainerProps={{
          className: 'mt-3',
          style: { width: REFERRAL_LINKS_TABLE.tableWidth },
        }}
        tableProps={{
          className: 'table-fixed table--is-sticky',
        }}
      >
        <thead>
          <ReferralLinksTableHeaders />
        </thead>

        <tbody>
          <ReferralLinksTableRows
            referralLinks={referralLinks}
            onEditClick={(referralLink) => {
              setReferralLinkBeingEdited(referralLink)
            }}
            onDeleteClick={(referralLink) => {
              setReferralLinkBeingDeleted(referralLink)
            }}
          />
        </tbody>
      </DataTable>

      {referralLinkBeingEdited && (
        <EditReferralLinkModal
          company={company}
          referralLink={referralLinkBeingEdited}
          onHide={() => setReferralLinkBeingEdited(null)}
        />
      )}

      {referralLinkBeingDeleted && (
        <ConfirmDeleteModal
          company={company}
          referralLink={referralLinkBeingDeleted}
          onHide={() => setReferralLinkBeingDeleted(null)}
        />
      )}
    </>
  )
}

export default ReferralLinksTable
