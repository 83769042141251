import useNotyf from '../../../../../hooks/useNotyf'
import {
  API_VIOLATION_CODE,
  LONG_MESSAGE_DURATION,
} from '../../../../../lib/constants'
import constraintViolationService from '../../../../../lib/services/constraintViolationService'

export function useHandleCreateOrUpdateReferralLinkError() {
  const notyf = useNotyf()

  const possibleErrorCodes = [
    API_VIOLATION_CODE.referralLinkNonStandaloneService,
    API_VIOLATION_CODE.missingReferralLinkIndividuals,
  ]

  function handleCreateOrUpdateReferralLinkError(error: unknown) {
    for (const errorCode of possibleErrorCodes) {
      const violation = constraintViolationService.getViolationByCode(
        error,
        errorCode,
      )

      if (violation) {
        notyf.errorWithoutReporting({
          message: violation.message,
          duration: LONG_MESSAGE_DURATION,
        })
        return
      }
    }

    notyf.error('Failed to create calendar link')
  }

  return { handleCreateOrUpdateReferralLinkError }
}
