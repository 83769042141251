import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faArrowRight,
  faExclamationTriangle,
  faInfoCircle,
  faPencil,
  faPlus,
  faTimes,
} from '@rq-ratings/pro-regular-svg-icons'
import { faCheck } from '@rq-ratings/pro-solid-svg-icons'
import { useMutation } from '@tanstack/react-query'
import classNames from 'classnames'
import { Form, Formik, FormikHelpers } from 'formik'
import React from 'react'
import { Button } from 'react-bootstrap'
import { Link, useSearchParams } from 'react-router'
import invariant from 'tiny-invariant'

import useCurrentCompanyOrFail from '../../../../hooks/useCurrentCompanyOrFail'
import { useInvalidatePanelQueries } from '../../../../hooks/useInvalidatePanelQueries'
import useNotyf from '../../../../hooks/useNotyf'
import {
  API_VIOLATION_CODE,
  COMMERCIAL_AGREEMENT_REFERRAL_DIRECTION,
  COMMERCIAL_AGREEMENT_REVIEW_STATUS,
  DOM_IDS,
  LONG_MESSAGE_DURATION,
  PENDING_PANEL_ACTION,
} from '../../../../lib/constants'
import {
  bool2Human,
  scrollToElement,
} from '../../../../lib/helpers/helperFunctions'
import { currentPath } from '../../../../lib/helpers/routeHelpers'
import { ROUTES } from '../../../../lib/routes'
import commercialAgreementService from '../../../../lib/services/commercialAgreementService'
import constraintViolationService from '../../../../lib/services/constraintViolationService'
import panelService from '../../../../lib/services/panelService'
import routeService from '../../../../lib/services/routeService'
import { COMMERCIAL_AGREEMENT_EDIT_PARAM } from '../../../../pages/commercialAgreementEdit/constants'
import { CommercialAgreementReferralDirection } from '../../../../types/misc'
import { ConfirmCommercialAgreementRequest } from '../../../../types/requests/commercial-agreements'
import {
  PanelItem,
  PanelItemCommercialAgreement,
} from '../../../../types/responses/panels'
import { OPTIONS } from '../../../flows/CompassFlow/utils/options'
import RadioOptionsInput from '../../../form/RadioOptionsInput'
import PreviewESignatureLetterModal from '../../../modals/PreviewESignatureLetterModal/PreviewESignatureLetterModal'
import { usePreviewESignatureLetterModal } from '../../../modals/PreviewESignatureLetterModal/usePreviewESignatureLetterModal'
import { useReviewESignatureLetterModal } from '../../../modals/ReviewESignatureLetterModal/useReviewESignatureLetterModal'
import ActionButton from '../../ActionButton'
import { COMMERCIAL_AGREEMENT_TYPE_OPTION } from '../../CommercialAgreementsForm/constants'
import InfoBox from '../../InfoBox'
import PlainButton from '../../PlainButton'
import ReviewESignatureLetterBox from '../../ReviewESignatureLetterBox'
import ServiceFeesTable from '../../ServiceFeesTable/ServiceFeesTable'
import {
  COMMERCIAL_AGREEMENT_TAB_KEY,
  COMMERCIAL_AGREEMENT_TAB_PARAMS,
  REVIEW_COMMERCIAL_AGREEMENT_FORM_FIELDS,
  ReviewCommercialAgreementFormValues,
} from '../constants'
import AgreementFieldRow from './AgreementFieldRow'
import AgreementStatusMessage from './AgreementStatusMessage'
import ExistingAgreements from './ExistingAgreements'
import RqCompliantFeeSharingTooltip from './RqCompliantFeeSharingTooltip'

interface Props {
  agreement?: PanelItemCommercialAgreement | null
  panel: PanelItem
  referralDirection: CommercialAgreementReferralDirection
  otherAgreementNeedsReview: boolean
  onAcceptInvite: () => void
  onConfirmAgreement: () => Promise<void>
}

interface ConfirmAgreementMutationVariables {
  values: ReviewCommercialAgreementFormValues
  formikHelpers: FormikHelpers<ReviewCommercialAgreementFormValues>
}

const DirectionAgreementDetails: React.FC<Props> = ({
  agreement,
  panel,
  referralDirection,
  otherAgreementNeedsReview,
  onAcceptInvite,
  onConfirmAgreement,
}) => {
  const previewESignatureLetterModal = usePreviewESignatureLetterModal()
  const reviewESignatureLetterModal = useReviewESignatureLetterModal()
  const needsReview = !!agreement?.needsReview
  const notyf = useNotyf()
  const invalidatePanelsQuery = useInvalidatePanelQueries()
  const currentCompany = useCurrentCompanyOrFail()
  const otherCompany =
    currentCompany.id === panel.fromCompany.id
      ? panel.toCompany
      : panel.fromCompany
  const [searchParams, setSearchParams] = useSearchParams()

  const isOutgoingAgreement =
    commercialAgreementService.isOutgoingAgreementDirection(referralDirection)

  const isPanelAwaitingApproval = panel.pendingActions.includes(
    PENDING_PANEL_ACTION.respondToInvite,
  )

  const deletePendingAgreementMutation = useMutation({
    mutationFn: (agreement: PanelItemCommercialAgreement) =>
      commercialAgreementService.deletePendingAgreement(agreement.id),
    onSuccess: async (_response, agreement) => {
      await handleConfirmOrRejectAgreementSuccess()

      notyf.success(
        agreement.needsReview
          ? 'Changes successfully rejected'
          : 'Changes successfully cancelled',
      )
    },

    onError: (_error, agreement) => {
      notyf.error(
        agreement.needsReview
          ? 'Failed to reject changes'
          : 'Failed to cancel changes',
      )
    },
  })

  const isOnlyAgreementThatNeedsReview =
    needsReview && !otherAgreementNeedsReview

  const canConfirmAndAcceptInvite =
    isPanelAwaitingApproval &&
    agreement?.isCurrentAgreement &&
    isOnlyAgreementThatNeedsReview

  async function confirmAgreement(values: ReviewCommercialAgreementFormValues) {
    const { existingAgreementFile, isRqHandleFeeSharing } = values
    invariant(agreement, 'Can only confirm an agreement if an agreement exists')

    const request: ConfirmCommercialAgreementRequest = {
      consentLetterApprovedAt: values.clientConsentLetterApprovedAt,
      consentLetterAmendRequest: values.clientConsentLetterAmendRequest,
    }

    if (existingAgreementFile) {
      request.existingAgreementFileName = existingAgreementFile.name
      request.existingAgreementFileBase64 = existingAgreementFile.base64
    }

    if (isRqHandleFeeSharing !== null) {
      request.isRqHandleFeeSharing = isRqHandleFeeSharing
    }

    await commercialAgreementService.confirmAgreement(agreement.id, request)

    await onConfirmAgreement()
  }

  const pendingAgreement = isOutgoingAgreement
    ? panel.pendingOutgoingAgreement
    : panel.pendingIncomingAgreement

  function shouldShowAmendButton(
    agreement?: PanelItemCommercialAgreement | null,
  ): agreement is PanelItemCommercialAgreement {
    // If there's no agreement, don't show the amend button
    if (!agreement) {
      return false
    }

    // If there is a pending agreement for this direction and we're viewing the
    // current agreement, don't show the amend button as the user open the
    // pending agreement and amend that instead
    if (agreement.isCurrentAgreement) {
      return !pendingAgreement
    }

    // Must be viewing the pending agreement in which case it's ok to show
    return true
  }

  const shouldShowPendingAgreementMessage =
    agreement?.isCurrentAgreement && !!pendingAgreement

  async function invalidateQueries() {
    await invalidatePanelsQuery()
  }

  function resetCommercialAgreementTab() {
    const tabName = isOutgoingAgreement
      ? COMMERCIAL_AGREEMENT_TAB_PARAMS.outgoingAgreementTab
      : COMMERCIAL_AGREEMENT_TAB_PARAMS.incomingAgreementTab

    searchParams.set(tabName, COMMERCIAL_AGREEMENT_TAB_KEY.current)
    setSearchParams(searchParams)
  }

  async function handleConfirmOrRejectAgreementSuccess() {
    await invalidateQueries()
    resetCommercialAgreementTab()
  }

  const confirmAgreementMutation = useMutation({
    mutationFn: (variables: ConfirmAgreementMutationVariables) =>
      confirmAgreement(variables.values),
    onSuccess: async () => {
      await handleConfirmOrRejectAgreementSuccess()
      notyf.success('Agreement successfully confirmed')
    },
    onError: handleConfirmAgreementError,
  })

  const confirmAgreementAndAcceptInviteMutation = useMutation({
    mutationFn: async (variables: ConfirmAgreementMutationVariables) => {
      await confirmAgreement(variables.values)
      return panelService.acceptPanelInvite(panel.id)
    },
    onSuccess: async () => {
      await handleConfirmOrRejectAgreementSuccess()
      notyf.success(
        `${panel.fromCompany.presentationName} was added to your approved referral partners`,
      )
      onAcceptInvite()
    },
    onError: handleConfirmAgreementError,
  })

  function handleConfirmAgreementError(error: unknown) {
    const consentLetterError = constraintViolationService.getViolationByCode(
      error,
      API_VIOLATION_CODE.unapprovedOrUnamendedConsentLetter,
    )

    if (consentLetterError) {
      notyf.open({
        type: 'error',
        message: consentLetterError.message,
        duration: LONG_MESSAGE_DURATION,
      })

      const consentLetterElement = document.getElementById(
        DOM_IDS.reviewConsentLetterBox,
      )

      if (consentLetterElement) {
        consentLetterElement.classList.remove('pulse-primary')

        setTimeout(() => {
          consentLetterElement.classList.add('pulse-primary')
        }, 10)

        scrollToElement(consentLetterElement)
      }

      return
    }

    notyf.error('Failed to confirm agreement')
  }

  const renderActionButtonText = () => {
    if (agreement?.isCurrentAgreement) {
      return canConfirmAndAcceptInvite
        ? 'Confirm & accept invitation'
        : 'Confirm'
    }

    return canConfirmAndAcceptInvite
      ? 'Approve changes & accept invitation'
      : 'Approve changes'
  }

  function handleSubmit(
    values: ReviewCommercialAgreementFormValues,
    helpers: FormikHelpers<ReviewCommercialAgreementFormValues>,
  ) {
    if (canConfirmAndAcceptInvite) {
      confirmAgreementAndAcceptInviteMutation.mutate({
        values,
        formikHelpers: helpers,
      })

      return
    }

    if (needsReview) {
      confirmAgreementMutation.mutate({ values, formikHelpers: helpers })
      return
    }

    throw new Error('Did not expect to reach this point')
  }

  function renderActionButton() {
    const buttonClassName =
      'd-flex align-items-center gap-2 btn-fluid btn-fluid justify-content-center'

    if (canConfirmAndAcceptInvite) {
      return (
        <ActionButton
          type="submit"
          variant="success"
          size="lg"
          isProcessing={confirmAgreementAndAcceptInviteMutation.isPending}
          style={{ minWidth: '110px' }}
          className={buttonClassName}
        >
          <FontAwesomeIcon icon={faCheck} className="text-lg" />
          {renderActionButtonText()}
        </ActionButton>
      )
    }

    if (needsReview) {
      return (
        <ActionButton
          type="submit"
          variant="success"
          size="lg"
          isProcessing={confirmAgreementMutation.isPending}
          style={{ minWidth: '110px' }}
          className={buttonClassName}
        >
          <FontAwesomeIcon icon={faCheck} className="text-lg" />
          {renderActionButtonText()}
        </ActionButton>
      )
    }

    return null
  }

  const initialValues: ReviewCommercialAgreementFormValues = {
    existingAgreementFile: null,
  }

  const isPendingAgreementAwaitingReviewByUs =
    pendingAgreement?.reviewStatus ===
    COMMERCIAL_AGREEMENT_REVIEW_STATUS.AWAITING_REVIEW_BY_US

  const isIncomingAgreement =
    referralDirection === COMMERCIAL_AGREEMENT_REFERRAL_DIRECTION.INCOMING

  function shouldReviewESignatureLetter(
    agreement?: PanelItemCommercialAgreement | null,
  ): agreement is PanelItemCommercialAgreement {
    // Only show review box for incoming agreements
    if (!isIncomingAgreement || !agreement) {
      return false
    }

    // Don't require review is agreement is already complete
    if (agreement.isAgreementComplete) {
      return false
    }

    return agreement.isCurrentAgreement
      ? panel.pendingActions.includes(
          PENDING_PANEL_ACTION.reviewCurrentIncomingAgreementConsentLetter,
        )
      : panel.pendingActions.includes(
          PENDING_PANEL_ACTION.reviewPendingIncomingAgreementConsentLetter,
        )
  }

  const agreementTab = isOutgoingAgreement
    ? COMMERCIAL_AGREEMENT_TAB_PARAMS.outgoingAgreementTab
    : COMMERCIAL_AGREEMENT_TAB_PARAMS.incomingAgreementTab

  const canAnswerRqHandleFeeSharingQuestion =
    needsReview && typeof agreement?.isRqHandleFeeSharing !== 'boolean'

  return (
    <>
      <div>
        {agreement?.isPendingAgreement && (
          <InfoBox
            variant="blue"
            icon={faInfoCircle}
            iconStyle={{ fontSize: '14px', top: '4px' }}
          >
            <p>
              Please note that this agreement is still pending confirmation from
              both parties. Until confirmed by both parties, any referral
              activity will assume the{' '}
              <PlainButton
                className="text-decoration-underline"
                onClick={() => {
                  searchParams.set(
                    agreementTab,
                    COMMERCIAL_AGREEMENT_TAB_KEY.current,
                  )
                  setSearchParams(searchParams)
                }}
              >
                current agreement
              </PlainButton>
              .
            </p>
          </InfoBox>
        )}

        {shouldShowPendingAgreementMessage && (
          <InfoBox
            variant="yellowDark"
            className=" mt-3"
            icon={
              isPendingAgreementAwaitingReviewByUs
                ? faExclamationTriangle
                : faInfoCircle
            }
          >
            <p className="mb-2">
              {isPendingAgreementAwaitingReviewByUs
                ? 'Changes to this current agreement are pending your review.'
                : "Changes to this current agreement are pending your partner's review."}
            </p>

            <Button
              className={classNames(
                'd-flex align-items-center gap-2',
                isPendingAgreementAwaitingReviewByUs
                  ? ''
                  : 'bg-white hover-bg-primary',
              )}
              variant={
                isPendingAgreementAwaitingReviewByUs
                  ? 'success'
                  : 'outline-primary'
              }
              size={isPendingAgreementAwaitingReviewByUs ? 'lg' : undefined}
              onClick={() => {
                const tabParam = isOutgoingAgreement
                  ? COMMERCIAL_AGREEMENT_TAB_PARAMS.outgoingAgreementTab
                  : COMMERCIAL_AGREEMENT_TAB_PARAMS.incomingAgreementTab

                searchParams.set(tabParam, COMMERCIAL_AGREEMENT_TAB_KEY.pending)
                setSearchParams(searchParams)
              }}
            >
              {isPendingAgreementAwaitingReviewByUs
                ? 'Review changes now'
                : 'View changes'}

              <FontAwesomeIcon icon={faArrowRight} />
            </Button>
          </InfoBox>
        )}

        <Formik initialValues={initialValues} onSubmit={handleSubmit}>
          {({ values, setFieldValue }) => {
            function onApprove() {
              setFieldValue(
                REVIEW_COMMERCIAL_AGREEMENT_FORM_FIELDS.clientConsentLetterApprovedAt,
                new Date().toISOString(),
              )

              reviewESignatureLetterModal.hideModal()
            }

            function onAmend(amendRequest: string) {
              notyf.success('Amendment request added')

              setFieldValue(
                REVIEW_COMMERCIAL_AGREEMENT_FORM_FIELDS.clientConsentLetterAmendRequest,
                amendRequest,
              )

              reviewESignatureLetterModal.hideModal()
            }

            return (
              <Form>
                {commercialAgreementService.shouldShowServiceFees(
                  agreement?.commercialAgreementType,
                ) && (
                  <div className="my-4">
                    <ServiceFeesTable
                      agreementType={agreement?.commercialAgreementType}
                      commercialAgreementId={agreement?.id}
                      serviceFees={agreement?.serviceFees ?? []}
                      addAgreementLink={routeService.addRelationshipAgreement(
                        panel.id,
                        {
                          referralDirection,
                          returnPath: currentPath(),
                        },
                      )}
                      isPreviewLetter={false}
                    />
                  </div>
                )}

                {agreement?.commercialAgreementType ? (
                  <>
                    <AgreementFieldRow
                      field={{
                        label: 'Agreement type',
                        value: commercialAgreementService.getAgreementTypeLabel(
                          agreement?.commercialAgreementType,
                        ),
                      }}
                    />

                    {isOutgoingAgreement &&
                      commercialAgreementService.hasCommercialAgreement(
                        agreement?.commercialAgreementType,
                      ) && (
                        <AgreementFieldRow
                          field={{
                            label: 'eSignature via RQ PDF',
                            value: (
                              <Button
                                onClick={() =>
                                  previewESignatureLetterModal.showPreview()
                                }
                                variant="outline-primary"
                                size="sm"
                                className="btn-fluid"
                              >
                                Preview PDF
                              </Button>
                            ),
                          }}
                        />
                      )}

                    <AgreementFieldRow
                      field={{
                        label: 'Existing agreements',
                        value: (
                          <ExistingAgreements
                            agreement={agreement}
                            needsReview={needsReview}
                          />
                        ),
                      }}
                    />
                  </>
                ) : (
                  <p>
                    No agreement added.{' '}
                    <Link
                      to={routeService.addRelationshipAgreement(panel.id, {
                        referralDirection,
                        returnPath: currentPath(),
                      })}
                      className="d-block mt-2 text-decoration-none"
                    >
                      <Button
                        variant="success"
                        size="lg"
                        className="d-flex gap-2 align-items-center btn-fluid d-flex justify-content-center justify-content-sm-start"
                      >
                        <FontAwesomeIcon icon={faPlus} />
                        Add an agreement
                      </Button>
                    </Link>
                  </p>
                )}

                {agreement?.commercialAgreementType ===
                  COMMERCIAL_AGREEMENT_TYPE_OPTION.YFS &&
                  isOutgoingAgreement && (
                    <AgreementFieldRow
                      field={{
                        label: (
                          <>
                            Would you like RQ to handle compliant fee sharing?
                            <RqCompliantFeeSharingTooltip />
                          </>
                        ),
                        value: canAnswerRqHandleFeeSharingQuestion ? (
                          <RadioOptionsInput
                            label={false}
                            optionLabelStyle={{ height: '32px' }}
                            name={
                              REVIEW_COMMERCIAL_AGREEMENT_FORM_FIELDS.isRqHandleFeeSharing
                            }
                            options={OPTIONS.yesNo}
                          />
                        ) : (
                          <>{bool2Human(!!agreement.isRqHandleFeeSharing)}</>
                        ),
                        // message: values.isRqHandleFeeSharing &&
                        //   permissions.canManagePaymentProfile && (
                        //     <Suspense fallback={<SkeletonRows count={1} />}>
                        //       <div className="small font-weight-normal text-end mt-2">
                        //         <PaymentProfileStatus />
                        //       </div>
                        //     </Suspense>
                        //   ),
                      }}
                    />
                  )}

                {referralDirection ===
                  COMMERCIAL_AGREEMENT_REFERRAL_DIRECTION.INCOMING &&
                  agreement?.isRqHandleFeeSharing && (
                    <InfoBox
                      icon={faInfoCircle}
                      variant="blue"
                      className="mt-3"
                    >
                      {otherCompany.presentationName} has chosen RQ to manage
                      payments. In future, all fee sharing will need to be paid
                      directly to RQ rather than directly to{' '}
                      {otherCompany.presentationName}.
                    </InfoBox>
                  )}

                {shouldReviewESignatureLetter(agreement) && (
                  <section className="mt-4">
                    <ReviewESignatureLetterBox
                      className="border-box mb-0"
                      fromCompanyId={otherCompany.id}
                      toCompanyId={currentCompany.id}
                      fromCompanyName={otherCompany.presentationName}
                      serviceFees={agreement?.serviceFees || []}
                      onApprove={onApprove}
                      onAmend={onAmend}
                      amendRequest={values.clientConsentLetterAmendRequest}
                      hasApproved={!!values.clientConsentLetterApprovedAt}
                      hasAddedAmendmentRequest={
                        !!values.clientConsentLetterAmendRequest
                      }
                      agreementStatus={agreement.status}
                    />
                  </section>
                )}

                {agreement && (
                  <AgreementStatusMessage
                    panel={panel}
                    referralDirection={referralDirection}
                    agreement={agreement}
                  />
                )}

                <div className="d-flex flex-column flex-sm-row gap-2 align-items-center mt-3">
                  {renderActionButton()}

                  {agreement?.isPendingAgreement && (
                    <ActionButton
                      onClick={() =>
                        deletePendingAgreementMutation.mutate(agreement)
                      }
                      isProcessing={deletePendingAgreementMutation.isPending}
                      variant="outline-danger"
                      size="lg"
                      className="btn-fluid d-inline-flex justify-content-center align-items-center gap-2"
                    >
                      <FontAwesomeIcon icon={faTimes} className="text-xl" />
                      {agreement.needsReview
                        ? 'Reject changes'
                        : 'Cancel changes'}
                    </ActionButton>
                  )}

                  {shouldShowAmendButton(agreement) && (
                    <Link
                      to={ROUTES.commercialAgreementEdit.replace(
                        `:${COMMERCIAL_AGREEMENT_EDIT_PARAM.commercialAgreementId}`,
                        agreement.id.toString(),
                      )}
                      className="btn btn-lg btn-outline-primary btn-fluid d-inline-flex justify-content-center align-items-center gap-2"
                    >
                      <FontAwesomeIcon icon={faPencil} />
                      Amend agreement
                    </Link>
                  )}
                </div>
              </Form>
            )
          }}
        </Formik>
      </div>

      {agreement && agreement.isOutgoingAgreement && (
        <PreviewESignatureLetterModal
          commissionJustification={
            agreement.commissionJustification || undefined
          }
          fromCompanyId={currentCompany.id}
          toCompanyId={otherCompany.id}
          title="eSignature via RQ PDF"
          serviceFees={agreement?.serviceFees || []}
          agreementStatus={agreement.status}
          renderHeader={() => (
            <p className="mb-0">
              This is what an eSignature letter via RQ will look like:
            </p>
          )}
        />
      )}
    </>
  )
}

export default DirectionAgreementDetails
