import { useQuery } from '@tanstack/react-query'
import invariant from 'tiny-invariant'

import { QUERY_KEYS } from '../lib/queryKeys'
import onboardingChecklistService from '../lib/services/onboardingChecklistService'
import { selectOnboardingChecklist } from '../redux/slices/onboardingChecklist'
import useAppSelector from './useAppSelector'

export function useOnboardingChecklistQuery(checklistId?: number) {
  const onboardingChecklist = useAppSelector(selectOnboardingChecklist)
  const id = checklistId || onboardingChecklist?.id

  return useQuery({
    queryKey: QUERY_KEYS.onboardingChecklist(),
    queryFn: () => {
      invariant(id, 'Expected checklistId to be defined')

      return onboardingChecklistService.getChecklist(id)
    },
    enabled: !!id,
  })
}
