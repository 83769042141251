import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSquare, faSquareMinus } from '@rq-ratings/pro-regular-svg-icons'
import React from 'react'

import { BulkUpdatePayload } from '../../../redux/slices/referralsImportBase'

interface Props {
  hasSelected: boolean
  bulkUpdateItems: (payload: BulkUpdatePayload) => void
}

const SelectHeader: React.FC<Props> = ({ hasSelected, bulkUpdateItems }) => {
  return (
    <button
      className="flex items-center justify-center border-0 text-base"
      style={{
        width: '40px',
        height: '45px',
        background: 'none',
        outline: 'none',
      }}
      onClick={() => {
        bulkUpdateItems({
          filter: {},
          data: { selected: !hasSelected },
        })
      }}
    >
      <FontAwesomeIcon icon={hasSelected ? faSquareMinus : faSquare} />
    </button>
  )
}

export default SelectHeader
