import React from 'react'
import { objectKeys } from 'ts-extras'

import rqLogoUrl from '../../../assets/img/logo.svg'
import compassIconUrl from './assets/compass-icon.svg'
import letsGoIconUrl from './assets/lets-go-icon.svg'
import professionalNetworkIconUrl from './assets/professional-network-icon.svg'
import referralEngineIconUrl from './assets/referral-engine-icon.svg'
import IntroducingRq from './steps/1_IntroducingRq/IntroducingRq'
import ProfessionalNetworkScreen1 from './steps/2_ProfessionalNetworkScreen1/ProfessionalNetworkScreen1'
import ProfessionalNetworkScreen2 from './steps/2_ProfessionalNetworkScreen2/ProfessionalNetworkScreen2'
import ProfessionalNetworkScreen3 from './steps/2_ProfessionalNetworkScreen3/ProfessionalNetworkScreen3'
import ReferralEngineScreen1 from './steps/3_ReferralEngineScreen1/ReferralEngineScreen1'
import ReferralEngineScreen2 from './steps/3_ReferralEngineScreen2/ReferralEngineScreen2'
import ReferralEngineScreen3 from './steps/3_ReferralEngineScreen3/ReferralEngineScreen3'
import CompassScreen1 from './steps/4_CompassScreen1/CompassScreen1'
import CompassScreen2 from './steps/4_CompassScreen2/CompassScreen2'
import LetsGo from './steps/5_LetsGo/LetsGo'

export type WalkthroughStepKey =
  | 'introducingRq'
  | 'professionalNetwork1'
  | 'professionalNetwork2'
  | 'professionalNetwork3'
  | 'referralEngine1'
  | 'referralEngine2'
  | 'referralEngine3'
  | 'compass1'
  | 'compass2'
  | 'letsGo'

export const WALKTHROUGH_STEP: Record<WalkthroughStepKey, WalkthroughStepKey> =
  {
    introducingRq: 'introducingRq',
    professionalNetwork1: 'professionalNetwork1',
    professionalNetwork2: 'professionalNetwork2',
    professionalNetwork3: 'professionalNetwork3',
    referralEngine1: 'referralEngine1',
    referralEngine2: 'referralEngine2',
    referralEngine3: 'referralEngine3',
    compass1: 'compass1',
    compass2: 'compass2',
    letsGo: 'letsGo',
  }

export const WALKTHROUGH_STEPS: Record<
  WalkthroughStepKey,
  WalkthroughStepDetail
> = {
  introducingRq: {
    label: 'Introducing RQ',
    key: WALKTHROUGH_STEP.introducingRq,
    icon: rqLogoUrl,
    content: <IntroducingRq />,
  },
  professionalNetwork1: {
    label: 'Professional network',
    key: WALKTHROUGH_STEP.professionalNetwork1,
    icon: professionalNetworkIconUrl,
    content: <ProfessionalNetworkScreen1 />,
  },
  professionalNetwork2: {
    label: 'Professional network',
    key: WALKTHROUGH_STEP.professionalNetwork2,
    icon: professionalNetworkIconUrl,
    content: <ProfessionalNetworkScreen2 />,
  },
  professionalNetwork3: {
    label: 'Professional network',
    key: WALKTHROUGH_STEP.professionalNetwork3,
    icon: professionalNetworkIconUrl,
    content: <ProfessionalNetworkScreen3 />,
  },
  referralEngine1: {
    label: 'Referral engine',
    key: WALKTHROUGH_STEP.referralEngine1,
    icon: referralEngineIconUrl,
    content: <ReferralEngineScreen1 />,
  },
  referralEngine2: {
    label: 'Referral engine',
    key: WALKTHROUGH_STEP.referralEngine2,
    icon: referralEngineIconUrl,
    content: <ReferralEngineScreen2 />,
  },
  referralEngine3: {
    label: 'Referral engine',
    key: WALKTHROUGH_STEP.referralEngine3,
    icon: referralEngineIconUrl,
    content: <ReferralEngineScreen3 />,
  },
  compass1: {
    label: 'Compass',
    key: WALKTHROUGH_STEP.compass1,
    icon: compassIconUrl,
    content: <CompassScreen1 />,
  },
  compass2: {
    label: 'Compass',
    key: WALKTHROUGH_STEP.compass2,
    icon: compassIconUrl,
    content: <CompassScreen2 />,
  },
  letsGo: {
    label: "Let's go!",
    key: WALKTHROUGH_STEP.letsGo,
    icon: letsGoIconUrl,
    content: <LetsGo />,
  },
}

export const WALKTHROUGH_STEPS_LIST: WalkthroughStepDetail[] =
  Object.values(WALKTHROUGH_STEPS)

export const WALKTHROUGH_STEP_KEYS: WalkthroughStepKey[] =
  objectKeys(WALKTHROUGH_STEP)

export const WALKTHROUGH_PAGE_SEARCH_PARAMS = {
  step: 'step',
}

export interface WalkthroughStepDetail {
  icon: string
  key: WalkthroughStepKey
  label: string
  content: React.ReactNode
}
