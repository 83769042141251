import Placeholder from '@tiptap/extension-placeholder'
import Underline from '@tiptap/extension-underline'
import { Editor, EditorContent, Extensions, useEditor } from '@tiptap/react'
import { StarterKit } from '@tiptap/starter-kit'
import classNames from 'classnames'
import { useField, useFormikContext } from 'formik'
import React from 'react'

import { isDevelopment } from '../../lib/helpers/envHelpers'
import CharacterLimitIndicator from '../misc/CharacterLimitIndicator'
import Dump from '../utils/Dump'
import EditorToolbar from './EditorToolbar'
import FieldError from './FieldError'
import FieldLabel from './FieldLabel'

export interface RichTextInputProps {
  name: string
  label?: string
  placeholder?: string
  isDisabled?: boolean
  autoFocus?: boolean
  characterLimit?: number
  additionalExtensions?: Extensions
  variant?: 'basic' | 'advanced'
  debug?: boolean
  children?: (renderProps: RenderProps) => React.ReactNode
}

interface RenderProps {
  editor: Editor
  renderField: () => React.ReactNode
}

const RichTextInput: React.FC<RichTextInputProps> = ({
  name,
  label,
  placeholder,
  isDisabled,
  autoFocus = false,
  characterLimit,
  variant = 'basic',
  children,
  debug = false,
}) => {
  const [, { value, error }] = useField(name)
  const { submitCount, setFieldValue, setFieldTouched } = useFormikContext()
  const showError = !!(submitCount > 0 && error)
  const id = `field_${name}`

  const editor = useEditor({
    content: value,
    autofocus: autoFocus,
    extensions: [StarterKit, Underline, Placeholder.configure({ placeholder })],
    onUpdate: ({ editor }) => {
      // Remove <p> tags from inside <li> tags:
      // https://github.com/ueberdosis/tiptap/issues/118#issuecomment-1354508276
      const html = editor
        .getHTML()
        .replaceAll(/<li><p>(.*?)<\/p><(\/?)(ol|li|ul)>/gi, '<li>$1<$2$3>')

      setFieldTouched(name, true)
      setFieldValue(name, html)
    },
  })

  function renderField() {
    if (!editor) {
      return null
    }

    return (
      <>
        {label && <FieldLabel htmlFor={id}>{label}</FieldLabel>}

        <div className="my-2">
          <EditorToolbar editor={editor} variant={variant} />
        </div>

        <EditorContent
          disabled={isDisabled}
          editor={editor}
          className={classNames('d-flex', isDisabled ? 'bg-light' : 'bg-white')}
          style={{ minHeight: '140px' }}
        />

        {debug && isDevelopment() && (
          <Dump
            label="Debug"
            data={{ html: editor.getHTML(), text: editor.getText() }}
          />
        )}

        <div className="d-flex align-items-end justify-content-between py-1">
          <div>
            {showError && <FieldError inputId={id}>{error}</FieldError>}
          </div>

          {characterLimit && (
            <div className="d-flex justify-content-end">
              <CharacterLimitIndicator input={value} limit={characterLimit} />
            </div>
          )}
        </div>
      </>
    )
  }

  if (typeof children === 'function' && editor) {
    return children({ editor, renderField })
  }

  return renderField()
}

export default RichTextInput
