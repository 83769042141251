import React from 'react'

import ImageWithText from '../../components/ImageWithText'
import screenshotUrl from './screenshot.png'

const ProfessionalNetworkScreen2: React.FC = () => (
  <ImageWithText
    imageUrl={screenshotUrl}
    text={
      <p>
        Click on a firm and you’ll see a profile appear. This enables you to get
        a snapshot of the firm and see their overview, locations, services,
        teams, leave internal comments and more. From here you can refer your
        clients easily, by clicking the blue “Refer” button.
      </p>
    }
  />
)

export default ProfessionalNetworkScreen2
