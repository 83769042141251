import { SerializableFile } from '../../../types/misc'

export interface ReviewCommercialAgreementFormValues {
  existingAgreementFile: SerializableFile | null
  clientConsentLetterApprovedAt?: string
  clientConsentLetterAmendRequest?: string
  isRqHandleFeeSharing?: boolean
}

export const REVIEW_COMMERCIAL_AGREEMENT_FORM_FIELDS: Record<
  keyof ReviewCommercialAgreementFormValues,
  keyof ReviewCommercialAgreementFormValues
> = {
  existingAgreementFile: 'existingAgreementFile',
  clientConsentLetterApprovedAt: 'clientConsentLetterApprovedAt',
  clientConsentLetterAmendRequest: 'clientConsentLetterAmendRequest',
  isRqHandleFeeSharing: 'isRqHandleFeeSharing',
}

export type CommercialAgreementTabKey = 'current' | 'pending'

export interface CommercialAgreementTab {
  label: string
  key: CommercialAgreementTabKey
}

export const COMMERCIAL_AGREEMENT_TAB_KEY: Record<
  CommercialAgreementTabKey,
  CommercialAgreementTabKey
> = {
  current: 'current',
  pending: 'pending',
}

export const COMMERCIAL_AGREEMENT_TAB_PARAMS = {
  outgoingAgreementTab: 'outgoingAgreementTab',
  incomingAgreementTab: 'incomingAgreementTab',
}

export const AGREEMENT_TABS: CommercialAgreementTab[] = [
  {
    label: 'Current agreement',
    key: COMMERCIAL_AGREEMENT_TAB_KEY.current,
  },
  {
    label: 'Pending agreement',
    key: COMMERCIAL_AGREEMENT_TAB_KEY.pending,
  },
]
