import { createSlice } from '@reduxjs/toolkit'

import { RootState } from '../store'
import {
  initialReferralsImportState,
  reducers,
  ReferralsImportState,
} from './referralsImportBase'

const INITIAL_STATE: ReferralsImportState = {
  ...initialReferralsImportState,
}

const referralsImportNew = createSlice({
  name: 'referralsImportNew',
  initialState: INITIAL_STATE,
  reducers,
})

export const referralsImportNewReducer = referralsImportNew.reducer

export const selectReferralsImportNew = (state: RootState) =>
  state.referralsImportNew

export const {
  setOpen,
  setMultiSelect,
  setImportItems,
  updateImportItem,
  removeImportItem,
  setStep,
  setOnClientDuplicate,
  updateImportItemStatus,
  selectItems,
  bulkUpdateImportItems,
} = referralsImportNew.actions
