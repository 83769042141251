import { faClock } from '@rq-ratings/pro-regular-svg-icons'
import React from 'react'

import {
  COMMERCIAL_AGREEMENT_TYPE,
  REFERRAL_DIRECTION_COMMERCIAL_AGREEMENT_STATUS,
} from '../../../../lib/constants'
import commercialAgreementService from '../../../../lib/services/commercialAgreementService'
import { CommercialAgreementReferralDirection } from '../../../../types/misc'
import {
  PanelItem,
  PanelItemCommercialAgreement,
} from '../../../../types/responses/panels'
import InfoBox from '../../InfoBox'

interface Props {
  panel: PanelItem
  referralDirection: CommercialAgreementReferralDirection
  agreement: PanelItemCommercialAgreement
}

const AgreementStatusMessage: React.FC<Props> = ({
  panel,
  referralDirection,
  agreement,
}) => {
  const agreementStatus =
    commercialAgreementService.isOutgoingAgreementDirection(referralDirection)
      ? panel.outgoingAgreementStatus
      : panel.incomingAgreementStatus

  const isPendingReviewByUs = agreement?.isCurrentAgreement
    ? agreementStatus ===
      REFERRAL_DIRECTION_COMMERCIAL_AGREEMENT_STATUS.CURRENT_AGREEMENT_AWAITING_REVIEW_BY_US
    : agreementStatus ===
      REFERRAL_DIRECTION_COMMERCIAL_AGREEMENT_STATUS.PENDING_AGREEMENT_AWAITING_REVIEW_BY_US

  const isPendingReviewByThem = agreement?.isCurrentAgreement
    ? agreementStatus ===
      REFERRAL_DIRECTION_COMMERCIAL_AGREEMENT_STATUS.CURRENT_AGREEMENT_AWAITING_REVIEW_BY_THEM
    : agreementStatus ===
      REFERRAL_DIRECTION_COMMERCIAL_AGREEMENT_STATUS.PENDING_AGREEMENT_AWAITING_REVIEW_BY_THEM

  return (
    <>
      {isPendingReviewByUs && (
        <InfoBox
          variant="yellowDark"
          className="mt-3"
          icon={faClock}
          iconStyle={{
            position: 'relative',
            top: '4px',
            fontSize: '17px',
          }}
        >
          {agreement.isCurrentAgreement ? (
            <>
              Your referral partner added the details of your commercial
              agreement. If you agree with this, please press 'Confirm' below or
              press 'Amend agreement' to request changes.
            </>
          ) : (
            <>
              Your referral partner added the details of your commercial
              agreement. If you agree with this, please press 'Approve changes'
              below or 'Reject changes' if you don't. Alternatively, press
              'Amend agreement' to request changes.
            </>
          )}
        </InfoBox>
      )}

      {isPendingReviewByThem && (
        <InfoBox
          variant="yellowDark"
          className="mt-3"
          icon={faClock}
          iconStyle={{
            position: 'relative',
            top: '4px',
            fontSize: '17px',
          }}
        >
          {agreement.commercialAgreementType === COMMERCIAL_AGREEMENT_TYPE.NO
            ? 'You have advised that there is no commercial agreement in place.'
            : 'You have added the details of your commercial agreement with this firm.'}{' '}
          We are waiting for confirmation from your referral partner.
        </InfoBox>
      )}
    </>
  )
}

export default AgreementStatusMessage
