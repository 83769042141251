import { keepPreviousData, useQuery } from '@tanstack/react-query'
import invariant from 'tiny-invariant'

import { QUERY_KEYS } from '../../../../lib/queryKeys'
import commercialAgreementService from '../../../../lib/services/commercialAgreementService'
import panelService from '../../../../lib/services/panelService'
import { COMMERCIAL_AGREEMENT_DIRECTION } from '../../CommercialAgreementsForm/constants'

export function useCommercialAgreementsQuery(panelId?: number) {
  return useQuery({
    enabled: !!panelId,
    queryKey: QUERY_KEYS.panelAgreements(panelId),
    queryFn: async () => {
      invariant(panelId, 'Expected panelId to be defined')

      const panel = await panelService.getPanel(panelId)

      const agreementIds = panel.commercialAgreements.map(
        (agreement) => agreement.id,
      )

      const agreements = await Promise.all(
        agreementIds.map((id) => commercialAgreementService.getAgreement(id)),
      )

      const fromToAgreement = agreements.find(
        (agreement) =>
          agreement.commercialAgreementDirectionType ===
          COMMERCIAL_AGREEMENT_DIRECTION.fromTo,
      )

      const toFromAgreement = agreements.find(
        (agreement) =>
          agreement.commercialAgreementDirectionType ===
          COMMERCIAL_AGREEMENT_DIRECTION.toFrom,
      )

      const outgoingReferralsAgreement = agreements.find(
        (agreement) => agreement.isOutgoingAgreement,
      )

      const incomingReferralsAgreement = agreements.find(
        (agreement) => agreement.isIncomingAgreement,
      )

      return {
        panel,
        outgoingReferralsAgreement,
        incomingReferralsAgreement,
        fromToAgreement,
        toFromAgreement,
      }
    },
    placeholderData: keepPreviousData,
    staleTime: 4000,
  })
}
