import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@rq-ratings/pro-regular-svg-icons'
import React from 'react'
import { Spinner } from 'react-bootstrap'

import { Z_INDEX } from '../../lib/constants'

interface Props {
  children: React.ReactNode
  isHiding: boolean

  onHide(): void
}

const Banner: React.FC<Props> = ({ children, isHiding, onHide }) => {
  return (
    <section
      className="d-flex justify-content-center position-sticky top-0 align-items-center gap-5 bg-primary text-white text-center text-lg p-3"
      style={{ zIndex: Z_INDEX.banner }}
    >
      <div>{children}</div>

      {isHiding ? (
        <Spinner size="sm" className="hover-dim" />
      ) : (
        <FontAwesomeIcon
          onClick={onHide}
          icon={faTimes}
          className="text-xl"
          role="button"
          title="Dismiss"
        />
      )}
    </section>
  )
}

export default Banner
