import {
  createAsyncThunk,
  createSelector,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit'

import {
  INITIAL_COMPANY_SEARCH_FILTERS,
  PANEL_INVITE_STEPS,
  PanelInviteStep,
} from '../../../components/flows/PanelInviteFlow/utils/constants'
import {
  CompanySearchFilter,
  CompanySearchUIFilters,
  EnqueuedCompany,
} from '../../../components/flows/PanelInviteFlow/utils/types'
import { getOrFail } from '../../../lib/helpers/helperFunctions'
import { isRoute } from '../../../lib/helpers/routeHelpers'
import { ROUTES } from '../../../lib/routes'
import companyService from '../../../lib/services/companyService'
import { CheckEnqueuedCompaniesResponse } from '../../../types/responses/companies'
import { DirectoryCollectionItem } from '../../../types/responses/directory'
import { AppDispatch, RootState } from '../../store'
import { clearCommercialAgreementFormValues } from '../commercialAgreementsForm'

export interface PanelInviteState {
  activeFlow: PanelInviteFlow
  addRelationship: PanelInviteFlowState
  findProfessional: PanelInviteFlowState
  canAddPanelDirectly: boolean
}

type PanelInviteFlow = 'addRelationship' | 'findProfessional'

export const PANEL_INVITE_FLOW: Record<PanelInviteFlow, PanelInviteFlow> = {
  addRelationship: 'addRelationship',
  findProfessional: 'findProfessional',
}

export interface PanelInviteFlowState {
  currentStep: PanelInviteStep
  isLoading: boolean

  // Company search
  isCompanySearchFiltersEnabled: boolean
  companySearchFilters: CompanySearchUIFilters
  companySearchResults: DirectoryCollectionItem[]
  numCompanySearchResults: number

  enqueuedCompanies: EnqueuedCompany[]
  enqueuedCompanyIndex: number
}

function isAddRelationshipRoute() {
  return isRoute(ROUTES.addRelationship)
}

const INITIAL_FLOW_STATE: PanelInviteFlowState = {
  currentStep: PANEL_INVITE_STEPS.companySearch,
  companySearchFilters: INITIAL_COMPANY_SEARCH_FILTERS,
  enqueuedCompanies: [],
  enqueuedCompanyIndex: 0,
  companySearchResults: [],
  numCompanySearchResults: 0,
  isLoading: false,
  isCompanySearchFiltersEnabled: false,
}

export function buildInitialState(): PanelInviteState {
  return {
    activeFlow: isAddRelationshipRoute()
      ? PANEL_INVITE_FLOW.addRelationship
      : PANEL_INVITE_FLOW.findProfessional,
    addRelationship: INITIAL_FLOW_STATE,
    findProfessional: INITIAL_FLOW_STATE,
    canAddPanelDirectly: false,
  }
}

const sliceName = 'panelInvite'

// TODO: Maybe it will be simpler to use different slices for the find professional
// and add relationship pages instead of handling both in the same slice.
export const panelInvite = createSlice({
  name: sliceName,
  initialState: buildInitialState,
  reducers: {
    goToStep: (state, action: PayloadAction<PanelInviteStep>) => {
      state[state.activeFlow].currentStep = action.payload
    },

    clearEnqueuedCompanies: (state) => {
      state[state.activeFlow].enqueuedCompanies = []
    },

    setCompanySearchResults: (
      state,
      action: PayloadAction<DirectoryCollectionItem[]>,
    ) => {
      state[state.activeFlow].companySearchResults = action.payload
    },

    setNumCompanySearchResults: (state, action: PayloadAction<number>) => {
      state[state.activeFlow].numCompanySearchResults = action.payload
    },

    updateCanAddPanelDirectly: (state, action: PayloadAction<boolean>) => {
      state.canAddPanelDirectly = action.payload
    },

    updateCompanySearchFilters: (
      state,
      action: PayloadAction<CompanySearchFilter[]>,
    ) => {
      const filters = action.payload

      let newFilters = filters.reduce(
        (prevFilters, updatedFilter) => ({
          ...prevFilters,
          [updatedFilter.name]: updatedFilter.value,
        }),
        state[state.activeFlow].companySearchFilters,
      )

      // Reset page number if not updated explicitly
      const hasUpdatedPageNumber = filters.some(
        (filter) => filter.name === 'page',
      )
      if (!hasUpdatedPageNumber) {
        newFilters = { ...newFilters, page: 1 }
      }

      state[state.activeFlow].companySearchFilters = newFilters
    },

    updateCompanySearchFilter: (
      state,
      action: PayloadAction<CompanySearchFilter>,
    ) => {
      const filter = action.payload

      let newFilters: CompanySearchUIFilters = {
        ...state[state.activeFlow].companySearchFilters,
        [filter.name]: filter.value,
      }

      // Reset page number if not updated explicitly
      if (filter.name !== 'page') {
        newFilters = { ...newFilters, page: 1 }
      }

      state[state.activeFlow].companySearchFilters = newFilters
    },

    enableCompanySearchFilters: (state) => {
      state[state.activeFlow].isCompanySearchFiltersEnabled = true
    },

    toggleCompanySearchFilters: (state) => {
      state[state.activeFlow].isCompanySearchFiltersEnabled =
        !state[state.activeFlow].isCompanySearchFiltersEnabled
    },

    clearCompanySearchFilters: (
      state,
      action: PayloadAction<ClearCompanySearchOptions | undefined>,
    ) => {
      const shouldClearCompanyType = action?.payload?.clearCompanyType || false

      const previousFilters = state[state.activeFlow].companySearchFilters
      state[state.activeFlow].companySearchFilters = {
        ...INITIAL_COMPANY_SEARCH_FILTERS,
        companyTypeId: shouldClearCompanyType
          ? INITIAL_COMPANY_SEARCH_FILTERS.companyTypeId
          : previousFilters.companyTypeId,
      }
      state[state.activeFlow].enqueuedCompanyIndex = 0
      state[state.activeFlow].numCompanySearchResults = 0
    },

    toggleCompanyInQueue: (
      state,
      action: PayloadAction<DirectoryCollectionItem>,
    ) => {
      const company = action.payload
      const isCompanyEnqueued = state[state.activeFlow].enqueuedCompanies.some(
        (enqueuedCompany) => enqueuedCompany.indexId === company.indexId,
      )

      // Dequeue company
      if (isCompanyEnqueued) {
        state[state.activeFlow].enqueuedCompanies = state[
          state.activeFlow
        ].enqueuedCompanies.filter(
          (enqueueCompany) => enqueueCompany.indexId !== company.indexId,
        )
        return
      }

      // Enqueue company
      state[state.activeFlow].enqueuedCompanies.push(company)
    },

    setEnqueuedCompanies: (state, action: PayloadAction<EnqueuedCompany[]>) => {
      state[state.activeFlow].enqueuedCompanies = action.payload
    },

    setActiveFlow: (state, action: PayloadAction<PanelInviteFlow>) => {
      state.activeFlow = action.payload
    },

    resetFlow: (state) => {
      state[state.activeFlow] = INITIAL_FLOW_STATE
    },

    goToNextEnqueuedCompany: (state) => {
      const nextEnqueuedCompanyIndex =
        state[state.activeFlow].enqueuedCompanyIndex + 1
      const nextEnqueuedCompany =
        state[state.activeFlow].enqueuedCompanies[nextEnqueuedCompanyIndex]

      state[state.activeFlow].enqueuedCompanyIndex = nextEnqueuedCompanyIndex

      // If no more companies are enqueued, proceed to summary screen
      if (!nextEnqueuedCompany) {
        state[state.activeFlow].currentStep =
          PANEL_INVITE_STEPS.invitationSummary
        return
      }

      state[state.activeFlow].currentStep = state.canAddPanelDirectly
        ? nextEnqueuedCompany.iri
          ? PANEL_INVITE_STEPS.commercialAgreements
          : PANEL_INVITE_STEPS.inviteNewFirm
        : PANEL_INVITE_STEPS.createPanelRequest
    },
  },

  extraReducers: (builder) => {
    builder.addCase(proceedWithEnqueuedCompanies.pending, (state) => {
      state[state.activeFlow].isLoading = true
    })

    builder.addCase(proceedWithEnqueuedCompanies.fulfilled, (state, action) => {
      const enqueuedCompaniesInfo = action.payload
      const newEnqueuedCompanies = state[
        state.activeFlow
      ].enqueuedCompanies.map((enqueuedCompany) => ({
        ...enqueuedCompany,
        iri: enqueuedCompaniesInfo[enqueuedCompany.indexId] || undefined,
      }))

      const firstEnqueuedCompany = newEnqueuedCompanies[0]

      // If the enqueued company is registered, show the commercial agreements
      // form. Otherwise, show the invite new firm screen.
      const nextStep = state.canAddPanelDirectly
        ? firstEnqueuedCompany?.iri
          ? PANEL_INVITE_STEPS.commercialAgreements
          : PANEL_INVITE_STEPS.inviteNewFirm
        : PANEL_INVITE_STEPS.createPanelRequest

      state[state.activeFlow].enqueuedCompanies = newEnqueuedCompanies
      state[state.activeFlow].enqueuedCompanyIndex = 0
      state[state.activeFlow].currentStep = nextStep
      state[state.activeFlow].isLoading = false
    })
  },
})

export const {
  clearCompanySearchFilters,
  updateCanAddPanelDirectly,
  updateCompanySearchFilter,
  updateCompanySearchFilters,
  toggleCompanySearchFilters,
  setCompanySearchResults,
  setNumCompanySearchResults,
  setEnqueuedCompanies,
  goToNextEnqueuedCompany,
  toggleCompanyInQueue,
  clearEnqueuedCompanies,
  setActiveFlow,
  resetFlow,
} = panelInvite.actions

export const proceedWithEnqueuedCompanies = createAsyncThunk<
  CheckEnqueuedCompaniesResponse,
  void,
  { state: RootState; dispatch: AppDispatch }
>(`${sliceName}/proceedWithEnqueuedCompanies`, async (_, thunkApi) => {
  const panelInvite = thunkApi.getState().panelInvite
  const indexIds = panelInvite[panelInvite.activeFlow].enqueuedCompanies.map(
    (company) => company.indexId,
  )

  thunkApi.dispatch(clearCommercialAgreementFormValues())

  return companyService.checkEnqueuedCompanies(indexIds)
})

export const panelInviteReducer = panelInvite.reducer

export const selectPanelInvite = (state: RootState) => state.panelInvite

export const selectActiveFlow = createSelector(
  selectPanelInvite,
  (panelInvite) => {
    const activeFlow =
      panelInvite.activeFlow === PANEL_INVITE_FLOW.addRelationship ||
      isAddRelationshipRoute()
        ? PANEL_INVITE_FLOW.addRelationship
        : PANEL_INVITE_FLOW.findProfessional

    return panelInvite[activeFlow]
  },
)

export const selectEnqueuedCompanies = createSelector(
  selectActiveFlow,
  (state) => state.enqueuedCompanies,
)

export const selectEnqueuedCompany = createSelector(
  selectActiveFlow,
  (state) => state.enqueuedCompanies[state.enqueuedCompanyIndex],
)

export const selectEnqueuedCompanyOrFail = createSelector(
  selectEnqueuedCompany,
  (enqueuedCompany): EnqueuedCompany => {
    return getOrFail(
      enqueuedCompany,
      'Expected enqueued company to be set at this point',
    )
  },
)

export const selectCompanySearchFilters = createSelector(
  selectActiveFlow,
  (state) => state.companySearchFilters,
)

export const selectIsCompanySearchFiltersEnabled = createSelector(
  selectActiveFlow,
  (state) => state.isCompanySearchFiltersEnabled,
)

export const selectCompanySearchResults = createSelector(
  selectActiveFlow,
  (state) => state.companySearchResults,
)

export const selectNumCompanySearchResults = createSelector(
  selectActiveFlow,
  (state) => state.numCompanySearchResults,
)

export const selectCurrentStep = createSelector(
  selectActiveFlow,
  (state) => state.currentStep,
)

export const selectIsLoading = createSelector(
  selectActiveFlow,
  (state) => state.isLoading,
)

export const selectEnqueuedCompanyIndex = createSelector(
  selectActiveFlow,
  (state) => state.enqueuedCompanyIndex,
)

export const selectEnqueuedCompaniesProgress = createSelector(
  [selectEnqueuedCompanies, selectEnqueuedCompanyIndex],
  (enqueuedCompanies, enqueuedCompanyIndex) => {
    return `${enqueuedCompanyIndex + 1} of ${enqueuedCompanies.length}`
  },
)

export const selectActiveFlowName = createSelector(
  selectPanelInvite,
  (state) => state.activeFlow,
)

export const selectIsAddRelationshipFlow = createSelector(
  selectActiveFlowName,
  (flowName) => flowName === PANEL_INVITE_FLOW.addRelationship,
)

export const selectIsFindProfessionalFlow = createSelector(
  selectActiveFlowName,
  (flowName) => flowName === PANEL_INVITE_FLOW.findProfessional,
)

export const useIsFullSearch = () =>
  isRoute(ROUTES.findProfessional) || isRoute(ROUTES.addRelationship)

export interface ClearCompanySearchOptions {
  clearCompanyType?: boolean
}
