import { ReferralsTab } from '../../pages/referrals/ReferralsPage'
import { CommercialAgreementTypeOption } from '../../redux/slices/commercialAgreementsForm'
import { OrderDirection, PaginationFilters } from '../../types/api'
import {
  UpdateReferralApprovalStatusRequest,
  UpdateReferralClientRequest,
} from '../../types/requests/clients'
import {
  CheckCanReferRequest,
  CreateHistoricalReferralRequest,
  CreateNewReferralMetadataRequest,
  CreateReferralEmailPreviewRequest,
  CreateReferralESignatureConsentPreviewRequest,
  CreateReferralInviteRequest,
  CreateReferralRequest,
  CreateReferralViaReferralLinkRequest,
  GetReferralFirmsRequestParams,
  GetReferralIndividualsRequestParams,
  GetReferralInvitesRequestParams,
  GetReferralServiceAreaRequestParams,
  RequestReferralCallbackRequest,
  SubmitESignatureConsentLetterRequest,
  UpdateReferralRequest,
} from '../../types/requests/referrals'
import {
  CheckCanReferItem,
  NewReferralMetadataItem,
  ReferralCollection,
  ReferralCompanyTypeCollection,
  ReferralEmailItem,
  ReferralFirmCollection,
  ReferralGuestItem,
  ReferralIndividualCollection,
  ReferralInviteCollection,
  ReferralInviteItem,
  ReferralItem,
  ReferralServiceAreaCollection,
  ReferralSetupDataItem,
  ReferralsTableMetadataItem,
  ReferralSummary,
  ReferralWithESignatureDetailsItem,
  SubmitESignatureConsentLetterResponse,
  WithdrawGdprConsentForReferralResponse,
} from '../../types/responses/referrals'
import { REFERRAL_STATUS_ID } from '../constants'
import apiService from './apiService'
import commercialAgreementService from './commercialAgreementService'
import legislationService from './legislationService'

export type ReferralCollectionParams = PaginationFilters &
  ReferralCollectionSearchFilters &
  ReferralCollectionOrderFilters

export type ReferralCollectionSearchFilters = {
  'referralDate[after]'?: string
  'referralDate[before]'?: string
  'dateConsented[after]'?: string
  'dateConsented[before]'?: string
  'user.fullName'?: string
  'client.clientId'?: ID
  'client.fullName'?: ID
  'fromClient.email'?: string
  'fromCompany.id'?: ID
  'toCompany.id'?: ID
  'fromCompany.name'?: string
  'toCompany.name'?: string
  'status.id'?: string
  introducedTo?: string
  'services.serviceArea.id'?: ID[]
  'fromCompany.networks.id'?: ID[]
  'toCompany.networks.id'?: ID[]
  isEra?: boolean
  reason?: string
  approvalStatus?: string
}

export type ReferralCollectionOrderFilters = {
  'order[referralDate]'?: OrderDirection
  'order[dateConsented]'?: OrderDirection
  'order[user.firstName]'?: OrderDirection
  'order[client.firstName]'?: OrderDirection
  'order[client.clientId]'?: OrderDirection
  'order[toCompany.name]'?: OrderDirection
  'order[fromCompany.name]'?: OrderDirection
  'order[status.id]'?: OrderDirection
  'order[services.serviceArea.name]'?: OrderDirection
  'order[isEra]'?: OrderDirection
  'order[fromCompany.networks.id]'?: OrderDirection
  'order[toCompany.networks.id]'?: OrderDirection
}

export class ReferralService {
  private endpoint = '/v1/referrals'

  async getReferrals(
    filters: ReferralCollectionParams,
  ): Promise<ReferralCollection> {
    const response = await apiService.get(this.endpoint, {
      params: filters,
    })

    return response.data
  }

  async getReferralsTableMetadata(
    companyId: number,
    tab: ReferralsTab,
  ): Promise<ReferralsTableMetadataItem> {
    const response = await apiService.get<ReferralsTableMetadataItem>(
      `${this.endpoint}/table-metadata`,
      { params: { companyId, direction: tab } },
    )

    return response.data
  }

  async getReferralSummary(
    referralId: number,
    token?: string,
  ): Promise<ReferralSummary> {
    const referral = token
      ? await this.getReferralInvite(referralId, { token })
      : await this.getReferral(referralId)

    const hasReferredForFcaService = referral.services.some(
      (service) => service['@type'] === 'fca-service',
    )

    // If referral is not for an FCA service, there's no need to
    // fetch legislations
    if (!hasReferredForFcaService) {
      return {
        referral,
        legislations: [],
      }
    }

    // Otherwise, let's fetch the legislations
    const serviceAreaIds = referral.services.map(
      (service) => service.serviceArea.id,
    )
    const legislationsData =
      await legislationService.getLegislationsByFcaServiceAreaIds(
        serviceAreaIds,
      )

    return { referral, legislations: legislationsData['hydra:member'] }
  }

  async getReferral(referralId: number): Promise<ReferralItem> {
    const response = await apiService.get(`${this.endpoint}/${referralId}`)

    return response.data
  }

  async getReferralInvite(
    referralId: number,
    params: {
      token: string
    },
  ): Promise<ReferralInviteItem> {
    const response = await apiService.get(
      `${this.endpoint}/invites/${referralId}`,
      { params },
    )

    return response.data
  }

  async getReferralInvites(
    params: GetReferralInvitesRequestParams,
  ): Promise<ReferralInviteCollection> {
    const response = await apiService.get<ReferralInviteCollection>(
      `${this.endpoint}/invites`,
      { params },
    )

    return response.data
  }

  async getReferralWithESignatureDetails(
    referralId: number,
    callbackCode: string,
  ): Promise<ReferralWithESignatureDetailsItem> {
    const response = await apiService.get(
      `${this.endpoint}/${referralId}/guest/with-esignature-details`,
      { params: { callbackCode } },
    )

    return response.data
  }

  async createReferralESignatureConsentPreview(
    request: CreateReferralESignatureConsentPreviewRequest,
  ): Promise<ReferralWithESignatureDetailsItem> {
    const response = await apiService.post(
      `${this.endpoint}/guest/esignature-consent-letter/preview`,
      request,
    )

    return response.data
  }

  async getReferralByCallbackCode(
    referralId: number,
    callbackCode: string,
  ): Promise<ReferralGuestItem> {
    const response = await apiService.get<ReferralGuestItem>(
      `${this.endpoint}/${referralId}/guest`,
      { params: { callbackCode } },
    )

    return response.data
  }

  async getReferralByWithdrawGdprConsentCode(
    referralId: number,
    withdrawGdprConsentCode: string,
  ): Promise<ReferralGuestItem> {
    const response = await apiService.get<ReferralGuestItem>(
      `${this.endpoint}/${referralId}/guest`,
      { params: { withdrawGdprConsentCode } },
    )

    return response.data
  }

  async createReferral(
    request:
      | CreateReferralRequest
      | CreateReferralInviteRequest
      | CreateHistoricalReferralRequest,
  ): Promise<ReferralItem> {
    const response = await apiService.post(this.endpoint, request)

    return response.data
  }

  async confirmClientConsentForReferral(
    referralId: number,
    callbackCode: string,
  ): Promise<ReferralGuestItem> {
    const response = await apiService.patch<ReferralGuestItem>(
      `${this.endpoint}/${referralId}/guest`,
      {
        status:
          '/v1/referral-statuses/' +
          REFERRAL_STATUS_ID.AWAITING_ADVISER_TO_CONTACT_CLIENT,
      },
      { params: { callbackCode } },
    )

    return response.data
  }

  async getReferralsCSV(filters: ReferralCollectionParams) {
    delete filters.page
    delete filters.pageSize
    delete filters.pagination
    const response = await apiService.get(`${this.endpoint}.csv`, {
      params: { ...filters, pagination: false },
      responseType: 'blob',
    })
    return response.data
  }

  async requestReferralCallback(
    referralId: number,
    callbackCode: string,
    request: RequestReferralCallbackRequest,
  ): Promise<ReferralItem> {
    const response = await apiService.patch<ReferralItem>(
      `${this.endpoint}/${referralId}/guest`,
      request,
      { params: { callbackCode } },
    )

    return response.data
  }

  async createReferralEmailPreview(
    request: CreateReferralEmailPreviewRequest,
  ): Promise<ReferralEmailItem> {
    const response = await apiService.post(
      `${this.endpoint}/email-preview`,
      request,
    )

    return response.data
  }

  async provideGdprConsentForReferral(referralId: number) {
    const response = await apiService.patch(`${this.endpoint}/${referralId}`, {
      hasGdprConsent: true,
    })

    return response.data
  }

  async withdrawGdprConsentForReferral(
    referralId: number,
    withdrawConsentCode: string,
  ): Promise<WithdrawGdprConsentForReferralResponse> {
    const response = await apiService.patch(
      `${this.endpoint}/${referralId}/guest/withdraw-gdpr-consent`,
      { hasGdprConsent: false },
      { params: { withdrawGdprConsentCode: withdrawConsentCode } },
    )

    return response.data
  }

  async updateReferralStatus(
    referralId: number,
    newStatusIri: IRI,
  ): Promise<ReferralItem> {
    const response = await apiService.patch<ReferralItem>(
      `${this.endpoint}/${referralId}/status`,
      {
        status: newStatusIri,
      },
    )

    return response.data
  }

  async guestUpdateReferralStatus(
    referralId: number,
    newStatusIri: IRI,
    callbackCode?: string,
    toCompanyUserNylasCalendarEmail?: string,
  ): Promise<ReferralItem> {
    const response = await apiService.patch(
      `${this.endpoint}/${referralId}/guest`,
      {
        status: newStatusIri,
        toCompanyUserNylasCalendarEmail,
      },
      { params: { callbackCode } },
    )

    return response.data
  }

  async updateReferralReason(
    referralId: number,
    reason: string,
  ): Promise<unknown> {
    return this.updateReferral(referralId, { reason })
  }

  async updateReferral(
    referralId: number,
    request: UpdateReferralRequest,
  ): Promise<unknown> {
    const response = await apiService.patch(
      `${this.endpoint}/${referralId}`,
      request,
    )

    return response.data
  }

  async attachCompassReportToReferral(
    referralId: number,
    compassReportIri: IRI,
  ) {
    const response = await apiService.patch(`${this.endpoint}/${referralId}`, {
      compassReport: compassReportIri,
    })

    return response.data
  }

  async unattachCompassReportFromReferral(referralId: number) {
    const response = await apiService.patch<ReferralItem>(
      `${this.endpoint}/${referralId}`,
      { compassReport: null },
    )

    return response.data
  }

  async createNewReferralMetadata(
    request: CreateNewReferralMetadataRequest,
  ): Promise<NewReferralMetadataItem> {
    const response = await apiService.post<NewReferralMetadataItem>(
      `${this.endpoint}/new-referral-metadata`,
      request,
    )

    return response.data
  }

  async checkCanRefer(
    request: CheckCanReferRequest,
  ): Promise<CheckCanReferItem> {
    const response = await apiService.post(
      `${this.endpoint}/check-can-refer`,
      request,
    )

    return response.data
  }

  async updateReferralUser(referralId: number, user: IRI): Promise<unknown> {
    const response = await apiService.patch(
      `${this.endpoint}/${referralId}/user`,
      { user },
    )

    return response.data
  }

  async updateReferralClient(
    referralId: number,
    request: UpdateReferralClientRequest,
  ) {
    const response = await apiService.patch(
      `${this.endpoint}/${referralId}/update-client`,
      request,
    )

    return response.data
  }

  async updateReferralClientEmail(
    referralId: number,
    pausedClientEmails: boolean,
  ) {
    const response = await apiService.patch(`${this.endpoint}/${referralId}`, {
      pausedClientEmails,
    })

    return response.data
  }

  async updateReferralApprovalStatus(
    referralId: number,
    request: UpdateReferralApprovalStatusRequest,
  ) {
    const response = await apiService.patch(
      `${this.endpoint}/${referralId}/update-approval-status`,
      request,
    )

    return response.data
  }

  async updateReferralIntroducedToUsers(
    referralId: number,
    userIds: number[],
  ): Promise<unknown> {
    const response = await apiService.patch(
      `${this.endpoint}/${referralId}/introduced-to-users`,
      { userIds },
    )

    return response.data
  }

  async submitESignatureConsentLetter(params: {
    referralId: number
    callbackCode: string
    request: SubmitESignatureConsentLetterRequest
  }): Promise<SubmitESignatureConsentLetterResponse> {
    const { referralId, request, callbackCode } = params

    const response = await apiService.post(
      `${this.endpoint}/${referralId}/guest/esignature-consent-letter/submit`,
      request,
      { params: { callbackCode } },
    )

    return response.data
  }

  async getReferralIndividuals(
    params: GetReferralIndividualsRequestParams,
  ): Promise<ReferralIndividualCollection> {
    const response = await apiService.get(
      `${this.endpoint}/by-firm-individuals`,
      { params },
    )

    return response.data
  }

  async getReferralFirms(
    params: GetReferralFirmsRequestParams,
  ): Promise<ReferralFirmCollection> {
    const response = await apiService.get(`${this.endpoint}/by-firms`, {
      params,
    })

    return response.data
  }

  async getReferralServiceAreas(
    params?: GetReferralServiceAreaRequestParams,
  ): Promise<ReferralServiceAreaCollection> {
    const response = await apiService.get(`${this.endpoint}/by-service-areas`, {
      params,
    })

    return response.data
  }

  async getReferralCompanyTypes(): Promise<ReferralCompanyTypeCollection> {
    const response = await apiService.get(`${this.endpoint}/by-company-types`)

    return response.data
  }

  async remindReferralApprovalRequests(referralId: number): Promise<unknown> {
    const response = await apiService.post(
      `${this.endpoint}/${referralId}/remind-approval-requests`,
      {},
    )

    return response.data
  }

  async resendReferralConsent(referralId: number): Promise<unknown> {
    const response = await apiService.post(
      `${this.endpoint}/${referralId}/resend-consent`,
      {},
    )

    return response.data
  }

  async resendReferralInvite(referralId: number): Promise<unknown> {
    const response = await apiService.post(
      `${this.endpoint}/${referralId}/resend-invite`,
      {},
    )

    return response.data
  }

  shouldShowCommissionJustification(
    agreementType: CommercialAgreementTypeOption | undefined,
  ): boolean {
    return commercialAgreementService.isFeeSharingAgreement(agreementType)
  }

  async createReferralViaReferralLink(
    request: CreateReferralViaReferralLinkRequest,
  ) {
    const response = await apiService.post(
      `${this.endpoint}/guest/create-via-referral-link`,
      request,
    )

    return response.data
  }

  async getReferralSetupData(): Promise<ReferralSetupDataItem> {
    const response = await apiService.get(`${this.endpoint}/setup-data`)

    return response.data
  }
}

const referralService = new ReferralService()

export default referralService

export interface GetReferralSummaryOptions {
  token?: string
  isInviteLink: boolean
}
