import {
  faCheckCircle,
  faQuestionCircle,
  faTimesCircle,
} from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationTriangle } from '@rq-ratings/pro-light-svg-icons'
import classNames from 'classnames'
import React, { Suspense } from 'react'
import {
  Card,
  Container,
  OverlayTrigger,
  Tab,
  Tabs,
  Tooltip,
} from 'react-bootstrap'
import { Helmet } from 'react-helmet-async'
import { Link, useSearchParams } from 'react-router'
import { useMount } from 'react-use'
import invariant from 'tiny-invariant'

import { useCompanyProfileQuery } from '../../../hooks/useCompanyProfileQuery'
import {
  COMPANY_IDENTIFIER,
  PANEL_AGREEMENT_MESSAGE,
  PANEL_STATUS,
  PENDING_PANEL_ACTION,
} from '../../../lib/constants'
import { ROUTES } from '../../../lib/routes'
import companyProfileService from '../../../lib/services/companyProfileService'
import { ReferralPartnersSearchParams } from '../../../lib/services/routeService'
import SkeletonBox from '../../skeleton/SkeletonBox'
import DevOnly from '../../utils/DevOnly'
import VideoEmbed from '../VideoEmbed'
import CompanyProfileHeader from './components/CompanyProfileHeader/CompanyProfileHeader'
import DevButtons from './components/DevActions'
import { COMPANY_PROFILE_SEARCH_PARAMS, COMPANY_PROFILE_TAB } from './constants'
import useInitialReviewsQuery from './hooks/useInitialReviewsQuery'
import CalendarReferrals from './tabs/CalendarReferrals/CalendarReferrals'
import CommercialAgreements from './tabs/CommercialAgreements/CommercialAgreements'
import Monitoring from './tabs/Monitoring'
import Overview from './tabs/Overview/Overview'
import Reviews from './tabs/Reviews/Reviews'
import ServicesAndExpertise from './tabs/ServicesAndExpertise/ServicesAndExpertise'
import Team from './tabs/Team'
import YourComments from './tabs/YourComments/YourComments'

export type CompanyProfileTab =
  | 'overview'
  | 'servicesAndExpertise'
  | 'reviews'
  | 'team'
  | 'monitoring'
  | 'commercialAgreements'
  | 'yourComments'
  | 'calendarLinks'

export interface CompanyProfileTabInfo {
  title: React.ReactNode | string
  key: CompanyProfileTab
  content: React.ReactNode
}

type Props = CompanyProfileSearchParams

export type CompanyProfileSearchParams = {
  identifier: string
  identifierType: string
  backLabel?: string
  backUrl?: string
  hasJustAddedRelationship?: string
} & ReferralPartnersSearchParams

const CompanyProfile: React.FC<Props> = ({ identifier, identifierType }) => {
  const isFcaFirm = identifierType === COMPANY_IDENTIFIER.frn
  const [searchParams, setSearchParams] = useSearchParams()
  const tabFromSearchParam =
    searchParams.get(COMPANY_PROFILE_SEARCH_PARAMS.tab) || ''

  const companyProfileQuery = useCompanyProfileQuery({
    identifier,
    identifierType,
  })

  const companyProfile = companyProfileQuery.data
  const { company, panel, directory, companiesHouse } = companyProfile

  const initialReviewsQuery = useInitialReviewsQuery(company?.id)
  const totalReviewsCount = initialReviewsQuery?.data?.['hydra:totalItems'] ?? 0

  const shouldShowServicesAndExpertiseTab =
    companyProfile.isRegistered && companyProfile.hasServices

  const isInPanel =
    panel &&
    [PANEL_STATUS.approved, PANEL_STATUS.requested].includes(panel.status)

  function determineInitialTab(): CompanyProfileTab {
    if (companyProfileService.isValidTab(tabFromSearchParam)) {
      return tabFromSearchParam
    }

    if (isFcaFirm) {
      return COMPANY_PROFILE_TAB.overview
    }

    if (shouldShowServicesAndExpertiseTab) {
      return COMPANY_PROFILE_TAB.servicesAndExpertise
    }

    if (isInPanel) {
      return COMPANY_PROFILE_TAB.commercialAgreements
    }

    return COMPANY_PROFILE_TAB.overview
  }

  function buildTabs() {
    invariant(companyProfile, 'Expected companyProfile to be defined')
    const tabs: CompanyProfileTabInfo[] = []

    // Overview
    // ---------
    tabs.push({
      title: 'Overview',
      key: COMPANY_PROFILE_TAB.overview,
      content: <Overview companyProfile={companyProfile} />,
    })

    // Services & Expertise
    // --------------------
    // We only want to show this tab if the company is registered on RQ
    // and has services
    if (companyProfile.isRegistered && companyProfile.hasServices) {
      tabs.push({
        title: 'Services & Expertise',
        key: COMPANY_PROFILE_TAB.servicesAndExpertise,
        content: (
          <ServicesAndExpertise
            companyServices={companyProfile.company?.services ?? []}
            expertiseAreas={companyProfile?.company?.expertiseAreas ?? []}
            fcaServices={companyProfile?.directory?.fca?.services ?? []}
          />
        ),
      })
    }

    // Commercial agreements
    const shouldShowCommercialAgreementsTab =
      company &&
      panel &&
      panel.status !== PANEL_STATUS.rejected &&
      !panel.internalRelationship

    if (shouldShowCommercialAgreementsTab) {
      const pendingActions = panel.pendingActions

      const needsToReview =
        pendingActions.includes(PENDING_PANEL_ACTION.reviewCurrentAgreement) ||
        pendingActions.includes(PENDING_PANEL_ACTION.reviewPendingAgreement)

      const needsToAddCommercialAgreement = pendingActions.includes(
        PENDING_PANEL_ACTION.addCommercialAgreement,
      )

      const needsToChaseCommercialAgreementReview =
        pendingActions.includes(
          PENDING_PANEL_ACTION.chaseCurrentAgreementReview,
        ) ||
        pendingActions.includes(
          PENDING_PANEL_ACTION.chasePendingAgreementReview,
        )

      const renderTooltip = () => {
        if (needsToReview) {
          return (
            <OverlayTrigger
              overlay={
                <Tooltip>{PANEL_AGREEMENT_MESSAGE.reviewAgreement}</Tooltip>
              }
            >
              <FontAwesomeIcon
                icon={faExclamationTriangle}
                className="text-warning"
              />
            </OverlayTrigger>
          )
        }

        if (needsToChaseCommercialAgreementReview) {
          return (
            <OverlayTrigger
              overlay={
                <Tooltip>
                  {PANEL_AGREEMENT_MESSAGE.chaseAgreementReview}
                </Tooltip>
              }
            >
              <FontAwesomeIcon
                icon={faExclamationTriangle}
                className="text-warning"
              />
            </OverlayTrigger>
          )
        }

        if (needsToAddCommercialAgreement) {
          return (
            <OverlayTrigger
              overlay={<Tooltip>No commercial agreements added</Tooltip>}
            >
              <FontAwesomeIcon
                icon={faExclamationTriangle}
                className="text-warning"
              />
            </OverlayTrigger>
          )
        }
      }

      tabs.push({
        title: (
          <div className="d-flex align-items-center gap-2">
            <div>Commercial agreements</div>
            {renderTooltip()}
          </div>
        ),
        key: COMPANY_PROFILE_TAB.commercialAgreements,
        content: (
          <Suspense fallback={<SkeletonBox style={{ height: '52vh' }} />}>
            <CommercialAgreements panelId={panel.id} />
          </Suspense>
        ),
      })
    }

    // Team
    if (companyProfile.fcaIndividuals.length > 0) {
      tabs.push({
        title: 'Team',
        key: COMPANY_PROFILE_TAB.team,
        content: <Team fcaIndividuals={companyProfile.fcaIndividuals} />,
      })
    }

    // Reviews
    if (company && totalReviewsCount > 0) {
      tabs.push({
        title: `Reviews (${totalReviewsCount})`,
        key: COMPANY_PROFILE_TAB.reviews,
        content: <Reviews companyId={company.id} />,
      })
    }

    // Monitoring
    if (directory && directory?.monitoringState !== null) {
      const getMonitoringStyles = () => {
        const hasRedFlag = directory.monitoringRedFlags.length > 0

        if (hasRedFlag) {
          return { icon: faTimesCircle, className: 'text-danger' }
        }

        return directory.monitoringState
          ? { icon: faCheckCircle, className: 'text-success' }
          : { icon: faQuestionCircle, className: 'text-warning' }
      }

      const monitoringStyles = getMonitoringStyles()

      tabs.push({
        title: (
          <div className="d-flex align-items-center">
            <FontAwesomeIcon
              icon={monitoringStyles.icon}
              className={classNames('me-2', monitoringStyles.className)}
            />
            Monitoring
          </div>
        ),
        key: COMPANY_PROFILE_TAB.monitoring,
        content: <Monitoring fca={directory} />,
      })
    }

    // Calendar links
    if (panel?.canCreateReferralLink && company) {
      tabs.push({
        title: (
          <div className="d-flex align-items-center gap-2">
            {company.hasReferralLinkIssue && (
              <OverlayTrigger
                overlay={
                  <Tooltip>
                    Some users of {company.presentationName} have disconnected
                    their calendars so some of your calendar links will not work
                    as expected.
                  </Tooltip>
                }
              >
                <FontAwesomeIcon
                  icon={faExclamationTriangle}
                  className="text-danger"
                />
              </OverlayTrigger>
            )}
            Calendar links
          </div>
        ),
        key: COMPANY_PROFILE_TAB.calendarLinks,
        content: (
          <Suspense fallback={<SkeletonBox style={{ height: '200px' }} />}>
            <CalendarReferrals company={company} />
          </Suspense>
        ),
      })
    }

    // Your Comments
    if (panel && panel.status !== PANEL_STATUS.rejected) {
      tabs.push({
        title: 'Your Comments',
        key: COMPANY_PROFILE_TAB.yourComments,
        content: <YourComments companyProfile={companyProfile} />,
      })
    }

    return tabs
  }

  function renderTabs() {
    return (
      <div className="tab tab--no-box-shadow tab--bordered-nav bg-white">
        <Tabs
          mountOnEnter
          activeKey={tabFromSearchParam}
          onSelect={(tab) => {
            if (tab) {
              handleTabChange(tab)
            }
          }}
        >
          {tabs.map((tab) => (
            <Tab title={tab.title} key={tab.key} eventKey={tab.key}>
              {tab.content}
            </Tab>
          ))}
        </Tabs>
      </div>
    )
  }

  function renderNoDataAvailableMessage() {
    return (
      <Card>
        <Card.Body>
          <p>There isn't any data available for this company.</p>

          <Link to={ROUTES.inviteToRq} className="btn btn-primary">
            Invite them to join you on RQ
          </Link>
        </Card.Body>
      </Card>
    )
  }

  const tabs = buildTabs()

  useMount(() => {
    const tab = determineInitialTab()
    searchParams.set(COMPANY_PROFILE_SEARCH_PARAMS.tab, tab)
    setSearchParams(searchParams, { replace: true })
  })

  function handleTabChange(tab: string) {
    searchParams.set(COMPANY_PROFILE_SEARCH_PARAMS.tab, tab)
    setSearchParams(searchParams)
  }

  const companyName =
    directory?.name ?? company?.name ?? companiesHouse?.name ?? 'Unknown'

  return (
    <>
      <Helmet title={companyName} />

      <Container fluid className="p-0">
        {company?.videoUrl && (
          <VideoEmbed
            className="mb-3"
            videoUrl={company.videoUrl}
            height={360}
          />
        )}

        <div className="mb-3">
          <CompanyProfileHeader companyProfile={companyProfile} />
        </div>

        {tabs.length > 0 ? renderTabs() : renderNoDataAvailableMessage()}
      </Container>

      <DevOnly>
        <DevButtons />
      </DevOnly>
    </>
  )
}

export const CompanyProfileSkeleton: React.FC = () => (
  <>
    <div className="px-3 d-flex flex-column gap-2">
      <SkeletonBox style={{ height: '200px' }} />

      <div className="d-flex flex-column gap-2">
        <SkeletonBox style={{ height: '50px' }} />

        <div className="d-flex flex-column flex-md-row gap-3">
          <div style={{ flex: 8 }}>
            <SkeletonBox style={{ height: '800px' }} />
          </div>

          <div style={{ flex: 4 }}>
            <SkeletonBox style={{ height: '800px' }} />
          </div>
        </div>
      </div>
    </div>
  </>
)

export default CompanyProfile
