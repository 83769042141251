import React from 'react'

import { EMAIL_ADDRESSES } from '../../lib/constants'
import { buildUrl } from '../../lib/helpers/helperFunctions'

interface Props {
  subject?: string
}

const RqSupportMailLink: React.FC<Props> = ({ subject }) => {
  const params: Record<string, string> = {}

  if (subject) {
    params.subject = subject
  }

  const url = buildUrl(`mailto:${EMAIL_ADDRESSES.rqSupport}`, params)

  return <a href={url}>{EMAIL_ADDRESSES.rqSupport}</a>
}

export default RqSupportMailLink
