import { sum } from 'lodash'

export const REFERRAL_LINKS_TABLE = {
  columnWidths: {
    introduceTo: 280,
    activity: 320,
    actions: 160,
  },

  get tableWidth() {
    return (
      sum(Object.values(this.columnWidths)) + 2 // (2px is for the borders)
    )
  },

  searchParams: {
    page: 'page',
  },

  pageSize: 5,
}
