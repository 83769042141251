import omit from 'lodash/omit'
import queryString from 'query-string'
import { matchPath } from 'react-router'

import { COMPASS_STEPS } from '../../components/flows/CompassFlow/utils/constants'
import { CompassStep } from '../../components/flows/CompassFlow/utils/types'
import { CompassSnapshotModalSearchParams } from '../../components/modals/SnapshotModal/constants'
import { CompanyTypeCode, ObjectMap } from '../../types/misc'
import { RoutePath, ROUTES } from '../routes'

export const RELATIONSHIPS_URL_PARAMS = {
  hasSentFirstInvite: 'hasSentFirstInvite',
}

export interface InviteToRqParams {
  companyTypeCode: CompanyTypeCode
}

export function buildInviteToRqUrl(companyTypeCode: CompanyTypeCode) {
  const params: InviteToRqParams = { companyTypeCode }
  return `${ROUTES.inviteToRq}?${queryString.stringify(params)}`
}

/**
 * Use the matchesPath helper instead to match against path patterns instead.
 *
 * @deprecated
 */
export function isRoute(route: string) {
  return window.location.pathname === route
}

interface CompanyCompassInvitePageParams {
  companyReferralCode: string
}

export const COMPANY_COMPASS_INVITE_PAGE_PARAMS: Record<
  keyof CompanyCompassInvitePageParams,
  keyof CompanyCompassInvitePageParams
> = {
  companyReferralCode: 'companyReferralCode',
}

interface CompanyCompassInvitePageSearchParams {
  shouldSkipPreviewMode?: boolean
  hasConfirmedSharing?: boolean
}

export const COMPANY_COMPASS_INVITE_PAGE_SEARCH_PARAMS: Record<
  keyof CompanyCompassInvitePageSearchParams,
  keyof CompanyCompassInvitePageSearchParams
> = {
  shouldSkipPreviewMode: 'shouldSkipPreviewMode',
  hasConfirmedSharing: 'hasConfirmedSharing',
}

export function buildCompanyCompassInviteUrl(
  companyReferralCode: string,
  params?: CompanyCompassInvitePageSearchParams,
) {
  const path = ROUTES.companyCompassInvite.replace(
    ':companyReferralCode',
    companyReferralCode,
  )

  return params ? `${path}?${queryString.stringify(params)}` : path
}

export function buildCompanyCompassInvitePreviewUrl(
  companyReferralCode: string,
) {
  return ROUTES.companyCompassInvitePreview.replace(
    `:${COMPANY_COMPASS_INVITE_PAGE_PARAMS.companyReferralCode}`,
    companyReferralCode,
  )
}

export interface CompanyCompassInviteConfirmSharingPageParams {
  companyReferralCode: string
}

export const COMPASS_INVITE_CONFIRM_SHARING_VIA_GLOBAL_LINK_PAGE_PARAMS: ObjectMap<CompanyCompassInviteConfirmSharingPageParams> =
  {
    companyReferralCode: 'companyReferralCode',
  }

export function buildCompassConfirmSharingViaGlobalLinkUrl(
  companyReferralCode: string,
) {
  return ROUTES.compassConfirmSharingViaGlobalLink.replace(
    `:${COMPASS_INVITE_CONFIRM_SHARING_VIA_GLOBAL_LINK_PAGE_PARAMS.companyReferralCode}`,
    companyReferralCode,
  )
}

export function buildCompassActionsUrl(token: string) {
  return ROUTES.compassActions.replace(':token', token)
}

export interface CompassSnapshotPageParams {
  token: string
  step?: string
}

export function buildCompassSnapshotUrl(params: CompassSnapshotPageParams) {
  const path = ROUTES.compassSnapshot.replace(':token', params.token)
  const step = params.step ?? COMPASS_STEPS.clientDetails

  const paramsWithoutToken = {
    ...omit(params, 'token'),
    step: step,
  }

  return `${path}?${queryString.stringify(paramsWithoutToken)}`
}

export function buildCompanyCompassViewReportUrl(
  params: CompassSnapshotPageParams,
) {
  return `${ROUTES.companyCompassView}?${queryString.stringify(params)}`
}

interface CompanyCompassUrlParams {
  step: CompassStep
  clientId?: number
  referralId?: number
  token?: string
}

export const COMPANY_COMPASS_URL_PARAMS: Record<
  keyof CompanyCompassUrlParams,
  keyof CompanyCompassUrlParams
> = {
  step: 'step',
  clientId: 'clientId',
  referralId: 'referralId',
  token: 'token',
}

export function buildCompanyCompassUrl(params: CompanyCompassUrlParams) {
  return `${ROUTES.companyCompass}?${queryString.stringify(params)}`
}

export interface SignInPageParams {
  token?: string
  redirectUri?: string
}

export function buildSignInPageUrl(params: SignInPageParams) {
  return `${ROUTES.signIn}?${queryString.stringify(params)}`
}

export interface CompassDetailsAlreadyOnFilePageParams {
  companyReferralCode: string
}

export const COMPASS_DETAILS_ALREADY_ON_FILE_PAGE_PARAMS: ObjectMap<CompassDetailsAlreadyOnFilePageParams> =
  {
    companyReferralCode: 'companyReferralCode',
  }

export function buildCompassDetailsAlreadyOnFileUrl(
  companyReferralCode: string,
) {
  return ROUTES.compassDetailsAlreadyOnFile.replace(
    `:${COMPASS_DETAILS_ALREADY_ON_FILE_PAGE_PARAMS.companyReferralCode}`,
    companyReferralCode,
  )
}

export interface CompassRedirectPageParams {
  returnUrl: string // The absolute URL to redirect to after the user logs in or registers.
  reportToPrefetch?: string

  // If the user verified their date of birth, we'll reuse their input so that
  // they don't have to re-verify after logging in or registering.
  accessToken?: string
}

export const COMPASS_REDIRECT_PAGE_PARAMS: Record<
  keyof CompassRedirectPageParams,
  keyof CompassRedirectPageParams
> = {
  returnUrl: 'returnUrl',
  reportToPrefetch: 'reportToPrefetch',
  accessToken: 'accessToken',
}

export function buildCompassRedirectPageUrl(params: CompassRedirectPageParams) {
  return (
    window.location.origin +
    `${ROUTES.compassRedirect}?${queryString.stringify(params)}`
  )
}

// Wrapper around react-router's matchPath function (https://reactrouter.com/en/main/utils/match-path)
// but which allows matching against multiple path patterns.
export function matchesPaths(path: string, pathPatterns: RoutePath[]) {
  return pathPatterns.some((pattern) => matchPath(pattern, path))
}

export function matchesPath(path: string, pathPattern: RoutePath) {
  return !!matchPath(pathPattern, path)
}

interface GetQueryParamOptions<T> {
  name: string
  transform?: (value: unknown) => T
  defaultValue?: T | null
}

export function getQueryParam<T>(options: GetQueryParamOptions<T>) {
  const { name, transform, defaultValue } = options
  const value = queryString.parse(window.location.search)[name]

  if (typeof transform === 'function') {
    return transform(value) as T
  }

  return (value || defaultValue) as T
}

export function currentPath() {
  return window.location.pathname + window.location.search
}

export function buildCompanyCompassSnapshotsPageUrl(
  params: CompassSnapshotModalSearchParams,
) {
  return `${ROUTES.companyCompassSnapshots}?${queryString.stringify(params)}`
}
