import useAppDispatch from '../../../hooks/useAppDispatch'
import panelService from '../../../lib/services/panelService'
import {
  REFER_CLIENT_STEP,
  REFERRAL_METHOD,
  setAdviserEmail,
  setCompanyTypeCode,
  setNewCompanyApprovals,
  setReferralFirm,
  setReferralMethod,
  setServiceAreaIds,
} from '../../../redux/slices/referClient'
import { useReferClientSteps } from './useReferClientSteps'

export function useHandleReferToAdviserEmail() {
  const { goToStep } = useReferClientSteps()
  const dispatch = useAppDispatch()

  return async function (adviserEmail?: string) {
    const data = await panelService.getPanelReferralApprovals({})
    if (data) {
      const newCompanySetup = data['hydra:member'].find(
        (approval) => approval.toCompany == null,
      )
      if (newCompanySetup && newCompanySetup.requiresColleagueApproval) {
        dispatch(
          setNewCompanyApprovals(
            newCompanySetup.referralApprovalUsers.map(
              (approval) => approval.user.fullName,
            ),
          ),
        )
      } else {
        dispatch(setNewCompanyApprovals([]))
      }
    }

    dispatch(setReferralMethod(REFERRAL_METHOD.adviserEmail))
    // Clear existing firm and company type if the user navigates back to this step with already set data
    dispatch(setReferralFirm(undefined))
    dispatch(setCompanyTypeCode(undefined))
    dispatch(setServiceAreaIds([]))

    if (adviserEmail) {
      dispatch(setAdviserEmail(adviserEmail))
    }

    goToStep(REFER_CLIENT_STEP.selectServiceOrAdviser)
  }
}
