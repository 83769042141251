import {
  faCheck,
  faExclamationTriangle,
  faSpinner,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'

import { MutationIconProps, MutationIconState } from '../../types/misc'

interface Props {
  state: MutationIconProps
}

const MutationIcon: React.FC<Props> = ({ state }) => {
  if (state.state === MutationIconState.mutating) {
    return (
      <>
        <FontAwesomeIcon icon={faSpinner} className="fa-spin" />
      </>
    )
  } else if (state.state === MutationIconState.success) {
    return (
      <>
        <FontAwesomeIcon
          icon={faCheck}
          title={state.message}
          className="text-success"
        />
      </>
    )
  } else if (state.state === MutationIconState.error) {
    return (
      <>
        <FontAwesomeIcon
          icon={faExclamationTriangle}
          title={state.message}
          className="text-danger"
        />
      </>
    )
  } else {
    return <></>
  }
}

export default MutationIcon
