import { IconProp } from '@fortawesome/fontawesome-svg-core'
import {
  faCheckCircle,
  faQuestionCircle,
} from '@fortawesome/free-regular-svg-icons'
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import React from 'react'
import { Accordion } from 'react-bootstrap'

import companiesHouseLogo from '../../../../assets/img/monitoring/companies-house.png'
import fcaLogo from '../../../../assets/img/monitoring/fca.png'
import fosLogo from '../../../../assets/img/monitoring/fos.png'
import fscsLogo from '../../../../assets/img/monitoring/fscs.png'
import { DATE_FORMATS, FCA_MONITORING_CODE } from '../../../../lib/constants'
import { formatDate } from '../../../../lib/helpers/helperFunctions'
import { FcaMonitoring } from '../../../../types/misc'
import { DirectoryFields } from '../../../../types/responses/directory'
import CollapsibleContent from '../../CollapsibleContent'

interface Props {
  fca: DirectoryFields
}

interface AccordionItem {
  headerText: string
  logo: string
  monitoring: FcaMonitoring | undefined
  additionalText?: string | null
}

const Monitoring: React.FC<Props> = ({ fca }) => {
  const fscs1 = findMonitoringByCode(FCA_MONITORING_CODE.FSCS1)
  const fscs2 = findMonitoringByCode(FCA_MONITORING_CODE.FSCS2)

  function findMonitoringByCode(code: string): FcaMonitoring | undefined {
    return fca.monitoring.find((item) => item.code === code)
  }

  const COMBINED_FSCS_CODE = 'FSCS'

  // We need to combine the two FSCS items into one
  const fscsItem = (): FcaMonitoring | undefined => {
    if (!fscs1 && !fscs2) {
      return undefined
    }

    return {
      flag: fscs1?.flag !== false && fscs2?.flag !== false,
      message: (fscs1 ? fscs1.message + '<br /><br />' : '') + fscs2?.message,
      lastUpdated: fscs1 ? fscs1.lastUpdated : null,
      code: COMBINED_FSCS_CODE,
    }
  }

  const accordionItems: AccordionItem[] = [
    {
      headerText: 'Financial Conduct Authority',
      logo: fcaLogo,
      monitoring: findMonitoringByCode(FCA_MONITORING_CODE.FCA),
      additionalText: fca.fca?.frnTradingNameId
        ? `Note: This data relates to ${fca.fca?.name} more generally and not ${fca.name} specifically`
        : null,
    },
    {
      headerText: 'Financial Ombudsman Service',
      logo: fosLogo,
      monitoring: findMonitoringByCode(FCA_MONITORING_CODE.FOS),
    },
    {
      headerText: 'Financial Services Compensation Scheme',
      logo: fscsLogo,
      monitoring: fscsItem(),
      additionalText: fca.fca?.frnTradingNameId
        ? `Note: This data relates to ${fca.fca?.name} more generally and not ${fca.name} specifically`
        : null,
    },
    {
      headerText: 'Companies House',
      logo: companiesHouseLogo,
      monitoring: findMonitoringByCode(FCA_MONITORING_CODE.CompaniesHouse),
      additionalText: fca.fca?.frnTradingNameId
        ? `Note: This data relates to ${fca.fca?.name} more generally and not ${fca.name} specifically`
        : null,
    },
  ]

  function renderAccordionButton(monitoring: FcaMonitoring) {
    const { icon, className } = getAccordionButtonStyles(monitoring)

    return (
      <>
        <div className="d-inline-block">
          <FontAwesomeIcon
            icon={icon}
            className={classNames('me-3', className)}
            size="2x"
          />
        </div>

        <div
          className={classNames({
            'd-inline-block': true,
            'd-none': !monitoring.message,
          })}
        >
          <Accordion.Button />
        </div>
      </>
    )
  }

  function getAccordionButtonStyles(monitoring: FcaMonitoring): {
    icon: IconProp
    className: string
  } {
    const hasRedFlag = fca.monitoringRedFlags.includes(monitoring.code)

    if (hasRedFlag) {
      return { icon: faTimesCircle, className: 'text-danger' }
    }

    return monitoring.flag
      ? { icon: faCheckCircle, className: 'text-success' }
      : { icon: faQuestionCircle, className: 'text-warning' }
  }

  return (
    <Accordion className="no-box-shadow">
      {accordionItems.map((accordionItem) => {
        const {
          headerText,
          logo,
          monitoring,
          additionalText = null,
        } = accordionItem

        const isDataUnavailable = monitoring && monitoring.flag === null

        const backgroundColour =
          monitoring && monitoring.flag === false
            ? 'accordion-header--warning-light'
            : 'bg-white'

        return (
          <Accordion.Item eventKey={headerText} key={headerText}>
            <div
              className={classNames(
                'd-flex align-items-center p-3 ' + backgroundColour,
                { 'opacity-50': isDataUnavailable },
              )}
            >
              <div className="float-start flex-fill">
                <img
                  src={logo}
                  className="me-5"
                  alt="FCA"
                  style={{ width: '80px' }}
                />
                {headerText}
              </div>

              {monitoring && (
                <div className="float-end">
                  {isDataUnavailable ? (
                    <div className="p-3">No data available</div>
                  ) : (
                    <>{renderAccordionButton(monitoring)}</>
                  )}
                </div>
              )}
            </div>

            {monitoring && monitoring.message && monitoring.message.length && (
              <Accordion.Body className={backgroundColour}>
                <CollapsibleContent
                  content={
                    (additionalText
                      ? '<b>' + additionalText + '</b><br/><br/>'
                      : '') +
                    (monitoring.lastUpdated
                      ? 'Last updated: ' +
                        formatDate(
                          monitoring.lastUpdated,
                          DATE_FORMATS.MONTH_DAY_YEAR,
                        ) +
                        '<br/><br/>'
                      : '') +
                    monitoring.message
                  }
                />
              </Accordion.Body>
            )}
          </Accordion.Item>
        )
      })}
    </Accordion>
  )
}

export default Monitoring
