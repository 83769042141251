import React from 'react'
import Skeleton from 'react-loading-skeleton'

import PageContainer from '../../components/misc/PageContainer'
import PageTitle from '../../components/typography/PageTitle'
import SkeletonBox from './SkeletonBox'

const SkeletonPage: React.FC = () => {
  return (
    <PageContainer>
      <PageTitle style={{ maxWidth: '400px' }}>
        <Skeleton />
      </PageTitle>

      <SkeletonBox style={{ height: '50vh' }} />
    </PageContainer>
  )
}

export default SkeletonPage
