import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@rq-ratings/pro-regular-svg-icons'
import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'

import useCurrentCompanyOrFail from '../../../../hooks/useCurrentCompanyOrFail'
import { useUserCompanies } from '../../../../hooks/useUserCompanies'
import { ROUTES } from '../../../../lib/routes'
import { SessionMetadataCompany } from '../../../../types/responses/session-metadata'
import HRMessage from '../../../misc/HRMessage'
import { useClaimReferralModal } from '../../../misc/ReferralDetails/hooks/useClaimReferralModal'
import SelectUserCompany, {
  SelectUserCompanyOption,
} from '../../../select/SelectUserCompany'
import { useHasMultipleReferralInvites } from '../hooks/useHasMultipleReferralInvites'
import { useReferralsTerm } from '../hooks/useReferralsTerm'
import ClaimReferralButton from './ClaimReferralButton'

interface ClaimOption {
  heading: string
  content: React.ReactNode
}

const AlreadyLoggedInAsCompanyUserModal: React.FC = () => {
  const claimReferralModal = useClaimReferralModal()
  const referralsTerm = useReferralsTerm()
  const currentCompany = useCurrentCompanyOrFail()
  const companies = useUserCompanies()
  const currentCompanyName = currentCompany.presentationName
  const hasMultipleInvites = useHasMultipleReferralInvites()
  const [selectedCompany, setSelectedCompany] =
    useState<SessionMetadataCompany | null>(null)

  const defaultValue: SelectUserCompanyOption | null = selectedCompany
    ? {
        label: selectedCompany.presentationName,
        value: selectedCompany.id,
        isDisabled: false,
      }
    : null

  function buildOptions(): ClaimOption[] {
    const options: ClaimOption[] = [
      {
        heading: `Claim ${referralsTerm} for ${currentCompanyName}`,
        content: (
          <>
            <p className="mb-2">
              Click the button below to claim {referralsTerm} for{' '}
              {currentCompanyName}.
            </p>

            <ClaimReferralButton
              companyIri={currentCompany['@id']}
              variant={selectedCompany ? 'primary' : 'success'}
            >
              Claim {referralsTerm} for {currentCompanyName}
            </ClaimReferralButton>
          </>
        ),
      },
    ]

    if (companies.length > 1) {
      options.push({
        heading: `Claim ${referralsTerm} for another company`,
        content: (
          <>
            <div className="mb-2">
              <p className="mb-2">
                If you want to claim {referralsTerm} for another of your
                companies, please select them below:
              </p>

              <SelectUserCompany
                placeholder="Select company"
                isClearable
                defaultValue={defaultValue}
                onChange={(company) => {
                  setSelectedCompany(company)
                }}
              />

              {selectedCompany && (
                <ClaimReferralButton
                  companyIri={selectedCompany['@id']}
                  className="mt-2"
                  variant="success"
                >
                  Claim {referralsTerm} for {selectedCompany.presentationName}
                </ClaimReferralButton>
              )}
            </div>
          </>
        ),
      })
    }

    options.push({
      heading: `Claim ${referralsTerm} for a new company`,
      content: (
        <>
          <p className="mb-2">
            If you want claim for a company not listed above, please add that
            company first and then revisit this page.
          </p>

          <a
            href={ROUTES.addCompany}
            className="btn btn-light btn-lg btn-fluid d-inline-flex justify-content-center align-items-center gap-2"
            target="_blank"
            rel="noreferrer"
          >
            <FontAwesomeIcon icon={faPlus} />
            Add new company
          </a>
        </>
      ),
    })

    return options
  }

  const options = buildOptions()

  return (
    <Modal onHide={claimReferralModal.hide} show={claimReferralModal.isShowing}>
      <Modal.Header closeButton>
        <Modal.Title className="text-xxl">
          Claim {hasMultipleInvites ? 'referrals' : 'referral'}
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <p className="text-lg mb-4">
          You're currently logged in under the company{' '}
          <strong>{currentCompanyName}</strong>. Please confirm how you want to
          proceed.
        </p>

        <div
          className="d-flex flex-column gap-3"
          css={{ h4: { color: 'black' } }}
        >
          {options.map((option, index) => (
            <>
              <section key={index}>
                <h4>
                  {index + 1}. {option.heading}
                </h4>
                {option.content}
              </section>
              {index !== options.length - 1 && <HRMessage message="OR" />}
            </>
          ))}
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default AlreadyLoggedInAsCompanyUserModal
