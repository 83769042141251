import React from 'react'
import { Form } from 'react-bootstrap'

interface Props {
  multiSelect: boolean
  setMultiSelect: (state: boolean) => void
}

const MultiSelectSwitch: React.FC<Props> = ({
  multiSelect,
  setMultiSelect,
}) => {
  return (
    <div>
      <Form.Label className="cursor-pointer">
        <Form.Switch
          checked={multiSelect}
          onChange={() => setMultiSelect(!multiSelect)}
          className="d-inline-block"
        />
        <span className="text-muted text-sm">Select multiple rows</span>
      </Form.Label>
    </div>
  )
}

export default MultiSelectSwitch
