import React from 'react'
import { Dropdown, DropdownButton, Form } from 'react-bootstrap'

import { BulkUpdatePayload } from '../../../redux/slices/referralsImportBase'

interface Props {
  hasUpdatableClients: boolean
  canPauseClientEmails: boolean
  bulkUpdateItems: (payload: BulkUpdatePayload) => void
  bulkUpdateSelectedOnly: boolean
}

const ClientHeaderDropdown: React.FC<Props> = ({
  hasUpdatableClients,
  canPauseClientEmails,
  bulkUpdateItems,
  bulkUpdateSelectedOnly,
}) => {
  if (!hasUpdatableClients && !canPauseClientEmails) {
    return null
  }
  const scope = bulkUpdateSelectedOnly ? 'selected' : 'all'
  return (
    <DropdownButton
      variant="outline-primary"
      title=""
      align="end"
      size="sm"
      style={{ position: 'absolute', right: '10px', top: '16px' }}
    >
      {hasUpdatableClients && (
        <>
          <Dropdown.Header>Existing client's data</Dropdown.Header>
          <Dropdown.Item
            onClick={() =>
              bulkUpdateItems({
                filter: { selected: bulkUpdateSelectedOnly },
                data: { onClientDuplicate: 'update' },
              })
            }
          >
            <Form.Switch checked readOnly className="d-inline-block" /> Update
            data for {scope} clients
          </Dropdown.Item>
          <Dropdown.Item
            onClick={() =>
              bulkUpdateItems({
                filter: { selected: bulkUpdateSelectedOnly },
                data: { onClientDuplicate: 'keep-original' },
              })
            }
          >
            <Form.Switch readOnly className="d-inline-block" /> Keep existing
            data for {scope} clients
          </Dropdown.Item>
        </>
      )}
      {canPauseClientEmails && hasUpdatableClients && <Dropdown.Divider />}
      {canPauseClientEmails && (
        <>
          <Dropdown.Header>Notifications</Dropdown.Header>
          <Dropdown.Item
            onClick={() =>
              bulkUpdateItems({
                filter: { selected: bulkUpdateSelectedOnly },
                data: { pausedClientEmails: false },
              })
            }
          >
            <Form.Switch checked readOnly className="d-inline-block" /> Enable
            notifications for {scope} clients
          </Dropdown.Item>
          <Dropdown.Item
            onClick={() =>
              bulkUpdateItems({
                filter: { selected: bulkUpdateSelectedOnly },
                data: { pausedClientEmails: true },
              })
            }
          >
            <Form.Switch readOnly className="d-inline-block" /> Pause
            notifications for
            {scope} clients
          </Dropdown.Item>
        </>
      )}
    </DropdownButton>
  )
}

export default ClientHeaderDropdown
