import { CompanyTypeCode } from '../../types/misc'
import { RegulatorItem } from '../../types/responses/common-data'
import { COMPANY_TYPE_CODE } from '../constants'

class RegulatorService {
  getRegulatorsByCompanyType(
    regulators: RegulatorItem[],
    companyTypeCode: CompanyTypeCode,
  ): RegulatorItem[] {
    if (companyTypeCode === COMPANY_TYPE_CODE.accountant) {
      return regulators.filter((r) => r.isAccountant)
    }

    if (companyTypeCode === COMPANY_TYPE_CODE.solicitor) {
      return regulators.filter((r) => r.isSolicitor)
    }

    if (companyTypeCode === COMPANY_TYPE_CODE.conveyancing) {
      return regulators.filter((r) => r.isConveyancer)
    }

    return []
  }
}

const regulatorService = new RegulatorService()

export default regulatorService
