import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@rq-ratings/pro-light-svg-icons'
import React, { useMemo, useRef, useState } from 'react'
import { Button, Form } from 'react-bootstrap'

import useAppSelector from '../../../hooks/useAppSelector'
import { selectReferralStatuses } from '../../../redux/slices/commonData'
import {
  BulkUpdatePayload,
  ReferralsImportItem,
  ReferralsImportItemPartial,
} from '../../../redux/slices/referralsImportBase'

interface Props {
  importItem: ReferralsImportItem
  updateImportItem: (item: ReferralsImportItemPartial) => void
  bulkUpdateItems: (payload: BulkUpdatePayload) => void
  bulkUpdateSelectedOnly: boolean
}

const StatusCell: React.FC<Props> = ({
  importItem,
  updateImportItem,
  bulkUpdateItems,
  bulkUpdateSelectedOnly,
}) => {
  const statuses = useAppSelector(selectReferralStatuses)
  const [isEdit, setEdit] = useState(false)
  const selectRef = useRef<HTMLSelectElement | null>(null)
  const isSet = !!importItem.status

  const statusOptions: SelectableOption[] = useMemo((): SelectableOption[] => {
    return statuses.map((item) => {
      return {
        label: item.name,
        value: item.code,
      }
    })
  }, [statuses])
  const handleChange = (value: string) => {
    updateImportItem({
      refUUID: importItem.refUUID,
      status: value,
    })
    setEdit(false)
  }
  const handleBulkApply = () => {
    bulkUpdateItems({
      filter: {
        selected: bulkUpdateSelectedOnly,
      },
      data: {
        status: importItem.status,
      },
    })
  }
  const bulkUpdateScope = !bulkUpdateSelectedOnly
    ? 'all'
    : importItem.selected
      ? 'selected'
      : 'none'

  return (
    <>
      {!isEdit && isSet ? (
        <>
          <span className="cursor-pointer" onClick={() => setEdit(true)}>
            {
              statusOptions.find((item) => item.value === importItem.status)
                ?.label
            }
          </span>
          <span className="show-on-row-hover text-xs">
            {', '}
            <Button
              variant="link"
              size="sm"
              className="text-xs p-0 m-0"
              onClick={() => setEdit(true)}
            >
              change
            </Button>
            {bulkUpdateScope == 'all' && (
              <>
                {' or '}
                <Button
                  variant="link"
                  size="sm"
                  className="text-xs p-0 m-0"
                  onClick={handleBulkApply}
                >
                  apply&nbsp;to&nbsp;all
                </Button>
              </>
            )}
            {bulkUpdateScope == 'selected' && (
              <>
                {' or '}
                <Button
                  variant="link"
                  size="sm"
                  className="text-xs p-0 m-0"
                  onClick={handleBulkApply}
                >
                  apply&nbsp;to&nbsp;selected
                </Button>
              </>
            )}
          </span>
        </>
      ) : (
        <>
          <Form.Select
            ref={selectRef}
            value={importItem.status ?? ''}
            onChange={(event) => handleChange(event.target.value)}
            onBlur={() => setEdit(false)}
          >
            {importItem.status ?? <option>Select status...</option>}
            {statusOptions.map((option) => {
              return (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              )
            })}
          </Form.Select>
          <span className="d-block text-end mt-1">
            <Button
              size="sm"
              variant="outline-primary"
              onClick={() => {
                if (selectRef.current) {
                  handleChange(selectRef.current.value)
                }
              }}
            >
              <FontAwesomeIcon icon={faCheck} />
            </Button>
          </span>
        </>
      )}
    </>
  )
}

interface SelectableOption {
  label: string
  value: string
}
export default StatusCell

//
