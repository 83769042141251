import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSquare } from '@rq-ratings/pro-regular-svg-icons'
import { faSquareCheck } from '@rq-ratings/pro-solid-svg-icons'
import React from 'react'

import { ReferralsImportItem } from '../../../redux/slices/referralsImportBase'
import { ModifierKey } from '../../../types/misc'

interface Props {
  importItem: ReferralsImportItem
  handleSelect: (refUUID: string, modifier: null | ModifierKey) => void
}

const SelectCell: React.FC<Props> = ({ importItem, handleSelect }) => {
  return (
    <button
      className="flex items-center justify-center border-0 text-base"
      style={{
        width: '40px',
        height: '45px',
        background: 'none',
        outline: 'none',
      }}
      onMouseDown={(event) => {
        if (event.shiftKey) {
          event.preventDefault()
        }
      }}
      onClick={(event) => {
        event.preventDefault()
        handleSelect(
          importItem.refUUID,
          event.altKey
            ? 'altKey'
            : event.ctrlKey
              ? 'ctrlKey'
              : event.metaKey
                ? 'metaKey'
                : event.shiftKey
                  ? 'shiftKey'
                  : null,
        )
        return false
      }}
    >
      <FontAwesomeIcon icon={importItem.selected ? faSquareCheck : faSquare} />
    </button>
  )
}

export default SelectCell
