import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser } from '@rq-ratings/pro-solid-svg-icons'
import React from 'react'

interface Props {
  name: string
  email: string
}

const Guest: React.FC<Props> = ({ name, email }) => {
  return (
    <li className="d-flex align-items-start gap-3 flex-row m-0 flex-1 pop-in">
      <FontAwesomeIcon icon={faUser} className="fs-2 mt-1" />

      <div className="d-flex flex-column gap-1">
        <strong style={{ lineHeight: 1 }}>{name}</strong>
        <span style={{ lineHeight: 1 }} className="fs-5 text-body-secondary">
          {email}
        </span>
      </div>
    </li>
  )
}

export default Guest
