import { CompanyTypeCode } from '../../types/misc'
import {
  CompanyTypeItem,
  ServiceAreaItem,
} from '../../types/responses/common-data'
import companyTypeService from '../services/companyTypeService'

export function getCompanyTypeIriByCode(
  companyTypes: CompanyTypeItem[],
  companyTypeCode: CompanyTypeCode,
): IRI {
  return companyTypeService.getCompanyTypeByCodeOrFail(
    companyTypes,
    companyTypeCode,
  )['@id']
}

export function companyTypeHasServices(
  companyType: CompanyTypeItem,
  serviceAreas: ServiceAreaItem[],
): boolean {
  return serviceAreas.some((serviceArea) =>
    serviceArea.companyTypes.includes(companyType['@id']),
  )
}
