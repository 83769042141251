import { useMutation } from '@tanstack/react-query'
import React from 'react'

import { useCreateStateMutation } from '../../hooks/useCreateStateMutation'
import useNotyf from '../../hooks/useNotyf'
import {
  EXTERNAL_LINKS,
  LONG_MESSAGE_DURATION,
  STATE_KEY,
  STATE_TYPE,
} from '../../lib/constants'
import Banner from './Banner'

const RqRoadmapBanner: React.FC = () => {
  const createStateMutation = useCreateStateMutation()
  const notyf = useNotyf()

  const hasInteractedWithBannerMutation = useMutation({
    mutationFn: () =>
      createStateMutation.mutateAsync({
        stateKey: STATE_KEY.hasInteractedWithRqRoadmapBanner,
        stateValue: true,
        stateType: STATE_TYPE.user,
      }),
    onSuccess: () => {
      notyf.success({
        message:
          'You can access the roadmap at any time from your account dropdown',
        duration: LONG_MESSAGE_DURATION,
      })
    },
    onError: () => {
      notyf.error('There was a problem dismissing the banner')
    },
  })

  const isHiding = hasInteractedWithBannerMutation.isPending

  return (
    <Banner
      isHiding={isHiding}
      onHide={() => hasInteractedWithBannerMutation.mutate()}
    >
      <>
        View our{' '}
        <a
          className="text-reset text-decoration-underline"
          href={EXTERNAL_LINKS.rqRoadmap}
          target="_blank"
          rel="noreferrer"
        >
          roadmap
        </a>{' '}
        and have your say in what features we build next
      </>
    </Banner>
  )
}

export default RqRoadmapBanner
