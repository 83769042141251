import React from 'react'

import { useAppState } from '../../hooks/useAppState'
import RqRoadmapBanner from './RqRoadmapBanner'

const Banner: React.FC = () => {
  const { hasInteractedWithRqRoadmapBanner } = useAppState()

  function getBanners() {
    const banners: React.ReactNode[] = []

    if (!hasInteractedWithRqRoadmapBanner) {
      banners.push(<RqRoadmapBanner />)
    }

    return banners
  }

  const banners = getBanners()

  const banner = banners[banners.length - 1]

  return <>{banner}</>
}

export default Banner
