import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationTriangle } from '@rq-ratings/pro-regular-svg-icons'
import classNames from 'classnames'
import React from 'react'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import { useSearchParams } from 'react-router'

import useAppDispatch from '../../../../hooks/useAppDispatch'
import useCurrentCompanyOrFail from '../../../../hooks/useCurrentCompanyOrFail'
import {
  PANEL_AGREEMENT_MESSAGE,
  PENDING_PANEL_ACTION,
} from '../../../../lib/constants'
import { isDevelopment } from '../../../../lib/helpers/envHelpers'
import commercialAgreementService from '../../../../lib/services/commercialAgreementService'
import { hideCommercialAgreementsModal } from '../../../../redux/slices/commercialAgreementsModal'
import { CommercialAgreementReferralDirection } from '../../../../types/misc'
import { PanelItem } from '../../../../types/responses/panels'
import TabTitle from '../../CompanyProfile/components/TabTitle'
import { CommercialAgreementDetailsProps } from '../CommercialAgreementsDetails'
import {
  AGREEMENT_TABS,
  COMMERCIAL_AGREEMENT_TAB_KEY,
  COMMERCIAL_AGREEMENT_TAB_PARAMS,
} from '../constants'
import { isValidCommercialAgreementTabKey } from '../helpers'
import DirectionAgreementDetails from './DirectionAgreementDetails'

type Props = Pick<
  CommercialAgreementDetailsProps,
  'onConfirmAgreement' | 'variant'
> & {
  panel: PanelItem
  direction: CommercialAgreementReferralDirection
}

const DirectionAgreements: React.FC<Props> = ({
  panel,
  variant,
  direction,
  onConfirmAgreement,
}) => {
  const currentCompany = useCurrentCompanyOrFail()
  const otherCompany =
    currentCompany.id === panel.fromCompany.id
      ? panel.toCompany
      : panel.fromCompany
  const currentCompanyName = currentCompany.presentationName
  const otherCompanyName = otherCompany.presentationName
  const [searchParams, setSearchParams] = useSearchParams()
  const dispatch = useAppDispatch()

  const outgoingAgreementTabFromSearchParam = searchParams.get(
    COMMERCIAL_AGREEMENT_TAB_PARAMS.outgoingAgreementTab,
  )
  const incomingAgreementTabFromSearchParam = searchParams.get(
    COMMERCIAL_AGREEMENT_TAB_PARAMS.incomingAgreementTab,
  )

  const isOutgoingDirection =
    commercialAgreementService.isOutgoingAgreementDirection(direction)

  const tabKeyName = isOutgoingDirection
    ? COMMERCIAL_AGREEMENT_TAB_PARAMS.outgoingAgreementTab
    : COMMERCIAL_AGREEMENT_TAB_PARAMS.incomingAgreementTab

  const pendingAgreement = isOutgoingDirection
    ? panel.pendingOutgoingAgreement
    : panel.pendingIncomingAgreement

  function getOutgoingAgreementTab() {
    let outgoingAgreementTab = isValidCommercialAgreementTabKey(
      outgoingAgreementTabFromSearchParam,
    )
      ? outgoingAgreementTabFromSearchParam
      : COMMERCIAL_AGREEMENT_TAB_KEY.current

    if (
      outgoingAgreementTab === COMMERCIAL_AGREEMENT_TAB_KEY.pending &&
      !panel.pendingOutgoingAgreement
    ) {
      outgoingAgreementTab = COMMERCIAL_AGREEMENT_TAB_KEY.current
    }

    return outgoingAgreementTab
  }

  const outgoingAgreementTab = getOutgoingAgreementTab()

  function getIncomingAgreementTab() {
    let incomingAgreementTab = isValidCommercialAgreementTabKey(
      incomingAgreementTabFromSearchParam,
    )
      ? incomingAgreementTabFromSearchParam
      : COMMERCIAL_AGREEMENT_TAB_KEY.current

    if (
      incomingAgreementTab === COMMERCIAL_AGREEMENT_TAB_KEY.pending &&
      !panel.pendingIncomingAgreement
    ) {
      incomingAgreementTab = COMMERCIAL_AGREEMENT_TAB_KEY.current
    }

    return incomingAgreementTab
  }

  const incomingAgreementTab = getIncomingAgreementTab()

  const isModal = variant === 'modal'

  const activeTabKey = isOutgoingDirection
    ? outgoingAgreementTab
    : incomingAgreementTab

  const isShowingCurrentOutgoingAgreement =
    outgoingAgreementTab === COMMERCIAL_AGREEMENT_TAB_KEY.current

  const outgoingAgreement = isShowingCurrentOutgoingAgreement
    ? panel.currentOutgoingAgreement
    : panel.pendingOutgoingAgreement

  const isShowingCurrentIncomingAgreement =
    incomingAgreementTab === COMMERCIAL_AGREEMENT_TAB_KEY.current

  const incomingAgreement = isShowingCurrentIncomingAgreement
    ? panel.currentIncomingAgreement
    : panel.pendingIncomingAgreement

  const agreement = isOutgoingDirection ? outgoingAgreement : incomingAgreement

  const otherAgreement = isOutgoingDirection
    ? incomingAgreement
    : outgoingAgreement

  return (
    <section>
      <TabTitle>
        {isOutgoingDirection ? (
          <>
            Commercial terms for referrals from {currentCompanyName} to{' '}
            {otherCompanyName}
          </>
        ) : (
          <>
            Commercial terms for referrals from {otherCompanyName} to{' '}
            {currentCompanyName}
          </>
        )}
      </TabTitle>

      {pendingAgreement && (
        <div>
          {AGREEMENT_TABS.map((tab) => {
            const isActive = tab.key === activeTabKey
            const isCurrentTab =
              tab.key === COMMERCIAL_AGREEMENT_TAB_KEY.current
            const isPendingTab =
              tab.key === COMMERCIAL_AGREEMENT_TAB_KEY.pending

            function getButtonVariant() {
              if (isActive) {
                return 'btn-secondary'
              }

              return isPendingTab
                ? 'btn-outline-warning'
                : 'btn-outline-secondary'
            }

            function getAgreementId() {
              if (isOutgoingDirection) {
                return isCurrentTab
                  ? panel.currentOutgoingAgreement?.id
                  : panel.pendingOutgoingAgreement?.id
              }

              return isCurrentTab
                ? panel.currentIncomingAgreement?.id
                : panel.pendingIncomingAgreement?.id
            }

            const agreementId = getAgreementId()

            const tooltipTitle =
              isDevelopment() && agreementId
                ? `Agreement ID: ${agreementId}`
                : undefined

            return (
              <div
                key={tab.key}
                title={tooltipTitle}
                className={classNames(
                  'd-inline-flex align-items-center gap-2 btn btn-fluid rounded-0',
                  getButtonVariant(),
                )}
                onClick={() => {
                  searchParams.set(tabKeyName, tab.key)
                  setSearchParams(searchParams)
                }}
              >
                {tab.label}

                {isPendingTab && (
                  <OverlayTrigger
                    overlay={
                      <Tooltip>
                        {panel.pendingActions.includes(
                          PENDING_PANEL_ACTION.reviewPendingAgreement,
                        )
                          ? PANEL_AGREEMENT_MESSAGE.reviewAgreement
                          : PANEL_AGREEMENT_MESSAGE.chaseAgreementReview}
                      </Tooltip>
                    }
                  >
                    <FontAwesomeIcon icon={faExclamationTriangle} />
                  </OverlayTrigger>
                )}
              </div>
            )
          })}
        </div>
      )}

      <div className="mt-3">
        <DirectionAgreementDetails
          panel={panel}
          agreement={agreement}
          otherAgreementNeedsReview={!!otherAgreement?.needsReview}
          referralDirection={direction}
          onAcceptInvite={() => {
            if (isModal) {
              dispatch(hideCommercialAgreementsModal())
            }
          }}
          onConfirmAgreement={async () => {
            if (onConfirmAgreement) {
              await onConfirmAgreement()
            }
          }}
        />
      </div>
    </section>
  )
}

export default DirectionAgreements
