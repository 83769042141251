import classNames from 'classnames'
import React from 'react'

import { useClaimReferralModal } from '../../../misc/ReferralDetails/hooks/useClaimReferralModal'

type Props = Omit<React.HTMLAttributes<HTMLSpanElement>, 'onClick'> & {
  children: React.ReactNode
}

const ClaimAccountButton: React.FC<Props> = ({
  children,
  className,
  ...props
}) => {
  const claimReferralModal = useClaimReferralModal()

  return (
    <span
      onClick={() => {
        claimReferralModal.show()
      }}
      className={classNames('cursor-pointer', className)}
      {...props}
    >
      {children}
    </span>
  )
}

export default ClaimAccountButton
