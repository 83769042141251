import { useMutation } from '@tanstack/react-query'
import classNames from 'classnames'
import React from 'react'
import { ButtonProps } from 'react-bootstrap'
import { useNavigate } from 'react-router'

import useNotyf from '../../hooks/useNotyf'
import { COMMERCIAL_AGREEMENT_REFERRAL_DIRECTION } from '../../lib/constants'
import { getIdFromIri } from '../../lib/helpers/helperFunctions'
import referralService from '../../lib/services/referralService'
import routeService from '../../lib/services/routeService'
import ActionButton, { ActionButtonProps } from './ActionButton'

type Props = ButtonProps & {
  toCompanyIri: IRI
  children: React.ReactNode
  onCanRefer: () => void
  showSpinner?: ActionButtonProps['showSpinner']
  isProcessing?: boolean
}

const ReferClientButton = React.forwardRef<HTMLButtonElement, Props>(
  ({ style, toCompanyIri, onCanRefer, ...props }, ref) => {
    const notyf = useNotyf()
    const navigate = useNavigate()
    const variant = props.variant || 'primary'

    const canReferClientMutation = useMutation({
      mutationFn: () =>
        referralService.checkCanRefer({ toCompany: toCompanyIri }),
      onSuccess: (canReferData) => {
        // We can't refer to adviser
        if (!canReferData.canRefer) {
          notyf.open({
            type: canReferData.missingAgreementDirection ? 'info' : 'error',
            message: canReferData.cannotReferReason!,
          })

          // If missing agreement direction is set, we need to redirect to add
          // panel agreement page
          if (canReferData.missingAgreementDirection) {
            return navigate(
              routeService.addRelationshipAgreement(
                getIdFromIri(canReferData.panel),
                {
                  referralDirection:
                    COMMERCIAL_AGREEMENT_REFERRAL_DIRECTION.OUTGOING,
                },
              ),
            )
          }

          return
        }

        onCanRefer()
      },
      onError: () => {
        notyf.error(
          'There was a problem selecting this adviser. Please refresh the page and try again',
        )
      },
    })

    const isProcessing =
      !!props.isProcessing || canReferClientMutation.isPending
    const shouldBeDisabled = props.disabled || isProcessing

    return (
      <ActionButton
        {...props}
        disabled={shouldBeDisabled}
        isProcessing={isProcessing}
        isProcessingText={props.children}
        onClick={() => canReferClientMutation.mutate()}
        ref={ref}
        variant={variant}
        className={classNames(props.className)}
        style={style}
      />
    )
  },
)

export default ReferClientButton
