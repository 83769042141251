import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@rq-ratings/pro-light-svg-icons'
import React, { useMemo, useRef, useState } from 'react'
import { Button, Form } from 'react-bootstrap'

import { useServiceAreas } from '../../../hooks/useServiceAreas'
import {
  BulkUpdatePayload,
  ReferralsImportItem,
  ReferralsImportItemPartial,
} from '../../../redux/slices/referralsImportBase'
import { HydraResource } from '../../../types/api'
import { ServiceAreaEntity } from '../../../types/entities'
import ServiceLockedWarning from './ServiceLockedWarning'
import ServiceMissingWarning from './ServiceMissingWarning'

interface Props {
  importItem: ReferralsImportItem
  updateImportItem: (item: ReferralsImportItemPartial) => void
  bulkUpdateItems: (payload: BulkUpdatePayload) => void
  bulkUpdateSelectedOnly: boolean
  withExempt?: boolean
}

const ServiceCell: React.FC<Props> = ({
  importItem,
  updateImportItem,
  bulkUpdateItems,
  bulkUpdateSelectedOnly,
  withExempt = false,
}) => {
  const [isEdit, setEdit] = useState(false)
  const selectRef = useRef<HTMLSelectElement | null>(null)
  const isSet = !!importItem.serviceArea
  const allServiceAreas = useServiceAreas()
  const isLocked =
    !importItem.otherCompany && importItem.importDirection == 'TO'

  const serviceCompany = useMemo(() => {
    return importItem.importDirection == 'TO'
      ? importItem.otherCompany
      : importItem.userCompany
  }, [importItem])

  const serviceAreaOptions: SelectableOption[] =
    useMemo((): SelectableOption[] => {
      return serviceCompany
        ? serviceCompany.serviceAreas
            .map((serviceArea) => {
              return {
                label: serviceArea.name,
                value: serviceArea.id,
              }
            })
            .filter(
              (value, index, array) =>
                array.findIndex((a) => a.value == value.value) === index,
            )
        : allServiceAreas.map((serviceArea) => {
            return {
              label: serviceArea.name,
              value: serviceArea.id,
            }
          })
    }, [serviceCompany, allServiceAreas])

  const handleChange = (value: number) => {
    const serviceArea = serviceCompany
      ? serviceCompany.serviceAreas.find(
          (serviceArea) => serviceArea.id === value,
        ) || null
      : allServiceAreas
          .map((serviceArea) => {
            return {
              id: serviceArea.id,
              name: serviceArea.name,
            } as HydraResource<Pick<ServiceAreaEntity, 'id' | 'name'>>
          })
          .find((serviceArea) => serviceArea.id === value) || null
    updateImportItem({
      refUUID: importItem.refUUID,
      serviceArea,
      serviceAreaDefaultUsed: false,
    })
    setEdit(false)
  }

  const handleBulkApply = () => {
    bulkUpdateItems({
      filter: {
        selected: bulkUpdateSelectedOnly,
        importDirection: importItem.importDirection,
        otherCompany:
          importItem.importDirection == 'TO'
            ? importItem.otherCompany?.id
            : undefined,
      },
      data: {
        serviceArea: importItem.serviceArea,
        serviceAreaDefaultUsed: false,
      },
    })
  }

  const Exempt = withExempt ? (
    <>
      <p className="text-muted text-xs mt-1 mb-none">
        Exempt regulated activity:{' '}
        <span className="font-weight-700">
          {importItem.isEraOverride ? 'Yes' : 'no'}
        </span>
      </p>
    </>
  ) : (
    <></>
  )

  const Warning =
    importItem.serviceAreaDefaultUsed && importItem.sourceServiceAreaId ? (
      <ServiceMissingWarning
        importItem={importItem}
        afterBlockElement={withExempt}
      />
    ) : isLocked ? (
      <ServiceLockedWarning afterBlockElement={withExempt} />
    ) : null

  const Name = (
    <>
      {importItem.serviceArea?.name || ''}
      {importItem.serviceAreaDefaultUsed && (
        <>
          <span className="text-warning text-xs"> (default)</span>
        </>
      )}
    </>
  )

  return (
    <>
      {!isEdit && isSet ? (
        <>
          {isLocked ? (
            Name
          ) : (
            <span onClick={() => setEdit(true)} className="cursor-pointer">
              {Name}
            </span>
          )}
          {Exempt}
          {Warning}
          {!isLocked && (
            <span className="show-on-row-hover text-xs">
              {(!withExempt || !!Warning) && ', '}
              <Button
                variant="link"
                size="sm"
                className="text-xs p-0 m-0"
                onClick={() => setEdit(true)}
              >
                change
              </Button>
              <BulkUpdateButton
                bulkUpdateSelectedOnly={bulkUpdateSelectedOnly}
                importItem={importItem}
              >
                {({ title, label }) => (
                  <>
                    {' or '}
                    <Button
                      variant="link"
                      size="sm"
                      className="text-xs p-0 m-0"
                      onClick={handleBulkApply}
                      title={title}
                    >
                      {label}
                    </Button>
                  </>
                )}
              </BulkUpdateButton>
            </span>
          )}
        </>
      ) : (
        <>
          <Form.Select
            ref={selectRef}
            onChange={(event) => handleChange(+event.target.value)}
            onBlur={() => setEdit(false)}
            value={importItem.serviceArea?.id}
          >
            {importItem.serviceArea?.name || <option>Select service...</option>}
            {serviceAreaOptions.map((option) => {
              return (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              )
            })}
          </Form.Select>
          {!importItem.serviceArea && (
            <ServiceMissingWarning importItem={importItem} afterBlockElement />
          )}
          <span className="d-block text-end mt-1">
            <Button
              size="sm"
              variant="outline-primary"
              onClick={() => {
                if (selectRef.current) {
                  handleChange(+selectRef.current.value)
                }
              }}
            >
              <FontAwesomeIcon icon={faCheck} />
            </Button>
          </span>
        </>
      )}
    </>
  )
}

function BulkUpdateButton({
  importItem,
  bulkUpdateSelectedOnly,
  children,
}: {
  importItem: ReferralsImportItem
  bulkUpdateSelectedOnly: boolean
  children: ({
    title,
    label,
  }: {
    title: string
    label: React.ReactNode
  }) => React.ReactNode
}) {
  const bulkUpdateScope =
    importItem.bulkCompanyCount > 1
      ? !bulkUpdateSelectedOnly
        ? 'all'
        : importItem.selected
          ? 'selected'
          : 'none'
      : 'none'
  if (bulkUpdateScope == 'all' && importItem.importDirection == 'TO') {
    return children({
      title: 'Apply this service for all referrals for this company',
      label: (
        <>
          apply&nbsp;to&nbsp;all&nbsp;for&nbsp;this&nbsp;company&nbsp;(
          {importItem.bulkCompanyCount})
        </>
      ),
    })
  } else if (bulkUpdateScope == 'all' && importItem.importDirection == 'FROM') {
    return children({
      title: 'Apply this service for all received referrals',
      label: (
        <>
          apply&nbsp;to&nbsp;all&nbsp;received&nbsp;referrals&nbsp;(
          {importItem.bulkCompanyCount})
        </>
      ),
    })
  } else if (
    bulkUpdateScope == 'selected' &&
    importItem.importDirection == 'TO'
  ) {
    return children({
      title: 'Apply this service for selected referrals for this company',
      label: <>apply&nbsp;to&nbsp;selected&nbsp;for&nbsp;this&nbsp;company</>,
    })
  } else if (
    bulkUpdateScope == 'selected' &&
    importItem.importDirection == 'FROM'
  ) {
    return children({
      title: 'Apply this service for selected received referrals',
      label: <>apply&nbsp;to&nbsp;selected&nbsp;received&nbsp;referrals</>,
    })
  } else {
    return <></>
  }
}

interface SelectableOption {
  label: string
  value: number
}
export default ServiceCell
