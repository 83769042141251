import './NylasScheduler.scss'

import {
  isNylasSuccessResponse,
  NylasSchedulerBookingParticipant,
  ThemeConfig,
} from '@nylas/core'
import { NylasScheduling } from '@nylas/react'
import { NylasEvent } from '@nylas/web-elements'
import React from 'react'

import { currentPath } from '../../../lib/helpers/routeHelpers'
import sentryService from '../../../lib/services/sentryService'
import NylasSchedulerError from './NylasSchedulerError'

interface Props {
  schedulerConfigId: string
  className?: string
  primaryParticipant: NylasSchedulerBookingParticipant
  guests: NylasSchedulerBookingParticipant[]
  onBookingComplete: (completedBooking: BookingDetails) => void
}

export interface BookingDetails {
  startTime: Date
  endTime: Date
  /**
   * The adviser the meeting was booked with (could be selected via round-robin process)
   */
  bookedWith: NylasSchedulerBookingParticipant
  clientDetails: NylasSchedulerBookingParticipant
  guests: NylasSchedulerBookingParticipant[]
}

const NylasScheduler: React.FC<Props> = ({
  schedulerConfigId,
  className,
  primaryParticipant,
  guests,
  onBookingComplete,
}) => {
  if (!schedulerConfigId) {
    return <NylasSchedulerError />
  }

  // This is a workaround for the fact that the NylasScheduler doesn't respect
  // updated props. Changing the `key` forces a re-render of that component.
  // The downside is a flicker as it re-renders, but we'll have to live with it.
  const key = [
    schedulerConfigId,
    primaryParticipant.email,
    primaryParticipant.name,
  ].join('-')

  return (
    // @see https://tinyurl.com/nylas-react
    <NylasScheduling
      key={key}
      configurationId={schedulerConfigId}
      mode="app"
      schedulerApiUrl="https://api.eu.nylas.com"
      bookingInfo={{ primaryParticipant, guests }}
      themeConfig={THEME_CONFIG}
      onNylasSchedulerError={(error) => {
        sentryService.captureMessage({
          message: `Nylas scheduler error on page: ${currentPath()}`,
          extra: {
            clientDetails: primaryParticipant,
            error: error.detail.notification,
          },
        })
      }}
      className={className}
      nylasBranding={false}
      enableUserFeedback={false}
      defaultSchedulerState={{ showBookingForm: false }}
      // @ts-expect-error: Nylas types are incorrect
      localization={{
        en: {
          // We skip Nylas' default confirmation step so change the default
          // button text from 'Next' to 'Schedule meeting'
          nextButton: 'Schedule meeting',
        },
      }}
      eventOverrides={{
        timeslotConfirmed: async (event, connector) => {
          event.preventDefault()

          if (!connector) {
            sentryService.captureMessage({
              message: 'Expected connector to be defined',
            })

            return
          }

          const response = await connector.scheduler.bookTimeslot({
            primaryParticipant,
            guests,
          })

          if (isNylasSuccessResponse(response)) {
            const booking: NylasEvent = response.data

            onBookingComplete({
              startTime: event.detail.start_time,
              endTime: event.detail.end_time,
              bookedWith: {
                name: booking.organizer.name,
                email: booking.organizer.email,
              },
              clientDetails: primaryParticipant,
              guests,
            })

            return
          }

          sentryService.captureMessage({
            message: 'Error booking Nylas timeslot',
            extra: {
              error: response.error,
            },
          })
        },
      }}
    />
  )
}

// Make the scheduler look as RQ-like as possible
const THEME_CONFIG: ThemeConfig = {
  '--nylas-primary': 'var(--bs-primary)',
  '--nylas-info': 'var(--bs-info)',
  '--nylas-success': 'var(--bs-success)',
  '--nylas-warning': 'var(--bs-warning)',
  '--nylas-error': 'var(--bs-error)',
  '--nylas-base-100': 'var(--bs-gray-100)',
  '--nylas-base-200': 'var(--bs-gray-200)',
  '--nylas-base-300': 'var(--bs-gray-300)',
  '--nylas-base-400': 'var(--bs-gray-400)',
  '--nylas-base-500': 'var(--bs-gray-500)',
  '--nylas-base-600': 'var(--bs-gray-600)',
  '--nylas-base-700': 'var(--bs-gray-700)',
  '--nylas-base-800': 'var(--bs-gray-800)',
  '--nylas-base-900': 'var(--bs-gray-900)',
  '--nylas-font-family': 'var(--bs-body-font-family)',
  '--nylas-font-size': 'var(--bs-body-font-size)',
  '--nylas-border-radius': 'calc(var(--bs-border-radius) * .5)',
  '--nylas-border-radius-2x': 'calc(var(--bs-border-radius) * 1)',
  '--nylas-border-radius-3x': 'calc(var(--bs-border-radius) * 1.5)',
}

export default NylasScheduler
