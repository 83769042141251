import {
  COMMERCIAL_AGREEMENT_DIRECTION,
  COMMERCIAL_AGREEMENT_TYPE_OPTION,
} from '../../components/misc/CommercialAgreementsForm/constants'
import {
  CommercialAgreementTypeOption,
  ServiceFeeInput,
} from '../../redux/slices/commercialAgreementsForm'
import {
  CommercialAgreementDirectionType,
  CommercialAgreementReferralDirection,
  CommercialAgreementType,
  FormErrors,
} from '../../types/misc'
import {
  ConfirmCommercialAgreementRequest,
  CreateCommercialAgreementMetadataRequest,
  CreateCommercialAgreementRequest,
  CreateExampleCommercialAgreementTextRequest,
  UpdateCommercialAgreementRequest,
  ValidateCommercialAgreementRequest,
} from '../../types/requests/commercial-agreements'
import {
  CommercialAgreementItem,
  CommercialAgreementsMetadataItem,
  CreateExampleCommercialAgreementResponse,
} from '../../types/responses/commercial-agreements'
import {
  COMMERCIAL_AGREEMENT_REFERRAL_DIRECTION,
  COMMERCIAL_AGREEMENT_TYPE,
} from '../constants'
import apiService from './apiService'
import serviceFeeService, {
  SERVICE_AREA_OPTION_OTHER,
} from './serviceFeeService'

class CommercialAgreementService {
  private endpoint = '/v1/commercial-agreements'

  async getAgreement(agreementId: number): Promise<CommercialAgreementItem> {
    const response = await apiService.get(`${this.endpoint}/${agreementId}`)
    return response.data
  }

  async createCommercialAgreement(
    request: CreateCommercialAgreementRequest,
  ): Promise<unknown> {
    request.serviceFees = request.serviceFees.map((serviceFee) =>
      serviceFeeService.normalizeServiceFee(serviceFee),
    )
    const response = await apiService.post(this.endpoint, request)

    return response.data
  }

  hasCommercialAgreement(
    agreementType?: CommercialAgreementTypeOption,
  ): agreementType is CommercialAgreementType {
    return (
      agreementType === COMMERCIAL_AGREEMENT_TYPE_OPTION.YFS ||
      agreementType === COMMERCIAL_AGREEMENT_TYPE_OPTION.YJV ||
      agreementType === COMMERCIAL_AGREEMENT_TYPE_OPTION.RQP
    )
  }

  async confirmAgreement(
    agreementId: number,
    request?: ConfirmCommercialAgreementRequest,
  ) {
    const response = await apiService.post(
      `${this.endpoint}/${agreementId}/confirm`,
      request || {},
    )
    return response.data
  }

  async createExampleAgreementText(
    request: CreateExampleCommercialAgreementTextRequest,
  ): Promise<CreateExampleCommercialAgreementResponse> {
    const response = await apiService.post(`${this.endpoint}/example`, {
      ...request,
      serviceFees: this.normalizeServiceFees(request.serviceFees),
    })
    return response.data
  }

  normalizeServiceFees(
    serviceFees?: ServiceFeeInput[],
  ): ServiceFeeInput[] | undefined {
    if (!serviceFees) {
      return serviceFees
    }

    return serviceFees.map((serviceFee) => {
      // If the 'Other' option was selected, don't pass `serviceArea` field
      // otherwise the API will error
      if (serviceFee.serviceArea === SERVICE_AREA_OPTION_OTHER) {
        return { ...serviceFee, serviceArea: undefined }
      }

      return serviceFee
    })
  }

  /*
   * @deprecated This method will be removed soon as part of RQ-1284
   */
  async requestAmendmentFromRq(agreementId: number, text: string) {
    const response = await apiService.post(
      `${this.endpoint}/${agreementId}/update`,
      { text },
    )

    return response.data
  }

  async updateAgreement(
    agreementId: number,
    request: UpdateCommercialAgreementRequest,
  ) {
    request.serviceFees = request.serviceFees.map((serviceFee) =>
      serviceFeeService.normalizeServiceFee(serviceFee),
    )

    const response = await apiService.patch(
      `${this.endpoint}/${agreementId}`,
      request,
    )

    return response.data
  }

  async deletePendingAgreement(agreementId: number) {
    const response = await apiService.delete(`${this.endpoint}/${agreementId}`)

    return response.data
  }

  async createMetadata(
    request: CreateCommercialAgreementMetadataRequest,
  ): Promise<CommercialAgreementsMetadataItem> {
    const response = await apiService.post(`${this.endpoint}/metadata`, request)

    return response.data
  }

  async validateAgreement(values: ValidateCommercialAgreementRequest) {
    const response = await apiService.post(`${this.endpoint}/validate`, values)

    return response.data
  }

  async sendReviewESignatureLetterReminder(
    commercialAgreementId: number,
  ): Promise<unknown> {
    const response = await apiService.post(
      `${this.endpoint}/${commercialAgreementId}/reminder/review-esignature-letter`,
      {},
    )

    return response.data
  }

  isOutgoingAgreementDirection(
    referralDirection: CommercialAgreementReferralDirection,
  ): boolean {
    return (
      referralDirection === COMMERCIAL_AGREEMENT_REFERRAL_DIRECTION.OUTGOING
    )
  }

  isIncomingAgreementDirection(
    referralDirection: CommercialAgreementReferralDirection,
  ): boolean {
    return (
      referralDirection === COMMERCIAL_AGREEMENT_REFERRAL_DIRECTION.INCOMING
    )
  }

  shouldShowExampleAgreementText(
    agreementType: CommercialAgreementTypeOption | undefined,
  ): boolean {
    if (!agreementType) {
      return false
    }

    if (agreementType === COMMERCIAL_AGREEMENT_TYPE.YFS) {
      return true
    }

    if (agreementType === COMMERCIAL_AGREEMENT_TYPE.YJV) {
      return true
    }

    return true
  }

  isValidReferralDirection(
    value: string,
  ): value is CommercialAgreementReferralDirection {
    return (
      value === COMMERCIAL_AGREEMENT_REFERRAL_DIRECTION.OUTGOING ||
      value === COMMERCIAL_AGREEMENT_REFERRAL_DIRECTION.INCOMING
    )
  }

  isValidCommercialAgreementType(
    value: unknown,
  ): value is CommercialAgreementType {
    return (
      typeof value === 'string' && !!value && value in COMMERCIAL_AGREEMENT_TYPE
    )
  }

  getAgreementTypeLabel(
    commercialAgreementType: CommercialAgreementType | undefined,
  ) {
    const labels: Record<CommercialAgreementType, string> = {
      YFS: 'Fee sharing',
      YJV: 'Joint Venture',
      RQP: 'RQ Partnership',
      NO: 'No commercial agreement in place',
    }

    return commercialAgreementType && labels[commercialAgreementType]
      ? labels[commercialAgreementType]
      : labels.NO
  }

  isFeeSharingAgreement(
    agreementType?: string | null,
  ): agreementType is CommercialAgreementType {
    return agreementType === COMMERCIAL_AGREEMENT_TYPE.YFS
  }

  isJointVentureAgreement(
    agreementType?: string,
  ): agreementType is CommercialAgreementType {
    return agreementType === COMMERCIAL_AGREEMENT_TYPE.YJV
  }

  isRqPartnershipAgreement(
    agreementType?: string | null,
  ): agreementType is CommercialAgreementType {
    return agreementType === COMMERCIAL_AGREEMENT_TYPE.RQP
  }

  isValidCommercialAgreementDirectionType(
    value?: string | null,
  ): value is CommercialAgreementDirectionType {
    const validValues: string[] = Object.values(COMMERCIAL_AGREEMENT_DIRECTION)

    return !!value && validValues.includes(value)
  }

  shouldShowFeePassBackQuestions(options: {
    agreementType?: CommercialAgreementTypeOption
    referralDirection: CommercialAgreementReferralDirection
  }) {
    return (
      this.isFeeSharingAgreement(options.agreementType) &&
      this.isOutgoingAgreementDirection(options.referralDirection)
    )
  }

  shouldAskForServiceFees(
    agreementType: CommercialAgreementTypeOption | undefined,
  ) {
    return (
      this.isFeeSharingAgreement(agreementType) ||
      this.isRqPartnershipAgreement(agreementType)
    )
  }

  shouldShowServiceFees(
    agreementType: CommercialAgreementTypeOption | undefined,
  ) {
    return this.isFeeSharingAgreement(agreementType)
  }

  hasServiceFeeErrorsOnly(formErrors: FormErrors): boolean {
    const errorFields = Object.keys(formErrors)

    return errorFields.every((errorField) =>
      /^serviceFees\[\d+]/.test(errorField),
    )
  }
}

const commercialAgreementService = new CommercialAgreementService()

export default commercialAgreementService
