import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTriangleExclamation } from '@rq-ratings/pro-solid-svg-icons'
import React from 'react'

interface Props {
  afterBlockElement?: boolean
}

const ServiceLockedWarning: React.FC<Props> = ({
  afterBlockElement = false,
}) => {
  return (
    <>
      {!afterBlockElement && (
        <>
          ,<br />
        </>
      )}
      <span className="text-muted text-xs">
        <FontAwesomeIcon icon={faTriangleExclamation} className="me-1" />
        "General Enquiries" is default service when we refer to somebody who is
        not yet in RQ
      </span>
    </>
  )
}

export default ServiceLockedWarning
