import { useSearchParams } from 'react-router'

import {
  WALKTHROUGH_PAGE_SEARCH_PARAMS,
  WALKTHROUGH_STEP_KEYS,
  WALKTHROUGH_STEPS,
  WALKTHROUGH_STEPS_LIST,
  WalkthroughStepDetail,
  WalkthroughStepKey,
} from '../constants'

export function useWalkthroughSteps() {
  const [searchParams, setSearchParams] = useSearchParams()

  const currentStepFromSearchParams =
    searchParams.get(WALKTHROUGH_PAGE_SEARCH_PARAMS.step) || ''

  const currentStep = isValidWalkthroughStep(currentStepFromSearchParams)
    ? WALKTHROUGH_STEPS[currentStepFromSearchParams]
    : (WALKTHROUGH_STEPS_LIST[0] as WalkthroughStepDetail)

  const currentStepIndex = WALKTHROUGH_STEPS_LIST.findIndex(
    (step) => step.key === currentStep.key,
  )

  const nextStep = WALKTHROUGH_STEPS_LIST[currentStepIndex + 1]
  const previousStep = WALKTHROUGH_STEPS_LIST[currentStepIndex - 1]
  const canGoToNextStep = !!nextStep
  const canGoToPreviousStep = !!previousStep

  function goToStep(step: WalkthroughStepKey) {
    searchParams.set(WALKTHROUGH_PAGE_SEARCH_PARAMS.step, step)
    setSearchParams(searchParams)
  }

  function goToNextStep() {
    if (!canGoToNextStep) {
      throw new Error('Cannot go to next step')
    }

    goToStep(nextStep.key)
  }

  function goToPreviousStep() {
    if (!canGoToPreviousStep) {
      throw new Error('Cannot go to previous step')
    }

    goToStep(previousStep.key)
  }

  return {
    currentStep,
    currentStepIndex,
    nextStep,
    previousStep,
    goToStep,
    goToNextStep,
    goToPreviousStep,
    canGoToNextStep,
    canGoToPreviousStep,
  }
}

function isValidWalkthroughStep(step: string): step is WalkthroughStepKey {
  return WALKTHROUGH_STEP_KEYS.some((validStep) => validStep === step)
}
