import React from 'react'

import ImageWithText from '../../components/ImageWithText'
import screenshotUrl from './screenshot.png'

const ReferralEngineScreen2: React.FC = () => (
  <ImageWithText
    imageUrl={screenshotUrl}
    text={
      <p>
        Referring your client is seamless and ensures compliance with regulatory
        rules when referring for regulated activities, such as investment
        advice. Click <strong>'Refer your client'</strong>, select which firm or
        adviser you want to refer to and complete the short referral journey.
      </p>
    }
  />
)

export default ReferralEngineScreen2
